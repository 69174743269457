import {Component} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../../../core/services/user.service";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: "user-password-dialog",
	templateUrl: "./user-password-dialog.html"
})
export class UserPasswordDialog {

	form: FormGroup;
	constructor(
		private ref: MatDialogRef<UserPasswordDialog>,
		private fb: FormBuilder,
		private userService: UserService,
		private error: ErrorsDialog
	) {
		this.form = fb.group({
			oldPassword: ["", Validators.required],
			password1: ["", Validators.required],
			password2: ["", Validators.required]
		});
	}

	ok() {
		this.userService.updatePassword(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		});
	}

	cancel() {
		this.ref.close(false);
	}

}