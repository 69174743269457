import {Component, forwardRef, Input} from "@angular/core";
import {IniInput} from "./ini.input";
import {AutocompleteNamesService} from "./autocomplete-names.service";
import {Observable} from "rxjs";
import {NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
	selector: "user-input",
	templateUrl: "./user-input.html",
	providers:[{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => UserInput),
		multi: true
	}]
})
export class UserInput extends IniInput { /**/

	@Input("placeholder") override placeholder = "";
	@Input("type") override type = "old";
	@Input("lang") override lang: string = "ka";

	constructor(
		private service: AutocompleteNamesService
	) {
		super();
	}

	override search(q: string): Observable<any[]> {

		if (this.type == "old") {
			return this.service.getUsers(q);
		} else {
			return this.service.getUsers(q);
		}
	}

}
