import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {
	AbstractControl,
	ControlValueAccessor,
	FormBuilder,
	FormGroup,
	NG_VALUE_ACCESSOR,
	Validators
} from "@angular/forms";

@Component({
	selector: "time-picker",
	templateUrl: "./time-picker.html",
	providers:[{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => TimePicker),
		multi: true
	}]
})
export class TimePicker implements ControlValueAccessor, OnInit {

	form: FormGroup;
	@Input("placeholder") placeholder = "";
	public onChange: (value: any) => void;

	constructor(
		private fb: FormBuilder
	) {
		this.form = fb.group({
			h: ["", [Validators.required, TimePicker.checkHour]],
			m: ["", [Validators.required, TimePicker.checkMinute]]
		});

	}

	static checkMinute(control: AbstractControl) {
		if (control.pristine) {
			return null;
		}
		let value: any = control.value;
		if (typeof value == "string") {
			value = parseInt(value);
		}
		if (value <0 || value > 59) {
			return {
				minuteFormatError: true
			};
		}
		if ((value + "").indexOf(".") != -1 ) {
			return {
				allowedOnlyNumbers: true
			};
		}
		return null;
	}

	static checkHour(control: AbstractControl) {
		if (control.pristine) {
			return null;
		}
		let value: any = control.value;
		if (typeof value == "string") {
			value = parseInt(value);
		}
		if (value <0 || value > 23) {
			return {
				hourFormatError: true
			};
		}
		if ((value + "").indexOf(".") != -1 ) {
			return {
				allowedOnlyNumbers: true
			};
		}
		return null;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
	}

	writeValue(obj: any): void {
		let changed = false;
		if (typeof obj == "string") {

			let val = obj;
			if (val.indexOf(" ") != -1) {
				val = val.split(" ")[0];
			}
			if (val.indexOf(":") != -1) {
				changed = true;
				let sp: any[] = val.split(":");
				try {
					if (obj.indexOf(" ") != -1 && obj.split(" ")[1].toLowerCase() == "pm") {
						sp[0] = parseInt(sp[0])+12;
					}
				} catch (ex) {}

				this.form.patchValue({
					h: this.normalize(sp[0]),
					m: this.normalize(sp[1])
				});
			}
		}
		if (!changed) {
			this.form.patchValue({
				h: "",
				m: ""
			});
		}

	}

	normalize(n) {
		if (n<10) {
			return "0"+parseInt(n);
		}
		return n;
	}

	change(value) {
		try {
			this.onChange(value);
		} catch (ex) {}
	}

	ngOnInit(): void {
		this.form.valueChanges.subscribe(value => {
			if (this.form.valid) {
				this.change(this.normalize(value.h) + ":" + this.normalize(value.m));
			} else {
				this.change("");
			}
		});
	}


}