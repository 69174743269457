import {Component} from "@angular/core";

@Component({
    selector: "component-tabs",
    templateUrl: "./components.tabs.html",
    styleUrls: ["./components.tabs.scss"]
})
export class ComponentsTabs {


    tabs = [
        {id: "list", name: "კომპონენტები" },
    ];

    constructor(

    ) {

    }


}
