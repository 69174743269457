import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../../../../../shared/error-dialogs/errors.dialog";
import {RegistrationService} from "../../../../../../registration.service";
import {UniConfig} from "../../../../../../../../../uni.config";
import {PermissionService} from "src/app/core/services/permission.service";
import {UserService} from "src/app/core/services/user.service";

@Component({
	selector: "praqtika-dialog",
	templateUrl: "./praqtika-dialog.html"
})
export class PraqtikaDialog implements OnInit {

	form: FormGroup;
	isProfTerm = UniConfig.profTerm == 2;
	subBooks: any[] = [];
	mode = 1;

	constructor(
		private ref: MatDialogRef<PraqtikaDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private error: ErrorsDialog,
		private fb: FormBuilder,
		private registrationService: RegistrationService,
		private dialog: MatDialog,
    private permissionService: PermissionService,
		private userService : UserService
	) {

		this.form = fb.group({
			id: "",
			bookId: "",
			groupId: "",
			type: ["", Validators.required],
			prof: "",
			profId: ["",Validators.required],
			profName: "",
			tema: ["",Validators.required],
			limit: "",
			confirm: "",
			listId: "",
      auditories : 1
		});
	}

	ok() {
		this.registrationService.addOrEditBookGroup(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		});
	}

	cancel() {
		this.ref.close(false);
	}

	ngOnInit(): void {

		this.form.patchValue({
			confirm: false
		});
		if(this.data.type == 'nashromi'){
      this.form.patchValue({
        limit: 1
      });
		}
		this.form.patchValue(this.data);
		if (this.data != null && typeof this.data.profId == "object") {
			this.form.patchValue({
				prof: {
					id: this.data.profId,
					name: this.data.profName
				}
			});
		}
		this.form.get("prof").valueChanges.subscribe(value => {
			if (typeof value == "object" && value != null && typeof value.id != "undefined") {
				this.form.patchValue({
					profId: value.id,
					profName: value.name
				});
			} else {
				this.form.patchValue({
					profId: "",
					profName: ""
				});
			}
		});
		if(
				!this.permissionService.can('menu.academicRegistration.groups.books.lectureSeminars.sabPraktSearchProfList') ||
				this.data.profNotIsMatser
		) {
      this.form.patchValue({
        profId: this.userService.user.id,
        profName: this.userService.user.name
      });
    }

	}

}