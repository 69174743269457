import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {StudentsService} from "../students.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";

@Component({
	selector: 'student-edit-log-dialog',
	templateUrl: './student-edit-log.dialog.html'
})

export class StudentEditLogDialog implements OnInit, AfterViewInit {

	items = [];
	studentId = 0;
	total = 0;
	page = 1;
	limit = 10;
	displayedColumns = [
		'id',
		'message'
	];

	constructor(
		public service: StudentsService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {

	}

	@ViewChild("paginator", {static: false}) paginator: MatPaginator;
	ngAfterViewInit(): void {
		this.paginator.page.subscribe(res => {
			this.page = res.pageIndex + 1;
			this.limit = res.pageSize;
			this.getItems();
		});
	}


	ngOnInit(): void {
		this.getItems();
	}

	getItems(): void {
		this.studentId = this.data.id;
		this.service.getStudentEditLogs({studentId: this.studentId}, this.page, this.limit)
			.subscribe(response => {
				this.items = response.data;
				this.total = response.total;
			});
	}

}