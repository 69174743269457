import {Pipe} from "@angular/core";
import moment from "moment";

@Pipe({
  name: "amMessageTimeAgo"
})
export class AmMessageTimeAgoPipe {

    transform(value: Date | moment.Moment): any {
      if (moment().diff(value, "minute") > 30) {
        return moment(value).fromNow();
      }
      return '';
    }

}
