import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Authorization', 'Bearer ' + localStorage.getItem("token"));

		const masterToken = localStorage.getItem("masterToken");
		headers = headers.append('X-Master-Token', masterToken != null ? masterToken : '');

		const copiedReq = req.clone(
			{headers: headers}
		);

		//const copiedReq = req.clone({params: req.params.set('auth', this.user.token)});
		return next.handle(copiedReq);
	}
}
