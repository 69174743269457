import {AfterViewInit, Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";
import {RegistrationService} from "../../registration.service";
import {map, startWith} from 'rxjs/operators';
import {Observable} from "rxjs";
import {SmsService} from "../../../../core/services/sms.service";
import {Router} from "@angular/router";

@Component({
  selector: "sendSms-dialog",
  templateUrl: "./sendSms-dialog.html"
})
export class SendSmsDialog implements OnInit, AfterViewInit {

  form: FormGroup;

  templateList: any = [];
  filteredOptions: Observable<any[]>;

  constructor(
      private ref: MatDialogRef<SendSmsDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private error: ErrorsDialog,
      private fb: FormBuilder,
      private registrationService: RegistrationService,
      private smsService: SmsService,
      private dialog: MatDialog,
      private router : Router
  ) {

    this.form = fb.group({
      bookGroupId: ["",Validators.required],
      groupId: ["",Validators.required],
      template: "",
      userType: ["student",Validators.required],
      text: ["",Validators.required],
      route:""
    });
  }


  ok() {
    this.registrationService.sendSms(this.form.value).subscribe(response => {
      if(response.result == 'yes'){
        this.cancel();
      }else{
        this.error.show(response)
      }
    })
  }

  cancel() {
    this.ref.close(false);
  }

  ngOnInit(): void {
    this.form.patchValue({
      groupId: this.data.groupId,
      bookGroupId: this.data.id,
      route: this.router.url
    });
    this.form.get('template').valueChanges.subscribe(value => {
      this.setTemplateText(value);
    })
    this.getSmsTemplates();
  }

  ngAfterViewInit(){

  }

  private _filter(value: string): string[] {
    if(value == ""){
      return this.templateList
    }else {
      return this.templateList.filter(option => option.name.indexOf(value) > -1);
    }
  }

  onlyOne = true;
  getSmsTemplates()
  {
    this.smsService.getSmsTemplates()
        .subscribe(result => {

          this.templateList = result;
          if(this.onlyOne == true){
            this.filteredOptions = this.form.get('template').valueChanges
                .pipe(
                    startWith(''),
                    map(value => this._filter(value))
                );
            this.onlyOne = false;
          }
          // this.filteredOptions = this.auditoriesList;
        })
  }

  displayFn(template)
  {

    let displayName = template != "" ? template.name : "";;
    return displayName;
  }

  setTemplateText(data)
  {
      this.form.patchValue({
        text: data.description
      })
  }



}