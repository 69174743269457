import {Component, ElementRef, ViewChild} from "@angular/core";
import {ChatComponent} from "../chat/chat.component";
import {ChatService} from "../../services/chat-service";
import {FormBuilder} from "@angular/forms";
import {UserService} from "../../services/user.service";
import {ChatUser} from "../../interfaces/ChatUser";
import {debounceTime} from 'rxjs/operators';
import {MatDialog} from "@angular/material/dialog";
import {ChatUserType} from "../../interfaces/ChatUserType";

@Component({
	selector: "new-chat-component",
	templateUrl: "./new-chat.component.html",
	styleUrls: [
		"../chat/chat.component.scss",
		"./new-chat.component.scss"
	]
})
export class NewChatComponent extends ChatComponent {

	users: ChatUser[] = [];
	@ViewChild('chatHeaderPanel') chatHeaderPanel: ElementRef;

	constructor(
		public override chatService: ChatService,
		public override fb: FormBuilder,
		public override userService: UserService,
		public override dialog: MatDialog
	) {
		super(chatService, fb, userService, dialog);
	}

	override addToListener() {
		this.form.get('to')?.valueChanges
			.pipe(debounceTime(400))
			.subscribe((value) => this.searchUsers(value));
	}

	override searchUsers(value: string) {
		this.subs.push(
			this.chatService.searchUsers(value).subscribe(users => this.users = users)
		);
	}

	override calculateHeight() {
		setTimeout(() => {
			if (this.isMobile) {
				this.height = window.innerHeight - 10 - this.chatHeaderPanel.nativeElement.offsetHeight - (this.members.length > 0 ? 40 : 0);
			} else {
				this.height = (435 - this.chatHeaderPanel.nativeElement.offsetHeight) - (this.members.length > 0 ? 40 : 0);
			}
		}, 100);
	}

	override userTypeTitle(type) {
		switch (type) {
			case ChatUserType.USER:
				return 'ადმინისტრაცია';
			case ChatUserType.PROFESSOR:
				return 'პროფესორი';
			default:
				return 'სტუდენტი';
		}
	}

	override ngAfterContentInit() {

	}
}
