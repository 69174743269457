import {Component, EventEmitter, Output} from "@angular/core";
import {ChatService} from "../../services/chat-service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {SearchMode} from "../../interfaces/SearchMode";

@Component({
	selector: 'ini-chat-user-search',
	templateUrl: "user-search.component.html",
	styleUrls: ["./user-search.component.scss"]
})
export class UserSearchComponent {

	mode: SearchMode = SearchMode.INACTIVE;
	form: FormGroup;
	input: boolean = false;
	@Output() onSearch: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private chatService: ChatService,
		private fb: FormBuilder
	) {
		this.form = fb.group({
			search: ['']
		});
		this.chatService.sideBarMode = this.mode;
		this.form.get('search')?.valueChanges.subscribe(value => this.mode == SearchMode.ACTIVE  && this.onSearch.emit(value));
		setTimeout(() => {
			this.input = true;
		}, 2000);
	}

	setActiveMode() {
		this.mode = SearchMode.ACTIVE;
		this.chatService.sideBarMode = this.mode;
		//this.chatService.hideRooms();
	}

	setInactiveMode() {
		this.mode = SearchMode.INACTIVE;
		this.chatService.sideBarMode = this.mode;
		this.form.patchValue({search: ''});
		//this.chatService.showRooms();
	}

}


