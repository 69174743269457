import {Injectable} from "@angular/core";
import {ChatUser} from "../interfaces/ChatUser";
import {UserSetting} from "../interfaces/UserSetting";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class UserService {

	user: ChatUser;

	constructor(
		private http: HttpClient
	) {
	}

	setUser(user: ChatUser) {
		this.user = user;
	}

	getToken() {
		return localStorage.getItem('token') || localStorage.getItem('Student-Token');
	}

	getUserSetting(): Observable<UserSetting> {
		return this.http.get<UserSetting>('chat://get-user-settings');
	}

}
