import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BooksService} from "../../books.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";

@Component({
    selector: "book-condition-group-dialog",
    templateUrl: "./book-condition-group.dialog.html"
})
export class BookConditionGroupDialog implements OnInit {

    form: FormGroup;
    constructor(
        private service: BooksService,
        private ref: MatDialogRef<BookConditionGroupDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private error: ErrorsDialog
    ) {
        this.form = fb.group({
            id: "",
            type: "",
            bookId: "",
            name: ["", Validators.required],
            limit: ""
        });
    }

    cancel() {
        this.ref.close(false);
    }

    ok() {
        this.service.addOrEditConditionGroup(this.form.value).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(true);
            } else {
                this.error.show(response);
            }
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            id: this.data.id,
            type: this.data.type,
            bookId: this.data.bookId,
            name: this.data.name,
            limit: this.data.limit
        });
    }
}
