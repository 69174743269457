import {Student} from "../../../students.models";
import {Program} from "../../../../structure/specials/program/program";

export class EducationInfo {
    id: number;
    studentId: number;
    schoolId: number;
    specId: number;
    level: number;
    programId: number;
    status: string;
    method: number;
    learningYear: string;
    learningSeason: string;
    special = new Specials;
    school = new  School;
    mobileYear: string;
    student_id_key:number;
    student_id_key2:number;

    licenseFile: string;
    personalFile: string;
    photo: string;
    militaryFile: string;
    learningFile: string;
    commandStartFile: string;
    commandStopFile: string;
	insurance?: string;
	insuranceStatus?: string;

    specialChange: number;
    langChange: number;
    choose: number;

    lang: string;
    score: number;
    langLevel: number;
    semester: number;
    grant: number;
    medalist: string;

    name: string;
    programs: any;

    altSpecialName: string;
    altSchoolName: string;
	additionalStatus: string;

    static statuses() {
        const statuses = {};
        for (const status of Student.eduStatuses()) {
            statuses[status.id] = status.name;
        }
        return statuses;
    }

    static methodNames() {
        let o: {[id: number] : string} = {};
        for (let method of EducationInfo.methods()) {
            o[method.id] = method.name;
        }
        return o;
    }

    static methods() {
        return [
            {id: 0, name: "ერთიანი ეროვნული გამ."},
            {id: 1, name: "სხვა"},
            {id: 2, name: "გარე მობილობა"},
            {id: 3, name: "შიდა მობილობა"},
            {id: 4, name: "უცხოეთში მიღებული განათლების აღიარება"}
        ];
    }
}
export class EducationInfoResponse {
    result: string;
    data: EducationInfo[];
    error: string;
    errors: string;
}
export class EducationFromData {
    mode: string;
    data: EducationInfo;
}
export class EducationInfoForm {
    constructor(
        public id = 0,
        public studentId = 0,
        public schoolId = 0,
        public specId = 0,
        public level = 0,
        public status = "",
        public method = "",
        public learningYear = "",
        public learningSeason = ""
    ) {

    }
}

export class School {
    id: number;
    name: string;
}
export class Specials {
    id: number;
    name: string;
    schoolId: number;
}
export class EducationInfoMetaResponse {
    result: string;
    specials: Specials[];
    schools: School[];
    programs: Program[];
	finances: any[];
	regions: any[];
	country: any[];
	studentGroups: any[];
	learningYears: any[];
}
export class EducationInfoViewResponse {
    data: EducationInfo;
}
export class LangCode {
    id: number;
    name: string;
}

export class LangCodesResponse {
    result: string;
    data: LangCode[];
}
