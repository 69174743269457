import {Component, ViewEncapsulation} from "@angular/core";
import {ActivatedRouteSnapshot, NavigationEnd, Router} from "@angular/router";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "structure-component",
    templateUrl: "./structure.component.html",
    styleUrls: ["./structure.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class StructureComponent {

    private sub: any;
    showTabs = true;

	graduationCap = faGraduationCap;
	university = faUniversity;

    constructor(
        private router: Router
    ) {
        this.sub = router.events.subscribe(event => {
            if(event instanceof NavigationEnd) {
                this.showTabs = false;
                this.parseRoute(router.routerState.snapshot.root);
            }
        });
    }

    parseRoute(node: ActivatedRouteSnapshot) {
        if (node.data.showTabs) {
            this.showTabs = true;
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }

}
