import {Injectable} from "@angular/core";
import {AppSettings} from "./app.settings";
import {Settings} from "./app.settings.model";

@Injectable()
export class AppService {

	public scrolledContent: any;
	public settings: Settings;
	public showBackToTop = false;
	public lastScrollTop = 0;
	public isStickyMenu = false;

	constructor(
		public appSettings: AppSettings
	) {
		this.settings = this.appSettings.settings;
	}

	public onPsScrollY(event) {
		this.scrolledContent = event.target;
		(this.scrolledContent.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
		if (this.settings.menu == 'horizontal') {
			if (this.settings.fixedHeader) {
				const currentScrollTop = (this.scrolledContent.scrollTop > 56) ? this.scrolledContent.scrollTop : 0;
				(currentScrollTop > this.lastScrollTop) ? this.isStickyMenu = true : this.isStickyMenu = false;
				this.lastScrollTop = currentScrollTop;
			} else {
				(this.scrolledContent.scrollTop > 56) ? this.isStickyMenu = true : this.isStickyMenu = false;
			}
		}
	}

	public scrollToTop() {
		const scrollDuration = 200;
		const scrollStep = -this.scrolledContent.scrollTop / (scrollDuration / 20);
		const scrollInterval = setInterval(() => {
			if (this.scrolledContent.scrollTop != 0) {
				this.scrolledContent.scrollBy(0, scrollStep);
			} else {
				clearInterval(scrollInterval);
			}
		}, 10);
		if (window.innerWidth <= 768) {
			this.scrolledContent.scrollTop = 0;
		}
	}

}
