import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BooksService} from "../books.service";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: "sub-book-form-component",
	templateUrl: "./sub-book-form.component.html"
})
export class SubBookFormComponent implements OnInit {

	form: FormGroup;

	constructor(
		private ref: MatDialogRef<SubBookFormComponent>,
		@Inject(MAT_DIALOG_DATA) private data,
		private fb: FormBuilder,
		private booksService: BooksService,
		private error: ErrorsDialog
	) {
		this.form = fb.group({
			id: ["", Validators.required],
			name: ["", Validators.required],
			nameEng: "",
			code: "",
			credit: ["", Validators.required],
			status: "",
		});
	}

	save() {
		this.booksService.subAddOrEdit(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		})
	}


	cancel() {
		this.ref.close(false);
	}

	ngOnInit(): void {
		this.form.patchValue(this.data);
	}

}