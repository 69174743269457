import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StudentsService} from "../../../students.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "src/app/shared/error-dialogs/errors.dialog";
import {PermissionService} from "../../../../../core/services/permission.service";
import {StudentSmsConfirmDialog} from "./student-sms-confirm/student-sms-confirm.dialog";

@Component({
	selector: 'app-student-sms-dialog',
	templateUrl: './student-sms-dialog.component.html',
	styleUrls: ['./student-sms-dialog.component.scss']
})
export class StudentSmsDialogComponent implements OnInit {

	form: FormGroup;

	actions = [];

	constructor(
		private ref: MatDialogRef<StudentSmsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private fb: FormBuilder,
		private studentService: StudentsService,
		private error: ErrorsDialog,
		private permissionService: PermissionService,
		private dialog: MatDialog,
	) {
		if (this.permissionService.can("menu.personalInfo.view.sms.sendAuthInfo")) {
			this.actions.push({
				"key": "authInfo",
				"value": "ავტორიზაციის მონაცემები"
			});
		}
		/*
		if (this.permissionService.can("menu.personalInfo.view.sms.sendAuthInfo")) {
		  this.actions.push({
			"key" : "dedashenZreuliMeilzeParolisGagzavna",
			"value" : "მეილზე პაროლის გაგზავნა"
		  });
		}
		 */
		if (this.permissionService.can("menu.personalInfo.view.sms.sendSms")) {
			this.actions.push({
				"key": "text",
				"value": "ტექსტური ინფორმაცია"
			});
		}

		if (this.permissionService.can("menu.personalInfo.view.sms.smsIdentification")) {
			this.actions.push({
				"key": "smsIdentification",
				"value": "სმს იდენტიფიკაცია"
			});
		}

		this.form = fb.group({
			action: ["", Validators.required],
			text: [""],
			student_id: this.data.studentId,
			mode: this.data.mode,
			type: [['sms'], Validators.required]
		});

		this.form.get('action').valueChanges
			.subscribe(value => {
				if (value == 'smsIdentification') this.form.get('text').setValue(this.getRandomInteger());
				else this.form.get('text').setValue("");
			});

	}

	getRandomInteger(): number {
		return Math.floor(1000 + Math.random() * 9000);
	}

	ngOnInit() {
	}

	cancel() {
		this.ref.close(false);
	}

	ok() {
		this.sendSmsOnlyStudent();
	}

	sendSmsOnlyStudent() {
		if (this.form.value.action === 'smsIdentification') {
			const ref = this.dialog.open(StudentSmsConfirmDialog, {
				disableClose: true,
				data: this.form.value,
				width: "500px",
			});

			ref.afterClosed().subscribe(result => {
				this.ref.close(true);
			});

		} else {
			this.studentService.sendSmsOnlyStudent(this.form.value).subscribe(response => {
				if (response.result == "yes") {
					this.ref.close(true);
				} else {
					this.error.show(response);
				}
			});
		}
	}

}
