import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "matrix-dialog",
    templateUrl: "./matrix.dialog.html"
})
export class MatrixDialog implements OnInit {

    form: FormGroup;

    constructor(
        private ref: MatDialogRef<MatrixDialog>,
        private fb: FormBuilder
    ) {
        this.form = fb.group({
            x: ['', Validators.required],
            y: ['', Validators.required]
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            x: 2,
            y: 2
        });
    }

    cancel() {
        this.ref.close(false);
    }

    ok() {
        this.ref.close(this.form.value);
    }



}