import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    forwardRef,
    Input, OnInit,
    ViewChild
} from "@angular/core";
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IniDatePickerHeader} from "./ini-date-picker-header";

@Component({
    selector: "ini-date-picker",
    templateUrl: "./ini-date-picker.component.html",
    styleUrls: ["./ini-date-picker.component.scss"],
    providers:[{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => IniDatePickerComponent),
        multi: true
    }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IniDatePickerComponent implements OnInit, ControlValueAccessor {

    form: FormGroup;
    private onChange: (value: any) => void;
    private onTouched: () => void;
    @Input("placeholder") placeholder = "";
    /**/@ViewChild("dateElement", {static: false}) dateElement: ElementRef;

    // noinspection JSUnusedGlobalSymbols
    months = [
        {id: 1,  name: "იანვარი"},
        {id: 2,  name: "თებერვალი"},
        {id: 3,  name: "მარტი"},
        {id: 4,  name: "აპრილი"},
        {id: 5,  name: "მაისი"},
        {id: 6,  name: "ივნისი"},
        {id: 7,  name: "ივლისი"},
        {id: 8,  name: "აგვისტო"},
        {id: 9,  name: "სექტემბერი"},
        {id: 10, name: "ოქტომბერი"},
        {id: 11, name: "ნოემბერი"},
        {id: 12, name: "დეკემბერი"},
    ];

    pickerHeader = IniDatePickerHeader;

    constructor(
        private fb: FormBuilder
    ) {
        this.form = fb.group({
            date: "",
            month: "",
            year: ""
        });
        this.form.get("date").valueChanges.subscribe(value => {
            if (typeof this.onChange == "function") {
                this.onChange(value);
            }
        });
    }

    focus() {

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (typeof isDisabled != "undefined") {
            if (isDisabled) {
                this.form.get("date").disable();
            } else {
                this.form.get("date").enable();
            }
        }
    }

    writeValue(obj: any): void {

        if (typeof obj != "undefined" && obj != null) {
            this.form.patchValue({
                date: obj
            });
        }
    }

    ngOnInit(): void {
        this.form.patchValue({
            date: ""
        });
    }
}
