import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import moment from "moment";

@Injectable()
export class CacheService {

	constructor() {
		const data = localStorage.getItem("cache-data");
		if (typeof data != "undefined" && data != null) {
			this.data = JSON.parse(data);
		}
	}

	data: any = {};

	cache(key, timeout, callback:Observable<any>):Observable<any> {
		const now = parseInt(moment().format("X"));
		if (typeof this.data[key] != "undefined") {
			if (parseInt(this.data[key].t) > now) {
				return new Observable(serve => {
					serve.next(this.data[key].v);
				});
			}
		}
		return new Observable(serve => {
			callback.subscribe(value => {
				this.data[key] = {
					t: now+(timeout*60),
					v: value
				};
				this.save();
				serve.next(value);
			});
		});
	}

	save() {
		localStorage.setItem("cache-data", JSON.stringify(this.data));
	}

}
