import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {ProgressResult, ProgressSource} from "./progress.model";
import {HttpClient} from "@angular/common/http";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {firstValueFrom} from 'rxjs';

@Component({
	selector: "progress-dialog",
	templateUrl: "./progress.dialog.html"
})
export class ProgressDialog implements OnInit, OnDestroy {

	public progress: number = 0;
	public interval: number = 1000;
	public out: string = "";
	public stop = false;

	constructor(
		private ref: MatDialogRef<ProgressDialog>,
		@Inject(MAT_DIALOG_DATA) public data: ProgressSource,
		private http: HttpClient
	) {
	}

	getProgress() {
		if (this.stop) {
			return;
		}
		firstValueFrom(this.http.get<ProgressResult>(this.data.source)).then(result => {
			if (result.res == "wait") {
				this.progress = result.progress;
				this.out = result.out;
				setTimeout(() => {
					this.getProgress();
				}, this.interval);
			} else {
				this.ref.close(result);
			}
		})
	}

	ngOnInit() {
		this.getProgress();
	}

	ngOnDestroy(): void {
		clearTimeout(this.interval);
		this.stop = true;
	}

}
