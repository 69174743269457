import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {Doc, DocumentResponse} from "./docs.models";
import {map} from "rxjs/operators";


@Injectable()
export class DocsService {

    constructor(
        private http: HttpClient
    ) {}

    add(data: FormData):Observable<DocumentResponse> {
        return this.http.post<DocumentResponse>("/documents/add", data);
    }

    edit(data: FormData) {
        return this.http.post<DocumentResponse>("/documents/edit", data);
    }

    list(filter: any={}, page=1, limit=13):Observable<DocumentResponse> {
        return this.http.post<DocumentResponse>("/documents/list?page="+page+"&limit="+limit, filter);
    }

    getNames():Observable<DocumentResponse> {
        return this.http.get<DocumentResponse>("/documents/names");
    }

    // noinspection JSUnusedGlobalSymbols
    getDetails(id: number):Observable<Doc> {
        return this.http.post<DocumentResponse>("/documents/get", {
            id: id
        }).pipe(map(response => {
            return response.document;
        }));
    }

    delete(id:number):Observable<DocumentResponse> {
        return this.http.post<DocumentResponse>("/documents/delete", {
            id: id
        });
    }
}
