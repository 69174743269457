
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {PollAlertService} from "../../shared/services/pollAlert.service";
import {HrCalendarDataService} from "../../pages/hr/hr-calendar/hr-calendar-data.service";
import {HrCalendarCommonService} from "../../shared/services/hrCalendarCommon.service";

@Injectable()
export class RouterService{

  constructor(
      private router: Router,
      private pollAlertService: PollAlertService,
      private hrCalendarCommonService: HrCalendarCommonService
  ){
    //
    this.router.events.subscribe( (val) => {
      //
    });
  }

  navigationStart(event)
  {
    this.pollAlertService.checkPollAlert(event);
    this.hrCalendarCommonService.checkMandatoryEvent(event);
  }

}