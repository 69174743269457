import {Component, Inject} from "@angular/core";
import {StudentActionFormDialog} from "../../student-details/actions/student-action-form/student-action-form.dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StudentsService} from "../../students.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: 'student-learning-start-date-dialog',
	templateUrl: './student-learning-start-date.dialog.html'
})

export class StudentLearningStartDateDialog  {

	form: FormGroup;

	constructor(
		public ref: MatDialogRef<StudentActionFormDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public studentService: StudentsService,
		public fb: FormBuilder,
		public error: ErrorsDialog
	) {
		this.form = fb.group({
			date: ["", Validators.required],
		});
	}



	ok() {
		const students =  this.data.students;
		this.studentService.addStudentStartDateAction({students, ...this.form.value}).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		}, (error) => {
			console.error(error);
		});
	}

	cancel()
	{
		this.ref.close();
	}

}