import {Component, Input} from "@angular/core";
import {Chat} from "../../interfaces/Chat";
import {ChatService} from "../../services/chat-service";

@Component({
  selector: 'ini-inactive-chat',
  templateUrl: './inactive-chat.component.html',
  styleUrls: ["./inactive-chat.component.scss"],
})
export class InactiveChat {

  @Input() chat: Chat;

  constructor(
    private chatService: ChatService
  ) {
  }

  openChat() {
    this.chatService.openChatById(this.chat.id);
  }

  close($event: any) {
    $event.stopPropagation();
    this.chatService.closeChat(this.chat.id);
  }

}
