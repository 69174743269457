import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../../../../../shared/error-dialogs/errors.dialog";
import {RegistrationService} from "../../../../../../registration.service";


@Component({
	selector: "praqtika-status-dialog",
	templateUrl: "./praqtika-status-dialog.html"
})
export class PraqtikaStatusDialog implements OnInit {

	form: FormGroup;

	constructor(
		private ref: MatDialogRef<PraqtikaStatusDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private error: ErrorsDialog,
		private fb: FormBuilder,
		private registrationService: RegistrationService,
		private dialog: MatDialog
	) {

		this.form = fb.group({
			registrationBookGroupId: "",
			status : ""
		});
	}

	ok() {
		this.registrationService.setBookGroupStatus(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		});
	}

	cancel() {
		this.ref.close(false);
	}

	ngOnInit(): void {

		this.form.patchValue({
      registrationBookGroupId: this.data.id,
			status : this.data.nashromi_praqtika_status
		});


	}

}