import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'kaSeason'
})
export class KaSeasonPipe implements PipeTransform {
	transform(value: string): string {
		let ka = "";
		switch(value){
			case 'spring':
				ka = 'საგაზაფხულო';
				break;
      case 'summer':
        ka = 'საზაფხულო';
        break;
      case 'autumn':
        ka = 'საშემოდგომო';
        break;
		}
		return ka;
	}
}