export class EstList {
    id: number;
    name: string;
    bookId: number;
    credit: number;
    learningYear: string;
    learningSeason: string;
    allowSign: number;

    static learningYears() {
        const items = [];
        const y = (new Date()).getFullYear();
        for (let i=y-7; i<y+7; i++) {
            items.push({
                id: i+"/"+(i+1),
                name: i+"/"+(i+1)
            });
        }
        return items;
    }
}
