import {Injectable} from "@angular/core";
import {AlertDialog} from "./alert.dialog";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class ErrorsDialog {

    constructor(
        private dialog: MatDialog
    ) {

    }

    show(response: any) {
        if (typeof response.error != "undefined") {
            this.dialog.open(AlertDialog, {
                data: {
                    title: "info",
                    text: response.error,
                    ok: "Ok"
                }
            });
        } else if (typeof response.errors != "undefined") {
            let s = "";
            for (const i in response.errors) {
                if (response.errors.hasOwnProperty(i)) {
                    s += response.errors[i]+"\r\n";
                }
            }
            this.dialog.open(AlertDialog, {
                data: {
                    title: "Error",
                    text: s,
                    ok: "Ok"
                }
            });
        }
    }

}