import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
	providedIn: 'root'
})
export class MobilityBooksService {

	constructor(
		private http: HttpClient
	) {
	}

	getMobilityBooks(data: any, page: number, limit: number): Observable<MobilityBookResponse> {
		return this.http.post<MobilityBookResponse>(`/books/mobility/list?page=${page}&limit=${limit}`, data);
	}

	addOrEditMobilityBook(data): Observable<any> {
		return this.http.post("/books/mobility/addOrEdit", data);
	}

	removeMobilityBook(data): Observable<any> {
		return this.http.post("/books/mobility/remove", data);
	}

}

export interface MobilityBook {
	id: number;
	bookName: string;
	bookNameEng: string;
	credit: number;
	created_at: string;
	updated_at: string;
}

export interface MobilityBookResponse {
	data: MobilityBook[];
	page: number;
	total: number;
	limit: number;
}