import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "required-message-dialog",
    templateUrl: "./required-message.dialog.html"
})
export class RequiredMessageDialog {

    constructor(
        public ref: MatDialogRef<RequiredMessageDialog>
    ) {

    }

    close() {
        this.ref.close();
    }
}