import {Component, OnInit} from "@angular/core";
import {AppSettings} from "../../app.settings";

@Component({
	selector: "empty-page-component",
	template: `<router-outlet></router-outlet>`
})
export class EmptyPageComponent implements OnInit {

	constructor(
		public appSettings: AppSettings,
	) {
	}

	ngOnInit(): void {
		this.appSettings.settings.loadingSpinner = false;
	}

}