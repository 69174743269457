import {AfterViewInit, Component, ViewChild} from "@angular/core";
import {StudentsService} from "../students.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
// import { createId } from '@paralleldrive/cuid2';
import {ProgressDialog} from "../../../theme/components/progressDialog/progress.dialog";

@Component({
	selector: "student-import-form-dialog",
	templateUrl: "./student-import-form.dialog.html",
	styleUrls: ["./student-import-form.dialog.scss"]
})
export class StudentImportFormComponent implements AfterViewInit {

	importable = false;
	data: any = [];
	loading = false;
	hasData = false;
	tableColumns = [];
	rowColumns = [];
	total = 0;
	showOnlyErrors = false;

	fieldOptions = [
		{name: 'სახელი', field: 'firstName', table: 'students', type: 'text'},
		{name: 'გვარი', field: 'lastName', table: 'students', type: 'text'},
		{name: 'სახელი, გვარი', field: 'name', table: 'students', type: 'text'},
		{name: 'სახელი (ENG)', field: 'firstNameEng', table: 'students', type: 'text'},
		{name: 'გვარი (ENG)', field: 'lastNameEng', table: 'students', type: 'text'},
		{name: 'პირადი ნომერი', field: 'personalNumber', table: 'students', type: 'text', unique: true},
		{name: 'დაბ. თარიღი', field: 'birthday', table: 'students', type: 'date'},
		{
			name: 'სქესი', field: 'gender', table: 'students', type: 'option', options: [
				{value: 'male', title: 'მამრობითი'},
				{value: 'female', title: 'მდედრობითი'}
			]
		},
		{name: 'რეგიონი', field: 'regionId', table: 'students', type: 'option', options: []}, // todo: get regions
		{name: 'მისამართი', field: 'address', table: 'students', type: 'text'},
		{name: 'ტელეფონი', field: 'phone', table: 'students', type: 'text'},
		{name: 'ელ-ფოსტა', field: 'mail', table: 'students', type: 'text'},
		{name: 'ქვეყანა', field: 'country', table: 'students', type: 'text'},
		{name: 'სკოლა', field: 'schoolId', table: 'education_info', type: 'option', options: []}, // todo: get schools
		{name: 'პროგრამა', field: 'programId', table: 'education_info', type: 'option', options: []}, // todo: get schools
		{name: 'სპეციალობა', field: 'specId', table: 'education_info', type: 'option', options: []}, // todo: get schools
		{
			name: 'ხარისხი', field: 'level', table: 'education_info', type: 'option', options: [
				{value: 1, title: "ბაკალავრი"},
				{value: 2, title: "მაგისტრი"},
				{value: 3, title: "დოქტორი"},
				{value: 4, title: "ერთსაფეხურიანი"},
				{value: 5, title: "პროფესიული"},
				{value: 6, title: "რეზიდენტი"},
				{value: 7, title: "მაგისტრი / ბაკალავრი"}
			]
		},
		{
			name: 'სტატუსი', field: 'status', table: 'education_info', type: 'option', options: [
				{value: 'active', title: "აქტიური"},
				{value: 'pause', title: "შეჩერებული"},
				{value: 'stop', title: "შეწყვეტილი"},
				{value: 'end', title: "კურსდამთავრებული"},
				{value: 'abiturient', title: "აბიტურიენტი"},
				{value: 'mag_candidate', title: "მაგისტრობის კანდიდატი"},
				{value: 'applicant', title: 'აპლიკანტი'},
				{value: 'pre', title: 'წინარე'}
			]
		},
		{
			name: 'მეთოდი', field: 'method', table: 'education_info', type: 'option', options: [
				{value: 0, title: "ერთიანი ეროვნული გამ."},
				{value: 1, title: "სხვა"},
				{value: 2, title: "გარე მობილობა"},
				{value: 3, title: "შიდა მობილობა"},
				{value: 4, title: "მინისტრის ბრძანება"}
			]
		},
		{name: 'სასწავლო წელი', field: 'learningSeason', table: 'education_info', type: 'number'},
		{name: 'სემესტრი', field: 'semester', table: 'education_info', type: 'number'},
		{
			name: 'ჩარიცხვის სეზონი', field: 'method', table: 'education_info', type: 'option', options: [
				{value: 'spring', title: "საგაზაფხულო"},
				{value: 'summer', title: "საზაფხულო"},
				{value: 'autumn', title: "საშემოდგომო"}
			]
		}

	];
	fillableColumns: any = {};
	filteredItems = []

	page = 1;
	limit = 10;

	constructor(
		public ref: MatDialogRef<StudentImportFormComponent>,
		public studentsService: StudentsService,
		public service: StudentsService,
		public dialog: MatDialog
	) {
		this.getMeta();
	}

	showErrors(page) {
		this.page = page;
		this.filteredItems = this.getPaginatedItems(this.page);
	}

	getItems() {
		if (this.showOnlyErrors) {
			return this.data.filter(item => this.hasError(item));
		}
		return this.data;
	}

	hasError(item) {
		let errors = [];
		for (let column in this.fillableColumns) {
			if (typeof item[column] == "undefined") {
				errors.push(column);
			} else {
				const option = this.fieldOptions.find(option => option.field == column);
				if (option && option.unique) {
					if (this.data.filter(it => it[column] == item[column]).length > 1) {
						errors.push(column);
					}
				}
				if (option && option.type == 'option') {
					if (option.options.findIndex(option => option.value == item[column]) == -1) {
						errors.push(column);
					}
				}
			}
		}
		return errors.length > 0;
	}

	getPaginatedItems(page: number) {
		let items = [];
		let data = this.getItems();
		for (let i = 0; i < this.limit; i++) {
			if (typeof data[(page - 1) * this.limit + i] != "undefined") {
				let item = data[(page - 1) * this.limit + i];
				item.itemIndex = 0; //createId();
				items.push(item);
			}
		}
		this.importable = !this.someFieldIsMissed();
		return items;
	}

	someFieldIsMissed() {
		return this.data.some(item => !!this.hasError(item));
	}

	getMeta() {
		this.service.getStudentFields().subscribe(({regions, schools, programs, specs}: any) => {
			this.fieldOptions = this.fieldOptions.map(field => {
				if (field.field == 'regionId') {
					field.options = regions.map(region => ({...region, value: region.value + ''}));
				}
				if (field.field == 'schoolId') {
					field.options = schools.map(school => ({...school, value: school.value + ''}));
				}
				if (field.field == 'programId') {
					field.options = programs.map(program => ({...program, value: program.value + ''}));
				}
				if (field.field == 'specId') {
					field.options = specs.map(spec => ({...spec, value: spec.value + ''}));
				}
				return field;
			});

		});
	}

	uploadFile(files: FileList) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		if (files.length > 0) {
			let fd = new FormData();
			fd.append("file", files[0], files[0].name);
			this.studentsService.parseExcel(fd).subscribe(response => {
				if (response.result == "yes") {
					let sheet = [];
					if (response.items.length > 0) {
						sheet = response.items[0];
					}
					this.hasData = true;
					let data = [];
					let columns = [];
					if (sheet.length > 0) {
						columns = sheet[0];
					}
					if (sheet.length > 1) {
						for (let i = 1; i < sheet.length; i++) {
							let item: any = {};
							let j = 0;
							for (let column of columns) {
								item[column] = sheet[i][j++] + '';
							}
							data.push(item);
						}
					}
					this.data = data;
					this.rowColumns = columns;
					this.tableColumns = ['v_actions', ...columns.map((column, key) => `v${key}_${column}`)];
					this.total = data.length;
					this.filteredItems = this.getPaginatedItems(this.page);
					for (let column of this.rowColumns) {
						const option = this.fieldOptions.find(option => option.field == column);
						if (option) {
							this.fillableColumns[column] = option;
						}
					}
				}
				this.loading = false;
				this.importable = !this.someFieldIsMissed();
			});
		}
	}

	remove(item: any) {
		if (item && item.itemIndex) {
			const index = this.data.findIndex(it => it.itemIndex == item.itemIndex);
			this.data = this.data.filter((item, key) => key != index);
			this.total = this.data.length;
			this.filteredItems = this.getPaginatedItems(this.page);
		}
	}

	columnValueIsValid(column: string, field: any, value) {
		return !!field.options.find(option => option.value == value);
	}

	columnValue(column: string, field: any, value) {
		const option = field.options.find(option => option.value == value);
		if (option) {
			return option.title;
		}
		return value;
	}

	setColumValues(column: string, index, oldValue, event: any) {
		for (let item of this.data) {
			if (item[column] == oldValue) {
				item[column] = event.value;
			}
		}
		this.showErrors(this.page);
	}

	// noinspection JSUnusedLocalSymbols
	setColumOption(column: string, index: number) {
		this.showErrors(this.page);
	}

	cancel() {
		this.ref.close(false);
	}

	dataMap(item) {
		let data = {};
		for (let column in item) {
			if (this.fillableColumns[column]) {
				data[this.fillableColumns[column].field] = item[column];
			}
		}
		return data;
	}

	ok() {
		this.studentsService.importStudents({
			columns: Object.keys(this.fillableColumns).map(key => ({
				field: this.fillableColumns[key].field,
				table: this.fillableColumns[key].table
			})),
			data: this.data.map((item: any) => this.dataMap(item)),
		}).subscribe(response => {
			if (response.result == 'yes') {
				this.dialog.open(ProgressDialog, {
					data: {
						source: '/students/get-import-progress/?pid=' + response.pid,
					},
					width: '400px'
				}).afterClosed().subscribe(() => {
					alert('სტუდენტები წარმატებით დაიმპორტდა');
					this.ref.close(true);
				})
			} else {
				alert('ვერ მოხერხდა სტუდენტების იმპორტი');
			}
		});
	}

	@ViewChild("paginator", {static: false}) paginator: MatPaginator;

	ngAfterViewInit(): void {
		this.paginator.page.subscribe(res => {
			this.page = res.pageIndex + 1;
			this.limit = res.pageSize;
			this.filteredItems = this.getPaginatedItems(this.page);
		});
	}

	log() {

	}

}
