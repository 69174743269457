import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";

@Injectable()
export class AutocompleteNamesService {

    constructor(
        private http: HttpClient
    ) {

    }

    getStudentNames(q): Observable<any[]> {
        return this.http.post<any[]>("/testing/getStudentNames", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }
    getNewStudentNames(q): Observable<any[]> {
        return this.http.post<any[]>("/testing/getNewStudentNames", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }
	getUsers(q): Observable<any[]> {
        return this.http.post<any[]>("/user/getUsers", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getBookNames(q, lang: string = 'ka', status = ''): Observable<any[]> {
        return this.http.post<any[]>("/testing/getBookNames", {
            q: typeof q == "object" && q != null ? q.name : q,
	        lang: lang,
			status
        });
    }

	getSubBookNames(q): Observable<any[]> {
        return this.http.post<any[]>("/books/getSubBookNames", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getProfs(q, bookId=''): Observable<any[]> {
        return this.http.post<any[]>("/testing/getProfs", {
            q: typeof q == "object" && q != null ? q.name : q,
			bookId
        });
    }

    getPrograms(q): Observable<any[]> {
        return this.http.post<any[]>("/books/programs", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getListNames(q): Observable<any[]> {
        return this.http.post<any[]>("/lists/getListNames", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getContacts(q): Observable<any[]> {
        return this.http.post<any[]>("/mailbox/contacts", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getAuditoriums(q): Observable<any[]> {
        return this.http.post<any[]>("/registration/book/getAuditoriums", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getSchoolNames(q): Observable<any[]> {
        return this.http.post<any[]>("/registration/school/names", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getSpecialNames(q): Observable<any[]> {
        return this.http.post<any[]>("/registration/special/names", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getRegistrationGroups(): Observable<any[]> {
        return this.http.get<any[]>("/registration/groups/getNames");
    }

    getRegistrationGroupBooks(data): Observable<any[]> {
        return this.http.post<any[]>("/registration/books/getNames", data);
    }

    getRegistrationSeminar(data): Observable<any[]> {
        return this.http.post<any[]>("/registration/seminar/getNames", data);
    }

    getRegistrationSeminarDetails(data): Observable<any> {
        return this.http.post<any>("/registration/seminar/details", data);
    }

	getGroupComment(q, registrationGroupId: string = ''): Observable<any[]> {
		return this.http.post<any[]>("/registration/statistic/getGroupComment", {
			q: typeof q == "object" && q != null ? q.name : q,
			registrationGroupId: registrationGroupId
		});
	}

}
