import {Component, Inject, OnInit} from "@angular/core";
import {BooksService} from "../books.service";

import {Book} from "../book";
import {BookComponent} from "./book.component";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";
import {BookComponentForm} from "./book-component-form/book-component-form";

import {EstComponent} from "../../components/est.component";
import {GenComponentsForBooksDialog} from "./gen-components-for-books-dialog/gen-components-for-books-dialog";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialog} from "../../../theme/components/confirmDialog/confirm.dialog";
import {BookLogsDialog} from "../book-logs/book-logs.dialog";


@Component({
    selector: "book-components-form",
    templateUrl: "./book-components.form.html",
    styleUrls: ["./book-components.form.scss"]
})
export class BookComponentsForm implements OnInit {

    components: BookComponent[] = [];
    displayedColumns = [
        "id",
        "name",
        "count",
        "weight",
        "minLimit",
        "actions"
    ];
    totalLimit = 0;

    constructor(
        public ref: MatDialogRef<BookComponentsForm>,
        @Inject(MAT_DIALOG_DATA) public book: Book,
        public service: BooksService,
        public error: ErrorsDialog,
        public dialog: MatDialog
    ) {

    }

    allComponents: {[coId: number]: EstComponent} = null;

    getComponents() {
        this.service.getBookComponents({
            bookId: this.book.id
        }).subscribe(response => {
            if (response.result == "yes") {
                this.components = response.data;
                if (this.allComponents == null) {
                    this.service.getAllComponents().subscribe(response => {
                        this.allComponents = {};
                        for (const com of response.data) {
                            this.allComponents[com.id] = com;
                        }
                        this.calcSum();
                    });
                } else {
                    this.calcSum();
                }
            }
        });
    }

    calcSum() {
        this.totalLimit = 0;
        const skipComps = {};
        for (const com of this.components) {
            if (
                this.allComponents != null
                && typeof this.allComponents[com.coId] != "undefined"
                && typeof skipComps[com.coId] == "undefined"
                && (
                    this.allComponents[com.coId].type == 1
                || this.allComponents[com.coId].type == 2
                )
            ) {
                const component: EstComponent = this.allComponents[com.coId];
                if (typeof component.required == "object" && typeof component.required.length != "undefined") {
                    this.totalLimit += parseFloat(com.weight);
                    if (component.required.length > 0) {
                        for (const coId of component.required) {
                            skipComps[coId] = true;
                        }
                    }
                }
            }
        }
    }

    addComponent() {
        this.editComponent({
            bookId: this.book.id
        });
    }

    setComponentActiveList() {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "კომპონენტების განახლება უწყისებზე",
                text: "ნამდვილად გსურთ კომპონენტების განახლება აქტიურ უწყისებზე?",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.service.setComponentActiveList({
                    bookId: this.book.id
                }).subscribe(response => {
                    if (response.result == "yes") {
                        this.ref.close(true);
                    }
                });
            }
        });
    }

    setComponentsFromBooks() {
        this.dialog.open(GenComponentsForBooksDialog, {
            data: {
                bookId: this.book.id
            }
        });
    }

    editComponent(item) {
        const ref = this.dialog.open(BookComponentForm, {
            data: {
                otherComponents: this.components,
                component: item
            },
            width: "900px"
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.getComponents();
            }
        });
    }

    removeComponent(item) {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "წაშლა",
                text: "ნამდვილად გსურთ წაშლა?",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.service.removeBookComponent(item).subscribe(response => {
                    if (response.result == "yes") {
                        this.getComponents();
                    } else {
                        this.error.show(response);
                    }
                });
            }
        });
    }

    ngOnInit(): void {
        this.getComponents();

    }

	viewLogs(item): void {
		this.dialog.open(BookLogsDialog, {
			width: "900px",
			data: {
				id: item.id,
				type: 'component',
			},
		});
	}

}
