import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ListService} from "../list.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";
import {EstResult} from "../est/view/est.result";
import {EstList} from "../est.list";

@Component({
    selector: "list-form-dialog",
    templateUrl: "./list-form.dialog.html"
})
export class ListFormDialog implements OnInit {

    form: FormGroup;

    constructor(
        private ref: MatDialogRef<ListFormDialog>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private service: ListService,
        private fb: FormBuilder,
        private error: ErrorsDialog
    ) {
        this.form = fb.group({
            id: "",
            book: "",
            bookId: ["", Validators.required],
            name: ["", Validators.required],
            credit: ["", Validators.required],
            learningYear: ["", Validators.required],
            learningSeason: ["", Validators.required],
            status: ["", Validators.required]
        });

    }

    // noinspection JSUnusedGlobalSymbols
    seasonNames = EstResult.eduSeasonNames();
    seasons = EstResult.seasons();
    seasonYears = EstList.learningYears();

    ok() {
        this.service.addOrEdit(this.form.value).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(true);
            } else {
                this.error.show(response);
            }
        });
    }

    cancel() {
        this.ref.close(false);
    }

    ngOnInit(): void {
        if (typeof this.data.id != "undefined") {
            this.data.book = {
                id: this.data.bookId,
                name: this.data.name
            };
        }
        this.form.patchValue(this.data);
        this.form.get("book").valueChanges.subscribe((value) => {
            if (typeof value == "object") {
                this.form.patchValue({
                    name: value.name,
                    bookId: value.id,
                    credit: value.credit
                });
            } else {
                this.form.patchValue({
                    bookId: ""
                });
            }
        });

    }


}
