import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "img[notFound]",
})
export class ImgNotFoundDirective {

  constructor(
    private el : ElementRef,
  ) {
    el.nativeElement.onerror = function() {
      //this.src = Config.defaultUserIcon;
		this.classList.add('default-user-icon');
    };
  }

}
