import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {OverlayContainer} from '@angular/cdk/overlay';
import {CustomOverlayContainer} from './theme/utils/custom-overlay-container';
import {SharedModule} from './shared/shared.module';
import {PipesModule} from './theme/pipes/pipes.module';
import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';
import {PagesComponent} from './pages/pages.component';
import {AppSettings} from './app.settings';
import {SidenavComponent} from './theme/components/sidenav/sidenav.component';
import {VerticalMenuComponent} from './theme/components/menu/vertical-menu/vertical-menu.component';
import {BreadcrumbComponent} from './theme/components/breadcrumb/breadcrumb.component';
import {FlagsMenuComponent} from './theme/components/flags-menu/flags-menu.component';
import {FullScreenComponent} from './theme/components/fullscreen/fullscreen.component';
import {ApplicationsComponent} from './theme/components/applications/applications.component';
import {MessagesComponent} from './theme/components/messages/messages.component';
import {MessagesComponent as MessagesComponent2} from './pages/messages/messages.component';
import {UserMenuComponent} from './theme/components/user-menu/user-menu.component';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {ChatModule} from "./modules/chat/chat.module";
import {CookieModule} from "ngx-cookie";
import {NgxDocViewerModule} from "ngx-doc-viewer";
import {QRCodeModule} from "angularx-qrcode";
import {EventsComponent} from "./theme/components/events/events.component";
import {AppMessagesComponent} from "./theme/components/messages/app-messages.component";
import {MailboxComponent} from "./pages/mailbox/mailbox.component";
import {RegistrationComponent} from "./pages/registration/registration.component";
import {TablesComponent} from "./pages/tables/tables.component";
import {TasksDashboard} from "./pages/tasks/tasks.dashboard";
import {ChancelleryRoutes} from "./pages/chancellery/chancellery.routes";
import {PortfolioRoutesComponent} from "./pages/portfolio/routes/portfolio-routes.component";
import {SubUsersComponent} from "./theme/components/sub-users/sub-users.component";
import {PublicationsDashboard} from "./pages/publications/publications.dashboard";
import {MemorandumDashboard} from "./pages/memorandum/memorandum.dashboard";
import {LangComponent} from "./pages/lang/lang.component";
import {StructureComponent} from "./pages/structure/structure.component";
import {ComponentsTabs} from "./pages/components/components.tabs";
import {ListsDashboardComponent} from "./pages/lists/dashboard/lists-dashboard.component";
import {NotificationComponent} from "./pages/notification/notification.component";
import {TestingComponent} from "./pages/testing/testing.component";
import {BooksComponent} from "./pages/books/books.component";
import {PermissionTabsComponent} from "./pages/permissions/tabs/permission.tabs.component";
import {FinancesRouteComponent} from "./pages/finances/module/finances.route-component";
import {VoteFormComponent} from "./pages/personal-vote/vote-form/vote-form.component";
import {RoutePage} from "./pages/route.page";
import {EmptyPageComponent} from "./pages/empty-page/empty-page.component";
import {VideoPayerComponent} from "./pages/lists/list/list-task-view/list-task-form/vide-player.component";
import {HrComponent} from "./pages/hr/hr.component";
import {FinancesNewRouteComponent} from "./pages/finances-new/module/finances-new.route-component";
import {NotificationsMenuComponent} from "./theme/components/notifications-menu/notifications-menu.component";
import {DiplomaEnclosureComponent} from "./pages/diploma-enclosure/diploma-enclosure.component";
import {ProgressDialog} from "./theme/components/progressDialog/progress.dialog";
import {ConfirmComponent} from "./shared/components/dialogs/confirm/confirm.component";
import {LanguageFormComponent} from "./pages/lang/language-form/language-form.component";
import {BookConditionGroupDialog} from "./pages/books/book-conditions/book-condition-group/book-condition-group.dialog";
import {BookFormDialog} from "./pages/books/book-form/book-form.dialog";
import {BookComponentsForm} from "./pages/books/book-components/book-components.form";
import {BookComponentForm} from "./pages/books/book-components/book-component-form/book-component-form";
import {BookConditionsDialog} from "./pages/books/book-conditions/book-conditions.dialog";
import {ConfirmDialog} from "./theme/components/confirmDialog/confirm.dialog";
import {AlertDialog} from "./shared/error-dialogs/alert.dialog";
import {StatementMessageDialog} from "./pages/messages/statement-message-dialog/statement-message-dialog";
import {RequiredMessageDialog} from "./theme/components/messages/required-message/required-message.dialog";
import {
	GenComponentsForBooksDialog
} from "./pages/books/book-components/gen-components-for-books-dialog/gen-components-for-books-dialog";
import {SyllabusesDialog} from "./pages/books/syllabuses-dialog/syllabuses-dialog";
import {StudentFormDialog} from "./pages/students/student-form/student-form.dialog";
import {StudentExportFormDialog} from "./pages/students/student-export-form/student-export-form.dialog";
import {StudentActionDialog} from "./pages/students/student-action-dialog/student-action-dialog";
import {StudentEditLogDialog} from "./pages/students/student-edit-log/student-edit-log.dialog";
import {
	SyllabusUploaderDialog
} from "./pages/books/syllabuses-dialog/syllabus-uploader-dialog/syllabus-uploader-dialog";
import {
	BookComponentAdditionalDataDialog
} from "./pages/books/book-components/book-component-form/book-component-additional-data/book-component-additional-data-dialog";
import {
	ExportStudentComponentDialog
} from "./pages/lists/list/export-student-component/export-student-component.dialog";
import {
	NotificationRecipientFormDialog
} from "./pages/notification/notification-statement/notification-form/notification-recipient-form.dialog";
import {
	FinanceEventFormComponent as FinanceEventFormComponent1
} from "./pages/finances-new/events/finances-event-form/finances-event-form.dialog";
import {
	FinanceEventFormComponent as FinanceEventFormComponent2
} from "./pages/finances/events/list/finance-event-form/finance-event-form.component";
import {
	StudentSmsDialogComponent
} from "./pages/students/student-details/student-sms/student-sms-dialog/student-sms-dialog.component";
import {
	StudentSmsMessageDialogComponent
} from "./pages/students/student-details/student-sms/student-sms-message-dialog/student-sms-message-dialog.component";
import {SubBookFormComponent} from "./pages/books/sub-book-form/sub-book-form.component";
import {
	FinanceGrantEventFormComponent
} from "./pages/finances/grant-events/list/finance-grant-event-form/finance-grant-event-form.component";
import {
	FinancePrevBalanceFormComponent
} from "./pages/finances/prev-balances/list/finance-prev-balance-form/finance-prev-balance-form.component";
import {BookCatDialog} from "./pages/books/book-cat/book-cat-dialog";
import {PollAlertComponent} from "./theme/components/poll-alert/poll-alert.component";
import {
	ExportTestingProgressDialog
} from "./pages/testing/tests/list/export-testing-progress/export-testing-progress.dialog";
import {
	SeminarOrLectureDialog
} from "./pages/registration/group/books/book-parameters/seminar-or-lecture/seminar-or-lecture-dialog/seminar-or-lecture.dialog";
import {
	RegisteredStudentsDialog
} from "./pages/registration/group/books/book-parameters/seminar-or-lecture/registered-students/registered-students.dialog";
import {ProfBookDialog} from "./pages/books/prof-book-dialog/prof-book-dialog";
import {EditSemesterDialog} from "./pages/students/student-action-dialog/edit-semester-dialog/edit-semester.dialog";
import {UserPasswordDialog} from "./theme/components/user-menu/user-password-dialog/user-password-dialog";
import {
	PraqtikaDialog
} from "./pages/registration/group/books/book-parameters/seminar-or-lecture/praqtika-nashromi-list/praqtika-dialog/praqtika-dialog";
import {
	PraqtikaStatusDialog
} from "./pages/registration/group/books/book-parameters/seminar-or-lecture/praqtika-nashromi-list/praqtika-status-dialog/praqtika-status-dialog";
import {
	CommandStudentStatus
} from "./pages/students/student-action-dialog/command-student-status/command-student-status";
import {RuleStudentsDialog} from "./pages/books/rule-students-dialog/rule-students-dialog";
import {LoginTimeoutDialog} from "./shared/components/login-timeout/login-timeout.dialog";
import {
	ProgressSpinnerDialogComponent
} from "./shared/components/progress-spinner-dialog/progress-spinner-dialog.component";
import {SendSmsDialog} from "./pages/registration/statistic/sendSms-dialog/sendSms-dialog";
import {MobilityBooksFormDialog} from "./pages/mobility-books/mobility-books-form/mobility-books-form.dialog";
import {StudentBanDialog} from "./pages/students/student-action-dialog/student-ban-dialog/student-ban.dialog";
import {AnalyticsMenu} from "./pages/analytics/analytics.menu";
import {Chat} from "./theme/components/chat/chat";
import {BookLogsDialog} from "./pages/books/book-logs/book-logs.dialog";
import {
	StudentActionFormDialog
} from "./pages/students/student-details/actions/student-action-form/student-action-form.dialog";
import {JwtHelperService} from "@auth0/angular-jwt";
import {FileStorage} from "./core/services/FileStorage";
import {AuthModel} from "./core/models/auth.model";
import {AuthService} from "./core/auth/auth.service";
import {UserService} from "./core/services/user.service";
import {PermissionModel} from "./core/models/permission.model";
import {RoleModel} from "./core/models/role.model";
import {PermissionService} from "./core/services/permission.service";
import {CacheService} from "./core/services/cache.service";
import {ListService} from "./pages/lists/list/list.service";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {CustomDateAdapter} from "./shared/services/customDateAdapter";
import {AlertService} from "./shared/services/alert.service";
import {LangService} from "./shared/services/lang.service";
import {ErrorsDialog} from "./shared/error-dialogs/errors.dialog";
import {StudentsService} from "./pages/students/students.service";
import {NotificationService} from "./shared/services/notification.service";
import {MessagesService} from "./pages/messages/messages.service";
import {HelperService} from "./shared/services/helper.service";
import {ConfirmService} from "./shared/services/confirm.service";
import {DefaultConfig} from "./config/DefaultConfig";
import {
	EducationInfoListService
} from "./pages/students/student-details/education-info/list/education-info-list.service";
import {DocsService} from "./pages/docs/docs.service";
import {BooksService} from "./pages/books/books.service";
import {ProgramService} from "./pages/structure/specials/program/program.service";
import {PrintService} from "./core/services/print.service";
import {LogService} from "./core/services/log.service";
import {MailboxService} from "./pages/mailbox/mailbox.service";
import {MenuService} from "./theme/components/menu/menu.service";
import {ChancelleryService} from "./pages/chancellery/chancellery.service";
import {ComponentService} from "./pages/components/component.service";
import {RouterService} from "./core/services/router.service";
import {PollAlertService} from "./shared/services/pollAlert.service";
import {SettingService} from "./core/services/setting.service";
import {RawListService} from "./core/services/raw.list.service";
import {SmsService} from "./core/services/sms.service";
import {UserNotificationService} from "./core/services/user-notification.service";
import {AppService} from "./app.service";
import {StepOverService} from "./shared/services/stepover.service";
import {HrCalendarCommonService} from "./shared/services/hrCalendarCommon.service";
import {HrService} from "./pages/hr/hr.service";
import {CsvWriterService} from "./core/services/CsvWriterService";
import {FinancesNewService} from "./pages/finances-new/module/finances-new.service";
import {NgScrollbarModule} from "ngx-scrollbar";
import {
	FinancesStudentJobComponent
} from "./pages/finances-new/students/finances-student-details/finances-student-job/finances-student-job.component";
import {FinanceService} from "./pages/finances/services/finance.service";
import {RegistrationService} from "./pages/registration/registration.service";
import {InputMaskModule} from '@ngneat/input-mask';
import {
	BookProfAddDialogComponent
} from "./pages/books/prof-book-dialog/book-prof-add-dialog/book-prof-add-dialog.component";
import {ProfStatementDialog} from "./core/dialogs/prof-statement/prof-statement.dialog";

export const MY_FORMATS = {
	parse: {
		dateInput: 'YYYY-MM-DD',
	},
	display: {
		dateInput: 'DD-MMM-YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		//PerfectScrollbarModule,
		// CalendarModule.forRoot({
		//   provide: DateAdapter,
		//   useFactory: adapterFactory
		// }),
		SharedModule,
		PipesModule,
		AppRoutingModule,
		NgxMatSelectSearchModule,
		CookieModule.withOptions(),
		NgxDocViewerModule,
		QRCodeModule,
		ChatModule,
		NgScrollbarModule,
		InputMaskModule
	],
	declarations: [
		AppComponent,
		SidenavComponent,
		VerticalMenuComponent,
		BreadcrumbComponent,
		FlagsMenuComponent,
		FullScreenComponent,
		ApplicationsComponent,
		UserMenuComponent,
		EventsComponent,
		AppMessagesComponent,
		MailboxComponent,
		RegistrationComponent,
		TablesComponent,
		TasksDashboard,
		ChancelleryRoutes,
		PortfolioRoutesComponent,
		SubUsersComponent,
		PublicationsDashboard,
		MemorandumDashboard,

		// router-components
		PagesComponent,
		LangComponent,
		StructureComponent,
		ComponentsTabs,
		ListsDashboardComponent,
		NotificationComponent,
		MessagesComponent,
		TestingComponent,
		BooksComponent,
		PermissionTabsComponent,
		FinancesRouteComponent,
		VoteFormComponent,
		RoutePage,
		EmptyPageComponent,
		VideoPayerComponent,
		HrComponent,
		FinancesNewRouteComponent,
		NotificationsMenuComponent,
		DiplomaEnclosureComponent,
		MessagesComponent2,

		// dialogs
		ProgressDialog,
		ConfirmComponent,
		LanguageFormComponent,
		BookConditionGroupDialog,
		BookFormDialog,
		BookComponentsForm,
		BookComponentForm,
		BookConditionsDialog,
		ConfirmDialog,
		AlertDialog,
		StatementMessageDialog,
		RequiredMessageDialog,
		GenComponentsForBooksDialog,
		SyllabusesDialog,
		FinancesStudentJobComponent,
		ProfStatementDialog,

		StudentFormDialog,
		StudentExportFormDialog,
		StudentActionDialog,
		StudentEditLogDialog,

		SyllabusUploaderDialog,
		BookComponentAdditionalDataDialog,
		ExportStudentComponentDialog,
		NotificationRecipientFormDialog,
		FinanceEventFormComponent1,
		FinanceEventFormComponent2,
		StudentSmsDialogComponent,
		StudentSmsMessageDialogComponent,
		SubBookFormComponent,
		FinanceGrantEventFormComponent,
		FinancePrevBalanceFormComponent,
		BookCatDialog,
		PollAlertComponent,
		ExportTestingProgressDialog,
		SeminarOrLectureDialog,
		RegisteredStudentsDialog,
		ProfBookDialog,
		EditSemesterDialog,
		UserPasswordDialog,
		PraqtikaDialog,
		PraqtikaStatusDialog,
		CommandStudentStatus,
		RuleStudentsDialog,
		LoginTimeoutDialog,
		ProgressSpinnerDialogComponent,
		SendSmsDialog,
		MobilityBooksFormDialog,
		StudentBanDialog,
		AnalyticsMenu,
		Chat,
		BookLogsDialog,
		StudentActionFormDialog,
		BookProfAddDialogComponent
	],
	providers: [
		AppSettings,
		//{provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
		{provide: OverlayContainer, useClass: CustomOverlayContainer},
		JwtHelperService,
		FileStorage,
		AuthModel,
		AuthService,
		UserService,
		PermissionModel,
		RoleModel,
		PermissionService,
		CacheService,
		ListService,

		{provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
		{provide: MAT_DATE_LOCALE, useValue: 'en-EN'},
		{provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
		//{provide: ECHO_CONFIG, useValue: echoConfig },


		AlertService,
		LangService,
		ErrorsDialog,
		StudentsService,


		NotificationService,
		MessagesService,
		HelperService,
		ConfirmService,
		DefaultConfig,
		EducationInfoListService,
		DocsService,
		BooksService,
		ProgramService,
		PrintService,
		LogService,
		MailboxService,
		MenuService,
		ChancelleryService,
		ComponentService,
		RouterService,
		PollAlertService,
		SettingService,
		RawListService,
		SmsService,
		UserNotificationService,
		AppService,
		StepOverService,
		HrCalendarCommonService,
		HrService,
		CsvWriterService,
		FinancesNewService,
		FinanceService,
		RegistrationService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
