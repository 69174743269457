import {Component, EventEmitter, Output} from "@angular/core";
import {MessageTextComponent} from "../text/message-text.component";
import {UploadedFile} from "../../../../interfaces/UploadedFile";
import {Config} from "../../../../config";

@Component({
	selector: 'ini-chat-message-file',
	templateUrl: './message.file.component.html',
	styleUrls: [
		'../text/message-text.component.scss',
		'./message.file.component.scss',
	]
})
export class MessageFileComponent extends MessageTextComponent {

	// noinspection JSUnusedGlobalSymbols
	downloadUrl = Config.storageUrl;
	@Output() onSlide: EventEmitter<any> = new EventEmitter<any>();


	override ngOnInit(): void {
		this.me = this.message.user.id === this.userService.user.id;
		try {
			this.file = JSON.parse(this.message.text) as UploadedFile;
		} catch (ex) {
			this.file = null;
		}
	}

	link() {
		// return this.downloadUrl + this.file?.filename;
		return this.file?.filename;
	}

}
