import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ChancelleryResponse} from "./interfaces/chancellery.response";

@Injectable()
export class ChancelleryService {

	constructor(
		private http: HttpClient
	) {

	}

	editorConfig: any = {
		editable: true,
		spellcheck: true,
		minHeight: '10rem',
		maxHeight: '15rem',
		placeholder: 'Enter text here...',
		translate: 'no',
		sanitize: false,
		toolbarPosition: 'top',
		defaultFontName: 'Arial',
		customClasses: [
			{
				name: 'winSize',
				class: 'winSize',
			},
			{
				name: 'quote',
				class: 'quote',
			},
			{
				name: 'redText',
				class: 'redText'
			},
			{
				name: 'titleText',
				class: 'titleText',
				tag: 'h1',
			},
		]
	};

	categories = [
		{id: 1, name: "შემოსული კორესპოდენცია"},
		{id: 2, name: "გასული კორესპოდენცია"},
		{id: 3, name: "ბრძანებები"},
		{id: 4, name: "ხელშეკრულება"},
	];
	types = [
		{id: 1, name: "ორგანიზაციები და მესამე პირები", sub: 1},
		{id: 2, name: "უნივერსიტეტის სტუდენტის განცხადებები", sub: 1},
		{
			id: 3,
			name: "აბიტურიენტების, მაგისტრანტობის კანდიდატებისა და მობილობის მსურველი სტუდენტების განცხადებები",
			sub: 1
		},
		{id: 4, name: "კურსდამთავრებულთა განცხადებები", sub: 1},
		{id: 5, name: "საუნივერსიტეტო კონკურსების მონაწილეთა განცხადებები", sub: 1},
		{id: 6, name: "აკადემიური პერსონალი", sub: 1},

		{id: 7, name: "წერილი", sub: 2},
		{id: 8, name: "მინდობილობა", sub: 2},
		{id: 10, name: "ანგარიშ-ფაქტურა", sub: 2},
		{id: 11, name: "დახასიათება-რეკომენდაცია", sub: 2},
		{id: 12, name: "ცნობა", sub: 2},
		{id: 13, name: "დიპლომი", sub: 2},
		{id: 14, name: "დიპლომის დანართი", sub: 2},

		{id: 15, name: "სტუდენტი", sub: 3},
		{id: 16, name: "ადმინისტრაცია", sub: 3},
		{id: 17, name: "აკადემიური პერსონალი", sub: 3},
		{id: 18, name: "სხვა", sub: 3},

		{id: 19, name: "ადმინიატრაცია", sub: 4},
		{id: 20, name: "აკადემიური პერსონალი", sub: 4},
		{id: 21, name: "ორგანიზაცია/ მესამე პირები", sub: 4},
		{id: 22, name: "ადმინისტრაცია", sub: 1}
	];
	statuses = [
		{id: "current", name: "მიმდინარე"},
		{id: "end", name: "დასრულებული"},
		{id: "processing", name: "ხელმოწერილი"},
		{id: "repeal", name: "გაუქმებული"},
	];

	statusesSecond = [
		{id: "current", name: "მიმდინარე"},
		{id: "cancel", name: "უარყოფილი"},
		{id: "success", name: "დადასტურებული"},
		{id: "repeal", name: "გაუქმებული"}
	];

	getKaParams(id, objectName) {
		for (let category of this[objectName]) {
			if (category.id == id) {
				return category.name;
			}
		}
		return '';
	}


	items(filter: any, page: number, limit: number): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/list/?page=" + page + "&limit=" + limit, filter);
	}

	addOrEdit(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/adOrEdit", data);
	}

	attachFiles(fd): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/attachFiles", fd);
	}

	removeItem(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/removeItem", data);
	}

	getStudentInfo(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getStudentInfo", data);
	}

	getLogs(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getLogs", data);
	}

	getPersonalInfoByNumber(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getPersonalInfoByNumber", data);
	}

	getPersonNames(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getPersonNames", data);
	}

	getOrgInfoByNumber(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getOrgInfoByNumber", data);
	}

	getOrgNames(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getOrgNames", data);
	}

	getChancelleryInfo(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getChancelleryInfo", data);
	}

	getComments(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/journal/comment", data);
	}

	addComment(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/journal/comment/store", data);
	}

	getActions(filter, page, limit): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/journal/rolls/?page=" + page + "&limit=" + limit, filter);
	}

	addAction(data) {
		return this.http.post<ChancelleryResponse>("/chancellery/journal/roll", data);
	}

	searchUsers(data, exceptList=null): Observable<any> {
		return this.http.post<any[]>("/chancellery/searchUsers", {
			...data,
			exceptList: exceptList
		});
	}
	getUsers(): Observable<any> {
		return this.http.post<any[]>("/permission/getUsers/?page=1&limit=999999", {});
	}

	searchUsersCustom(data): Observable<any> {
		return this.http.post<any[]>("/chancellery/chancellerySearchUsers", data);
	}

	getDocumentDetails(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getDocumentDetails", data);
	}

	setStatus(data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/journal/status", data);
	}

	getTemplateList(page, limit, data): Observable<ChancelleryResponse> {
		return this.http.post<ChancelleryResponse>("/chancellery/getTemplateList/?page=" + page + "&limit=" + limit, data);
	}

	addTemplate(data) {
		return this.http.post<ChancelleryResponse>("/chancellery/addTemplate", data);
	}

	editTemplate(data) {
		return this.http.post<ChancelleryResponse>("/chancellery/editTemplate", data);
	}

	editTemplateDetails(data) {
		return this.http.post<ChancelleryResponse>("/chancellery/editTemplateDetails", data);
	}

	removeTemplate(data) {
		return this.http.post<ChancelleryResponse>("/chancellery/removeTemplate", data);
	}

	getTemplate(data) {
		return this.http.post<ChancelleryResponse>("/chancellery/getTemplate", data);
	}

	getProgramList() {
		return this.http.get<ChancelleryResponse>("/chancellery/getProgramList");
	}

	getMySentJournalList(page, limit) {
		return this.http.get<ChancelleryResponse>("/chancellery/journal/getMySentJournalList/?page=" + page + "&limit=" + limit);
	}

	getAllTemplate(data) {
		return this.http.post<any>("/chancellery/getAllTemplate", data);
	}

	getUserInfo() {
		return this.http.get<any>("/chancellery/getUserInfo");
	}

	sendTemplate(data) {
		return this.http.post<ChancelleryResponse>("/chancellery/journal/createJurnalItem", data);
	}

	getSentJournal(data) {
		return this.http.post<any>("/chancellery/journal/getSentJournal", data);
	}

	getJournalStatus(data): Observable<any> {
		return this.http.post<ChancelleryResponse>("/chancellery/journal/roll/my/status", data);
	}

	getJournalDocumentList(data, page, limit) {
		return this.http.post<ChancelleryResponse>("/chancellery/journal/getJournalDocumentList/?page=" + page + "&limit=" + limit, data);
	}

	addJournalDocument(data) {
		return this.http.post<any>("/chancellery/addJournalDocument", data);
	}

	uploadJournalDocumet(data) {
		return this.http.post<any>("/chancellery/uploadJournalDocument ", data);
	}

	removeDocument(data) {
		return this.http.post<any>("/chancellery/removeDocument ", data);
	}

	archive(data) {
		return this.http.post<any>("/chancellery/archive", data);
	}

	unarchive(data) {
		return this.http.post<any>("/chancellery/unarchive", data);
	}

	setFinalStatus(data) {
		return this.http.post<any>("/chancellery/setFinalStatus", data);
	}

	createPattern(data) {
		return this.http.post<any>("/chancellery/createPattern", data);
	}

	removePattern(data) {
        return this.http.post<any>("/chancellery/setFinalStatus", data);
    }

	reserveNumber(data) {
        return this.http.post<any>("/chancellery/reserveNumber", data);
    }

	patternsList(filter, page, limit) {
		return this.http.post<any>("/chancellery/patternsList/?page=" + page + "&limit=" + limit, filter);
	}

	getReservedNumberList(filter, page, limit) {
		return this.http.post<any>("/chancellery/getReservedNumberList/?page=" + page + "&limit=" + limit, filter);
	}

	getReservedNumbersListByType(data) {
		return this.http.post<any>("/chancellery/getReservedNumberList",data);
	}

	addOrEditJournalLetter(data) {
		return this.http.post<any>("/chancellery/addOrEditJournalLetter", data);
	}

	getJournalLetter(data) {
		return this.http.post<any>("/chancellery/getJournalLetter", data);
	}

	JournalLetterSeen(data) {
		return this.http.post<any>("/chancellery/JournalLetterSeen", data);
	}

	setLetterEditStatus(data) {
		return this.http.post<any>("/chancellery/setLetterEditStatus", data);
	}

	getIncomingJournalAlert(data):Observable<any> {
		return this.http.get<any>("/chancellery/getIncomingJournalAlert", data);
	}

	setSeenIncomingJournal(data) {
		return this.http.post<any>("/chancellery/setSeenIncomingJournal", data);
	}

	updateSettings(data) {
		return this.http.post<any>("/chancellery/settingUpdate", data);
	}

	getSettings(data) {
		return this.http.post<any>("/chancellery/getSettings", data);
	}

	getSignStatus(data) {
		return this.http.post<any>("/chancellery/getSignStatus", data);
	}

	getOneUserConfirm(data): Observable<any> {
		return this.http.post<any>("/chancellery/oneUserConfirm", data);
	}

	updateOneUserConfirm(data): Observable<any> {
		return this.http.post<any>("/chancellery/updateOneUserConfirm", data);
	}

	archiveForMe(data): Observable<any> {
		return this.http.post<any>("/chancellery/archiveForMe", data);
	}

	unArchiveForMe(data): Observable<any> {
		return this.http.post<any>("/chancellery/unArchiveForMe", data);
	}

	changeJurnalTextFile(data): Observable<any> {
		return this.http.post<any>("/chancellery/journal/changeJurnalTextFile", data);
	}
	getLastExamId(data): Observable<any> {
		return this.http.post<any>("/chancellery/journal/getLastExamId", data);
	}

	addExamScore(data): Observable<any> {
		return this.http.post<any>("/testing/addExamScore", data);
	}

	getAdditionalScore(data): Observable<any> {
		return this.http.post<any>("/testing/getAdditionalScore", data);
	}

	massArchive(data): Observable<any> {
		return this.http.post<any>("/chancellery/massArchive", data);
	}

	metaForChancellery(): Observable<any> {
		return this.http.get("/educationInfo/meta");
	}

	getUsersFromHr(filter): Observable<any> {
		return this.http.post<any>('/hr/users', filter);
	}

	getProjects(filter = {}) {
		return this.http.post<any>('/chancellery/projects', filter);
	}

}
