import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Inject,
	OnDestroy,
	ViewEncapsulation
} from "@angular/core";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatCalendar} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MatDateFormats} from "@angular/material/core";

declare let moment: any;

@Component({
	selector: "ini-date-picker-header",
	templateUrl: "./ini-date-picker-header.html",
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ["./ini-date-picker-header.scss"],
	encapsulation: ViewEncapsulation.None
})
export class IniDatePickerHeader<D> implements OnDestroy {
	private destroyed = new Subject<void>();
	form: FormGroup;
	months = [
		{id: 0, name: "იანვარი"},
		{id: 1, name: "თებერვალი"},
		{id: 2, name: "მარტი"},
		{id: 3, name: "აპრილი"},
		{id: 4, name: "მაისი"},
		{id: 5, name: "ივნისი"},
		{id: 6, name: "ივლისი"},
		{id: 7, name: "აგვისტო"},
		{id: 8, name: "სექტემბერი"},
		{id: 9, name: "ოქტომბერი"},
		{id: 10, name: "ნოემბერი"},
		{id: 11, name: "დეკემბერი"},
	];
	years = [{
		id: 0,
		name: 0
	}];

	constructor(
		private calendar: MatCalendar<D>,
		private dateAdapter: DateAdapter<D>,
		private fb: FormBuilder,
		@Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
		cdr: ChangeDetectorRef
	) {
		const y = (new Date).getFullYear();
		this.years = [];
		for (let i = y - 50; i < y + 50; i++) {
			this.years.push({
				id: i,
				name: i
			});
		}
		this.form = fb.group({
			m: "",
			y: ""
		});
		calendar.stateChanges
			.pipe(takeUntil(this.destroyed))
			.subscribe(() => cdr.markForCheck());
		this.updateValue(calendar.activeDate);
		calendar.selectedChange.subscribe(result => {
			this.updateValue(result);

		});
		this.form.get("m").valueChanges.subscribe((m) => {
			const currentDate = moment(this.calendar.activeDate);
			if (currentDate.day() > 0) {
				currentDate.month(m);
				this.calendar.activeDate = this.dateAdapter.createDate(
					currentDate.year(),
					currentDate.month(),
					currentDate.day()
				);
			}
		});
		this.form.get("y").valueChanges.subscribe((y) => {

			const currentDate = moment(this.calendar.activeDate);
			//
			if (currentDate.day() > 0) {
				currentDate.year(y);
				this.calendar.activeDate = this.dateAdapter.createDate(
					currentDate.year(),
					currentDate.month(),
					currentDate.date()
				);
			}
		});
	}

	updateValue(result) {
		this.form.patchValue({
			m: parseInt(moment(result).format("M")) - 1,
			y: parseInt(moment(result).format("Y"))
		});
	}

	ngOnDestroy(): void {
		this.destroyed.next();
		this.destroyed.complete();
	}

	get periodLabel() {
		return this.dateAdapter
			.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel)
			.toLocaleUpperCase();
	}

	previousClicked(mode: 'month' | 'year') {
		this.calendar.activeDate = mode === 'month' ?
			this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1) :
			this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
	}

	nextClicked(mode: 'month' | 'year') {
		this.calendar.activeDate = mode === 'month' ?
			this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1) :
			this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
	}


}
