import {Component, EventEmitter, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {ListService} from "../../list.service";
import {ActivatedRoute} from "@angular/router";
import {ListData} from "./list.data";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EstResultForm} from "./est-result-form/est-result-form";
import {EditStudentListComponent} from "../../edit-student-list/edit-student-list.component";
import {EditListComponents} from "./edit-list-components/edit-list-components";
import {Socket} from "ngx-socket-io";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {Student} from "src/app/pages/students/students.models";
import {PermissionService} from "../../../../../core/services/permission.service";
import {UniConfig} from "../../../../../../uni.config";
import {ComponentService} from "../../../../components/component.service";
import {UserService} from "../../../../../core/services/user.service";
import {EstSignFormDialog} from "./est-sign-form/est-sign-form.dialog";
import {ConfirmDialog} from "../../../../../theme/components/confirmDialog/confirm.dialog";
import {ErrorsDialog} from "../../../../../shared/error-dialogs/errors.dialog";
import {ProgressSpinnerDialogComponent} from "../../../../../shared/components/progress-spinner-dialog/progress-spinner-dialog.component";
import {Observable} from "rxjs";

declare const document: any;

@Component({
	selector: "est-view-component",
	templateUrl: "./est-view.component.html",
	styleUrls: [
		"./est-view.component.scss",
		"./../../../../students/student-details/result/result.component.scss"
	],
	encapsulation: ViewEncapsulation.None
})
export class EstViewComponent implements OnInit, OnDestroy {

	dataLoaded = false;
	subs = [];
	listId = 0;
	listData = new ListData();
	searchForm: FormGroup;
	components = {};
	est: { [studentId: number]: { [coId: number]: any } } = {};
	//compData = {};
	groupStudents = {};
	defaultImage = "assets/img/users/default-user.jpg";
	componentPermissions: any = {};
	notBannedStudents = false;
	examIsAllowed = false;
	uniConfig = UniConfig;
	isProf = false;

	matherBook = {
		groupList: [],
		selectedGroups: []
	}


	constructor(
		public service: ListService,
		public router: ActivatedRoute,
		public dialog: MatDialog,
		public sanitizer: DomSanitizer,
		public fb: FormBuilder,
		public permissionService: PermissionService,
		public title: Title,
		public componentService: ComponentService,
		public userService: UserService,
		public errors: ErrorsDialog,
	) {
		this.searchForm = fb.group({
			name: "",
			groupName: "",
		});
		title.setTitle('უწყისი');
		this.isProf = this.userService.user.guard == 'prof';
	}

	result(student, component) {
		if (typeof student == "undefined") return '';
		if (typeof student.id == "undefined") return '';

		if (
			typeof this.listData != "undefined"
			&& typeof this.listData.est != "undefined"
			&& typeof this.listData.est[student.id] != "undefined"
			&& typeof this.listData.est[student.id][component.coId] != "undefined"
			&& typeof this.listData.est[student.id][component.coId]["result"] != "undefined"
		) {
			return this.sanitizer.bypassSecurityTrustHtml(this.listData.est[student.id][component.coId]["result"]);
			//return this.listData.est[student.id][component.coId]["result"];
		}
		return "";
	}


	parseEst() {
		this.est = {};
		for (let groupId in this.listData.students) {
			for (let group of this.listData.groups) {
				if (parseInt(groupId) == parseInt(group.id)) {
					for (let com of group.components) {
						for (let student of this.listData.students[groupId]) {
							if (typeof this.est[student.id] == "undefined") {
								this.est[student.id] = {};
							}
							if (
								typeof this.listData.est[student.id] != "undefined"
								&& typeof this.listData.est[student.id][com.coId] != "undefined"
								&& typeof this.listData.est[student.id][com.coId]["result"] != "undefined"
							) {
								this.est[student.id][com.coId] = this.sanitizer.bypassSecurityTrustHtml(this.listData.est[student.id][com.coId]["result"]);
								if (typeof this.listData.est[student.id][com.coId]['items'] != "undefined") {
									this.est[student.id][com.coId]['items'] = this.listData.est[student.id][com.coId]['items'];
								}

								//this.est[student.id][com.coId] = this.listData.est[student.id][com.coId]["result"];
							} else {
								this.est[student.id][com.coId] = '';
							}
						}
					}
				}
			}
		}
	}

	editGroups() {
		let ref = this.dialog.open(EditStudentListComponent, {
			data: this.listData.list
		});
		ref.afterClosed().subscribe(() => {
			this.getListDetails();
		});
	}

	editComponents(group) {
		let ref = this.dialog.open(EditListComponents, {
			width: "800px",
			data: {
				group: group,
				allComponents: this.components,
				list: this.listData.list,
				book: this.listData.book
			}
		});
		ref.afterClosed().subscribe(() => {
			this.getListDetails();
		});
	}

	setResults(student, group) {
		for (let com of group.components) {
			let key = -1;
			if (this.components[com.coId].type == 3) {
				continue;
			}
			for (let item of com.items) {
				key++;
				if (
					typeof this.listData.est[student.id] != "undefined" &&
					typeof this.listData.est[student.id][com.coId] != "undefined" &&
					typeof this.listData.est[student.id][com.coId]["items"] != "undefined" &&
					typeof this.listData.est[student.id][com.coId]["items"][key] != "undefined"
				) {
					item.value = this.listData.est[student.id][com.coId]["items"][key]["value"];
					item.result = this.listData.est[student.id][com.coId]["items"][key]["result"];
				} else {
					item.value = "";
					item.result = "";
				}
			}

		}
		return group;
	}

	editResult(student, group, com1) {
		if (student.ban == "yes") {
			return;
		}
		if (this.components[com1.coId].type == 3) {
			return;
		}
		if (!this.permissionService.can("allComponentWriter")) {
			if (typeof this.componentPermissions[com1.coId] == "undefined") {
				return;
			}
		}
		group = this.setResults(student, group);

		let canPrev = false;
		let old = null;
		for (let s of this.groupStudents[group.id]) {
			if (s.id == student.id && old != null) {
				canPrev = true;
				break;
			}
			old = s;
		}
		let canNext = false;
		let found = false;
		for (let s of this.groupStudents[group.id]) {
			if (found) {
				canNext = true;
				break;
			}
			if (s.id == student.id) {
				found = true;
			}
		}

		let ref = this.dialog.open(EstResultForm, {
			panelClass: "my-panel",
			data: {
				canPrev: canPrev,
				canNext: canNext,
				event: this.estEvent,
				student: student,
				group: group,
				component: com1,
				list: this.listData.list,
				allComponents: this.components
			}
		});
		ref.afterClosed().subscribe(result => {
			if (result) {
				if (result == "next") {
					this.next(student, group, com1);
				} else if (result == "prev") {
					this.prev(student, group, com1);
				}
				//this.getListDetails();
			}
		});
	}

	estData(student, group, com1) {
		if (this.components[com1.coId].type == 3) {
			return true;
		}
		group = this.setResults(student, group);
		return this.checkCustomValidation({
			student: student,
			group: group,
			component: com1,
			list: this.listData.list,
			allComponents: this.components
		});
	}

	checkCustomValidation(data) {
		let fields = {};
		if (typeof data.component == "object" && data.component != null) {
			if (typeof data.component.items == "object" && data.component.items != null && typeof data.component.items.length != "undefined") {
				let formIndex = 0;
				for (let item of data.component.items) {
					if (typeof item.data == "object" && item.data != null && typeof item.data.component != "undefined") {
						//
						let otherCompEst: any = this.getOtherCompEst(item.data.component, data);
						if (typeof otherCompEst == "object" && otherCompEst != null) {
							//
							let count = 0;
							let sum = 0;
							let max = 0;
							let i = 0;


							if (item.data.groupType == "group") {

								for (let est of otherCompEst.items) {
									try {

										let groupName = "-";
										if (typeof est.group == "undefined" || est.group == null || est.group == '' || est.group == 0) {
											groupName = "-";
										} else {
											groupName = est.group;
										}
										if (groupName == item.data.from) {
											let est_data = [];
											try {
												if (typeof est.value == "string") {
													est_data = JSON.parse(est.value);
												}
											} catch (ex) {
												est_data = [];
											}

											for (let j = 0; j < est.weight; j++) {
												i++;
												//
												if (typeof est_data[j] != "undefined" && est_data[j] == 1) {
													sum += 0.1;
												}
												max += 0.1;
												count++;
											}

										}
									} catch (e) {
									}
								}
							} else {
								for (let est of otherCompEst.items) {
									//
									try {

										let est_data = [];
										try {
											if (typeof est.value == "string") {
												est_data = JSON.parse(est.value);
											}
										} catch (ex) {
											est_data = [];
										}
										for (let j = 0; j < est.weight; j++) {
											i++;
											//
											if (i >= item.data.from && i <= item.data.to) {
												if (typeof est_data[j] != "undefined" && est_data[j] == 1) {
													sum += 0.1;
												}
												max += 0.1;
												count++;
											}
										}

									} catch (ex) {
									}
								}
							}
							if (item.data.type == 'percentage') {
								if (max > 0) {
									sum = this.round(100 / max * sum);
								} else {
									sum = 0;
								}
							}
							fields[formIndex] = sum >= item.data.argument;
							if (!fields[formIndex]) {
								return false;
							}
						}
					}
					formIndex++;
					fields[formIndex] = true;
				}
			}
		}
		// return fields;
		return true;
	}

	getOtherCompEst(componentCode, data) {
		let coId = 0;
		let type = 0;
		let selectableValues = [];
		for (let i in data.allComponents) {
			if (data.allComponents[i].code == componentCode) {
				coId = data.allComponents[i].id;
				type = data.allComponents[i].type;
				selectableValues = typeof data.allComponents[i].selectableValues == "object" && data.allComponents[i].selectableValues != null ? data.allComponents[i].selectableValues : [];
				break;
			}
		}
		if (coId != 0) {
			for (let comp of data.group.components) {
				if (coId == comp.coId) {
					if (type == 4) {
						return {
							selectable: selectableValues,
							items: comp.items
						};
					}
				}
			}
		}
		return false;
	}

	round(a: number, b: number = 0) {
		return parseFloat(Number(this.toInt(a)).toFixed(b));
	}

	toInt(a: any) {
		let b: any = parseFloat(a);
		if (b + "" == "NaN") return 0; else return b;
	}

	next(student, group, com) {
		let found = false;
		for (let s of this.groupStudents[group.id]) {
			if (found) {
				this.editResult(s, group, com);
				break;
			}
			if (s.id == student.id) {
				found = true;
			}
		}
	}

	prev(student, group, com) {
		let old = null;
		for (let s of this.groupStudents[group.id]) {
			if (s.id == student.id && old != null) {
				this.editResult(old, group, com);
				break;
			}
			old = s;
		}
	}

	getListDetails() {
		this.service.getListDetails({
			id: this.listId,
			matherBook: this.matherBook
		}).subscribe(response => {
			this.listData = response.items;
			this.components = response.items.components;
			this.componentPermissions = response.permissions;
			this.parseEst();
			this.searchStudents(this.list_saved[this.listId]);
			this.afterLoad();
			this.dataLoaded = true;
		});
	}

	afterLoad() {

	}

	studentName = "";

	ngOnInit(): void {
		this.subs.push(this.router.params.subscribe(params => {
			this.listId = params.listId;
			if (typeof params.studentName != "undefined") {
				this.studentName = params.studentName;
			}
			//this.getListDetails();
			this.socketSubscribe();
			this.loadStorage();
			this.getBookGroupForList();
		}));
	}

	list_saved: any = {};

	loadStorage() {
		this.list_saved = localStorage.getItem("list-search-saved");
		if (this.list_saved == null) {
			this.list_saved = {};
		} else {
			try {
				this.list_saved = JSON.parse(this.list_saved);
			} catch (e) {
				this.list_saved = {};
			}
		}
		if (typeof this.list_saved[this.listId] != "undefined" && this.list_saved[this.listId] != '' && this.list_saved[this.listId] != null) {
			this.searchForm.patchValue(
				this.list_saved[this.listId]
			);
		} else {
			this.searchForm.patchValue({
				name: "",
				groupName: "",
			});
		}


		this.searchForm.valueChanges.subscribe((value) => {
			if (typeof value == "object" && value != null) {
				this.list_saved[this.listId] = value;
				localStorage.setItem("list-search-saved", JSON.stringify(this.list_saved));
			}
			this.searchStudents(value);
		});
		if (this.studentName != "") {
			this.searchForm.patchValue({
				name: this.studentName
			});
		}

	}

	searchStudents(data: any) {
		let groups = this.listData.students;
		if (typeof data == "object" && typeof data != null) {
			if (typeof data.groupName != "undefined" && data.groupName != '') {
				let g: any = {};
				for (let groupId in groups) {
					let originalGroup = null;
					for (let gg of this.listData.groups) {
						if (gg.id == groupId) {
							originalGroup = gg;
							break;
						}
					}
					if (originalGroup != null) {
						if ((originalGroup.name + "").indexOf(data.groupName) != -1) {
							g[groupId] = groups[groupId];
						}
					}
				}
				groups = g;
			}


			if (typeof data.name != "undefined" && data.name != '') {
				this.groupStudents = {};
				for (let groupId in groups) {
					this.groupStudents[groupId] = groups[groupId].filter((student) => {
						return student.name.indexOf(data.name) != -1;
					});
				}
				return;

			}
		}
		this.groupStudents = groups;
	}


	getSubscribers() {
		this.service.emit("getSubscribers");
	}

	onData: (data) => void = null;
	onConnection: () => void = null;
	onDisconnect: () => void = null;

	subKey = "";
	so: Socket = null;
	socketConnection = false;

	estEvent = new EventEmitter<any>();

	socketSubscribe() {

		this.onData = (data) => {

			this.estEvent.emit(data);
			this.componentService.debugEvents.emit(data);
			switch (data.eventName) {
				default:
					// continue
					break;
				case "calcEst":
					if (typeof this.listData.est[data.studentId] == "undefined") {
						this.listData.est[data.studentId] = [];
					}
					if (typeof this.listData.est[data.studentId][data.coId] == "undefined") {
						this.listData.est[data.studentId][data.coId] = {
							items: [],
							result: 0
						};
					}
					this.listData.est[data.studentId][data.coId]["items"] = data.result;
					break;
				case "calcEstSum":
				case "calcEstGroup":

					if (typeof this.listData.est[data.studentId] == "undefined") {
						this.listData.est[data.studentId] = [];
					}
					if (typeof this.listData.est[data.studentId][data.coId] == "undefined") {
						this.listData.est[data.studentId][data.coId] = {
							items: [],
							result: 0
						};
					}
					this.listData.est[data.studentId][data.coId]["items"]["value"] = data.result;

					if (typeof this.est[data.studentId] == "undefined") {
						this.est[data.studentId] = [];
					}
					this.est[data.studentId][data.coId] = this.sanitizer.bypassSecurityTrustHtml(data.result);
					//this.est[data.studentId][data.coId] = data.result;

					break;
			}


		};

		this.onConnection = () => {
			if (this.socketConnection) {
				return;
			}
			this.socketConnection = true;
			this.subKey = Math.random().toString(36).substr(2, 9);
			this.service.emit("subscribe", {
				channelName: "list-" + this.listId,
				key: this.subKey
			});
		};
		this.onDisconnect = () => {
			this.socketConnection = false;
		};
		this.service.on("hello", this.onConnection);
		this.service.on("disconnect", this.onDisconnect);
		try {
			this.service.emit("testConnection");
		} catch (ex) {
		}


		this.service.on("debug", this.onData);
		this.service.on("calcEst", this.onData);
		this.service.on("calcEstSum", this.onData);
		this.service.on("calcEstGroup", this.onData);
		this.service.on("subscriberList", this.onData);
	}

	ngOnDestroy(): void {
		for (let sub of this.subs) {
			try {
				sub.unsubscribe();
			} catch (ex) {
			}
		}
		this.service.removeListener("calcEst", this.onData);
		this.service.removeListener("calcEstSum", this.onData);
		this.service.removeListener("calcEstGroup", this.onData);
		this.service.removeListener("subscriberList", this.onData);
		this.service.removeListener("connection", this.onConnection);
		this.service.emit("unSubscribe", {
			key: this.subKey
		});
		document.body.classList.remove("printable");
	}

	profs: string[] | string = [];
	printableGroups = [];
	printData: any = {};
	seasons = {
		spring: "საგაზაფხულო",
		summer: "საზაფხულო",
		autumn: "საშემოდგომო"
	};

	print() {
		this.getPrintDetails();
		document.body.classList.add("printable");
		this.printFilter();
	}

	printCancel() {
		document.body.classList.remove("printable");
	}

	resetList(): void {
		const ref = this.dialog.open(ConfirmDialog, {
			data: {
				title: "უწყისის გასუფთავება",
				text: "ნამდვილად გსურთ ამ მოქმედების გაკეთება?",
				ok: "დიახ",
				cancel: "არა"
			}
		});

		ref.afterClosed().subscribe(result => {
			if (!result) return;
			const data = this.getDataForReset();
			this.showProgressSpinnerUntilExecuted(this.service.resetList({data: data, listId: this.listId}));
		});
	}


	// noinspection DuplicatedCode
	showProgressSpinnerUntilExecuted(observable: Observable<Object>) {
		let dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.dialog.open(ProgressSpinnerDialogComponent, {
			panelClass: 'custom-spinner-dialog',
			disableClose: true
		});
		let subscription = observable.subscribe({
			next: (response: any) => {
				subscription.unsubscribe();

				if (response.result == "yes") {
					window.location.href = response.link;
					this.getListDetails();
				} else {
					this.errors.show(response);
				}
				dialogRef.close();
			},
			error: () => {
				subscription.unsubscribe();
				dialogRef.close();
			}
		});
	}


	getDataForReset(): any {
		let items = [];

		for (let group of this.listData.groups) {
			let groupStudents = {
				groupName: group.name,
				students: []
			};
			for (let student of this.groupStudents[group.id]) {
				let studentData = {
					student: student.name,
					studentPersonalNumber: student.personalNumber,
					studentMail: student.mail,
					studentPhone: student.phone,
					components: []
				};
				for (let com of group.components) {
					if (com.items.length > 1) {
						for (let i in com.items) {
							let subName = com.items.name == null || com.items.name == '' || typeof com.items.name == 'undefined' ? parseInt(i) + 1 : com.items.name;
							studentData.components.push({
								name: com.name + " - " + subName,
								value: typeof this.est[student.id][com.coId].items != "undefined" && typeof this.est[student.id][com.coId].items[i] != "undefined" ? this.est[student.id][com.coId].items[i].result : "",
								isOk: this.estData(student, group, com)
							});
						}
					} else {
						studentData.components.push({
							name: com.name,
							value: typeof this.est[student.id][com.coId] == "object" ? this.est[student.id][com.coId].changingThisBreaksApplicationSecurity : this.est[student.id][com.coId],
							isOk: this.estData(student, group, com)
						});
					}
				}


				groupStudents.students.push(studentData);
			}
			items.push(groupStudents);
		}
		return items;

	}


	//
	// showProgressSpinnerUntilExecuted(observable: Observable<Object>) {
	// 	let dialogRef: MatDialogRef<ProgressSpinnerDialogComponent> = this.dialog.open(ProgressSpinnerDialogComponent, {
	// 		panelClass: 'custom-spinner-dialog',
	// 		disableClose: true
	// 	});
	// 	let subscription = observable.subscribe(
	// 		(response: any) => {
	// 			subscription.unsubscribe();
	//
	// 			if (response.result == "yes") {
	// 				this.ref.close(true);
	// 			} else {
	// 				this.errors.show(response);
	// 			}
	// 			dialogRef.close();
	// 		},
	// 		(error) => {
	// 			subscription.unsubscribe();
	// 			dialogRef.close();
	// 		}
	// 	);
	// }


	filteredGroups = [];

	printFilter() {
		if (this.printableGroups.length > 0) {
			this.filteredGroups = this.listData.groups.filter(group => {
				return this.printableGroups.indexOf(group.id) != -1;
			});
		} else {
			this.filteredGroups = this.listData.groups;
		}
		this.setProfs();
	}


	getPrintDetails() {
		this.service.getListPrintDetails({
			listId: this.listId,
			firstStudentId: this.getFirstStudentId(),
		}).subscribe(response => {
			this.printData = response;
			this.setProfs();
		});
	}

	setProfs() {
		if (this.printableGroups.length > 0) {
			let p = [];
			for (let g of this.printableGroups) {
				if (typeof this.printData.profByGroups[g] != "undefined") {
					if (typeof this.printData.profByGroups[g].length != "undefined") {
						for (let profName of this.printData.profByGroups[g]) {
							if (p.indexOf(profName) == -1) {
								p.push(profName);
							}
						}
					}

				}
			}
			this.profs = p.join(", ");
		} else {
			this.profs = this.printData.profs;
		}
	}

	levels = Student.levelNums();

	getFirstStudentId() {
		for (let group of this.listData.groups) {
			// noinspection LoopStatementThatDoesntLoopJS
			for (let student of this.groupStudents[group.id]) {
				return student.id;
			}
		}
		return 0;
	}

	componentResultById(student, coId) {
		if (typeof this.est != "undefined" && this.est != null) {
			if (typeof this.est[student.id] != "undefined" && this.est[student.id] != null) {
				if (typeof this.est[student.id][coId] != "undefined" && this.est[student.id][coId] != null) {
					return this.est[student.id][coId];
				}
			}
		}
		return "-";
	}

	componentDetailsById(group
		                     :
		                     any, student, coId
	) {
		let data = {
			type: 0
		};
		for (let com of group.components) {
			if (com.coId == coId) {
				return com;
			}
		}
		return data;
	}

	objAt(o, key) {
		if (typeof o == "undefined" || o == null) return '';
		if (typeof o[key] != "undefined") {
			return o[key];
		}
		return '';
	}

	getBookGroupForList() {
		this.service.getBookGroupForList({
			id: this.listId
		}).subscribe(response => {
			this.matherBook.groupList = response;
			this.selectAllGroupElement(response);
			this.getListDetails();
		});
	}

	selectAllGroupElement(groupList) {
		for (let gr of groupList) {
			this.matherBook.selectedGroups.push(gr.id)
		}

	}

	tokaDay(dayNum) {
		let days = [
			"ორშაბათი",
			"სამშაბათი",
			"ოთხშაბათი",
			"ხუთშაბათი",
			"პარასკევი",
			"შაბათი",
			"კვირა"
		];
		return days[dayNum];
	}

	sign(listId
		     :
		     number
	):
		void {

		const firstConfirm = this.dialog.open(ConfirmDialog, {
			data: {
				title: "ხელმოწერა",
				text: "ამ ხელმოწერით ვადასტურებ უწყისში არსებული ქულების სისწორეს",
				ok: "დიახ",
				cancel: "არა"
			}
		});

		firstConfirm.afterClosed().subscribe(resultOne => {
			if (!resultOne) return;

			const secondConfirm = this.dialog.open(ConfirmDialog, {
				data: {
					title: "ხელმოწერა",
					text: "გთხოვთ, გაითვალისწინოთ თქვენს ხელმოწერას გააჩნია იურიდიული ძალა, თანახმა ხართ მოაწეროთ ხელი?",
					ok: "დიახ",
					cancel: "არა"
				}
			});

			secondConfirm.afterClosed().subscribe(resultSecond => {
				if (!resultSecond) return;

				const ref = this.dialog.open(EstSignFormDialog, {
					width: "500px",
					disableClose: true,
					data: {
						listId: listId
					}
				});

				ref.afterClosed().subscribe(result => {

				});

			});

		});

	}


	getGroupName(group) {
		const curation = group.curation ? ' (' + group.curationFromDate + ' - ' + group.curationToDate + ')' : '';
		return group.profName + ' ' + this.tokaDay(group.day) + ' ' + group.h + ':' + group.m + ' ' + group.auditoria + curation;
	}
}
