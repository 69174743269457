import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ListService} from "../../../lists/list/list.service";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";

@Component({
    selector: "gen-components-for-books-dialog",
    templateUrl: "./gen-components-for-books-dialog.html"
})
export class GenComponentsForBooksDialog implements OnInit{

    form: FormGroup;

    constructor(
        private ref: MatDialogRef<GenComponentsForBooksDialog>,
        private service: ListService,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private error: ErrorsDialog
    ) {
        this.form = fb.group({
            bookSelector: ["", Validators.required]
        });
    }

    cancel() {
        this.ref.close();
    }

    ok() {
        this.service.genComponentsForBooks({
            from: this.data.bookId,
            to: this.form.value.bookSelector
        }).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close();
            } else {
                this.error.show(response);
            }
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            bookSelector: []
        });
    }

}