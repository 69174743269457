import {AuthModel, AuthModelData} from "../models/auth.model";
import {EventEmitter, Injectable, OnDestroy, OnInit} from "@angular/core";
import {PermissionModel} from "../models/permission.model";
import {RoleModel} from "../models/role.model";
import {JwtHelperService} from "@auth0/angular-jwt";
import {UserData} from "./user.data";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {LoginTimeoutDialog} from "../../shared/components/login-timeout/login-timeout.dialog";
import {environment} from "../../../environments/environment";

declare const localStorage: any;

@Injectable()
export class UserService implements OnDestroy, OnInit {
	token: string | null = "";
	is_logged = false;
	user: UserData = new UserData();
	onChange: EventEmitter<string> = new EventEmitter();
	timer: any = null;
	lastUpdate: any = 0;
	loginTimeout = 900;
	debug = false;
	sessionDialogMode = false;
	loginTimeOutStatus = false; // ჩართვა/გამორთვა

	constructor(
		private auth: AuthModel,
		private permission: PermissionModel,
		private role: RoleModel,
		private http: HttpClient,
		private router: Router,
		private dialog: MatDialog
	) {
		if (this.loginTimeOutStatus) {
			document.body.addEventListener("click", () => {
				if (this.is_logged && !this.sessionDialogMode) {
					if (this.debug) {

					}
					this.addLoginTimeout(true);
				}
			})
			this.parseToken(localStorage.getItem("token"));
			if (this.is_logged) {
				this.addLoginTimeout(true, true);
			} else {
				if (this.debug) {

				}
			}
		}
	}

	checkProfStatement(): Observable<any> {
	  return this.http.get('/user/check-prof-statement');
	}

	setProfStatement(data: any): Observable<any> {
	  return this.http.post('/user/set-prof-statement', data);
	}

	handleToken(): void {
		this.parseToken(localStorage.getItem("token"));
	}

	update() {
		this.lastUpdate = Math.round(new Date().getTime() / 1000);
		localStorage.setItem("lastUpdate", this.lastUpdate + '');
	}

	addLoginTimeout(first = false, init = false) {
		if (this.loginTimeOutStatus) {
			if (this.debug) {
			}
			try {
				if (this.timer) {
					clearInterval(this.timer);
				}
			} catch (ex) {
			}
			if (!this.lastUpdate || first) {
				if (init) {
					this.lastUpdate = localStorage.getItem("lastUpdate");
					if (this.lastUpdate) {
						this.lastUpdate = parseFloat(this.lastUpdate);
					} else {
						this.update();
					}
				} else {
					this.update();
				}
			}
			this.timer = setTimeout(() => {
				if (this.debug) {
					//
				}
				if (this.is_logged) {
					let current = Math.round(new Date().getTime() / 1000);
					if (this.debug) {
						//
					}
					if (current > (this.lastUpdate + this.loginTimeout) - 30) {
						this.sessionDialogMode = true;
						this.dialog.open(LoginTimeoutDialog, {
							data: {
								lastUpdate: this.lastUpdate,
								loginTimeout: this.loginTimeout
							}
						}).afterClosed().subscribe(result => {
							this.sessionDialogMode = false;
							if (!result) {
								this.logout();
								this.router.navigate(["/login"]).then(() => {
								});
							} else {
								this.addLoginTimeout(true);
							}
						});
					} else {
						this.addLoginTimeout();
					}
				}
			}, 1000);
		}
	}

	getLeftSeconds() {
		if (this.is_logged) {
			let current = Math.round(new Date().getTime() / 1000);
			return this.lastUpdate + this.loginTimeout - current;
		}
		return 0;
	}

	logout(): boolean {
		return this.clearUser();
	}

	parseToken(token) {
		this.token = token;
		if (this.token !== null && typeof this.token !== "undefined" && this.token != "") {
			const helper = new JwtHelperService();
			const decodedToken = helper.decodeToken(this.token);
			if (typeof decodedToken.id !== "undefined") {
				this.is_logged = true;
				this.user = {
					...decodedToken,
					image: this.fixImagePath(decodedToken.image)
				};
			}
		}
		setTimeout(() => {
			this.onChange.emit("changed");
		}, 500);
	}

	fixImagePath(src) {
		if (this.isRemote(src)) {
			return this.isRemote(src);
		}
		return [environment.APP_URL, src].join('');
	}

	initUser(token, decodedToken) {
		this.auth.data = decodedToken;
		this.auth.token = token;
		this.auth.isAuth = true;
		this.auth.guard = decodedToken.guard;
		this.parseToken(token);
	}

	isRemote(url) {
		let urlStr = url + '';
		urlStr = urlStr.replace('/fileshttps://', 'https://');
		return (urlStr.substring(0, 8) == "https://" || urlStr.substring(0, 7) == "http://" || urlStr.substring(0, 2) == "//") ? urlStr : false;
	}

	info() {

	}

	clearUser() {

		try {
			this.auth.data = new AuthModelData();
			this.auth.token = '';
			this.auth.isAuth = false;
			this.auth.guard = '';
			this.permission.permissionArray = [];
			this.role.roleArray = [];
			this.token = '';
			this.is_logged = false;
			localStorage.removeItem('token');
			localStorage.removeItem('masterToken');
			this.onChange.emit("change");
		} catch (ex) {

			return false;
		}
		return true;
	}

	ngOnDestroy(): void {

	}

	ngOnInit(): void {
	}

	updatePassword(data): Observable<any> {
		return this.http.post("/user/updatePassword", data);
	}

	changeObservingStartStatus(data): Observable<any> {
		return this.http.post<any>("/user/changeObservingStartStatus", data);
	}

	getUserData(data): Observable<any> {
		return this.http.post<any>("/user/getUserData", data);
	}


	getAvailableAccounts(data): Observable<any> {
		return this.http.post("/user/available-accounts", data);
	}

	switchToAvailableAccount(data): Observable<any> {
		return this.http.post("/user/available-accounts/switch", data);
	}

	me(): Observable<any> {
		return this.http.post("/auth/me", {});
	}
}
