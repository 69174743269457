import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-poll-alert',
  templateUrl: './poll-alert.component.html',
  styleUrls: ['./poll-alert.component.scss']
})
export class PollAlertComponent implements OnInit {

  constructor(
      private ref: MatDialogRef<PollAlertComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
  }

  cancel()
  {
    this.ref.close();
  }

  ok()
  {
    this.cancel();
  }


}
