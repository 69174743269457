import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Inject, OnInit,
    ViewChild
} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NotificationRecipientGroup} from "./notification-recipient-group";
import {NotificationRecipient} from "./notification-recipient";
import {NotificationService} from "../../notification.service";
import {School, Specials} from "../../../students/student-details/education-info/list/education-info-list.models";
import {Doc} from "../../../docs/docs.models";
import {EducationInfoListService} from "../../../students/student-details/education-info/list/education-info-list.service";
import {DocsService} from "../../../docs/docs.service";
import {StudentsService} from "../../../students/students.service";
import {Student} from "../../../students/students.models";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

@Component({
    selector: "notification-recipient-form-dialog",
    templateUrl: "./notification-recipient-form.dialog.html",
    styleUrls: ["./notification-recipient-form.dialog.scss"]
})
export class NotificationRecipientFormDialog implements OnInit, AfterViewInit {

    selectedItem:NotificationRecipient | null = null;
    title = "";
    items: NotificationRecipient[] = [];
    addresses = new MatTableDataSource<NotificationRecipient>();
    constructor(
        private ref: MatDialogRef<NotificationRecipientFormDialog>,
        @Inject(MAT_DIALOG_DATA) public data: NotificationRecipientGroup,
        private service: NotificationService,
        private changeDetectorRefs: ChangeDetectorRef,
        private educationInfoService: EducationInfoListService,
        private docService: DocsService,
        private studentService: StudentsService
    ) {
        this.filter = data.filter;
        this.addresses.data = data.addresses;
    }
    filter: any = {};

    schools:School[] = [];
    specials:Specials[] = [];
    documents:Doc[] = [];

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    ngAfterViewInit(): void {
        this.paginator.page.subscribe(res => {
            this.page = res.pageIndex+1;
            this.limit = res.pageSize;
            this.getStudents();
        });
    }


    prepStudentFilter() {
        this.educationInfoService.getMeta().subscribe(response => {
            this.schools = response.schools;
            this.specials = response.specials;
        });
        this.docService.getNames().subscribe(response => {
            this.documents = response.data;
        });
        this.ref.updateSize();



        this.getStudents();
    }

    prepProfFilter() {
        this.filter.userType = "prof";
        this.getStudents();
    }

    getSpecials() {
        if (this.filter.schoolId == null) {
            return this.specials;
        } else {
            let a: any[] = [];
            for (let i =0; i<this.specials.length; i++) {
                if (this.specials[i].schoolId == this.filter.schoolId) {
                    a.push(this.specials[i]);
                }
            }
            return a;
        }
    }

    page = 1;
    limit = 5;
    total = 0;
    totalStudents = 0;
    studentList = new MatTableDataSource<Student>();
    displayedColumnsStudents = [
        "id",
        "name",
        "personalNumber",
        "gender",
        "phone",
        "mail"
    ];
    getStudents() {
        this.studentService.list(this.filter, this.page, this.limit).subscribe(response => {
            this.studentList.data = response.data;
            this.totalStudents = response.total;
        });
    }
    genders = {
        male: "მამრობითი",
        female: "მდედრობითი"
    };



    ngOnInit(): void {
        switch (this.data.type) {
            default:
                this.title = "ჯგუფი";
                this.getGroups();
                break;
            case "Student":
                this.title = "სტუდენტები";
                this.prepStudentFilter();
                break;
            case "Prof":
                this.title = "პროფესორები";
                this.prepProfFilter();
                break;
            case "User":
                this.title = "მომხმარებლები";
                break;
            case "School":
                this.title = "საგანმანათლებლო ერთეული";
                this.getSchools();
                break;
            case "Special":
                this.title = "საგანმანათლებლო პროგრამა";
                this.getSpecial();
                break;
        }

    }

    getGroups() {
        this.service.getGroups().subscribe(response => {
            this.items = response.recipients;
        });
    }
    getSchools() {
        this.service.getSchools().subscribe(response => {
            this.items = response.recipients;
        });
    }

    getSpecial() {
        this.service.getSpecial().subscribe(response => {
            this.items = response.recipients;
        });
    }

    close() {
        this.ref.close(false);
    }

    add() {
        if (this.selectedItem != null) {
            for (let i in this.data.addresses) {
                if (this.data.addresses[i].id == this.selectedItem.id) {
                    return;
                }
            }
            this.data.addresses.push({
                id: this.selectedItem.id,
                name: this.selectedItem.name
            });
            this.addresses.data = this.data.addresses;

        }
    }

    displayedColumns = [
        "id",
        "name",
        "action"
    ];

    remove(item: NotificationRecipient) {
        let a: NotificationRecipient[] = [];
        for (let i in this.data.addresses) {
            if (this.data.addresses[i].id != item.id) {
                a.push(this.data.addresses[i]);
            }
        }
        this.data.addresses = a;
        this.addresses.data = a;
    }

    save() {
        if (this.data.type == "Student") {
            this.data.filter = this.filter;
            this.data.total = this.totalStudents;
        } else if (this.data.type == "Prof") {

            this.data.filter = this.filter;
            this.data.total = this.totalStudents;
        }
        this.ref.close(this.data);
    }

    students = "";
    // noinspection JSUnusedGlobalSymbols
    addStudents() {
        this.service.getStudents(this.students).subscribe(response => {
            this.data.addresses = response.recipients;
            this.addresses.data = response.recipients;
        });
    }
    addUser() {
        this.service.addUser(this.students).subscribe(response => {
            this.data.addresses = response.recipients;
            this.addresses.data = response.recipients;
        });
    }


}
