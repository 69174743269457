import {Component, OnDestroy} from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from "@angular/router";

@Component({
	templateUrl: "./analytics.menu.html",
	selector: "analytics-menu",
	styleUrls: ["../students/student-details/student-details.component.scss"]
})
export class AnalyticsMenu implements OnDestroy {

	leftSideMenu = false;
	disableMenu = false;
	subs: any = [];

	constructor(
		private router: Router
	) {
		this.subs.push(router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				this.leftSideMenu = false;
				this.disableMenu = false;
				this.parseRoute(router.routerState.snapshot.root);
			}
		}));
	}

	parseRoute(node: ActivatedRouteSnapshot) {
		if (node.data.leftSideMenu) {
			this.leftSideMenu = true;
		}
		if (node.data.disableStudentMenu) {
			this.disableMenu = true;
		}
		if (node.firstChild) {
			this.parseRoute(node.firstChild);
		}
	}

	ngOnDestroy(): void {
		for (let sub of this.subs) {
			try {
				sub.unsubscribe();
			} catch (e) {}
		}

	}

}