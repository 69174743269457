import {Pipe,PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";


@Pipe({ name: 'safeHtml'})
export class SafeHtmlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    //return this.sanitized.bypassSecurityTrustResourceUrl(value);
    if (typeof value != "undefined") {
      return this.sanitized.bypassSecurityTrustHtml(value);
    }
    return value
  }
}