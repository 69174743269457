import {Pipe, PipeTransform} from "@angular/core";
import moment from "moment";

@Pipe({
  name: 'activeTimeAgo'
})
export class ActiveTimeAgoPipe implements PipeTransform {
  transform(value: Date | moment.Moment): any {
    return 'Active '+moment(value).fromNow();
  }
}
