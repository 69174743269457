import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class LogService {

    constructor(
        private http: HttpClient
    ) {

    }

    getLogs(filter: any, page:number, limit:number):Observable<LogResponse> {
        return this.http.post<LogResponse>("/logs/get/?page="+page+"&limit="+limit, filter);
    }


}

export interface LogResponse {
    result: "yes";
    data: UniLog[]
    total: number;
}
export interface UniLog {
    id: number;
    userId: number;
    studentId: number;
    location: number;
    testId: number;
    listId: number;
    action: string;
    text: string;
    data: string;
    created_at: string;
    updated_at: string;
    ipAddress: any;

}
