import {Component, EventEmitter, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BooksService} from "../../books.service";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FileStorage} from "../../../../core/services/FileStorage";

@Component({
	selector: "syllabus-uploader-dialog",
	templateUrl: "./syllabus-uploader-dialog.html"
})
export class SyllabusUploaderDialog implements OnInit {

	form: FormGroup;
	file: File = null;

	// progress = null;

	constructor(
		private fb: FormBuilder,
		private ref: MatDialogRef<SyllabusUploaderDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private bookService: BooksService,
		private error: ErrorsDialog,
		private storage: FileStorage,
	) {
		this.form = fb.group({
			id: "",
			bookId: ["", Validators.required],
			name: ["", Validators.required],
			type: ["", Validators.required],
			size: ["", Validators.required],
			address: ["", Validators.required],
			status: ["", Validators.required],
			isStorage: [1],
		});
	}

	ok() {
		this.bookService.addSyllabus(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		});
	}

	// upload(files: FileList) {
	// 	if (files.length>0) {
	// 		const fd = new FormData();
	// 		fd.append("file", files[0], files[0].name);
	// 		const uploader = this.bookService.uploadSyllabus(this.data.bookId, fd);
	// 		this.progress = uploader.pipe(map(response => {
	// 			switch (response.type) {
	// 				default:
	// 					// continue
	// 				break;
	// 				case HttpEventType.UploadProgress:
	// 					return Math.round(100 * response.loaded / response.total);
	// 				case HttpEventType.Response:
	// 					this.form.patchValue({
	// 						name: response.body.name,
	// 						type: response.body.type,
	// 						size: response.body.size,
	// 						address: response.body.address
	// 					});
	// 					return 100;
	// 			}
	// 			return 0;
	// 		}));
	// 	}
	// }

	cancel() {
		this.ref.close(false);
	}

	ngOnInit(): void {
		if (typeof this.data.status == "undefined") {
			this.data.status = "active";
		}
		if (typeof this.data.name == "undefined") {
			this.data.name = "";
		}
		this.form.patchValue(this.data);
	}


	progress: EventEmitter<number> = null;

	async pickFile() {
		const files = await this.storage.openFileDialog({multiple: false});
		if (files) {



			const {
				addresses,
				error
			} = await this.storage.upload(files, 'syllabuses', progress => this.progress = progress);
			this.progress = null;

			if (error) {
				console.error(error);
				this.error.show({error: 'ფაილის ატვირთვა ვერ მოხერხდა'});
			} else {

				this.form.patchValue({
					name: addresses[0].split('/').reverse()[0],
					type: 'file',
					size: 0,
					address: addresses[0],
				});

			}
		}
	}


}