// noinspection JSDeprecatedSymbols

import {NgModule} from "@angular/core";
import {Message} from "./components/message/message.component";
import {Room} from "./components/room/room.component";
import {SideBar} from "./components/side-bar/side-bar.component";
import {User} from "./components/user/user.component";
import {FlexLayoutModule} from "@angular/flex-layout";
import {IniChatToggle} from "./components/buttons/ini-chat-toggle";
import {ChatService} from "./services/chat-service";
import {MatDialogModule} from "@angular/material/dialog";
import {CommonModule} from "@angular/common";
import {DateFormatPipe} from "./pipes/moment-pipe";
import {AmTimeAgoPipe} from "./pipes/amTimeAgo-pipe";
import {UserSearchComponent} from "./components/user-search/user-search.component";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ChatComponent} from "./components/chat/chat.component";
import {Footer} from "./components/footer/footer.component";
import {InactiveChat} from "./components/inactive-chat/inactive-chat.component";
import {ActiveTimeAgoPipe} from "./pipes/activeTimeAgo";
import {UserService} from "./services/user.service";
import {MessageTextComponent} from "./components/message/types/text/message-text.component";
import {AmMessageTimeAgoPipe} from "./pipes/amMessageTimeAgo";
import {NewChatComponent} from "./components/new-chat/new-chat.component";
import {ChatIconComponent} from "./components/chat-icon/chat-icon.component";
import {ImgNotFoundDirective} from "./components/img-not-found/img-not-found";
import {UploaderService} from "./services/uploader.service";
import {MessageProgressComponent} from "./components/message/types/progress/message.progress.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {MessageFileComponent} from "./components/message/types/file/message.file.component";
import {MessageImageComponent} from "./components/message/types/image/message.image.component";
import {EmojiComponent} from "./components/emoji/emoji.component";
import {ChatBudget} from "./components/chat-budget/chat-budget.component";
import {UserRoom} from "./components/user-room/user-room.component";
import {ImageSliderDialogComponent} from "./components/chat/image-slider-dialog/image-slider-dialog";
import {HighlightSearchPipe} from "./pipes/highlightSearch";
import {SharedModule} from "../../shared/shared.module";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {PickerModule} from "@ctrl/ngx-emoji-mart";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {TokenInterceptor} from "../../core/interceptors/token.interceptor";
import {ChatInterceptor} from "./interceptor/chat-interceptor";

@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatDialogModule,
		MatIconModule,
		MatButtonModule,
		MatFormFieldModule,
		MatInputModule,
		ReactiveFormsModule,
		InfiniteScrollModule,
		MatProgressBarModule,
		HttpClientModule,
		PickerModule,
		SharedModule,
		SlickCarouselModule,
		FormsModule
	],
	declarations: [
		Message,
		Room,
		SideBar,
		User,
		IniChatToggle,
		DateFormatPipe,
		AmTimeAgoPipe,
		UserSearchComponent,
		ChatComponent,
		Footer,
		InactiveChat,
		ActiveTimeAgoPipe,
		MessageTextComponent,
		AmMessageTimeAgoPipe,
		NewChatComponent,
		ChatIconComponent,
		ImgNotFoundDirective,
		MessageProgressComponent,
		MessageFileComponent,
		MessageImageComponent,
		EmojiComponent,
		ChatBudget,
		UserRoom,
		ImageSliderDialogComponent,
		HighlightSearchPipe
	],
	entryComponents: [
		Footer,
		SideBar,
		EmojiComponent,
		ImageSliderDialogComponent
	],
	providers: [
		UploaderService,
		UserService,
		ChatService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ChatInterceptor,
			multi: true
		}
	],
	exports: [
		IniChatToggle,
		ChatBudget
	]
})
export class ChatModule {

}

