import {Injectable} from "@angular/core";

@Injectable()
export class CsvWriterService {

	constructor() {

	}

	makeCsv(data: any[]) {
		const csvString = this.convertArrayToCSV(data);
		const blob = new Blob([csvString], { type: 'text/csv' });
		return URL.createObjectURL(blob);
	}

	download(url) {
		const link = document.createElement('a');
		link.setAttribute('download', 'users.csv');
		link.setAttribute('href', url);
		document.body.appendChild(link);
		link.click();
	}

	convertArrayToCSV(array: any[]) {
		const header = Object.keys(array[0]).join(',');
		const rows = array.map(obj => Object.values(obj).join(','));
		return `${header}\n${rows.join('\n')}`;
	}


}