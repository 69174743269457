import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BooksService} from "../../books.service";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: 'book-prof-add-dialog-component',
	templateUrl: './book-prof-add-dialog.component.html',
})
export class BookProfAddDialogComponent implements OnInit {

	form: FormGroup;

	constructor(
		private ref: MatDialogRef<BookProfAddDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private bookService: BooksService,
		private error: ErrorsDialog,
		private fb: FormBuilder,
	) {
		this.form = this.fb.group({
			id: [""],
			prof_id: [""],
			prof: ["", Validators.required],
			price: [0.00, Validators.required],
			book_id: ["", Validators.required],
			additionalPay: [false],
			additionalPayTime: [""],
			additionalPayPrice: [0.00],
			hasFreeTime: [false],
			freeTime: [""],
		});
	}

	ok() {
		this.bookService.addBookProf({
			...this.form.value,
			prof_id: this.form.value.prof.id,
		}).subscribe(response => {
			if (response.result == 'yes') {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		});
	}

	close() {
		this.ref.close(false);
	}

	ngOnInit(): void {
		this.form.patchValue(this.data);
	}
}
