import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EstList} from "../est.list";
import {ListService} from "../list.service";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";
import {ListGroupFormComponent} from "./list-group-form/list-group-form.component";
import {GroupStudentsComponent} from "./group-students/group-students-component";
import {ConfirmDialog} from "../../../../theme/components/confirmDialog/confirm.dialog";
import {ListGroupProfs} from "./list-group-profs/list-group-profs";
import {FormControl} from "@angular/forms";
import {UniConfig} from "../../../../../uni.config";

@Component({
    selector: "edit-student-list-component",
    templateUrl: "./edit-student-list.component.html",
    styleUrls: ["./edit-student-list.component.scss"]
})
export class EditStudentListComponent implements OnInit {

    isProfTerm = false;

    constructor(
        private ref: MatDialogRef<EditStudentListComponent>,
        @Inject(MAT_DIALOG_DATA) private list: EstList,
        private service: ListService,
        private error: ErrorsDialog,
        private dialog: MatDialog
    ) {
        this.isProfTerm = typeof UniConfig.profTerm != "undefined" && UniConfig.profTerm == 2;
        this.groupSearch.valueChanges.subscribe(() => {
            this.filterGroup();
        });
    }

    groups = [];
    displayedColumns = [
        "id",
        "name",
        "pn",
        "actions"
    ];
    profDisplayedColumns = [
        "id",
        "name",
        "type",
        "actions"
    ];
    types = {
        edit: "ნიშნის დაწერა",
        view: "ჩვენება"
    };
    students = [];
    selectedGroupId = 0;
    groupIndex = 0;
    groupSearch: FormControl = new FormControl();
    studentSearch: FormControl = new FormControl();
    filteredGroups: any[] = [];

    profs = [];

    filterGroup() {
        const q = this.groupSearch.value;
        const items = [];
        for (const item of this.groups) {
            if (q == null || q == "" || q == " " || ((item.name + "").indexOf(q) != -1)) {
                items.push(item);
            }
        }
        this.filteredGroups = items;
    }

    removeStudent(item) {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "წაშლა",
                text: "ნამდვილად გსურთ წაშლა?.",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
	        if (result) {
		        this.service.removeStudent(item).subscribe(() => {
			        this.getStudents();
		        });
	        }
        });
    }

    setGroup() {
        this.selectedGroupId = this.filteredGroups[this.groupIndex];
        this.getStudents();
        this.getGroupProfs();
    }

    getGroupProfs() {
        this.service.getGroupProfs({
            groupId: this.selectedGroupId
        }).subscribe(response => {
            this.profs = response.data;
        });
    }

    getGroups() {
        this.service.getGroups({
            listId: this.list.id
        }).subscribe(response => {
            this.groups = response.data;
            if (this.groups.length > 0) {
                this.selectedGroupId = this.groups[0].id;
                this.getStudents();
                this.getGroupProfs();
            }
            this.filterGroup();
        });
    }

    addGroup() {
        const ref = this.dialog.open(ListGroupFormComponent, {
            data: {
                listId: this.list.id,
                bookId: this.list.bookId
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.getGroups();
            }
        });
    }

    addStudents(group) {
        const ref = this.dialog.open(GroupStudentsComponent, {
            data: group
        });
        ref.afterClosed().subscribe(() => {
            this.getStudents();
        });
    }

    getStudents() {
        this.service.getStudents({
            groupId: this.selectedGroupId
        }).subscribe(response => {
            this.students = response.data;
        });
    }

    removeProf(prof) {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "წაშლა",
                text: "ნამდვილად გსურთ წაშლა?.",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.service.removeListProf(prof).subscribe(response => {
                    if (response.result == "yes") {
                        this.getGroupProfs();
                    } else {
                        this.error.show(response);
                    }
                });
            }
        });
    }

    addProf(group) {

        const ref = this.dialog.open(ListGroupProfs, {
            data: {
                listId: group.listId,
                groupId: group.id
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.getGroupProfs();
            }
        });
    }

    ngOnInit(): void {
        this.getGroups();
    }

}
