import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class HrService {

	readonly academicPersonnelPersonalCaseTypes = [
		{id: 1, name: 'განცხადება'},
		{id: 2, name: 'პირადობის მოწმობის ასლი ID'},
		{id: 3, name: 'დიპლომები (ბაკალავრი)'},
		{id: 4, name: 'დიპლომი (მაგისტრატურა)'},
		{id: 5, name: 'დიპლომი (დოქტორანტურა)'},
		{
			id: 6,
			name: 'ცნობა ცნობა სქესობრივი თავისუფლებისა და ხელშეუხებლობის წინააღმდეგ მიმართული დანაშაულის ჩადენისთვის'
		},
		{id: 7, name: 'ცნობა უფლების ჩამორთმევის შესახებ'},
		{id: 8, name: 'ბანკის რეკვიზიტები'},
	];

	readonly administrativePersonnelPersonalCaseTypes = [
		// {id: 1, name: 'განცხადება'},
		// {id: 2, name: 'პირადობის მოწმობის ასლი ID'},
		// {id: 3, name: 'დიპლომები (ბაკალავრი)'},
		// {id: 4, name: 'დიპლომი (მაგისტრატურა)'},
		// {id: 5, name: 'დიპლომი (დოქტორანტურა)'},
		// {id: 6, name: 'ცნობა სამეცნიერო-პედაგოგიური გამოცდილების შესახებ'},
		// {
		// 	id: 7,
		// 	name: 'ცნობა ცნობა სქესობრივი თავისუფლებისა და ხელშეუხებლობის წინააღმდეგ მიმართული დანაშაულის ჩადენისთვის'
		// },
		{id: 8, name: 'განცხადება'},
		{id: 9, name: 'პირადობის დამადასტურებელი მოწმობა/პასპორტი'},
		{id: 10, name: "ცნობა უფლების ჩამორთმევის შესახებ"},
		{
			id: 11,
			name: "ცნობა სქესობრივი თავისუფლებისა და ხელშეუხებლობის წინააღმდეგ მიმართული დანაშაულის ჩადენისთვის ნასამართლეობის შესახებ"
		},
		{id: 12, name: "საბანკო რეკვიზიტები"},
		{id: 13, name: "სხვა"}
	];

	constructor(
		private http: HttpClient
	) {

	}

	getDepartments(filter, page, limit): Observable<DepartmentResponse> {
		return this.http.post<DepartmentResponse>("/hr/department/list/?page=" + page + "&limit=" + limit, filter);
	}

	addOrEditDepartment(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/department/addOrEdit", data);
	}

	removeDepartment(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/department/remove", data);
	}

	getDepartmentLogs(filter, page, limit): Observable<DepartmentLogResponse> {
		return this.http.post<DepartmentLogResponse>("/hr/department/getLogs/?page=" + page + "&limit=" + limit, filter);
	}

	getPositions(filter, page, limit): Observable<PositionResponse> {
		return this.http.post<PositionResponse>("/hr/position/list/?page=" + page + "&limit=" + limit, filter);
	}

	addOrEditPosition(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/position/addOrEdit", data);
	}

	removePosition(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/position/remove", data);
	}

	getDepartmentsByQuery(data): Observable<Department[]> {
		return this.http.post<Department[]>("/hr/department/getByQuery", data);
	}

	getPositionLogs(data, page, limit): Observable<any> {
		return this.http.post<any>("/hr/position/getLogs/?page=" + page + "&limit=" + limit, data);
	}

	getPositionDetails(data): Observable<IPosition> {
		return this.http.post<IPosition>("/hr/position/details", data);
	}

	getPositionsByQuery(data): Observable<IPosition[]> {
		return this.http.post<IPosition[]>("/hr/position/getByQuery", data);
	}


	copyPosition(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/position/copyPosition", data);
	}

	updatePositionDetails(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/position/updatePositionDetails", data);
	}

	getRegions(): Observable<RegionResponse> {
		return this.http.get<RegionResponse>('/region/list');
	}

	getAdministrativePersonnel(filter, page, limit): Observable<AdministrativePersonnelResponse> {
		return this.http.post<AdministrativePersonnelResponse>('/hr/administrative/list/?page=' + page + "&limit=" + limit, filter);
	}

	getAdministrativePersonnelAll(): Observable<any> {
		return this.http.get<any>('/hr/administrative/listAll');
	}

	addAdministrativePersonnel(data): Observable<AddResponse> {
		return this.http.post<AddResponse>('/hr/administrative/add', data);
	}

	getAdministrativePersonnelLogs(data, page, limit): Observable<any> {
		return this.http.post<any>("/hr/administrative/logs/?page=" + page + "&limit=" + limit, data);
	}

	getAdministrativeDetails(data): Observable<AdministrativePersonnel> {
		return this.http.post<AdministrativePersonnel>("/hr/administrative/details", data);
	}

	updateAdministrativePersonnel(data): Observable<AddResponse> {
		return this.http.post<AddResponse>("/hr/administrative/update", data);
	}

	uploadHrFiles(data): Observable<any> {
		return this.http.post<any>("/hr/uploadFiles", data);
	}

	getAdministrativePersonnelEducation(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/education", data);
	}

	addOrEditAdministrativePersonnelEducation(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/education/addOrEdit", data);
	}

	removeAdministrativePersonnelEducation(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/education/remove", data);
	}

	getAdministrativePersonnelExperience(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/experience", data);
	}

	addOrEditAdministrativePersonnelExperience(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/experience/addOrEdit", data);
	}

	removeAdministrativePersonnelExperience(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/experience/remove", data);
	}

	addOrEditAdministrativeComputerSkills(data): Observable<any> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/computer-skills/addOrEdit", data);
	}

	removeAdministrativePersonnelComputerSkills(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/computer-skills/remove", data);
	}

	getAdministrativePersonnelComputerSkills(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/computer-skills", data);
	}


	addOrEditAdministrativeInfo(data): Observable<any> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/info/addOrEdit", data);
	}

	// removeAdministrativePersonnelInfo(data): Observable<AddOrEditResponse> {
	// 	return this.http.post<AddOrEditResponse>("/hr/administrative/info/remove", data);
	// }

	getAdministrativePersonnelInfo(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/info", data);
	}


	getAdministrativePersonnelLanguage(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/language", data);
	}

	deleteAdministrativePersonnelLanguage(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/language/remove", data);
	}

	addOrEditAdministrativePersonnelLanguage(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/language/addOrEdit", data);
	}

	// removeAdministrativePersonnelLanguage(data): Observable<AddOrEditResponse> {
	// 	return this.http.post<AddOrEditResponse>("/hr/administrative/language/remove", data);
	// }

	getAdministrativePersonnelCertificate(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/certificate", data);
	}

	addOrEditAdministrativePersonnelCertificate(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/certificate/addOrEdit", data);
	}

	removeAdministrativePersonnelCertificate(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/certificate/remove", data);
	}

	// getAdministrativePersonnelCV(data): Observable<any> {
	// 	return this.http.post<any>("/hr/administrative/cv", data);
	// }

	addOrEditAdministrativePersonnelCV(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/cv/addOrEdit", data);
	}

	// removeAdministrativePersonnelCV(data): Observable<AddOrEditResponse> {
	// 	return this.http.post<AddOrEditResponse>("/hr/administrative/cv/remove", data);
	// }

	downloadAdministrativeCV(data): Observable<any> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/cv/download", data);
	}

	getAdministrativePersonnelPosition(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/position", data);
	}

	addOrEditAdministrativePersonnelPosition(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/position/addOrEdit", data);
	}

	removeAdministrativePersonnelPosition(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/position/remove", data);
	}

	getAdministrativePersonnelCommand(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/command", data);
	}

	addOrEditAdministrativePersonnelCommand(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/command/addOrEdit", data);
	}

	removeAdministrativePersonnelCommand(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/command/remove", data);
	}


	getAdministrativePersonnelDayOff(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/dayoff", data);
	}

	addOrEditAdministrativePersonnelDayOff(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/dayoff/addOrEdit", data);
	}

	removeAdministrativePersonnelDayOff(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/dayoff/remove", data);
	}

	getAdministrativePersonnelVacation(data): Observable<any> {
		return this.http.post<any>("/hr/administrative/vacation", data);
	}

	addOrEditAdministrativePersonnelVacation(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/vacation/addOrEdit", data);
	}

	removeAdministrativePersonnelVacation(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/vacation/remove", data);
	}


	getAdministrativePersonnelProjects(data, page: number, limit: number): Observable<any> {
		return this.http.post(`/hr/administrative/projects/list?page=${page}&limit=${limit}`, data);
	}

	addOrEditAdministrativePersonnelProjects(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/projects/addOrEdit", data);
	}

	removeAdministrativePersonnelProject(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/administrative/projects/remove", data);
	}

	getAdministrativeInternationalList(filter: any, page: number, limit: number): Observable<any> {
		return this.http.post(`/hr/administrative/international/list/?page=${page}&limit=${limit}`, filter);
	}

	removeAdministrativeInternational(data): Observable<any> {
		return this.http.post("/hr/administrative/international/remove", data);
	}

	addOrEditAdministrativeInternational(data): Observable<any> {
		return this.http.post("/hr/administrative/international/addOrEdit", data);
	}

	getLanguages(): Observable<any> {
		return this.http.get<any>('/hr/languages');
	}

	getLanguageLevels(): Observable<any> {
		return this.http.get<any>('/hr/languages/levels');
	}

	getUsersByQuery(data): Observable<any> {
		return this.http.post("/hr/getUsersByQuery", data);
	}

	addUserToPosition(data): Observable<any> {
		return this.http.post("/hr/position/addUser", data);
	}

	// getActionsPlans(data): Observable<any> {
	// 	return this.http.post("/hr/actionPlans", data);
	// }

	// addActionPlan(data): Observable<any> {
	// 	return this.http.post("/hr/actionPlans/add", data);
	// }

	actionPlanList(filter, page, limit): Observable<any> {
		return this.http.post("/hr/actionPlan/list/?page=" + page + "&limit=" + limit, filter);
	}

	addOrEditSettingYears(data): Observable<any> {
		return this.http.post("/hr/settings/year/addOrEdit", data);
	}

	getSettingYears(filter, page, limit): Observable<any> {
		return this.http.post("/hr/settings/year/list/?page=" + page + "&limit=" + limit, filter);
	}

	removeSettingYear(data): Observable<any> {
		return this.http.post("/hr/settings/year/remove", data);
	}

	addOrEditSettingRestDay(data): Observable<any> {
		return this.http.post("/hr/settings/rest-day/addOrEdit", data);
	}

	getSettingRestDays(filter, page, limit): Observable<any> {
		return this.http.post("/hr/settings/rest-day/list/?page=" + page + "&limit=" + limit, filter);
	}

	removeSettingRestDay(data): Observable<any> {
		return this.http.post("/hr/settings/rest-day/remove", data);
	}

	addOrEditSettingVacation(data): Observable<any> {
		return this.http.post("/hr/settings/vacation/addOrEdit", data);
	}

	getSettingVacations(filter, page, limit): Observable<any> {
		return this.http.post("/hr/settings/vacation/list/?page=" + page + "&limit=" + limit, filter);
	}

	removeSettingVacation(data): Observable<any> {
		return this.http.post("/hr/settings/vacation/remove", data);
	}

	getVacationMeta(): Observable<any> {
		return this.http.get('/hr/administrative/vacation/meta');
	}

	F

	getSettingVacationTemplates(): Observable<any> {
		return this.http.get("/hr/settings/vacation/templates");
	}

	getWorkingDaysNumber(data): Observable<any> {
		return this.http.post("/hr/administrative/vacation/getWorkingDaysNumber", data);
	}

	addVacation(data): Observable<any> {
		return this.http.post("/hr/vacations/add", data);
	}

	updateVacation(data): Observable<any> {
		return this.http.post("/hr/vacations/update", data);
	}

	getUnpaidVacationStatus(): Observable<any> {
		return this.http.get<any>("/hr/vacations/unpaid-vacation-status");
	}

	getWorkingDays(data): Observable<any> {
		return this.http.post<any>("/hr/vacations/workingDays", data);
	}

	getVacationLogs(data, page, limit): Observable<any> {
		return this.http.post('/hr/vacations/logs/?page=' + page + "&limit=" + limit, data);
	}

	getVacationActions(data): Observable<any> {
		return this.http.post<any>("/hr/vacations/requestActions", data);
	}

	getAcademicPersonnel(filter, page, limit): Observable<AcademicPersonnelResponse> {
		return this.http.post<AcademicPersonnelResponse>('/hr/academic/list/?page=' + page + "&limit=" + limit, filter);
	}

	// exportAcademicPersonelToExcel(data): Observable<any> {
	// 	return this.http.post<any>("/hr/academic/exportAcademicPersonelToExcel", data);
	// }

	addAcademicPersonnel(data): Observable<AddResponse> {
		return this.http.post<AddResponse>('/hr/academic/add', data);
	}

	getAcademicPersonnelLogs(data, page, limit): Observable<any> {
		return this.http.post<any>("/hr/academic/logs/?page=" + page + "&limit=" + limit, data);
	}

	getAcademicDetails(data): Observable<AcademicPersonnel> {
		return this.http.post<AcademicPersonnel>("/hr/academic/details", data);
	}

	updateAcademicPersonnel(data): Observable<AddResponse> {
		return this.http.post<AddResponse>("/hr/academic/update", data);
	}

	getAcademicPersonnelEducation(data): Observable<any> {
		return this.http.post<any>("/hr/academic/education", data);
	}

	addOrEditAcademicPersonnelEducation(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/education/addOrEdit", data);
	}

	removeAcademicPersonnelEducation(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/education/remove", data);
	}

	getAcademicPersonnelExperience(data): Observable<any> {
		return this.http.post<any>("/hr/academic/experience", data);
	}

	addOrEditAcademicPersonnelExperience(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/experience/addOrEdit", data);
	}

	removeAcademicPersonnelExperience(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/experience/remove", data);
	}

	addOrEditAcademicComputerSkills(data): Observable<any> {
		return this.http.post<AddOrEditResponse>("/hr/academic/computer-skills/addOrEdit", data);
	}

	removeAcademicPersonnelComputerSkills(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/computer-skills/remove", data);
	}

	getAcademicPersonnelComputerSkills(data): Observable<any> {
		return this.http.post<any>("/hr/academic/computer-skills", data);
	}

	addOrEditAcademicInfo(data): Observable<any> {
		return this.http.post<AddOrEditResponse>("/hr/academic/info/addOrEdit", data);
	}

	removeAcademicPersonnelInfo(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/info/remove", data);
	}

	getAcademicPersonnelInfo(data): Observable<any> {
		return this.http.post<any>("/hr/academic/info", data);
	}

	getAcademicPersonnelLanguage(data): Observable<any> {
		return this.http.post<any>("/hr/academic/language", data);
	}

	addOrEditAcademicPersonnelLanguage(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/language/addOrEdit", data);
	}

	removeAcademicPersonnelLanguage(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/language/remove", data);
	}

	getAcademicPersonnelCertificate(data): Observable<any> {
		return this.http.post<any>("/hr/academic/certificate", data);
	}

	addOrEditAcademicPersonnelCertificate(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/certificate/addOrEdit", data);
	}

	removeAcademicPersonnelCertificate(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/certificate/remove", data);
	}

	// getAcademicPersonnelCV(data): Observable<any> {
	// 	return this.http.post<any>("/hr/academic/cv", data);
	// }

	addOrEditAcademicPersonnelCV(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/cv/addOrEdit", data);
	}

	// removeAcademicPersonnelCV(data): Observable<AddOrEditResponse> {
	// 	return this.http.post<AddOrEditResponse>("/hr/academic/cv/remove", data);
	// }

	getAcademicPersonnelPosition(data): Observable<any> {
		return this.http.post<any>("/hr/academic/position", data);
	}

	addOrEditAcademicPersonnelPosition(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/position/addOrEdit", data);
	}

	removeAcademicPersonnelPosition(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/position/remove", data);
	}

	getAcademicPersonnelCommand(data): Observable<any> {
		return this.http.post<any>("/hr/academic/command", data);
	}

	addOrEditAcademicPersonnelCommand(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/command/addOrEdit", data);
	}

	removeAcademicPersonnelCommand(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/command/remove", data);
	}

	addOrEditAcademicPersonnelPublication(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/publication/addOrEdit", data);
	}

	getAcademicPersonnelPublication(filter, page, limit): Observable<any> {
		return this.http.post<any>("/hr/academic/publication/?page=" + page + "&limit=" + limit, filter);
	}

	removeAcademicPersonnelPublication(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/publication/remove", data);
	}

	addOrEditAcademicPersonnelReport(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/report/addOrEdit", data);
	}

	getAcademicPersonnelReport(data): Observable<any> {
		return this.http.post<any>("/hr/academic/report", data);
	}

	removeAcademicPersonnelReport(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/report/remove", data);
	}

	addOrEditAcademicPersonnelActivity(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/activity/addOrEdit", data);
	}

	getAcademicPersonnelActivity(data): Observable<any> {
		return this.http.post<any>("/hr/academic/activity", data);
	}

	removeAcademicPersonnelActivity(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/activity/remove", data);
	}

	getSchools(): Observable<any> {
		return this.http.post('/structure/getSchools', {});
	}

	getEduMeta(): Observable<any> {
		return this.http.get('/educationInfo/meta');
	}

	getAcademicPersonnelJobExperience(data): Observable<any> {
		return this.http.post<any>("/hr/academic/job-experience", data);
	}

	addOrEditAcademicPersonnelJobExperience(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/job-experience/addOrEdit", data);
	}

	removeAcademicPersonnelJobExperience(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/job-experience/remove", data);
	}

	getAcademicPersonnelBooks(data): Observable<any> {
		return this.http.post<any>("/hr/academic/books", data);
	}

	getAcademicContractNumber(data): Observable<any> {
		return this.http.post("/hr/academic/contract/getNumber", data);
	}

	addOrEditAcademicPersonnelContract(data): Observable<any> {
		return this.http.post("/hr/academic/contract/add", data);
	}

	removeAcademicPersonnelContract(data): Observable<any> {
		return this.http.post("/hr/academic/contract/remove", data);
	}

	getAcademicPersonnelContracts(data): Observable<any> {
		return this.http.post("/hr/academic/contract/list", data);
	}

	getAcademicPersonnelContractDetails(data): Observable<any> {
		return this.http.post("/hr/academic/contract/details", data);
	}

	signAcademicContract(data): Observable<any> {
		return this.http.post("/hr/academic/contract/signAcademicContract", data);
	}

	sendSms(data): Observable<any> {
		return this.http.post("/hr/sendSms", data);
	}

	structureMeta(): Observable<any> {
		return this.http.get("/educationInfo/meta");
	}

	getOnlyPositionList(filter, page, limit): Observable<any> {
		return this.http.post("/hr/administrative/getOnlyPositionList/?page=" + page + "&limit=" + limit, filter);
	}

	getAllPositions(): Observable<any> {
		return this.http.get("/hr/administrative/getAllPositions");
	}

	addOnlyPosition(data): Observable<any> {
		return this.http.post("/hr/administrative/addOnlyPosition", data);
	}

	removeOnlyPosition(data): Observable<any> {
		return this.http.post("/hr/administrative/removeOnlyPosition", data);
	}

	exportAdministrativePersonnelToExcel(data): Observable<any> {
		return this.http.post("/hr/administrative/export", data);
	}

	exportAcademicPersonnelToExcel(data): Observable<any> {
		return this.http.post("/hr/academic/export", data);
	}


	getAdministrativeContractNumber(data): Observable<any> {
		return this.http.post("/hr/administrative/contract/getNumber", data);
	}

	addOrEditAdministrativePersonnelContract(data): Observable<any> {
		return this.http.post("/hr/administrative/contract/add", data);
	}

	removeAdministrativePersonnelContract(data): Observable<any> {
		return this.http.post("/hr/administrative/contract/remove", data);
	}

	getAdministrativePersonnelContracts(data): Observable<any> {
		return this.http.post("/hr/administrative/contract/list", data);
	}

	getAdministrativePersonnelContractDetails(data): Observable<any> {
		return this.http.post("/hr/administrative/contract/details", data);
	}

	signAdministrativeContract(data): Observable<any> {
		return this.http.post("/hr/administrative/contract/signAdministrativeContract", data);
	}

	getAdministrativeContractTemplates(data): Observable<any> {
		return this.http.post("/hr/administrative/contract/templates", data);
	}

	getInternationalList(filter: any, page: number, limit: number): Observable<any> {
		return this.http.post(`/hr/academic/international/list/?page=${page}&limit=${limit}`, filter);
	}

	removeInternational(data): Observable<any> {
		return this.http.post("/hr/academic/international/remove", data);
	}

	addOrEditInternational(data): Observable<any> {
		return this.http.post("/hr/academic/international/addOrEdit", data);
	}

	getAcademicPersonnelProjects(data, page: number, limit: number): Observable<any> {
		return this.http.post(`/hr/academic/projects/list?page=${page}&limit=${limit}`, data);
	}

	addOrEditAcademicPersonnelProjects(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/projects/addOrEdit", data);
	}

	removeAcademicPersonnelProject(data): Observable<AddOrEditResponse> {
		return this.http.post<AddOrEditResponse>("/hr/academic/projects/remove", data);
	}

	meta(data): Observable<any> {
		return this.http.post("/hr/me", data);
	}

	administrativeMeta(id): Observable<any> {
		return this.http.get(`/hr/administrative/meta/${id}`);
	}

	getAdministrativePersonnelCVHtml(id, data): Observable<any> {
		return this.http.post(`/hr/administrative/cv/html/${id}`, data);
	}

	academicMeta(id): Observable<any> {
		return this.http.get(`/hr/academic/meta/${id}`);
	}

	getAcademicPersonnelCVHtml(id, data): Observable<any> {
		return this.http.post(`/hr/academic/cv/html/${id}`, data);
	}

	downloadAcademicCV(data): Observable<any> {
		return this.http.post<AddOrEditResponse>("/hr/academic/cv/download", data);
	}


	updateCV(data): Observable<any> {
		return this.http.post<any>("/hr/cv/update", data);
	}

	updateRetirement(data): Observable<any> {
		return this.http.post<any>("/hr/cv/updateRetirement", data);
	}

	addAcademicPersonnelPersonalCase(data): Observable<any> {
		return this.http.post('/hr/academic/personal-case/addOrEdit', data);
	}

	getAcademicPersonnelPersonalCase(data, page: number, limit: number): Observable<any> {
		return this.http.post(`/hr/academic/personal-case/list?page=${page}&limit=${limit}`, data);
	}

	removeAcademicPersonalCase(data): Observable<any> {
		return this.http.post("/hr/academic/personal-case/remove", data);
	}

	addAdministrativePersonnelPersonalCase(data): Observable<any> {
		return this.http.post('/hr/administrative/personal-case/addOrEdit', data);
	}

	getAdministrativePersonnelPersonalCase(data, page: number, limit: number): Observable<any> {
		return this.http.post(`/hr/administrative/personal-case/list?page=${page}&limit=${limit}`, data);
	}

	removeAdministrativePersonalCase(data): Observable<any> {
		return this.http.post("/hr/administrative/personal-case/remove", data);
	}

	addAdministrativeAttachedContract(data): Observable<any> {
		return this.http.post("/hr/administrative/contract/addAttached", data);
	}

	addAcademicAttachedContract(data): Observable<any> {
		return this.http.post("/hr/academic/contract/addAttached", data);
	}
}

export const Genders = [
	{name: 'მამრობითი', value: 'male'},
	{name: 'მდედრობითი', value: 'female'},
];

export interface Department {
	id: number;
	name: string;
	description?: string;
	created_at: string;
	updated_at: string;
}

export interface IPosition {
	id: number;
	departmentId: number;
	name: string;
	dutyPerson: string;
	schedule: string;
	description?: string;
	departmentName: string;
	totalCount: number;
	usedCount: number;
	availableCount: number;
	department?: Department;
	subordinated?: any;
	subordinates?: any;
	accounting?: any;
	qualification: any;
	knowledge: any;
	experience: any;
	computerSkills: any;
	languages: any;
	skills: any;
	innerCommunication: any;
	roles: any;
	connections: any;
	users: [],
}

export interface DepartmentResponse {
	data: Department[];
	total: number;
	page: number;
}

export interface PositionResponse {
	data: IPosition[];
	total: number;
	page: number;
}

export interface AddOrEditResponse {
	result: string;
	error: string;
	errors: string[];
}

export interface DepartmentLog {
	userName: string;
	ipAddress: string;
	action: number;
	departmentId: number;
	id: number;
}

export interface DepartmentLogResponse {
	data: DepartmentLog[];
	total: number;
	page: number;
}

export interface Region {
	id: number;
	name: string;
}

export interface RegionResponse {
	data: Region[];
}

export interface AdministrativePersonnel {
	id: number;
	firstName: string;
	lastName: string;
	firstNameEng: string;
	lastNameEng: string;
	personalNumber: string;
	address: string;
	country: string;
	regionId: number;
	phone: string;
	email: string;
	userId: number;
	birthD: number;
	birthM: number;
	birthY: number;
	gender: string;
	region: Region;
	photo: string;
	cvUpdate: any;
}

export interface AdministrativePersonnelResponse {
	data: AdministrativePersonnel[];
	total: number;
	page: number;
	limit: number;
}

export interface AcademicPersonnel {
	id: number;
	firstName: string;
	lastName: string;
	firstNameEng: string;
	lastNameEng: string;
	personalNumber: string;
	address: string;
	country: string;
	regionId: number;
	phone: string;
	email: string;
	userId: number;
	birthD: number;
	birthM: number;
	birthY: number;
	gender: string;
	region: Region;
	photo: string;
	cvUpdate: any;
	retirement: any;
}


export interface AcademicPersonnelResponse {
	data: AcademicPersonnel[];
	total: number;
	page: number;
	limit: number;
}


export interface AddResponse {
	result: string;
	error?: string;
	errors?: any;
}

export const Levels = {
	1: 'ბაკალავრი',
	2: 'მაგისტრი',
	3: 'დოქტორი',
	4: 'მაგისტრთან გათანაბრებული',
	5: 'ერთსაფეხურიანი'
};

export const Education = {
	high: 'საშუალო',
	higher: 'უმაღლესი'
};

export const COMPUTER_SKILLS = {
	1: 'ძალიან კარგად',
	2: 'კარგად',
	3: 'საშუალოდ',
	4: 'ცუდად',
	5: 'ძალიან ცუდად',
};

export const EducationLevels = [
	{id: 1, value: 'ბაკალავრი'},
	{id: 2, value: 'მაგისტრი'},
	{id: 3, value: 'დოქტორი'},
	{id: 4, value: 'მაგისტრთან გათანაბრებული'},
	{id: 5, value: 'ერთსაფეხურიანი'},
];

export const LanguageEvaluation = {
	1: 'საშუალოდ',
	2: 'კარგად',
	3: 'ცუდად'
}

export const MONTH_LIST = {
	1: 'იანვარი',
	2: 'თებერვალი',
	3: 'მარტი',
	4: 'აპრილი',
	5: 'მაისი',
	6: 'ივნისი',
	7: 'ივლისი',
	8: 'აგვისტო',
	9: 'სექტემბერი',
	10: 'ოქტომბერი',
	11: 'ნოემბერი',
	12: 'დეკემბერი'
};

export const MONTHS = [
	{name: 'იანვარი', value: 1},
	{name: 'თებერვალი', value: 2},
	{name: 'მარტი', value: 3},
	{name: 'აპრილი', value: 4},
	{name: 'მაისი', value: 5},
	{name: 'ივნისი', value: 6},
	{name: 'ივლისი', value: 7},
	{name: 'აგვისტო', value: 8},
	{name: 'სექტემბერი', value: 9},
	{name: 'ოქტომბერი', value: 10},
	{name: 'ნოემბერი', value: 11},
	{name: 'დეკემბერი', value: 12},
];

export const PUBLICATION_TYPES = [
	{id: 1, name: 'სტატია'},
	{id: 2, name: 'სამეცნიერო პროექტი'},
	{id: 3, name: 'მონოგრაფია'},
	{id: 4, name: 'სახელმძღვანელო'},
	{id: 5, name: 'დამხმარე სახელმძღვანელო'},
	{id: 6, name: 'ქეისი ანალიზი'},
	{id: 7, name: 'კანონის კომენტარი'},
	{id: 8, name: 'თარგმანი'},
	{id: 9, name: 'წიგნი'},
];

export const REPORT_TYPES = [
	{id: 1, name: 'სამეცნიერო კონფერენცია'},
	{id: 2, name: 'კონგრესი'},
	{id: 3, name: 'სიმპოზიუმი'},
];

export const DIRECTIONS = [
	{id: 1, name: 'სამართალი'},
	{id: 2, name: 'ბიზნესის ადმინისტრირება (ქართულენოვანი)'},
	{id: 3, name: 'ბიზნესის ადმინისტრირება (ინგლისურენოვანი)'},
	{id: 4, name: 'მენეჯმენტი'},
	{id: 5, name: 'ტურიზმი'},
	{id: 6, name: 'ფინანსები'},
	{id: 7, name: 'ინფორმაციული ტექნოლოგიები და სისტემები ბიზნესში'},
	{id: 8, name: 'საბანკო საქმე'},
	{id: 9, name: 'ადამიანური რესურსების მართვა'},
	{id: 10, name: 'განათლების ადმინისტრირება'},
	{id: 11, name: 'ფსიქოლოგია'},
	{id: 12, name: 'საერთაშორისო ურთიერთობები'},
	{id: 13, name: 'ჟურნალისტიკა'},
	{id: 14, name: 'კლინიკური ფსიქოლოგია'},
	{id: 15, name: 'სოციალური ფსიქოლოგია'},
	{id: 16, name: 'დიპლომირებული მედიკოსი'},
	{id: 17, name: 'უცხო ენები'},
	{id: 18, name: 'ეკონომიკის მიმართულება'},
	{id: 19, name: 'ინგლისურენოვანი საუნივერსიტეტო არჩევითი სასწავლო კურსები'},
	{id: 20, name: 'საუნივერსიტეტო არჩევითი სასწავლო კურსები'},
	{id: 21, name: 'აკადემიური წერა'},
	{id: 22, name: 'მათემატიკა,სტატისტიკა'},
	{id: 23, name: 'ინგლისურენოვანი ბიზნესი/მათემატიკა/სტატისტიკა'},
	{id: 24, name: 'ინფორმაციული ტექნოლოგიები და სისტემები ბიზნესში/კომპიუტერული მეცნიერებები'},
	{id: 25, name: 'განათლების ადმინისტრირება/უცხო ენები'},
	{id: 26, name: 'კომპიუტერული მეცნიერებები'},
	{id: 27, name: 'ინგლისურენოვანი ბიზნესი/აღრიცხვა ანალიზი და აუდიტი'},
	{id: 28, name: 'ბიზნესის ადმინისტრირება/ეკონომიკა'},
	{id: 29, name: 'ინგლისურენოვანი ბიზნესი/მენეჯმენტი'},
	{id: 30, name: 'ინგლისურენოვანი ბიზნესი/მარკეტინგი'},
	{id: 31, name: 'საერთაშორისო ურთიერთობები და ჟურნალისტიკა'},
	{id: 32, name: 'სტომატოლოგია'},
	{id: 32, name: 'მონაცემთა მეცნიერება'},
];

export const ACADEMIC_JOB_POSITIONS = [
	{id: 1, name: 'ასოცირებული პროფესორი'},
	{id: 2, name: 'პროფესორი'},
	{id: 3, name: 'ასისტენტი'},
	{id: 4, name: 'მოწვეული სპეციალისტი'},
	{id: 5, name: 'ასისტენტ-პროფესორი'},
	{id: 6, name: 'სამაგისტრო ნაშრომის ხელმძღვანელი'},
	{id: 7, name: 'საბაკალავრო ნაშრომის ხელმძღვანელი'},
];
