import {NgModule} from "@angular/core";
import {DateFromTo} from "./date.from.to";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";

@NgModule({
    imports: [
        ReactiveFormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule
    ],
    declarations: [
        DateFromTo
    ],
    exports: [
        DateFromTo
    ]
})
export class DateFromToModule {

}