import {Component, Inject} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FinanceService} from "../../../services/finance.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../../shared/error-dialogs/errors.dialog";
import {StudentPrevBalance} from "../../../services/interfaces";

@Component({
	selector: 'finance-prev-balance-form',
	styleUrls: ['./finance-prev-balance-form.component.scss'],
	templateUrl: "./finance-prev-balance-form.component.html"
})

export class FinancePrevBalanceFormComponent {
	form: FormGroup;

	currencyTypes: { id: number, name: string }[] = [
		{id: 1, name: "GEL"}
	];

	constructor(
		private financeService: FinanceService,
		private fb: FormBuilder,
		private ref: MatDialogRef<FinancePrevBalanceFormComponent>,
		@Inject(MAT_DIALOG_DATA) private data: StudentPrevBalance,
		private error: ErrorsDialog
	) {
		this.form = this.fb.group({
			action: ["", Validators.required],
			currency: ["", Validators.required],
			student: ["", Validators.required],
			amount: ["", [Validators.required, Validators.min(0.01)]],
		});
	}

	cancel() {
		this.ref.close(false);
	}

	ok() {
		this.financeService.addOrEditStudentPrevBalance(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		})
	}

	ngOnInit() {
		if (typeof this.data != "undefined" && this.data != null) {
			this.form.patchValue(this.data);
		}
	}

}
