import {Component, Inject, OnInit} from "@angular/core";
import {BooksService} from "../books.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {Book} from "../book";
import {ConfirmDialog} from "../../../theme/components/confirmDialog/confirm.dialog";
import {SyllabusUploaderDialog} from "./syllabus-uploader-dialog/syllabus-uploader-dialog";
import {BookLogsDialog} from "../book-logs/book-logs.dialog";

@Component({
	selector: "syllabuses-dialog",
	templateUrl: "./syllabuses-dialog.html",
	styleUrls: ["./syllabuses-dialog.scss"]
})
export class SyllabusesDialog implements OnInit {

	items = [];
	displayedColumns = [
		"id",
		"name",
		"status",
		// "download",
		"actions"
	];
	statuses = {
		active: "აქტიური",
		disabled: "გათიშული"
	};

	constructor(
		private bookService: BooksService,
		@Inject(MAT_DIALOG_DATA) public book: Book,
		private dialog: MatDialog
	) {

	}


	add() {
		this.edit({
			bookId: this.book.id
		});
	}

	edit(item) {
		const ref =this.dialog.open(SyllabusUploaderDialog,{
			data: item
		});
		ref.afterClosed().subscribe(result => {
			this.getItems();
		});
	}

	remove(item) {
		const ref = this.dialog.open(ConfirmDialog, {
			data: {
				title: "სილაბუსის წაშლა",
				text: "ნამდვილად გსურთ სილაბუსის წაშლა",
				ok: "დიახ",
				cancel: "არა"
			}
		});
		ref.afterClosed().subscribe(result => {
			if (result) {
				this.bookService.removeSyllabus(item).subscribe(response => {
					this.getItems();
				});
			}
		});
	}

	getItems() {
		this.bookService.getSyllabuses(this.book).subscribe(response => {
			this.items = response.data;
		});
	}

	ngOnInit(): void {
		this.getItems();
	}

	viewLogs(item): void {
		this.dialog.open(BookLogsDialog, {
			width: "900px",
			data: {
				id: item.id,
				type: 'syllab',
			},
		});
	}


	downloadFile(item): void {
		if (item.isStorage == 1) {
			window.open(item.address, "_blank");
		} else {
			window.open("api/books/syllabus/download/" + item.id, "_blank");
		}
	}

}