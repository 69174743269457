import {Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {PermissionService} from "../services/permission.service";

@Directive({
    selector: "[hasPermissionIf]"
})
export class HasPermissionIf implements OnInit,OnDestroy {

	permissionUserId: any = null;

    constructor(
        private elRef: ElementRef,
        private service: PermissionService,

        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef
    ) {
        this.element = elRef.nativeElement;

    }

    @Input() set hasPermissionIf(permissionName: string){
		if (typeof permissionName == 'string' && permissionName.indexOf(':') != -1) {
			this.permissionUserId = permissionName.split(':')[1];
			this.permissionName = permissionName.split(':')[0];
		} else {
			this.permissionName = permissionName;
		}
    }

    private element: HTMLInputElement;
    private permissionName = "";

    subscriber: any;

    // noinspection JSUnusedLocalSymbols
    private elementOldStyle = "initial";

    ngOnInit(): void {
        this.render();
        this.subscriber = this.service.onChange.subscribe(() => this.render());
    }

    render() {
        if (!this.service.can(this.permissionName, this.permissionUserId)) {
            this.viewContainer.clear();
        } else {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }

    }

    ngOnDestroy(): void {
        this.subscriber.unsubscribe();
    }



}
