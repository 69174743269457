import {Component, Input, OnInit} from "@angular/core";
import {ChatRoom} from "../../interfaces/ChatRoom";
import {ChatService} from "../../services/chat-service";
import {ChatUser} from "../../interfaces/ChatUser";

@Component({
	selector: 'ini-chat-room',
	templateUrl: './room.component.html',
	styleUrls: ["./room.component.scss"]
})
export class Room implements OnInit {

	@Input('room') room: ChatRoom | ChatUser;
	@Input('highlight') highlight: string = '';
	lastMessageType = 'text';
	name = '';
	description: string[] = null;

	constructor(
		public chatService: ChatService
	) {

	}

	isJsonString(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return false;
		}
		return true;
	}

	openChat() {
		this.chatService.openChat(<ChatRoom>this.room);
	}

	ngOnInit(): void {
		if (this.room?.lastMessage && typeof this.room.lastMessage == 'string') {
			if (this.isJsonString(this.room.lastMessage)) {
				this.lastMessageType = 'file';
			}
		}
		this.name = this.room?.name || '';
		if ((this.name+'').indexOf("\r\n\t")) {
			this.name.split("\r\n\t").forEach((item, index) => {
				if (index == 0) {
					this.name = item;
				} else {
					if (!this.description) {
						this.description = [];
					}
					this.description.push(item);
				}
			});
		}
	}
}
