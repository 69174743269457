import {Component, OnInit} from "@angular/core";
import {UserService} from "../../../core/services/user.service";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";
import {Router} from "@angular/router";
import {AuthService} from "../../../core/auth/auth.service";

@Component({
	selector: 'app-sub-users-component',
	templateUrl: './sub-users.component.html',
	styleUrls: ['./sub-users.component.scss']
})

export class SubUsersComponent implements OnInit {

	users = [];

	constructor(
		public service: UserService,
		public auth: AuthService,
		public errors: ErrorsDialog,
		public router: Router,
	) {
	}

	userTypes = {
		user: 'მომხმარებელი',
		custom: 'მომხმარებელი',
		prof: 'პროფესორი',
	};



	ngOnInit(): void {
		this.getSubUsers();
	}

	getSubUsers(): void {
		this.service.getAvailableAccounts({})
			.subscribe(response => {
				this.users = response.data;
			});
	}

	switchToUser(user): void {
		this.service.switchToAvailableAccount(user)
			.subscribe(response => {
				if (response.result === 'no') {
					this.errors.show(response);
					return;
				}

				localStorage.setItem("token", response.token);
				this.auth.tokenInit(response.token);
				this.router.navigate(['/hr/calendar']);
				window.location.reload();
			});
	}

}