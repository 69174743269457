import {AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {RegistrationService} from "../../../../../registration.service";
import {ConfirmDialog} from "../../../../../../../theme/components/confirmDialog/confirm.dialog";
import {ErrorsDialog} from "../../../../../../../shared/error-dialogs/errors.dialog";
import {UniConfig} from "../../../../../../../../uni.config";
import {MatPaginator} from "@angular/material/paginator";

@Component({
	selector: "registered-students-dialog",
	templateUrl: "./registered-students.dialog.html",
	styleUrls: ["./registered-students.dialog.scss"]
})
export class RegisteredStudentsDialog implements OnInit, AfterViewInit, OnDestroy {

	customStudentFilter = false;
	page = 1;
	limit = 10;
	total = 0;
	displayItems = [
		"id",
		"name",
		"personalNumber",
		"phone",
		"choiceTime",
		//"prof",
		//"day",
		//"auditorium",
		//"time",
		"type",
		"ipAddress",
		//"userType",
		"actions"
	];

	searchBarConfig = {
		withAction: true,
		withSelectAllButton: false,
		showAddButton: false,
		showExportButton: true,
	};
	addEventEmmiter = new EventEmitter<string>();
	getStudentsEventEmmiter = new EventEmitter<string>();
	actionDialog = new EventEmitter<string>();
	selectedStudentCount = 0;
	selectAllEmmiter = new EventEmitter<string>();
	filter:any = {
		lectureOrSeminarId: 0,
		canceled: 0,
		student: ""
	};
	items = [];
	days = [
		"ორშაბათი",
		"სამშაბათი",
		"ოთხშაბათი",
		"ხუთშაბათი",
		"პარასკევი",
		"შაბათი",
		"კვირა"
	];
	removed = false;
	types = {
		lecture: "ლექცია",
		seminar: "სემინარი"
	};
	isProfTerm = UniConfig.profTerm == 2;
	subs = [];

	constructor(
		public ref: MatDialogRef<RegisteredStudentsDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public registrationService: RegistrationService,
		public dialog: MatDialog,
		public error: ErrorsDialog
	) {
		/*
		this.selectAllEmmiter.subscribe((result) => {
			if (result == "select") {
				this.studentService.getStudentSelection(this.filter).subscribe(response => {
					if (response.result == "yes") {
						this.selectedStudent = response.data;
						this.checkSelectedStudentCount();
					}
				});
			} else {
				this.selectedStudent = {};
				this.checkSelectedStudentCount();
			}
		});
		*/

	}

	cancelRegistration(book) {
		let ref = this.dialog.open(ConfirmDialog, {
			data: {
				title: "რეგისტრაციის გაუქმება",
				text: "ნამდვილად გსურთ რეგისტრაციის გაუქმება?",
				ok: "დიახ",
				cancel: "არა"
			}
		});
		ref.afterClosed().subscribe(result => {
			if (result) {
				this.registrationService.cancelRegistration({
					id: book.id,
					registrationGroupId: book.groupId,
					eduId: book.eduId,
					studentId: book.studentId
				}).subscribe(response => {
					if (response.result == "yes") {
						this.removed = true;
						this.getItems();
					} else {
						this.error.show(response);
					}
				});
			}
		})
	}

  cancelRegistrationInGroup() {
    let ref = this.dialog.open(ConfirmDialog, {
      data: {
        title: "რეგისტრაციის გაუქმება",
        text: "ნამდვილად გსურთ რეგისტრაციის გაუქმება ჯგუფში?",
        ok: "დიახ",
        cancel: "არა"
      }
    });
    ref.afterClosed().subscribe(result => {
      if (result) {
        this.registrationService.cancelRegistrationInGroup({
          lectureOrSeminarId: this.data.id,
          registrationGroupId: this.data.groupId,
          type: this.data.type
        }).subscribe(response => {
          if (response.result == "yes") {
            this.removed = true;
            this.getItems();
          } else {
            this.error.show(response);
          }
        });
      }
    })
  }

	/**/@ViewChild("paginator", {static: false}) paginator: MatPaginator;
	ngAfterViewInit(): void {
		this.paginator.page.subscribe(res => {
			this.page = res.pageIndex+1;
			this.limit = res.pageSize;
			this.getItems();
		});
	}

	ngOnInit(): void {
		this.filter.lectureOrSeminarId = this.data.id;
		//this.getItems();
		this.subs.push(this.getStudentsEventEmmiter.subscribe(() => {

			this.getItems();
		}));
		this.getItems();

	}


	parse(response) {
		this.items = response.data;
		let items = [];
		for (let item of response.data) {
			if (typeof item.lecture != "undefined" && item.lecture != null) {
				items.push({
					id: item.id,
					name: item.student.firstName + " "+item.student.lastName,
					personalNumber: item.student.personalNumber,
					phone: item.student.phone,
					choiceTime: item.created_at,
					profName: item.lecture.profName,
					day: item.lecture.day,
					auditorium: item.lecture.auditorium.name,
					time: (item.lecture.h < 10 ? "0"+item.lecture.h : item.lecture.h) +":"+(item.lecture.m < 10 ? "0"+item.lecture.m : item.lecture.m),
					ipAddress: this.long2ip(item.ipAddress),
					type: "lecture",
					show: true,
					line: 2,
					groupId: item.registrationGroupId,
					eduId: item.eduId,
					studentId: item.studentId,
					userType: item.type,
					canceled: item.canceled,
					bookName: item.bookName,
					schoolName: item.schoolName,
					credit: item.credit,
				});
			}
			if (typeof item.seminar != "undefined" && item.seminar != null) {
				// noinspection PointlessBooleanExpressionJS
				items.push({
					id: item.id,
					name: item.student.firstName + " "+item.student.lastName,
					personalNumber: item.student.personalNumber,
					phone: item.student.phone,
					choiceTime: item.created_at,
					profName: item.seminar.profName,
					day: item.seminar.day,
					auditorium: item.seminar.auditorium.name,
					time: (item.seminar.h < 10 ? "0"+item.seminar.h : item.seminar.h) +":"+(item.seminar.m < 10 ? "0"+item.seminar.m : item.seminar.m),
					ipAddress: this.long2ip(item.ipAddress),
					type: "seminar",
					show: !(typeof item.lecture != "undefined" && item.lecture != null),
					line: 1,
					groupId: item.registrationGroupId,
					eduId: item.eduId,
					studentId: item.studentId,
					userType: item.type,
					canceled: item.canceled,
					bookName: item.bookName,
					schoolName: item.schoolName,
					credit: item.credit,
				});
			}
		}

		this.items = items;
	}

	getItems() {
		this.registrationService.getStudentList( this.page, this.limit, this.filter).subscribe(response => {
			this.parse(response);
			this.total = response.total;
		});
	}

	long2ip(ip){
		return [ip >>> 24, ip >>> 16 & 0xFF, ip >>> 8 & 0xFF, ip & 0xFF].join('.');
	}

	ngOnDestroy(): void {
		for (let sub of this.subs) {
			try {
				sub.unsubscribe();
			} catch (ex) {}
		}
	}


}