import {Component, Input, ViewEncapsulation} from "@angular/core";
import {Variant} from "./variant";
import {Answer} from "../../../../tests/correct/answer";

@Component({
    selector: "variant-component",
    templateUrl: "./variant-component.html",
    styleUrls: ["./variant-component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class VariantComponent {

    @Input() variant: Variant;
    @Input() num: number;
    @Input() type: string;
    @Input() answer: Answer;
    @Input() correctAnswer: string;

    constructor() {

    }

    isVariant() {
        return this.type.substring(0, 6) == "Closed";
    }

    isText() {
        return !this.isImage();
    }

    isImage() {
        return this.type == "Closed_TextVariantImage"
            || this.type == "Closed_ImageVariantImage"
        ;
    }

    isMixed() {
        return this.type.substring(0, 5) == "Mixed";
    }

    isCorrect() {

        switch (this.type) {
            default:
                if (this.type.substr(0,6) == "Closed") {
                    return this.correctAnswer == (this.variant.k)+"";
                }
                return this.correctAnswer == (this.num)+"";
            case "Mixed_Variants":
            case "Mixed_Variant_Score":
                return this.variant.text == this.correctAnswer;
        }

    }

    isSelected() {
        switch (this.type) {
            default:
                if (this.type.substr(0,6) == "Closed") {
                    return this.answer.text == (this.variant.k)+"";
                }
                return this.answer.text == (this.num) + "";
            case "Mixed_Variants":
            case "Mixed_Variant_Score":
                return this.getSelectedVariant() && this.getSelectedVariant() == this.variant.text;
        }
    }

    getSelectedVariant() {
        if (
               typeof this.answer != "undefined"
            && typeof this.answer != null
            && typeof this.answer.text != "undefined"
            && typeof this.answer.text != null
            && typeof this.answer.text.itemAnswers != "undefined"
            && typeof this.answer.text.itemAnswers[this.num] != "undefined"
            && typeof this.answer.text.itemAnswers[this.num].answer != "undefined"
        ) {
            return this.answer.text.itemAnswers[this.num].answer;
        }
        return false;
    }

    color() {
        if (this.isCorrect() && this.isSelected()) { return "success"; }
        if (this.isCorrect()) { return "primary"; }
        if (this.isSelected()) { return "accent"; }
        return "default";
    }
}