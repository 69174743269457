import {Component, forwardRef, Input} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {IniInput} from "./ini.input";
import {Observable} from "rxjs";
import {AutocompleteNamesService} from "./autocomplete-names.service";

@Component({
    selector: "prof-input",
    templateUrl: "./auto-complete-prof.html",
    providers:[{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ProfInput),
        multi: true
    }]
})
export class ProfInput extends IniInput { /**/

	_bookId = '';
	@Input("placeholder") override placeholder = "";
	@Input("type") override type = "old";
	@Input("lang") override lang: string = "ka";
	@Input('multiple') override multiple = false;
	@Input("bookId") set bookId(value: string) {
		this._bookId = value;
		this.formControl.patchValue('')
	}

    constructor(
        private service: AutocompleteNamesService
    ) {
        super();
    }

	override search(q: string): Observable<any[]> {
        return this.service.getProfs(q, this._bookId);
    }



}
