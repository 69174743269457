import {EventEmitter, Injectable, OnDestroy} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {MailboxMail} from "./interfaces/mailbox.mail";
import {Socket} from "ngx-socket-io";
import {UserService} from "../../core/services/user.service";
import {map} from "rxjs/operators";
import {CacheService} from "../../core/services/cache.service";
import {UniConfig} from "../../../uni.config";

@Injectable()
export class MailboxService extends Socket implements OnDestroy {

	types = {
		student: "სტუდენტი",
		prof: "პროფესორი",
		users: "ადმინისტრაცია",
		custom: "ადმინისტრაცია"
	};
	editorConfig =  {
		editable: true,
		spellcheck: true,
		minHeight: '15rem',
		maxHeight: '15rem',
		placeholder: 'Enter text here...',
		translate: 'no',
		sanitize: false,
		toolbarPosition: 'top',
		defaultFontName: 'Arial',
		customClasses: [
			{
				name: 'quote',
				class: 'quote',
			},
			{
				name: 'redText',
				class: 'redText'
			},
			{
				name: 'titleText',
				class: 'titleText',
				tag: 'h1',
			},
		]
	};
	event: EventEmitter<string> = new EventEmitter<string>();
	subs: any = [];
	isAuth = false;

	constructor(
		private http: HttpClient,
		private userService: UserService,
		private cacheService: CacheService
	) {
		super({
			url: UniConfig.chatSocketUrl,
			options: {
				transports: ["websocket"]
			}
		});
    this.on("AuthSuccess", () => {
      this.isAuth = true;
      this.subscribeEvents();
    });
		this.subs.push(this.userService.onChange.subscribe(() => {
			if (this.userService.is_logged) {
				this.auth();
			} else {
				this.logout();
			}
		}));
		if (this.userService.is_logged) {
			this.auth();
		}
	}

	logout() {
		this.emit("logout");
	}

	subscribeEvents() {
		this.emit("subscribe", {
			channelName: "events-"+this.userService.user.id,
			key: "events-"+this.userService.user.id
		});
    this.on("chatEvent", (msg) => {
      this.event.emit(msg);
    });
    this.on("events-"+this.userService.user.id, (msg) => {
      this.event.emit(msg);
    });

	}

	auth() {
		const token = this.userService.token;
		if (typeof token == "string") {
			this.emit("auth", {
				token
			});
		}
	}

	send(data): Observable<MailboxResponse> {
		return this.http.post<MailboxResponse>("/mailbox/send", data).pipe(res => {
			this.emit("notifyMessage", {
				id: data.to.id
			});
			return res;
		});
	}


	getMails(filter, page, limit): Observable<MailboxResponse> {
		return this.http.post<MailboxResponse>("/mailbox/get/?page="+page+"&limit="+limit, filter);
	}

	getMessage(mailId): Observable<MailboxResponse> {
		return this.http.post<MailboxResponse>("/mailbox/getMail", {
			id: mailId
		}).pipe(map(res => {
			if (typeof res == "object" && res != null && res.reRead) {
				this.emit("notifyRead", {
					id: res.message.fromId
				});
			}
			return res;
		}));
	}

	attachFile(file: File): Observable<any> {
		const f = new FormData();
		f.append("file", file, file.name);
		return this.http.post<MailboxResponse>("/mailbox/attachFile", f, {
			reportProgress: true,
			observe: 'events'
		});
	}

	getNotifyCount(): Observable<MailboxResponse> {
		return this.cacheService.cache("mail-getNotifyCount", 5, this.http.get<MailboxResponse>("/mailbox/getNotifyCount"));
		//return this.http.get<MailboxResponse>("/mailbox/getNotifyCount");
	}
	getChancelleryCount(): Observable<MailboxResponse> {
		//return this.cacheService.cache("chancellery-getNotifyCount", 5, this.http.get<MailboxResponse>("/chancellery/getIncomingJournalAlert"));
		return this.http.get<MailboxResponse>("/chancellery/getIncomingJournalAlert");
	}

	ngOnDestroy(): void {
		for (const sub of this.subs) {
			try {
				sub.unsubscribe();
			} catch (ex) {}
		}
	}

	getDiplomaEnclosureCount(): Observable<MailboxResponse> {
		return this.http.get<MailboxResponse>("/diploma-enclosures/count");
	}

}

export interface MailboxResponse {
	message: MailboxMail;
	result: string;
	error: string;
	errors: string[];
	items: [];
	data: any[];
	total: number;
	reRead: boolean;
	inbox: number;
}
