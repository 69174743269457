import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Student} from "../../../../../students/students.models";

@Component({
    selector: "condition-error-dialog",
    templateUrl: "./condition-error-dialog.html",
    styleUrls: ["./condition-error-dialog.scss"]
})
export class ConditionErrorDialog {

    canConfirm = false;

    constructor(
        private ref: MatDialogRef<ConditionErrorDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConditionErrorData
    ) {
        if (data.canConfirm) {
            this.canConfirm = true;
        }
    }

    ok() {
        this.ref.close(true);
    }

    cancel() {
        this.ref.close(false);
    }

}
export interface ConditionErrorData {
    type: string;
    student: Student;
    items: ConditionGroupError[];
    canConfirm: boolean;
}
export interface ConditionGroupError {
    type: string;
    confirm: boolean;
    limit: number;
    confirmedLimit: number;
    books: any;
}