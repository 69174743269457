import {Injectable} from "@angular/core";


declare let $: any;

@Injectable()
export class PrintService {

    delName = "print-delimiter";

    pageHeight = 800;

    constructor() {

    }

    removeActiveDelimiters() {
        $("."+this.delName).removeClass("active");
    }

    checkDelimiters() {
        //let height = 0;
        let parentHeight = 0;
        $("."+this.delName).each((num, el) => {
            let h = $(el).offset().top+$(".inner-sidenav-content").scrollTop();
            if ((h-parentHeight)>this.pageHeight) {
                parentHeight = h;
                $(el).addClass("active");
            }

        });
    }

    init(className) {
        this.delName = className;
        setTimeout(() => {
            this.removeActiveDelimiters();
            this.checkDelimiters();
        }, 500);
    }

}
