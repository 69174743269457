import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppSettings} from './app.settings';
import {Settings} from './app.settings.model';
import {Event, NavigationStart, Router} from "@angular/router";
import {AuthService} from "./core/auth/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {RouterService} from "./core/services/router.service";
import {PollAlertService} from "./shared/services/pollAlert.service";


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	public settings: Settings;

	param = {value: 'world'};
	interval : any = '';

	constructor(
		public appSettings: AppSettings,
		private authService: AuthService,
		private translate: TranslateService,
		private router: Router,
		private routerService: RouterService,
		private pollAlertService: PollAlertService
	) {
		this.settings = this.appSettings.settings;
		translate.setDefaultLang('ka');


		let lang = localStorage.getItem("lang");
		if (typeof lang == "undefined" || lang == null) {
			translate.use('ka');
		} else {
			translate.use(lang);
		}


		this.router.events.subscribe( (event: Event) => {
			if(event instanceof NavigationStart){
				this.routerService.navigationStart(event);
			}
		})


	}

	ngOnInit() {
		this.authService._onInit();
		this.pollAlertService.getPollForAlert();
		this.pollAlertEventEmiter();
		this.pollAlertService.menuVisibility();
	}

	ngOnDestroy() {
		clearInterval(this.interval);
	}

	pollAlertEventEmiter()
	{
		this.interval = setInterval(() => {
			this.pollAlertService.getPollForAlert();
		}, 60000);
	}
}
