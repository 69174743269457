export class EstComponent {
    id: number;
    coId: number;
    name: string;
    eng_name: string;
    code: string;
    type: any;
    status: string;
    isHidden: boolean;
    required: any;
    items: any;
    component_data: any;
    selectableValues: any;
    autoInsert: boolean;
    resultAs: number;

    static types() {
        return [
            "",
            "შეფასებითი",
            "შეფასებითი არჩევითი",
            "შეფასებების ჯგუფი",
            "შერჩევითი"
        ];
    }

    static typeNames() {
        return [
            {id: 1, name: "შეფასებითი"},
            {id: 2, name: "შეფასებითი არჩევითი"},
            {id: 3, name: "შეფასებების ჯგუფი"},
            {id: 4, name: "შერჩევითი"},
        ];
    }

    static statuses() {
        return {
            open: "აქტიური",
            closed: "დახურული"
        };
    }

    static statusNames() {
        return [
            {id: "open", name: "აქტიური"},
            {id: "closed", name: "დახურული"}
        ];
    }
}
