import {Component, Inject, OnInit} from "@angular/core";
import {StudentsService} from "../../students.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FileStorage} from "../../../../core/services/FileStorage";

@Component({
	selector: "send-sms-dialog",
	templateUrl: "./send-sms.dialog.html"
})
export class SendSmsDialog implements OnInit {

	public form: FormGroup;
	type: string = "";
	showProgress = false;
	mailForm = false;
	progress: any = null;

	constructor(
		private ref: MatDialogRef<SendSmsDialog>,
		private studentService: StudentsService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private error: ErrorsDialog,
		private fb: FormBuilder,
		private fileStorage: FileStorage
	) {
		this.form = fb.group({
			text: ["", Validators.required],
			students: [""],
			profs: [""],
			users: [""],
			log: [false],
			type: [['sms'], Validators.required],
			subject: '',
			files: [[]]
		});
		this.form.get('type').valueChanges.subscribe(value => {
			this.mailForm = value && value.includes('mail');
		});
	}

	basename(path) {
		return path.substring(path.lastIndexOf('/') + 1);
	}

	removeFile(index) {
		this.form.patchValue({
			files: this.form.value.files.filter((file, i) => i != index)
		});
	}

	async attachFile() {
		const files = await this.fileStorage.openFileDialog();
		if (files) {
			const result = await this.fileStorage.upload(files, 'student-mail', progress => this.progress = progress);
			this.progress = null;
			if (result.error) {
				this.error.show(result.error);
			}
			else {
				this.form.patchValue({
					files: [
						...this.form.value.files,
						...result.addresses
					]
				});
			}
		}
	}

	ok() {
		this.showProgress = true;
		this.studentService.sendSmsToStudents(this.form.value).subscribe(response => {
			this.showProgress = false;
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		})
	}

	cancel() {
		this.ref.close(false);
	}

	ngOnInit(): void {
		this.form.patchValue(this.data);
	}

}
