import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EstList} from "../../est.list";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ListService} from "../../list.service";
import {ErrorsDialog} from "../../../../../shared/error-dialogs/errors.dialog";

@Component({
    selector: "list-group-form-component",
    templateUrl: "./list-group-form.component.html"
})
export class ListGroupFormComponent implements OnInit {

    form: FormGroup;

    constructor(
        private ref: MatDialogRef<ListGroupFormComponent>,
        @Inject(MAT_DIALOG_DATA) private data: EstList,
        private fb: FormBuilder,
        private service: ListService,
        private error: ErrorsDialog
    ) {
        this.form = fb.group({
            listId: ["", Validators.required],
            bookId: ["", Validators.required],
            name: ["", Validators.required]
        });
    }

    cancel() {
        this.ref.close(false);
    }

    ok() {
        this.service.addOrEditGroup(this.form.value).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(true);
            } else {
                this.error.show(response);
            }
        });
    }

    ngOnInit(): void {
        this.form.patchValue(this.data);
    }

}
