import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {AppSettings} from "../../app.settings";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable} from "rxjs";

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
	constructor(private setting: AppSettings) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.substring(0, 8) == "https://" || request.url.substring(0, 7) == "http://" || request.url.substring(0, 7) == "chat://") {
			return next.handle(request);
		}
		let prefix = "";
		const token = localStorage.getItem("token");
		if (token !== null && typeof token !== "undefined" && token != "") {
			const helper = new JwtHelperService();
			const decodedToken = helper.decodeToken(token);
			if (typeof decodedToken.id !== "undefined") {
				if (
					(decodedToken.guard == "prof-api")
					&& ((request.url + "").indexOf(".") == -1)
					&& ((request.url + "").indexOf("login") == -1)
				) {
					prefix = "/prof";
				}
			}
		}
		if (this.isRemote(request.url)) {
			return next.handle(request);
		}

		// if (window.location.href.indexOf("localhost") != -1) {
		// 	request = request.clone({
		// 		url: environment.API_URL + prefix + request.url
		// 	});
		// 	return next.handle(request);
		// }

		request = request.clone({
			url: this.setting.apiUrl + prefix + request.url
		});
		return next.handle(request);
	}

	isRemote(url) {
		if (url.indexOf('/assets/i18n') != -1) {
			return url;
		}
		const urlStr = url + "";
		return urlStr.substring(0, 8) == "https://" || urlStr.substring(0, 7) == "http://" || urlStr.substring(0, 2) == "//"
	}
}
