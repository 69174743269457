import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {NotificationService} from "../../../shared/services/notification.service";
import {MatMenuTrigger} from "@angular/material/menu";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    NotificationService
  ]
})
export class EventsComponent implements OnInit {
  /**/@ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  public count = 0;
  public events:any;
  public selectedTab = 0;
  constructor(
      private notificationService:NotificationService
  ) { }

  ngOnInit() {
    this.onGetEventCount();
    setInterval(() =>{
      this.onGetEventCount()
    }, 20000);
  }

  private onGetEventCount()
  {
    this.notificationService.getEventCount()
        .subscribe(res => {
          this.count = res.count
        })
  }

  public getNotificationEvents()
  {
    this.notificationService.getNotificationEvents()
        .subscribe(res => {
          this.events = res;
          this.watchEvent();
        })
  }

  private watchEvent(){
    this.notificationService.watchEvent()
        .subscribe(res => {
          if(res.res == 'yes'){
            this.count = 0;
          }
        })
  }

  stopClickPropagate(event: any){
    event.stopPropagation();
    event.preventDefault();
  }

  openMessagesMenu() {
    this.getNotificationEvents();
    this.trigger.openMenu();
    this.selectedTab = 0;
  }

  closeMenu(){
    this.trigger.closeMenu();
  }

  // noinspection JSUnusedGlobalSymbols
  onMouseLeave(){
    this.trigger.closeMenu();
  }

}
