

export class AuthModelData {
	id: string;
	image: string;
	name: string;
	email: string;
	jsonGuard?: string;
}

export class AuthModel {
	data: AuthModelData;
	token: string;
	guard = 'auth';
	isAuth = false;
	permissions: string;

	constructor(){
	}

}
