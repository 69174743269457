export class UserData {
    email: string;
    exp: number;
    guard: string;
    iat: number;
    id: number;
    iss: string;
    jti: string;
    name: string;
    firstName: string;
    lastName: string;
    personalNumber: string;
    image: string;
    nbf: number;
    permissions: any[];
    prv: string;
    sub: number;
    jsonGuard?: string;
	observer: boolean;
	observingLimit: number;
	observingSpace: number;
	observingStart: boolean;
	hrUserId: number;
}
