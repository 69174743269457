import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Student} from "../../../../students/students.models";
import {
  EducationInfoListService
} from "../../../../students/student-details/education-info/list/education-info-list.service";
import {UniConfig} from "../../../../../../uni.config";

@Component({
  selector: "uni-student-filter",
  templateUrl: "./uni-student-filter.component.html",
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UniStudentFilterComponent),
    multi: true
  }]
})
export class UniStudentFilterComponent implements ControlValueAccessor, OnInit {

  private onChange: (value: string) => void;
  private onTouched: () => void;
  uniConfig = UniConfig;
  filter: any = {};
  @Input("getStudents") getStudentEmitter = new EventEmitter<string>();
  @Input("column") columns: any = null;
  @Input("config") config = {
    withBook: false,
    withDate: false
  };

  @Input("exportToExcel") exportToExcelEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output("columnChange") columnEmitter = new EventEmitter<any>();

  statuses = Student.statuses();

  schools: any[] = [];
  specials: any[] = [];
  semesters = Student.semesters();
  levels = Student.levels();
  statusNames = Student.statusNames();

  grantsFromServer: any = [];
  //concessionsFromServer = [];

  grants = [
    {displayName: "100% - სახელმწიფო", grantType: "state", name: "100%"},
    {displayName: "70% - სახელმწიფო", grantType: "state", name: "70%"},
    {displayName: "50% - სახელმწიფო", grantType: "state", name: "50%"},
    {displayName: "30% - სახელმწიფო", grantType: "state", name: "30%"},

    {displayName: "100% - სოციალური", grantType: "social", name: "100% სოციალური"},
    {displayName: "50% - სოციალური", grantType: "social", name: "50% სოციალური"},
    {displayName: "30% - სოციალური", grantType: "social", name: "30% სოციალური"},

    {displayName: "თავდაცვის გრანტი 50%", grantType: "social", name: "თავდაცვის გრანტი 50%"},
    {displayName: "გახარიას გრანტი", grantType: "social", name: "გახარიას გრანტი"},
  ];

  constructor(
    private educationInfoService: EducationInfoListService
  ) {
    this.educationInfoService.getMeta().subscribe(response => {
      this.schools = response.schools;
      this.specials = response.specials;
    });

    this.educationInfoService.getGrantTemplates()
      .subscribe(response => {
        this.grantsFromServer = response;
        this.grantsFromServer.unshift({
          id: "", name: "ყველა"
        });
      });

    // this.educationInfoService.getConcessionTemplates()
    //     .subscribe(response => {
    // 	    this.concessionsFromServer = response;
    // 	    this.concessionsFromServer.unshift({
    // 		    id: "", title: "ყველა"
    // 	    });
    //     });
  }

  columnIds: any = [];

  // noinspection JSUnusedGlobalSymbols
  setupColumns() {
    this.columnEmitter.emit(this.columnIds);
  }

  getStudents() {
    this.getStudentEmitter.emit("");
    this.onChange(this.filter);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(value: any): void {
    if (value === null || value === undefined || value === '') {
      value = {};
    }
    this.filter = value;
  }

  ngOnInit(): void {
    if (this.columns != null) {
      this.columnIds = [];
      for (const o of this.columns) {
        this.columnIds.push(o.id);
      }
    }
  }

  exportToExcel() {
    this.exportToExcelEmitter.emit("");
  }

}
