export class StudentCommand {

    public static categories() {
        return {
            active: [
                "აკადემიური შვებულებიდან დაბრუნების აღდგენის ბრძანება", // 0
                "აღდგენის ბრძანება (სხვა მიზეზი)", // 1
                "დამატებითი სემესტრი", // 2
                "მონაცემების შევსება/კორექტირება", // 3
                "პირობით ჩარიცხული სტუდენტის რექტორის ბრძანებით ჩარიცხვა", // 4
                "რექტორის ბრძანებით შეჩერებული სტატუსის მქონე სტუდენტის აღდგენა", // 5
                "რექტორის ბრძანებით შეწყვეტილი სტატუსის მქონე სტუდენტის აღდგენა", // 6
                "რექტორის ბრძანებით ჩარიცხვა", // 7
                "შიდა მობილობა", // 8
                "შიდა მობილობის ბათილად ცნობა", // 9
	            "რექტორის ბრძანებით შეჩერებული სტატუსის მქონე სტუდენტის აღდგენა", // 10
	            "ერთიანი ეროვნული გამოცდებით ჩარიცხვა", // 11
	            "მინისტრის ბრძანებით ჩარიცხვა", // 12
	            "მობილობის წესით ჩარიცხვა", // 13
	            "მობილობის ბათილად ცნობა", // 14
            ],
            pause: [
                "მონაცემების შევსება/კორექტირება",
                "სტატუსის შეჩერება (სხვა მიზეზი)",
                "სტატუსის შეჩერება ავადმყოფობის გამო",
                "სტატუსის შეჩერება ორსულობის, მშობიარობის და ბავშვის მოვლის გამო",
                "სტატუსის შეჩერება საზღვარგარეთ სასწავლებლად წასვლის გამო",
                "სტატუსის შეჩერება ფინანსური დავალიანების გამო",
	            "სტატუსის შეჩერება პირადი განცხადების საფუძველზე"
            ],
            stop: [
                "მონაცემების შევსება/კორექტირება",
                "პირობით ჩარიცხული სტუდენტის სტატუსის შეწყვეტა",
                "სტატუსის შეწყვეტა (სხვა მიზეზი)",
                "სტატუსის შეწყვეტა აკადემიური დავალიანების გამო",
                "სტატუსის შეწყვეტა დისციპლინური პასუხისმგებლობის გამო",
                "სტატუსის შეწყვეტა პირადი განცხადების გამო"
            ],
            moved: [
                "სტატუსის შეწყვეტა სხვა სასწავლებელში გადასვლის გამო"
            ],
            end: [
                "კვალიფიკაციის მინიჭება",
                "მონაცემების შევსება/კორექტირება"
            ],
            abiturient: [
                "მონაცემების შევსება/კორექტირება",
                "უცხოეთში მიღებული განათლების აღიარების საფუძველზე სწავლების უფლების მინიჭება",
	            "ერთიანი ეროვნული გამოცდებით სწავლის გაგრძელების უფლების მინიჭება",
	            "ოკუპირებული ტერიტორიებიდან ერთიანი ეროვნული გამოცდების გარეშე სწავლის უფლების მინიჭება",
            ],
            exchange: [
                "გაცვლითი სტუდენტის რექტორის ბრძანებით ჩარიცხვა",
                "გაცვლითი სტუდენტის სწავლის გაგრძელება რექტორის ბრძანებით",
                "მონაცემების შევსება/კორექტირება"
            ],
            conditional: [
                "მონაცემების შევსება/კორექტირება",
                "რექტორის ბრძანებით პირობითი ჩარიცხვა"
            ],
            waiting: [
                "მონაცემების შევსება/კორექტირება"
            ],
	        'exchange/active': [
		        "აკადემიური შვებულებიდან დაბრუნების აღდგენის ბრძანება", // 0
		        "აღდგენის ბრძანება (სხვა მიზეზი)", // 1
		        "დამატებითი სემესტრი", // 2
		        "მონაცემების შევსება/კორექტირება", // 3
		        "პირობით ჩარიცხული სტუდენტის რექტორის ბრძანებით ჩარიცხვა", // 4
		        "რექტორის ბრძანებით შეჩერებული სტატუსის მქონე სტუდენტის აღდგენა", // 5
		        "რექტორის ბრძანებით შეწყვეტილი სტატუსის მქონე სტუდენტის აღდგენა", // 6
		        "რექტორის ბრძანებით ჩარიცხვა", // 7
		        "შიდა მობილობა", // 8
		        "შიდა მობილობის ბათილად ცნობა", // 9
		        "რექტორის ბრძანებით შეჩერებული სტატუსის მქონე სტუდენტის აღდგენა", // 10
		        "ერთიანი ეროვნული გამოცდებით ჩარიცხვა", // 11
		        "მინისტრის ბრძანებით ჩარიცხვა", // 12
		        "მობილობის წესით ჩარიცხვა", // 13
		        "მობილობის ბათილად ცნობა", // 14
	        ],
	        'exchange/pause': [
		        "მონაცემების შევსება/კორექტირება",
		        "სტატუსის შეჩერება (სხვა მიზეზი)",
		        "სტატუსის შეჩერება ავადმყოფობის გამო",
		        "სტატუსის შეჩერება ორსულობის, მშობიარობის და ბავშვის მოვლის გამო",
		        "სტატუსის შეჩერება საზღვარგარეთ სასწავლებლად წასვლის გამო",
		        "სტატუსის შეჩერება ფინანსური დავალიანების გამო",
		        "სტატუსის შეჩერება პირადი განცხადების საფუძველზე"
	        ],
	        'exchange/stop' : [
		        "მონაცემების შევსება/კორექტირება",
		        "პირობით ჩარიცხული სტუდენტის სტატუსის შეწყვეტა",
		        "სტატუსის შეწყვეტა (სხვა მიზეზი)",
		        "სტატუსის შეწყვეტა აკადემიური დავალიანების გამო",
		        "სტატუსის შეწყვეტა დისციპლინური პასუხისმგებლობის გამო",
		        "სტატუსის შეწყვეტა პირადი განცხადების გამო"
	        ],
	        'exchange/waiting': [
		        "მონაცემების შევსება/კორექტირება"
	        ],
	        'exchange/end': [
		        "კვალიფიკაციის მინიჭება",
		        "მონაცემების შევსება/კორექტირება"
	        ],
        };
    }

}
