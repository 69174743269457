import {Component} from "@angular/core";
import {Room} from "../room/room.component";
import {ChatUser} from "../../interfaces/ChatUser";

@Component({
	  selector: 'ini-chat-user-room',
	  templateUrl: './user-room.component.html',
	  styleUrls: [
		  "./../room/room.component.scss",
		  "./user-room.component.scss"
	  ]
})
export class UserRoom extends Room {


	override openChat() {
		this.chatService.createChat([
			<ChatUser>this.room
		]);

	}


}
