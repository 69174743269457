import {EstList} from "../../est.list";
import {Student} from "../../../../students/students.models";
import {EstGroup} from "./est.group";
import {Book} from "../../../../books/book";

export class ListData {
    groups: EstGroup[];
    list: EstList;
    students: Student[][];
    est: any[][];
    components: any;
    book: Book;
    group: EstGroup;
    allComponents: any;
}

