import {Component} from "@angular/core";
import {StudentTablesComponent} from "../students/student-details/tables/student-tables.component";

declare let window: any;

@Component({
	selector: "tables-component",
	templateUrl: "./tables.component.html",
	styleUrls: ["./../students/student-details/tables/student-tables.component.scss"]
})
export class TablesComponent extends StudentTablesComponent {

	override types: any = {
		lecture: "ლექცია",
		seminar: "სემინარი",
		both: "ლექცია/სემინარი"
	};

	currentLang: string = 'ka';

	registrationGroupId: number = 0;
	registrationGroups = [];
	prof = "";

	override getItems() {
		this.studentService.getProfTables({
			groupId: this.registrationGroupId,
			prof: this.prof
		}).subscribe(response => {
			if (response.result == "yes") {
				this.serverTime = response.time;
				this.items = response.data;
			}
		});
	}

	override ngOnInit(): void {
		this.getActiveRegistrationGroups();
	}

	exportGroup(item) {
		this.studentService.exportGroupToExcel({
			id: item.data.id
		}).subscribe(response => {
			if (response.result == "yes") {
				window.location.href = response.link;
			}
		})
	}

	getActiveRegistrationGroups(): void {
		this.studentService.getActiveRegistrationGroups()
			.subscribe(response => {
				this.registrationGroups = response;
				if (this.registrationGroups.length > 0) {
					this.registrationGroupId = this.registrationGroups[0].id;
					this.getItems();
				}
			});
	}

}
