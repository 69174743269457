import {AfterViewInit, Component, ViewChild} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {BooksService} from "./books.service";
import {BookFormDialog} from "./book-form/book-form.dialog";
import {ConfirmDialog} from "../../theme/components/confirmDialog/confirm.dialog";
import {ErrorsDialog} from "../../shared/error-dialogs/errors.dialog";
import {Book} from "./book";
import {BookConditionsDialog} from "./book-conditions/book-conditions.dialog";
import {BookComponentsForm} from "./book-components/book-components.form";
import {SyllabusesDialog} from "./syllabuses-dialog/syllabuses-dialog";
import {ComponentService} from "../components/component.service";
import {SubBookFormComponent} from "./sub-book-form/sub-book-form.component";
import {BookCatDialog} from "./book-cat/book-cat-dialog";
import {ProfBookDialog} from "./prof-book-dialog/prof-book-dialog";
import {RuleStudentsDialog} from "./rule-students-dialog/rule-students-dialog";
import {BookLogsDialog} from "./book-logs/book-logs.dialog";


@Component({
    selector: "books-component",
    templateUrl: "./books.component.html",
    styleUrls: ["./books.component.scss"]
})
export class BooksComponent implements AfterViewInit {

    items: Book[] = [];
    page = 1;
    limit = 15;
    total = 0;
    displayedColumns = [
        "id",
        "name",
        "code",
        "credit",
        "action"
    ];
    filter = {
        name: "",
        credit: "",
        type: "",
        status: "",
        conditionStatus: "",
        componentCount: "",
	    lang: "",
	    langCode: "",
    };
	settings:any = {};
	tabIndex = 0;
	currentLang: string = 'ka';

    constructor(
        private service: BooksService,
        private dialog: MatDialog,
        private error: ErrorsDialog,
        private componentService: ComponentService
    ) {
        this.getItems();
        this.getParams();
    }

	openCatDialog(item) {
		this.dialog.open(BookCatDialog, {
			data: item,
			width: "800px"
		});
	}

	getParams() {
    	this.componentService.getSettings("learningSystem").subscribe(response => {
    		this.settings = response;
	    });
	}

    /**/@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    ngAfterViewInit(): void {
        this.paginator.page.subscribe(res => {
            this.page = res.pageIndex+1;
            this.limit = res.pageSize;
            this.getItems();
        });
    }

    getItems() {
	    this.items = [];
    	if (this.tabIndex == 0) {
		    this.service.getItems(this.filter, this.page, this.limit, this.total).subscribe(response => {
			    this.items = response.data;
			    this.total = response.total;
		    });
	    } else {
		    this.service.getSubItems(this.filter, this.page, this.limit, this.total).subscribe(response => {
			    this.items = response.data;
			    this.total = response.total;
		    });
	    }
    }

	sub_add() {
    	this.sub_edit({
		    id: -1
	    });
	}

	sub_edit(item) {
		this.dialog.open(SubBookFormComponent, {
			data: item
		}).afterClosed().subscribe(result => {
			if (result) {
				this.getItems();
			}
		});
	}

	sub_remove(item) {
    	this.dialog.open(ConfirmDialog, {
    		data: {
    			title: "წაშლა",
			    text: "ნამდვილად გსურთ წაშლა?",
			    ok:"დიახ",
			    cancel: "არა"
		    }
	    }).afterClosed().subscribe(result => {
	    	if (result) {
	    		this.service.removeSubBook(item).subscribe(response => {
	    			if (response.result == "yes") {
	    				this.getItems();
				    } else {
	    				this.error.show(response);
				    }
			    });
		    }
	    });
	}

    add() {
        this.edit({});
    }

    edit(item) {
        const ref = this.dialog.open(BookFormDialog, {
            data: item
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.getItems();
            }
        });
    }

    remove(item) {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "სასწავლო კურსის წაშლა",
                text: "ნამდვილად გსურთ წაშალოთ სასწავლო კურსი?",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.service.remove(item).subscribe(response => {
                    if (response.result == "yes") {
                        this.getItems();
                    } else {
                        this.error.show(response);
                    }
                });
            }
        });
    }

    openConditions(item: Book) {
        this.dialog.open(BookConditionsDialog, {
            data: item
        });
    }

    exportToExcel() {
        this.service.exportToExcel(this.filter).subscribe(response => {
            if (response.result == "yes") {
                window.location.href = response.href;
            } else {
                alert("Error");
            }
        });
    }

    openComponents(item: Book) {
        this.dialog.open(BookComponentsForm, {
            data: item,
            width: "900px"
        });
    }

    openSyllabusDialog(item: Book) {
        const ref = this.dialog.open(SyllabusesDialog, {
            width: "700px",
            data: item
        });
        ref.afterClosed().subscribe((result) => {
            if (result) {
                this.getItems();
            }
        });
    }

    openBookProfDialog(item: Book) {
      const ref = this.dialog.open(ProfBookDialog, {
        width: "600px",
        data: item
      });
      ref.afterClosed().subscribe((result) => {
        if (result) {
          this.getItems();
        }
      });
    }

	openRuleStudents(item: Book) {
		const ref = this.dialog.open(RuleStudentsDialog, {
			width: "600px",
			data: item
		});
		ref.afterClosed().subscribe((result) => {
			if (result) {
				this.getItems();
			}
		});
	}


	showSub(item: any) {
		item.showActions = true;
	}
	hideSub(item: any) {
		item.showActions = false;
	}

	viewLogs(item): void {
		this.dialog.open(BookLogsDialog, {
			width: "900px",
			data: {
				id: item.id,
				bookId: item.id,
				type: 'book',
			},
		});
	}

}
