import {Pipe} from "@angular/core";
import moment from "moment";

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe {

    transform(value: Date | moment.Moment, dateFormat: string): any {
      return moment(value).format(dateFormat);
    }

}
