import {Component, Inject, ViewEncapsulation} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../../../shared/error-dialogs/errors.dialog";
import {BooksService} from "../../../../../books/books.service";
import {BookComponentsForm} from "../../../../../books/book-components/book-components.form";
import {BookComponentForm} from "../../../../../books/book-components/book-component-form/book-component-form";
import {ListData} from "../list.data";
import {ConfirmDialog} from "../../../../../../theme/components/confirmDialog/confirm.dialog";

@Component({
    selector: "edit-group-dialog",
    templateUrl: "./../../../../../books/book-components/book-components.form.html",
    styleUrls: [
        "./../../../../../books/book-components/book-components.form.scss",
    ],
    encapsulation: ViewEncapsulation.None
})
export class EditListComponents extends BookComponentsForm {

	override components = [];
	override allComponents = {};
    constructor(
        public override ref: MatDialogRef<EditListComponents>,
        @Inject(MAT_DIALOG_DATA) public data: ListData,
        public override service: BooksService,
        public override error: ErrorsDialog,
        public override dialog: MatDialog
    ) {
        super(ref, data.book, service, error, dialog);
    }

	override getComponents() {

        this.components = this.data.group.components;
        this.allComponents = this.data.allComponents;
        this.calcSum();
    }

	override addComponent() {
        this.editComponent({
            listId: this.data.list.id,
            groupId: this.data.group.id,
            bookId: this.data.book.id,
        });
    }

	override editComponent(item) {
        item.editMode = "list";
        const ref = this.dialog.open(BookComponentForm, {
            data: {
                component: item,
                otherComponents: this.components
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.getListComponents();
            }
        });
    }

    getListComponents() {
        this.service.getGroupComponents({
            groupId: this.data.group.id
        }).subscribe(response => {
            this.components = response.data;
            this.calcSum();
        });
    }

	override removeComponent(item, confirm="") {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "წაშლა",
                text: "ნამდვილად გსურთ წაშლა?",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {

                this.service.removeListComponent(item).subscribe(response => {
                    if (response.result == "yes") {
                        this.getListComponents();
                    } else {
                        this.error.show(response);
                    }
                });
            }
        });
    }

}
