export class Book {
    id: number;
    name: string;
    code: string;
    description: string;
    type: string;
    status: string;
    credit: number;
    gpaLimit: number;
    conditionStatus: boolean;
    componentCount: number;
    created_at: string;
    updated_at: string;
}
