import {Component, OnDestroy} from '@angular/core';
import {Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd} from "@angular/router";
import {Title} from '@angular/platform-browser';
import {AppSettings} from '../../../app.settings';
import {Settings} from '../../../app.settings.model';
import {UserService} from "../../../core/services/user.service";

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnDestroy {

	public pageTitle: string;
	public breadcrumbs: {
		name: string;
		url: string
	}[] = [];

	homeLink = "/";

	public settings: Settings;
	subs: any = [];

	constructor(public appSettings: AppSettings,
	            public router: Router,
	            public title: Title,
	            private userService: UserService
	) {
		this.settings = this.appSettings.settings;
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.breadcrumbs = [];
				this.parseRoute(this.router.routerState.snapshot.root);
				this.pageTitle = "";
				this.breadcrumbs.forEach(breadcrumb => {
					this.pageTitle += ' > ' + breadcrumb.name;
				});
				this.title.setTitle(this.settings.name + this.pageTitle);
			}
		});
		this.subs.push(this.userService.onChange.subscribe(() => {
			this.parseUserData();
		}));

	}

	parseUserData() {
		if (this.userService.user.guard == "prof") {
			this.homeLink = "/lists/list";
		} else {
			this.homeLink = "/";
		}
	}

	parseParams(node, params) {
		if (typeof params.title != "undefined" && params.title != '') {

			let urlSegments: UrlSegment[] = [];
			node.pathFromRoot.forEach(routerState => {
				urlSegments = urlSegments.concat(routerState.url);
			});
			let url = urlSegments.map(urlSegment => {
				return urlSegment.path;
			}).join('/');
			this.breadcrumbs.push({
				name: params.title,
				url: '/' + url
			});
		}
	}

	private parseRoute(node: ActivatedRouteSnapshot) {
		this.parseParams(node, node.params);

		if (node.data['breadcrumb']) {
			if (node.url.length) {
				let urlSegments: UrlSegment[] = [];
				node.pathFromRoot.forEach(routerState => {
					urlSegments = urlSegments.concat(routerState.url);
				});
				let url = urlSegments.map(urlSegment => {
					return urlSegment.path;
				}).join('/');
				this.breadcrumbs.push({
					name: node.data['breadcrumb'],
					url: '/' + url
				})
			}
		}
		if (node.firstChild) {
			this.parseRoute(node.firstChild);
		}
	}

	public closeSubMenus() {
		let menu = document.querySelector(".sidenav-menu-outer");
		if (menu) {
			for (let i = 0; i < menu.children[0].children.length; i++) {
				let child = menu.children[0].children[i];
				if (child) {
					if (child.children[0].classList.contains('expanded')) {
						child.children[0].classList.remove('expanded');
						child.children[1].classList.remove('show');
					}
				}
			}
		}
	}

	ngOnDestroy(): void {
		for (let sub of this.subs) {
			try {
				sub.unsubscribe();
			} catch (ex) {}
		}
	}


}
