import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

@Component({
  selector: 'app-alert',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent  {
  public info = {
    text: ''
  };

  constructor(
      //public dialogRef: MatDialogRef<UpdateStatusDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.info.text = data;
  }



}
