import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TestLogInterfaceResponse} from "../students/student-list/education/view/testlog/interfaces/test-log-interface";
import {
	FinanceEvent,
	FinanceEventResponse,
	FinanceGrantEventsResponse,
	FinanceGrantOrderResponse,
	FinanceGrantsResponse,
	FinanceOrderResponse,
	FinancePersonalCardResponse,
	FinanceProgramsResponse,
	FinanceResponse,
	FinanceSeasonListResponse,
	FinanceStatementDetailResponse,
	FinanceStatementResponse,
	FinanceStudentEducationResponse,
	FinanceStudentGrantsResponse,
	FinanceStudentResponse,
	InternalDiscountResponse,
	StudentBankTransactionPerm,
	StudentBankTransactionPermResponse,
	StudentConcessionResponseWrapper,
	StudentConcessionWrapper,
	StudentPrevBalanceResponse,
	StudentStatementInterfaceResponse
} from "./interfaces";


@Injectable()
export class FinanceService {

	date = null;
	months = [
		"იანვარი",
		"თებერვალი",
		"მარტი",
		"აპრილი",
		"მაისი",
		"ივნისი",
		"ივლისი",
		"აგვისტო",
		"სექტემბერი",
		"ოქტომბერი",
		"ნოემბერი",
		"დეკემბერი"
	];
	accountTypes = [
		"",
		"ძირითადი",
		"კრედიტის საფასური"
	];
	currencyTypes = [
		"",
		"GEL",
		"USD",
		"EUR"
	];

	orderStatuses = [
		"გადახდილი",
		"გადასახდელი",
		"",
		"გაუქმებული"
	];

	onChange: EventEmitter<any> = new EventEmitter<any>();



	constructor(
		private http: HttpClient
	) {
	}

	seasonNames() {
		return {
			autumn: 'საშემოდგომო',
			spring: 'საგაზაფხულო',
			reSpring: 'აღდგენა'
		};
	}

	loadedCalculateDate(data: any): Observable<FinanceProgramsResponse> {
		return this.http.post<FinanceProgramsResponse>("/finance/loadedCalculateDate", data);
	}

	checkLoadedDataExport(ids: number[]): Observable<FinanceProgramsResponse> {
		return this.http.post<FinanceProgramsResponse>("/finance/checkLoadedDataExport", { ids });
	}

	getLoadedDataExportList(filter: any, page: number, limit: number): Observable<FinanceProgramsResponse> {
		return this.http.post<FinanceProgramsResponse>("/finance/getLoadedDataExportList/?page=" + page + "&limit=" + limit, filter);
	}

	exportLoadedData(data: any): Observable<FinanceProgramsResponse> {
		return this.http.post<FinanceProgramsResponse>('/finance/exportLoadedData', data);
	}

	getPrograms(filter: any, page: number, limit: number): Observable<FinanceProgramsResponse> {
		return this.http.post<FinanceProgramsResponse>("/finance/special/list/?page=" + page + "&limit=" + limit, filter);
	}

	getStatements(filter: any, page: number, limit: number): Observable<FinanceStatementResponse> {
		return this.http.post<FinanceStatementResponse>("/finance/special/statement/list/?page=" + page + "&limit=" + limit, filter);
	}

	addStatement(data: any): Observable<FinanceResponse> {
		return this.http.post<FinanceResponse>("/finance/special/statement/addOrEdit", data);
	}

	getStatement(id: number): Observable<FinanceStatementDetailResponse> {
		return this.http.post<FinanceStatementDetailResponse>("/finance/special/statement/getStatementDetails", {
			id: id
		});
	}

	setStudentStatement(data: any): Observable<FinanceStatementDetailResponse> {
		return this.http.post<FinanceStatementDetailResponse>("/finance/student/statement/setStudentStatement", data);
	}

	getStudentStatement(id: number): Observable<FinanceStatementDetailResponse> {
		return this.http.post<FinanceStatementDetailResponse>("/finance/student/statement/getStudentStatement", {
			id: id
		});
	}

	setStatementDetails(data: any): Observable<FinanceResponse> {
		return this.http.post<FinanceResponse>("/finance/special/statement/setStatementDetails", data);
	}

	getStudentList(filter: any, page: number, limit: number): Observable<FinanceStudentResponse> {
		return this.http.post<FinanceStudentResponse>("/finance/students/?page=" + page + "&limit=" + limit, filter);
	}

	getStudentEducationInfo(filter: any, page: number, limit: number): Observable<FinanceStudentEducationResponse> {
		return this.http.post<FinanceStudentEducationResponse>("/finance/students/details/?page=" + page + "&limit=" + limit, filter);
	}

	getSeasons(): Observable<FinanceSeasonListResponse> {
		return this.http.post<FinanceSeasonListResponse>("/finance/seasons/getLearningSeasons", {});
	}

	addOrEditSeasons(data: any): Observable<FinanceSeasonListResponse> {
		return this.http.post<FinanceSeasonListResponse>("/finance/seasons/addOrEditSeasons", data);
	}

	updateCurrentSeason(seasonData: any, prefix: any): Observable<FinanceResponse> {
		return this.http.post<FinanceResponse>("/finance/seasons/updateCurrentSeason", {
			id: seasonData.id,
			season: {
				autumn: 1,
				spring: 2,
				reSpring: 3
			}[prefix]
		});
	}

	getEvents(filter: any, page: number, limit: number): Observable<FinanceEventResponse> {
		return this.http.post<FinanceEventResponse>("/finance/events/?page=" + page + "&limit=" + limit, filter);
	}

	deleteEvent(data: any): Observable<any> {
		return this.http.post<any>("/finance/events/deleteEvent", data);
	}

	getOrders(filter: any, page: number, limit: number): Observable<FinanceOrderResponse> {
		return this.http.post<FinanceOrderResponse>("/finance/getOrders/?page=" + page + "&limit=" + limit, filter);
	}

	// cancelOrder(data: any): Observable<any> {
	// 	return this.http.post<any>("/finance/cancelOrder", data);
	// }

	resetSingleOrder(data: any): Observable<any> {
		return this.http.post<any>("/finance/resetSingleOrder", data);
	}

	addEvent(data: FinanceEvent): Observable<FinanceResponse> {
		return this.http.post<FinanceResponse>("/finance/event/add", data);
	}

	getPersonalCardInfo(data: any): Observable<FinancePersonalCardResponse> {
		return this.http.post<FinancePersonalCardResponse>("/finance/student/getPersonalCardInfo", data);
	}

	generateStudentOrder(data: any): Observable<any> {
		return this.http.post("/finance/test/genOrder", data);
	}

	payGrantOrder(data: any): Observable<any> {
		return this.http.post("/finance/test/payGrantOrder", data);
	}

	genPersonalCardOrder(data: any): Observable<any> {
		return this.http.post("/finance/test/genPersonalCardOrder", data);
	}

	payPersonalCardOrder(data: any): Observable<any> {
		return this.http.post("/finance/test/payPersonalCardOrder", data);
	}

	changeStudentStatus(data: any): Observable<any> {
		return this.http.post("/finance/test/changeStudentStatus", data);
	}

	getStudEdInfo(data: any): Observable<any> {
		return this.http.post("/finance/test/getStudEdInfo", data);
	}

	resetData(data: any): Observable<any> {
		return this.http.post("/finance/test/reset", data);
	}

	resetStudentInfo(data: any): Observable<any> {
		return this.http.post('/finance/test/resetStudentFinances', data);
	}

	getBasicLog(filter: any, page: number, limit: number): Observable<TestLogInterfaceResponse> {
		return this.http.post<TestLogInterfaceResponse>("/finance/test/getBasicLogs?page=" + page + "&limit=" + limit, filter);
	}

	addTestEvent(data: any): Observable<any> {
		return this.http.post("/finance/test/testEvent", data);
	}

	getLogsReport(data: any): Observable<any> {
		return this.http.post('/finance/test/logExport', data);
	}

	getInternalDiscount(filter: any, page: number, limit: number): Observable<InternalDiscountResponse> {
		return this.http.post<InternalDiscountResponse>("/finance/concession/list?page=" + page + "&limit=" + limit, filter);
	}

	addOrEditInternalDiscount(data: any): Observable<InternalDiscountResponse> {
		return this.http.post<InternalDiscountResponse>("/finance/addOrEditConcession", data);
	}

	deleteDiscount(data: any): Observable<InternalDiscountResponse> {
		return this.http.post<InternalDiscountResponse>("/finance/deleteConcession", data);
	}

	getInternalDiscountByQuery(data: any): Observable<any[]> {
		return this.http.post<any[]>("/finance/getInternalDiscountByQuery", data);
	}

	getStudentConcession(filter: any, page: number, limit: number): Observable<StudentConcessionResponseWrapper> {
		return this.http.post<StudentConcessionResponseWrapper>("/finance/student/getConcessions?page=" + page + "&limit=" + limit, filter);
	}

	addOrEditStudentConcession(data: any): Observable<StudentConcessionWrapper> {
		return this.http.post<StudentConcessionWrapper>("/finance/student/addOrEditConcession", data);
	}

	deleteStudentConcession(data: any): Observable<StudentConcessionWrapper> {
		return this.http.post<StudentConcessionWrapper>("/finance/student/deleteStudentConcession", data);
	}

	getGrants(filter: any, page: number, limit: number): Observable<FinanceGrantsResponse> {
		return this.http.post<FinanceGrantsResponse>("/finance/grant/list?page=" + page + "&limit=" + limit, filter);
	}

	addOrEditGrant(data: any): Observable<FinanceGrantsResponse> {
		return this.http.post<FinanceGrantsResponse>("/finance/grant/addOrEditGrant", data);
	}

	deleteGrant(data: any): Observable<FinanceGrantsResponse> {
		return this.http.post<FinanceGrantsResponse>("/finance/grant/deleteGrant", data);
	}

	addOrEditStudentGrant(data: any): Observable<FinanceStudentGrantsResponse> {
		return this.http.post<FinanceStudentGrantsResponse>("/finance/student/grant/addOrEditStudentGrant", data);
	}

	deleteStudentScholarship(data: any): Observable<FinanceStudentGrantsResponse> {
		return this.http.post<FinanceStudentGrantsResponse>("/finance/student/grant/deleteStudentGrant", data);
	}

	getStudentGrants(filter: any, page: number, limit: number) {
		return this.http.post<FinanceStudentGrantsResponse>("/finance/student/grant/list?page=" + page + "&limit=" + limit, filter);
	}

	getGrantsByQuery(data: any): Observable<any[]> {
		return this.http.post<any[]>("/finance/grant/getByQuery", data);
	}

	getGrantEvents(filter: any, page: number, limit: number): Observable<FinanceGrantEventsResponse> {
		return this.http.post<FinanceGrantEventsResponse>("/finance/grant/getGrantEventList?page=" + page + "&limit=" + limit, filter);
	}

	addGrantEvent(data: any): Observable<FinanceGrantEventsResponse> {
		return this.http.post<FinanceGrantEventsResponse>("/finance/grant/addGrantEvent", data);
	}

	getGrantOrders(filter: any, page: number, limit: number): Observable<FinanceGrantOrderResponse> {
		return this.http.post<FinanceGrantOrderResponse>("/finance/grant/getGrantOrderList?page=" + page + "&limit=" + limit, filter);
	}

	addOrEditStudentPrevBalance(data: any): Observable<StudentPrevBalanceResponse> {
		return this.http.post<StudentPrevBalanceResponse>("/finance/addOrEditPrevBalance", data);
	}

	getStudentPrevBalance(filter: any, page: number, limit: number): Observable<StudentPrevBalanceResponse> {
		return this.http.post<StudentPrevBalanceResponse>("/finance/getPrevBalanceList?page=" + page + "&limit=" + limit, filter);
	}

	getStudentBankTransactPerm(data: any): Observable<StudentBankTransactionPerm> {
		return this.http.post<StudentBankTransactionPerm>("/finance/student/event/getBankTransactPerm", data);
	}

	updateStudentBankTransactPerm(data: any): Observable<StudentBankTransactionPermResponse> {
		return this.http.post<StudentBankTransactionPermResponse>("/finance/student/event/updateBankTransactPerm", data);
	}

	updateStudentAttachedStatement(data: any): Observable<StudentBankTransactionPermResponse> {
		return this.http.post<StudentBankTransactionPermResponse>("/finance/student/updateStudentAttachedStatement", data);
	}

	getStudentAttachedStatement(data: any): Observable<StudentStatementInterfaceResponse> {
		return this.http.post<StudentStatementInterfaceResponse>("/finance/student/getAttachedStatement", data);
	}

	getStatementsByQuery(data: any): Observable<any[]> {
		return this.http.post<any[]>("/finance/getStatementsByQuery", data);
	}

	generateStudentAllOrders(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/generateAllOrders", data);
	}

	generateStudentAccounts(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/generateStudentAccounts", data);
	}

	updateStudentFinance(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/updateStudentFinance", data);
	}

	getAdditionalCreditsCard(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/getAdditionalCreditsCard", data);
	}

	getOldOrders(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/getOldOrders", data);
	}

	addOldOrder(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/addOldOrder", data);
	}

	getAllowCredit(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/getAllowCreditBuy", data);
	}

	updateAllowCredit(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/allowBuyCredit", data);
	}

	updateFinLog(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/updateFinLog", data);
	}

	removeBasicLog(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/removeBasicLog", data);
	}

	fromLogToAccount(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/fromLogToAccount", data);
	}


	generateOrderForItem(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/generateOrderForItem", data);
	}

	resetUniOrder(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/resetUniOrder",data);
	}

	resetStudentSeason(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/resetStudentCard", data);
	}

	updateCardComment(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/updateCardComment", data);
	}

	exportFinances(data: any): Observable<any> {
		return this.http.post<any>("/finance/exportFinances", data);
	}

	getFinanceStatistics(filter: any, page: number, limit: number): Observable<any> {
		return this.http.post<any>("/finance/getFinancesStatistics/?page=" + page + "&limit=" + limit, filter)
	}

	getStatisticsReport(data: any): Observable<any> {
		return this.http.post<any>("/finance/generateStatisticsReport", data);
	}

	getResetMeta(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/getResetData", data);
	}

	resetLastSeason(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/resetLastSeason",data);
	}

	generateLastSeason(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/generateLastSeason", data);
	}

	reCalculate(data: any): Observable<any> {
		return this.http.post("/finance/student/reCalculateLog", data);
	}

	updatePersonalCard(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/updatePersonalCard", data);
	}

	attachGrant(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/attachGrant", data);
	}

	cancelGrantOrder(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/cancelGrantOrder", data);
	}

	nullifyLogs(data: any): Observable<any> {
		return this.http.post("/finance/student/nullifyLogs", data);
	}

	transferTransaction(data: any): Observable<any> {
		return this.http.post("/finance/student/transferTransaction", data);
	}

	transferAdditionalCredit(data: any): Observable<any> {
		return this.http.post<any>("/finance/student/transferAdditionalCredit", data);
	}

	getLoads(data = {}) {
		return this.http.post<any>("/finance/loads", data);
	}

	toggleLoadSeason(data: any) {
		return this.http.post<any>("/finance/toggleLoadSeason", data);
	}

	runLoadedAsManual(data: any) {
		return this.http.post<any>("/finance/runLoadedAsManual", data);
	}

	editLoadedItem(data: any) {
		return this.http.post<any>("/finance/editLoadedItem", data);
	}

	getOffDays() {
		return this.http.get<any>("/finance/getOffDays", {});
	}

	removeOffDay(item: any) {
		return this.http.post<any>("/finance/removeOffDay", item);
	}

	addOrEditOffDay(item: any) {
		return this.http.post<any>("/finance/addOrEditOffDay", item);
	}

	addLoadedRecord(item: any) {
		return this.http.post<any>("/finance/addLoadedRecord", item);
	}

	getRestoreBudget() {
		return this.http.get<any>("/finance/getRestoreBudget", {});
	}

	getLoadedReport(data: any) {
		return this.http.post<any>("/finance/getLoadedReport", data);
	}

	getProfessorFinanceInfo(data: any) {
		return this.http.post<any>("/finance/getProfessorFinanceInfo", data);
	}

	deleteLoadedItem(data: any) {
		return this.http.post<any>("/finance/deleteLoadedItem", data);
	}

	clearDay(data: any) {
		return this.http.post<any>("/finance/clearDay", data);
	}

	setFinanceLoadTime(data: any) {
		return this.http.post<any>("/finance/setFinanceLoadTime", data);
	}

	setCustomLoadedData(data: any) {
		return this.http.post<any>("/finance/setCustomLoadedData", data);
	}

	getProfsForLoaded(data) {
		return this.http.post<any>("/finance/getProfsForLoaded",data);
	}

}

