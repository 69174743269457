import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'stringToLink'
})
export class StringToLinkPipe implements PipeTransform {
	transform(value: string): string {
		const urlRegex = /https?:\/\/[^\s<>"]+|www\.[^\s<>"]+/gi;

		return value.replace(urlRegex, function (url) {
			return '<a target="_blank" href="' + url + '">' + url + '</a>';
		});
	}
}