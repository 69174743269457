import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable()
export class NotificationService{
  constructor(
      private http:HttpClient
  ){}

  public getEventCount(){
   return this.http.get<any>('/event/getEventCount');
  }

  public getNotificationEvents(){
    return this.http.get('/event/getNotificationEvents');
  }

  public watchEvent(){
    return this.http.get<any>('/event/watchEvent');
  }
}