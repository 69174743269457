import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";

declare const JSON: any;

@Injectable()
export class HrCalendarCommonService {

	constructor(
		private http: HttpClient,
		private dialog: MatDialog,
		private router: Router,
	) {
	}

	checkMandatoryEvent(event)
	{

		if(!this.isThisPage('login', event))
		{

		}
	}

	isThisPage(page, rout = null) {
		let rt = rout != null ? rout : this.router;
		let splitUrl = rt.url.split('/');
		return splitUrl[1] == page;

	}


}