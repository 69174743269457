import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../../../../shared/error-dialogs/errors.dialog";
import {RegistrationService} from "../../../../../registration.service";
import {ConfirmDialog} from "../../../../../../../theme/components/confirmDialog/confirm.dialog";
import {UniConfig} from "../../../../../../../../uni.config";

@Component({
	selector: "seminar-or-lecture-dialog",
	templateUrl: "./seminar-or-lecture.dialog.html"
})
export class SeminarOrLectureDialog implements OnInit {

	form: FormGroup;
	isProfTerm = UniConfig.profTerm == 2;
	subBooks: any[] = [];
	mode = 1;

	constructor(
		private ref: MatDialogRef<SeminarOrLectureDialog>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private error: ErrorsDialog,
		private fb: FormBuilder,
		private registrationService: RegistrationService,
		private dialog: MatDialog
	) {
		this.form = fb.group({
			id: "",
			bookId: "",
			groupId: "",
			type: ["", Validators.required],
			prof: "",
			profId: ["", Validators.required],
			profName: "",
			day: ["", Validators.required],
			h: ["", [Validators.required, Validators.min(0), Validators.max(23)]],
			m: ["", [Validators.required, Validators.min(0), Validators.max(59)]],
			limit: "",
			//auditories: ["", Validators.required],
			auditorium: "",
			auditories: "",
			duration: "",
			isPilot: "",
			sameProf: "",
			confirm: "",
			listId: "",
			visibility:1,
			limitVisibility:1
		});
	}

	days = [
		"ორშაბათი",
		"სამშაბათი",
		"ოთხშაბათი",
		"ხუთშაბათი",
		"პარასკევი",
		"შაბათი",
		"კვირა"
	];

	getSubBooks() {
		this.registrationService.getSubBooks(this.data.listId).subscribe(response => {
			this.subBooks = response.data;
		})
	}

	ok() {
		this.registrationService.addOrEditBookGroup(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else if (response.result == "overwrite") {
				let errors = [];
				for (let item of response.errors) {
					if (item.type == "prof") {
						errors.push("გადაკვეთა პროფესორთან <span class='red-text'>"+item.profName+"</span> <span class='orange-text'>"+this.days[item.day]+"</span> საათი: <span class='orange-text'>"+item.aDate+"</span> -> <span class='orange-text'>"+item.bDate+"</span>");
					}else if(item.type == "childBook"){
						errors.push("გადაკვეთა შვილ საგანთან <span class='orange-text'>"+this.days[item.day]+"</span> საათი: <span class='orange-text'>"+item.aDate+"</span> -> <span class='orange-text'>"+item.bDate+"</span>");
					}else if(item.type == "medicBook"){
						errors.push("გადაკვეთა მედიცინის საგანთან <span class='orange-text'>"+this.days[item.day]+"</span> საათი: <span class='orange-text'>"+item.aDate+"</span> -> <span class='orange-text'>"+item.bDate+"</span>");
					}else if(item.type == "activeMedicFolder"){
						errors.push("გადაკვეთა მედიცინის აქტიური საგნის აუდიტორიასთან <span class='red-text'>"+item.auditoriumName+"</span> <span class='orange-text'>"+this.days[item.day]+"</span> საათი: <span class='orange-text'>"+item.aDate+"</span> -> <span class='orange-text'>"+item.bDate+"</span>");
					} else {
						errors.push("გადაკვეთა აუდიტორიასთან <span class='red-text'>"+item.auditoriumName+"</span> <span class='orange-text'>"+this.days[item.day]+"</span> საათი: <span class='orange-text'>"+item.aDate+"</span> -> <span class='orange-text'>"+item.bDate+"</span>");
					}
				}
				let ref = this.dialog.open(ConfirmDialog, {
					data: {
						title: "კვეთა",
						text: errors.join("\r\n"),
						ok: "გადაკვეთა",
						cancel: "გაუქმება"
					}
				});
				ref.afterClosed().subscribe(result => {
					if (result) {
						this.form.patchValue({
							confirm: true
						});
						this.ok();
					}
				});
			} else {
				this.error.show(response);
			}
		});
	}

	cancel() {
		this.ref.close(false);
	}

	ngOnInit(): void {

		this.form.patchValue({
			confirm: false
		});
		this.form.patchValue(this.data);
		if (typeof this.data == "object" && this.data != null && this.data.listId != undefined && this.data.listId != '' && this.data.listId != null && this.data.listId != 0) {
			this.mode = 2;
			this.getSubBooks();
		}
		if (this.data != null && typeof this.data.profId == "object") {
			this.form.patchValue({
				prof: {
					id: this.data.profId,
					name: this.data.profName
				}
			});
		}
		this.form.get("prof").valueChanges.subscribe(value => {
			if (typeof value == "object" && value != null && typeof value.id != "undefined") {
				this.form.patchValue({
					profId: value.id,
					profName: value.name
				});
			} else {
				this.form.patchValue({
					profId: "",
					profName: ""
				});
			}
		});
		if (this.mode == 1) {
			this.form.get("auditorium").valueChanges.subscribe(value => {
				this.form.patchValue({
					auditories: value.id,
					limit: value.limit
				});
			});
		}

	}

}