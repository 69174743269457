import {Injectable} from "@angular/core";

@Injectable()
export class HelperService {

  constructor(){}

  randomPassword(length = 8){
    var chars = "abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNP123456789";
    var pass = "";
    for (var x = 0; x < length; x++) {
      var i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
    return pass;
  }

}