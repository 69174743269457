import {Component, Input, OnInit} from "@angular/core";
import {ChatMessage} from "../../../../interfaces/ChatMessage";
import {UserService} from "../../../../services/user.service";
import {UploadedFile} from "../../../../interfaces/UploadedFile";
import {DomSanitizer} from "@angular/platform-browser";
import {Config} from "../../../../config";

@Component({
  selector: 'ini-chat-message-text',
  templateUrl: './message-text.component.html',
  styleUrls: ['./message-text.component.scss']
})
export class MessageTextComponent implements OnInit {

  @Input() message: ChatMessage;
  me: boolean = false;
  file: UploadedFile | null = null;
	defaultIcon = Config.defaultUserIcon;


  constructor(
    public userService: UserService,
    public sanitizer: DomSanitizer,
  ) {

  }

  trustUrl(url: string) {
	  	return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnInit(): void {
    this.me = this.message.user.id === this.userService.user.id && this.message.user.type == this.userService.user.type;
  }

}
