export interface IniEditorConfig {
    editable?: boolean;
    height?: string;
    heading?: boolean;
    styles?: boolean;
    FBColor?: boolean;
    BGColor?: boolean;

}
export const iniEditorConfig = {
    editable: true,
    height: "200px",
    heading: true,
    styles: true,
    FBColor: true,
    BGColor: true

};
