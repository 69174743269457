import {Component} from "@angular/core";
import {MessageFileComponent} from "../file/message.file.component";
import {Config} from "../../../../config";

@Component({
    selector: 'ini-chat-message-image',
    templateUrl: 'message.image.component.html',
    styleUrls: [
      '../text/message-text.component.scss',
      '../file/message.file.component.scss',
      'message.image.component.scss'
    ]
})
export class MessageImageComponent extends MessageFileComponent {


	storageUrl = Config.storageUrl;


}
