import {Component, Inject, OnInit} from "@angular/core";
import {AutocompleteNamesService} from "./autocomplete-names.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
	selector: "seminar-input-dialog",
	templateUrl: "./seminar-input-dialog.html"
})
export class SeminarInputDialog implements OnInit {

	form: FormGroup;
	groups: any[] = [];
	books: any[] = [];
	seminars: any[] = [];
	seasons = {
		autumn: "საშემოდგომო",
		spring: "საგაზაფხულო",
		summer: "საზაფხულო"
	};
	days = [
		"ორშაბათი",
		"სამშაბათი",
		"ოთხშაბათი",
		"ხუთშაბათი",
		"პარასკები",
		"შაბათი",
		"კვირა"
	];
	booksFiltered: Observable<any[]>;
	bookController = new FormControl();


	constructor(
		private ref: MatDialogRef<SeminarInputDialog>,
		private service: AutocompleteNamesService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private fb: FormBuilder
	) {
		this.form = fb.group({
			group: ["", Validators.required],
			book: ["", Validators.required],
			seminar: ["", Validators.required],
			name: ""
		});
		this.booksFiltered = this.bookController.valueChanges.pipe(
			startWith(''),
			map(name => name ? this.filteredBooks(name) : this.books.slice())
		);
	}

	filteredBooks(value: string) {
		const filterValue = (value+"").toLowerCase();
		return this.books.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
	}

	getGroups() {
		this.service.getRegistrationGroups().subscribe(response => {
			this.groups = response;
		});
	}

	getBooks(groupId) {
		this.service.getRegistrationGroupBooks({
			groupId: groupId
		}).subscribe(response => {
			this.books = response;
		})
	}

	getSeminars(groupId, bookId) {
		this.service.getRegistrationSeminar({
			groupId: groupId,
			bookId: bookId
		}).subscribe(response => {
			this.seminars = response;
		})
	}

	ngOnInit(): void {

		this.form.get("group").valueChanges.subscribe((groupId) => {
			this.getBooks(groupId);
		});
		this.form.patchValue({
			group: this.data.group
		});
		this.form.get("book").valueChanges.subscribe((bookId) => {
			if (typeof this.form.value.group != "undefined" && this.form.value.group != null) {
				this.getSeminars(
					this.form.value.group,
					bookId
				);
			}
		});
		this.form.patchValue({
			book: this.data.book
		});
		this.bookController.patchValue(this.data.book);
		this.bookController.valueChanges.subscribe(value => {
			this.form.patchValue({
				book: value
			});
		});
		this.form.get("seminar").valueChanges.subscribe(seminarId => {
			for (let seminar of this.seminars) {
				if (seminarId == 0) {
					for (let book of this.books) {
						if (book.bookId == this.form.value.book) {
							this.form.patchValue({
								name: "ყველა "+book.name + "-დან"
							});
							break;
						}
					}
				} else if (seminar.id == seminarId) {
					this.form.patchValue({
						name: seminar.id
							+" - "
							+seminar.profName
							+" "
							+this.days[seminar.day]
							+" - "
							+(
								(seminar.h < 10 ? '0'+seminar.h : seminar.h)+":"+(seminar.m < 10 ? '0'+seminar.m : seminar.m)
							)
							+" - "
							+seminar.auditorium.name
						}
					);
					break;
				}
			}
		});
		this.form.patchValue({
			seminar: this.data.seminar,
			name: this.data.name,
		});
		this.getGroups();
	}

	cancel() {
		this.ref.close(false);
	}

	ok() {
		this.ref.close(this.form.value);
	}

}