import {Component} from "@angular/core";

@Component({
	selector: "vote-form-component",
	templateUrl: "./vote-form.component.html",
	styleUrls: ["./vote-form.component.scss"]
})
export class VoteFormComponent {

	a = "";

	displayColumns = [
		"score",
		"column4",
		"column3",
		"column2",
		"column1",
		"column0",
	];

	data = [
		{
			id: 1,
			title: "შეფასების შედეგი",
			items: [
				{id: 1, name: "ხარისხის შფასება"},
				{id: 2, name: "სტუდენტის შეფასება"},
				{id: 3, name: "ხელმძღვანელის შეფასება"},
				{id: 4, name: "შენიშვნები ვადებთან დაკავშირებით"},
				{id: 5, name: "დისციპლინური სახდელები"}
			]
		},
		{
			id: 2,
			title: "სასწავლო კურსის განხორციელება",
			items: [
				{id: 6, name: "განხორციელებული სასწავლო კურსების რაოდენობა"},
				{id: 7, name: "უცხო ენახე განხორციელებული სასწავლო კურსები"},
				{id: 8, name: "საავტორო სასწავლო კურსები"},
				{id: 9, name: "სასწავლო კურსისთვის შემუშავებული რიდერები"}
			]
		},
		{
			id: 3,
			title: "პროგრამის განვითარება",
			items: [
				{id: 10, name: "სასწავლო კურსების განახლება"},
				{id: 11, name: "საგამოცდო საკითხთა განახლება"},
				{id: 12, name: "საგამოცდო საკითხების მართვა"},
				{id: 13, name: "ახალი სასწავლო კურსების შემუშავება"},
				{id: 14, name: "ინგლისურენოვანი სასწავლო კურსების შემუშავება"},
				{id: 15, name: "პროგრამის ხელმძღვანელობა"}
			]
		},
		{
			id: 4,
			title: "ინტერნაციონალიზაცია",
			items: [
				{id: 16, name: "საჯარო ლექცია უცხო ქვეყნის უნივერსიტეტში"},
				{id: 17, name: "სალექციო კურსი უცხო ქვეყნის უნივერსიტეტში"},
				{id: 18, name: "საერთაშორისო პროექტებში მონაწილეობა"}
			]
		},
		{
			id: 5,
			title: "კვლევა",
			items: [
				{id: 19, name: "რეფერირებად კონფერენციაზე მოხსენება"},
				{id: 20, name: "სამეცნიერო პუბლიკაციები უნივერსიტეტის გამოცემებში"},
				{id: 21, name: "სამეცნიერო პუბლიკაციები სხვა რეფერირებად გამოცემებში საქართველოში"},
				{id: 22, name: "სამეცნიერო პუბლიკაციები რეფერირებად გამოცემებში საზღვარგარეთ"},
				{id: 23, name: "სამეცნიერო პუბლიკაციები იმპაქტ-ფაქტორის მქონე გამომცემლობებში"},
				{id: 24, name: "საუნივერსიტეტო კვლევითი გრანტები"},
				{id: 25, name: "სხვა კვლევითი გრანტები"},
				{id: 26, name: "გამოქვეყნებული სახელმძღვანელოები და მონოგრაფები"},
				{id: 27, name: "ციტირებათა რაოდენობა"},
				{id: 28, name: "ჰირშის ინდექსის მაჩვენებელი"}
			]
		},
		{
			id: 6,
			title: "პროგრამის აქტივობებში მონაწილეობა",
			items: [
				{id: 29, name: "პროგრამის კომიტეტის სხდომაში მონაწილეობა"},
				{id: 30, name: "საუნივერსიტეტო კომისიებში მონაწილეობა"},
				{id: 31, name: "საბაკალავრო ნაშრომის ხელმძღვანელობა"},
				{id: 32, name: "სტუდენტთა სამეცნიერო ნაშრომების ხელმძღვანელობა"},
				{id: 33, name: "სამაგისტრო ნაშრომების ხელმძღვანელობა"},
				{id: 34, name: "დამსაქმებელთან შეხვედრების ორგანიზება"},
				{id: 35, name: "კურიკულუმგარეშე აქტივობებში ჩართულობა"},
				{id: 36, name: "კურიკულუმგარეშე აქტივობების ორგანიზება"}
			]
		},


	];

}