export class EstResult {

    id: number;
    type: string;
    studentId: number;
    listId: number;
    mobilityId: number;
    bookId: any;
    semester: number;
    bookName: string;
    score: number;
    result: string;
    confirm: string;
    credit: number;
    year: string;
    season: string;
    profId: number;
    profName: string;
    groupId: any;
    progId: number;
    specId: number;
    eduId: number;
    courseType: number;
    exists: boolean;
    isGrouped: boolean;


    static seasons() {
        return [
            {id: "spring", name: "საგაზაფხულო"},
            {id: "summer", name: "საზაფხულო"},
            {id: "autumn", name: "საშემოდგომო"}
        ];
    }
    static eduSeasonNames() {
        return {
            spring: "საგაზაფხულო",
            summer: "საზაფხულო",
            autumn: "საშემოდგომო"
        };
    }

    static signedNames() {
        return {
            0: 'არა ',
            1: 'კი  '
        }
    }
}
