export enum ChatEventType {
	HIDE_ROOMS                = 'HIDE_ROOMS',
	SHOW_ROOMS                = 'SHOW_ROOMS',
	SET_ROOMS                 = 'setRooms',
	CLOSE_CHAT                = 'CLOSE_CHAT',
	OPEN_CHAT                 = 'openChat',
	SET_CHAT_STATUS           = 'setChatStatus',
	MESSAGE_RECEIVED          = 'messageReceived',
	MESSAGES                  = 'messages',
	ADD_MEMBER_TO_CHAT        = 'addMemberToChat',
	REMOVE_MEMBER_FROM_CHAT   = 'removeMemberFromChat',
	LEAVE_CHAT                = 'leaveChat',
	CONNECT                   = 'welcome',
	AUTH                      = 'auth',
	AUTH_ERROR                = 'auth_error',
	AUTH_SUCCESS              = 'auth_success',
	GET_ROOMS                 = 'getRooms',
	SEARCH_USERS              = 'searchUsers',
	CALLBACK                  = 'callback',
	CREATE_CHAT               = 'createChat',
	SEND_MESSAGE              = 'sendMessage',
	UPDATE_CHAT_ROOM          = 'updateChatRoom',
	UPDATE_MESSAGE_SEEN       = 'updateMessageSeen',
	SEEN_CHAT                 = 'seenChat',
	SEND_FILES                = 'sendFiles',
	FILE_UPLOAD_PROGRESS      = 'fileUploadProgress',
	FILE_UPLOAD_FINISH        = 'fileUploadFinish',
	DISCONNECT                = 'disconnect',
	GET_USER_SETTINGS		  = 'getUserSettings',
	SET_USER_SETTINGS		  = 'setUserSettings',
	GET_USER_DIRECTORIES	  = 'getUserDirectories',
	GET_DIRECTORY_USERS	      = 'getDirectoryUsers',
	SET_CUSTOM_TITLE		  = 'setCustomTitle',
	DELETE_CHAT			      = 'deleteChat',
}
