import {ChatUser} from "./ChatUser";
import {ChatStatus} from "./ChatStatus";
import {ChatMessage} from "./ChatMessage";
import moment from "moment";
import {MessageSeenType} from "./MessageSeenType";

export interface Chat {
	id: number;
	title: string;
	icon: string | string[];
	status: ChatStatus;
	members: ChatUser[],
	lastActiveDate: Date | moment.Moment;
	seenUsers?: ChatUser[];
	seen: MessageSeenType;
	mainSmile: '',
	online: boolean;
	lastMessage: string;
	lastMessageAuthor: ChatUser | null;
	messages: ChatMessage[];
	loadedPage: number;
	lastMessageRaw?: ChatMessage,
	custom_title: string,
	admins: ChatUser[],
	listId?: number,
}

// noinspection JSUnusedGlobalSymbols
export const emptyChat: Chat = {
	id: -1,
	title: '',
	members: [],
	lastActiveDate: new Date(),
	mainSmile: '',
	icon: '',
	status: ChatStatus.ACTIVE,
	online: false,
	lastMessage: '',
	lastMessageAuthor: null,
	messages: [],
	loadedPage: 0,
	seen: MessageSeenType.USER_SEEN,
	custom_title: '',
	admins: [],
	listId: null,
}
