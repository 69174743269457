import {AfterViewInit, Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

declare const videojs: any;
declare const document: any;

@Component({
	selector: "video-player-component",
	template: `<video *ngIf="url" id="video_{{idx}}"
	                  class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9"
	                  controls preload="auto"  width="640" height="264">

		<source [src]="url" type="video/mp4" />

	</video>`
})
export class VideoPayerComponent implements AfterViewInit {

	idx = 1;
	player;

	constructor(
		@Inject(MAT_DIALOG_DATA) public url
	) {

	}

	ngAfterViewInit(): void {
		// ID with which to access the template's video element
		let el = 'video_' + this.idx;

		// setup the player via the unique element ID
		this.player = videojs(document.getElementById(el), {}, function() {

			// Store the video object
			const myPlayer = this, id = myPlayer.id();

			// Make up an aspect ratio
			const aspectRatio = 264/640;

			// internal method to handle a window resize event to adjust the video player
			function resizeVideoJS(){
				const width = document.getElementById(id).parentElement.offsetWidth;
				myPlayer.width(width).height( width * aspectRatio );
			}

			// Initialize resizeVideoJS()
			resizeVideoJS();

			// Then on resize call resizeVideoJS()
			window.onresize = resizeVideoJS;
		});
	}


}
