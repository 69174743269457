import {Component, Inject} from "@angular/core";
import {BooksService} from "../books.service";
import {Book} from "../book";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";
import {BookConditionGroupDialog} from "./book-condition-group/book-condition-group.dialog";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ConfirmDialog} from "../../../theme/components/confirmDialog/confirm.dialog";
import {BookLogsDialog} from "../book-logs/book-logs.dialog";


@Component({
    selector: "book-conditions-dialog",
    templateUrl: "./book-conditions.dialog.html"
})
export class BookConditionsDialog {

    constructor(
        private service: BooksService,
        private ref: MatDialogRef<BookConditionsDialog>,
        @Inject(MAT_DIALOG_DATA) public book: Book,
        private error: ErrorsDialog,
        private dialog: MatDialog
    ) {
        this.getItems();
    }

    bookName = "";

    groups = {
        conditions: [],
        nonCondition: [],
        programs: []
    };

    displayedColumns = [
        "bookId",
        "name",
        "updated_at",
        "created_at",
        "action"
    ];

    programName = "";

    displayedColumnsPrograms = [
        "id",
        "name",
        "created_at",
        "updated_at",
        "action"
    ];



    getItems() {
        this.service.conditions(this.book.id).subscribe(response => {
            this.groups.conditions = [];
            this.groups.nonCondition = [];
            for (const group of response.data) {
                if (group.type == "condition") {
                    this.groups.conditions.push(group);
                } else if (group.type == "nonCondition") {
                    this.groups.nonCondition.push(group);
                }
            }
            this.groups.programs = response.programs;


        });
    }



    updateItems() {
        this.getItems();
    }

    setNewLimit() {
        this.service.setNewLimit(this.book).subscribe(() => {});
    }

    addGroup(type: string) {
        const ref = this.dialog.open(BookConditionGroupDialog, {
            data: {
                type,
                bookId: this.book.id
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.getItems();
            }
        });
    }

    editGroup(group) {
        const ref = this.dialog.open(BookConditionGroupDialog, {
            data: group
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.getItems();
            }
        });
    }
    removeGroup(group) {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "ჯგუფის წაშლა",
                text: "ნამდვილად გსურთ ჯგუფის წაშლა?",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.service.removeConditionGroup(group).subscribe(response => {
                    if (response.result == "yes") {
                        this.getItems();
                    } else {
                        this.error.show(response);
                    }
                });
            }
        });
    }

    addCondition(group, bookName) {
        this.service.addCondition(group, bookName).subscribe(response => {
            if (response.result == "yes") {
                this.updateItems();
            } else {
                this.error.show(response);
            }
        });
    }


    removeCondition(group, item) {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "პირობის წაშლა",
                text: "ნამდვილად გსურთ პირობის წაშლა?",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.service.removeCondition(item).subscribe(response => {
                    if (response.result == "yes") {
                        this.updateItems();
                    } else {
                        this.error.show(response);
                    }
                });
            }
        });

    }
    addProgram(program) {
        this.service.addProgram({
            name: program.name,
            bookId: this.book.id,
            programId: program.id
        }).subscribe(() => {
            this.getItems();
        });
    }
    removeProgram(item) {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "საგანმანათლებლო პროგრამის სეზღუდვის წაშლა",
                text: "ნამდვილად გსურთ წაშალოთ საგანმანათლებლო პროგრამის შეზღუდვა?",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.service.removeProgram(item).subscribe(response => {
                    if (response.result == "yes") {
                        this.getItems();
                    } else {
                        this.error.show(response);
                    }
                });
            }
        });
    }

	viewLogs(item, source): void {
		this.dialog.open(BookLogsDialog, {
			width: "900px",
			data: {
				id: item.id,
				type: source,
			},
		});
	}

}
