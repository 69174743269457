import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import html2canvas from 'html2canvas';

declare let MathQuill: any;
declare let $: any;

@Component({
    selector: "math-editor-dialog",
    templateUrl: "./math-editor-dialog.html"
})
export class MathEditorDialog {

    text = "";
    // noinspection JSUnusedGlobalSymbols
    MQ = MathQuill.getInterface(2);
    constructor(
        private ref: MatDialogRef<MathEditorDialog>
    ) {

    }

    cancel() {
        this.ref.close(false);
    }

    save() {
        const el =$(".mq-root-block:last");
        el.css({
            display: "inline-block",
            width: "auto"
        });
        html2canvas(el[0], {
            logging: false,
            backgroundColor: null
        }).then(canvas => {
            const s = canvas.toDataURL("image/png");
            this.ref.close(s);
        });

    }

}