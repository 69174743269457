import {Component, EventEmitter, forwardRef, Input, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {EducationInfoListService} from "../student-details/education-info/list/education-info-list.service";
import {School, Specials} from "../student-details/education-info/list/education-info-list.models";
import {Doc} from "../../docs/docs.models";
import {StudentFilter} from "../students.models";
import {MatDialog} from "@angular/material/dialog";
import {StudentsService} from "../students.service";
import {MatOption} from "@angular/material/core";
import {UserService} from "../../../core/services/user.service";
import {StudentImportFormComponent} from "../student-import-form/student-import-form.dialog";

@Component({
	selector: "student-filter",
	templateUrl: "./student-filter.component.html",
	styleUrls: ["./student-filter.component.scss"],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => StudentFilterComponent),
		multi: true
	}]
})
export class StudentFilterComponent implements ControlValueAccessor {

	profBooks = {};
	profGroups = {};
	filteredProfGroups: any[] = [];
	jsonGuard = "";

	constructor(
		public educationInfoService: EducationInfoListService,
		private dialog: MatDialog,
		private studentsService: StudentsService,
		private userService: UserService
	) {
		this.jsonGuard = userService.user.guard;
		this.educationInfoService.getMeta().subscribe(response => {
			this.schools = response.schools;
			this.specials = response.specials;
			this.financesLearningSeasons = response.finances;
			this.regions = response.regions;
			this.countryList = response.country;
			this.filteredCountryList = response.country;
			this.studentGroups = response.studentGroups;
			this.learningYears = response.learningYears;
		});

		this.getAgents();
		this.getStates();
		this.getStudentFilterMeta();
		if (this.jsonGuard == 'prof') {
			this.getMetaForCalendarProfUsers();
		}
	}

	filter: StudentFilter = new StudentFilter();
	schools: School[] = [];
	specials: Specials[] = [];
	documents: Doc[] = [];
	agents: any = [];
	filterMeta: any = [];
	fiteredTagList: any[] = [];
	financesLearningSeasons: any[] = [];
	regions: any[] = [];
	countryList: any[] = [];
	learningYears: any[] = [];
	states: any[] = [];

	@Input("selectAll") selectAllEmmiter: EventEmitter<string> = new EventEmitter<string>();
	@Input("action") action: EventEmitter<string> = new EventEmitter<string>();
	@Input("getStudents") getStudentsEmmiter: EventEmitter<string> = new EventEmitter<string>();
	@Input("add") addEmmiter: EventEmitter<string> = new EventEmitter<string>();
	@Input("addTag") addTagEmmiter: EventEmitter<string> = new EventEmitter<string>();
	@Input("exportToExcel") exportToExcelEmmiter: EventEmitter<string> = new EventEmitter<string>();
	@Input("selectedStudentCount") selectedStudentCount: any = 0;
	@Input("canFilterQuestionnaire") canFilterQuestionnaire: any = false;
	@Input("config") config = {
		showAddButton: true,
		showExportButton: true,
		withAction: false,
		withSelectAllButton: false,
		alumni: false,
		showAddTagButton: false,
		isExpanded: null,
	};

	private onChange: (value: any) => void;
	private onTouched: () => void;

	openActionDialog() {
		this.action.emit("");
	}

	selectAll() {
		this.selectAllEmmiter.emit("select");
	}

	deSelectAll() {
		this.selectAllEmmiter.emit("deSelect");
	}

	exportToExcel() {
		this.exportToExcelEmmiter.emit("");
	}

	add() {
		this.addEmmiter.emit("");
	}

	addTag() {
		this.addTagEmmiter.emit("");
	}

	getStudents() {
		this.getStudentsEmmiter.emit("update");
		this.onChange(this.filter);
	}

	getSpecials() {
		if (this.filter.schoolId == null) {
			return this.specials;
		} else {
			const a: any[] = [];
			for (let i = 0; i < this.specials.length; i++) {
				for (let schoolId of this.filter.schoolId) {
					if (this.specials[i].schoolId == schoolId) {
						a.push(this.specials[i]);
					}
				}
			}
			return a;
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
	}

	writeValue(value: any): void {
		if (value === null || value === undefined || value === '') {
			value = {};
		}
		this.filter = value;
	}

	// importFromExcel() {
	//   this.dialog.open(StudentImportFormComponent).afterClosed().subscribe((result) => {
	//     if (result) {
	//       this.getStudents();
	//     }
	//   });
	// }

	getAgents(): void {
		this.educationInfoService.getAgents()
			.subscribe(response => {
				this.agents = this.filteredAgents = response;
			});
	}

	getMetaForCalendarProfUsers(): void {
		this.educationInfoService.getMetaForCalendarProfUsers()
			.subscribe(response => {
				this.profBooks = response.books;
				this.profGroups = response.groups;
			});
	}

	getStudentFilterMeta(): void {
		this.studentsService.getStudentFilterMeta()
			.subscribe(response => {
				this.filterMeta = response;
				this.fiteredTagList = response.tags;
			});
	}

	filterTagOptions(event) {
		if (event != "") {
			let searchedItems: any[] = [];
			for (let j = 0; j < this.filterMeta.tags.length; j++) {
				let name = this.filterMeta.tags[j].name + " " + this.filterMeta.tags[j].positionName;
				if (name.match(event)) {
					searchedItems.push(this.filterMeta.tags[j])
				}
			}
			this.fiteredTagList = searchedItems;
		} else {
			this.fiteredTagList = this.filterMeta.tags;
		}
	}

	@ViewChild('allSelected') private allSelected: MatOption;

	toggleAllSelection() {
		if (this.allSelected.selected) {
			this.filter.agentId = [...this.agents.map(item => item.id), 0];
		} else {
			this.filter.agentId = [];
		}

		this.getStudents();

	}

	@ViewChild('allSelectedStates') private allSelectedStates: MatOption;

	toggleAllSelectionStates() {
		if (this.allSelectedStates.selected) {
			this.filter.stateId = [...this.states.map(item => item.id), 0];
		} else {
			this.filter.stateId = [];
		}

		this.getStudents();
	}

	togglePerOne() {
		if (this.allSelected.selected) {
			this.allSelected.deselect();
		}

		this.getStudents();
	}

	filteredCountryList: any[] = [];
	studentGroups: any[] = [];

	filterCountryOptions(event) {
		if (event != "") {

			let searchedItems: any[] = [];
			for (let j = 0; j < this.countryList.length; j++) {
				let name = this.countryList[j].country;
				if (name.match(event)) {
					searchedItems.push(this.countryList[j])
				}
			}
			this.filteredCountryList = searchedItems;
		} else {
			this.filteredCountryList = this.countryList;
		}
	}

	filteredAgents: any[] = [];

	filterAgentsOptions(event) {
		if (event != "") {

			let searchedItems: any[] = [];
			for (let j = 0; j < this.agents.length; j++) {
				let name = this.agents[j].name.toLowerCase();
				event = event.toLowerCase();
				if (name.match(event)) {
					searchedItems.push(this.agents[j])
				}
			}
			this.filteredAgents = searchedItems;
		} else {
			this.filteredAgents = this.agents;
		}
	}

	@ViewChild('allSelectedCountry') private allSelectedCountry: MatOption;

	toggleAllSelectionCountry(): void {
		if (this.allSelectedCountry.selected) {
			this.filter.country = [...this.filteredCountryList.map(item => item.country), 0];
		} else {
			this.filter.country = [];
		}

		this.getStudents();
	}

	togglePerOneCountry(): void {
		if (this.allSelectedCountry.selected) {
			this.allSelectedCountry.deselect();
		}

		this.getStudents();
	}

	// togglePerOneGroup(all) {
	//   if (this.allSelectedGroup.selected) {
	//     this.allSelectedGroup.deselect();
	//   }
	//
	//   this.getStudents();
	// }

	changeProfBook(event) {
		if (event.value != "" && event.value != undefined) {
			for (let i in this.profGroups) {
				if (this.profGroups[i].bookId == event.value) {
					this.filteredProfGroups.push(this.profGroups[i]);
				}
			}
		} else {
			this.filteredProfGroups = [];
			this.filter.profGroups = [];
		}
		this.getStudents();
	}

	@ViewChild('allSelectedGroup') private allSelectedGroup: MatOption;

	toggleAllSelectionGroup() {
		if (this.allSelectedGroup.selected) {
			this.filter.group = [...this.studentGroups.map(item => item.group), -1];
		} else {
			this.filter.group = [];
		}

		this.getStudents();
	}

	getStates(): void {
		this.studentsService.getStates()
			.subscribe(response => {
				this.states = response;
			});
	}

	importFromExcel() {
		this.dialog.open(StudentImportFormComponent).afterClosed().subscribe((result) => {
			if (result) {
				this.getStudents();
			}
		});
	}


}
