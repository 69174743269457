import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MobilityBooksService} from "../mobility-books.service";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
	selector: 'mobility-books-form-dialog',
	templateUrl: './mobility-books-form.dialog.html'
})

export class MobilityBooksFormDialog {

	form: FormGroup;
	disableSaveButton: boolean = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public service: MobilityBooksService,
		public errors: ErrorsDialog,
		public ref: MatDialogRef<MobilityBooksFormDialog>,
		public fb: FormBuilder,
	) {
		this.form = fb.group({
			id: [""],
			bookName: ["", Validators.required],
			bookNameEng: [""],
			credit: ["", Validators.required],
		});

		this.form.patchValue(data);
	}


	ok(): void {
		this.disableSaveButton = true;
		this.service.addOrEditMobilityBook(this.form.value)
			.subscribe(response => {
				if (response.result == 'yes') this.ref.close(true);
				else this.errors.show(response);
				this.disableSaveButton = false;
			}, error => {
				console.error(error);
				this.disableSaveButton = false;
			});
	}

	cancel(): void {
		this.ref.close(false);
	}
}