import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ProgramResult} from "./program.result";
import {ProgramData} from "./program-view/program.data";

@Injectable()
export class ProgramService {


	constructor(
		private http: HttpClient
	) {

	}

	getPrograms(filter, page, limit): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/items/?page=" + page + "&limit=" + limit, filter);
	}

	addOrEdit(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/addOrEdit", data);
	}

	remove(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/remove", data);
	}

	getData(data): Observable<ProgramData> {
		return this.http.post<ProgramData>("/programs/get", data);
	}

	addOrEditModule(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/addOrEditModule", data);
	}

	removeModule(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/removeModule", data);
	}

	addOrEditGroup(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/addOrEditGroup", data);
	}

	removeGroup(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/removeGroup", data);
	}

	addOrEditBook(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/addOrEditBook", data);
	}

	removeBook(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/removeBook", data);
	}

	addMinorProgramToStudent(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/addMinorProgramToStudent", data);
	}

	removeMinorProgramToStudent(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/removeMinorProgramToStudent", data);
	}

	getMinorProgramStudents(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/getMinorProgramStudents", data);
	}

	getBookResults(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/getBookResults", data);
	}

	getStudentProgram(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/getStudentProgram", data);
	}

	editResultGroup(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/editResultGroup", data);
	}

	getEducations(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/getEducations", data);
	}

	setGroup(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/setGroup", data);
	}

	sortBooks(data): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/sortBooks", data);
	}

	exportBookIds(data: { bookIds: number[] }): Observable<ProgramResult> {
		return this.http.post<ProgramResult>("/programs/exportBookIds", data);
	}
}
