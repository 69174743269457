import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatNativeDateModule, MatOptionModule, MatRippleModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {MatBadgeModule} from "@angular/material/badge";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {StudentFilterComponent} from "../pages/students/student-filter/student-filter.component";
import {StudentImportFormComponent} from "../pages/students/student-import-form/student-import-form.dialog";
import {
	UniStudentFilterComponent
} from "../pages/testing/results/list-student/uni-student-filter/uni-student-filter.component";
import {QuestionComponent} from "../pages/testing/results/testing-exam/details/question/question.component";
import {VariantComponent} from "../pages/testing/results/testing-exam/details/variant/variant-component";
import {ListFormDialog} from "../pages/lists/list/list-form/list-form.dialog";
import {EstResultForm} from "../pages/lists/list/est/view/est-result-form/est-result-form";
import {EditListComponents} from "../pages/lists/list/est/view/edit-list-components/edit-list-components";
import {EditStudentListComponent} from "../pages/lists/list/edit-student-list/edit-student-list.component";
import {ListGroupFormComponent} from "../pages/lists/list/edit-student-list/list-group-form/list-group-form.component";
import {GroupStudentsComponent} from "../pages/lists/list/edit-student-list/group-students/group-students-component";
import {
	ConditionErrorDialog
} from "../pages/lists/list/edit-student-list/group-students/condition-error-dialog/condition-error-dialog";
import {AuthDialog} from "./components/auth-dialog/auth-dialog";
import {
	SelectableFieldComponent
} from "../pages/lists/list/est/view/est-result-form/selectable-field/selectable-field.component";
import {CommentDialogComponent} from "./components/comment-dialog/comment-dialog.component";
import {SafeHtmlPipe} from "./pipes/safeHtml.pipe";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {TranslateLoader, TranslateModule, TranslateService} from "@ngx-translate/core";
import {MomentModule} from "ngx-moment";
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {IniInputModule} from "../core/components/book-input/ini-input.module";
import {DateFromToModule} from "../core/components/date-from-to/date.from.to.module";
import {IniEditorModule} from "../core/components/ini-editor/ini.editor.module";
import {NgxMaterialTimepickerModule} from "ngx-material-timepicker";
import {IniDatePickerModule} from "../core/components/ini-date-picker/ini-date-picker.module";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {CodemirrorModule} from "@ctrl/ngx-codemirror";
import {SocketIoModule} from "ngx-socket-io";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {MathEditor} from "../core/directives/math.editor";
import {ResultType} from "../core/directives/result.type";
import {DialogDraggableTitleDirective} from "../core/components/draggable/dialog.draggable.title.directive";
import {EditableComponent} from "../core/components/editable/editable.component";
import {DateInputComponent} from "./components/date-input/date-input.component";
import {GroupSelectComponent} from "../core/components/groupSelect/groupSelect.component";
import {MathEditorDialog} from "../core/dialogs/math-editor-dialog/math-editor-dialog";
import {HasPermission} from "../core/directives/has.permission";
import {HasPermissionIf} from "../core/directives/had.permission.if";
import {LangObjectDirective} from "../core/directives/lang.object";
import {MatInputZero} from "../core/directives/mat-input-zero";
import {FaIcon} from "../theme/components/fa-icons/fa.icon";
import {ClickOutside} from "./components/click-outside/click-outside";
import {FocusableDirective} from "../core/directives/focusable.directive";
import {EditModeDirective} from "../core/directives/edit-mode.directive";
import {EditableOnEnterDirective} from "../core/directives/edit-on-enter.directive";
import {ViewModeDirective} from "../core/directives/view-mode.directive";
import {TokenInterceptor} from "../core/interceptors/token.interceptor";
import {UrlInterceptor} from "../core/interceptors/url.interceptor";
import {faGraduationCap, faUniversity} from "@fortawesome/free-solid-svg-icons";
import {SendSmsDialog} from "../pages/students/student-action-dialog/send-sms-dialog/send-sms.dialog";
import {InputMaskModule} from "@ngneat/input-mask";
import { OtpCountdownComponent } from './components/otp-countdown/otp-countdown.component';

export class MyTranslateLoader implements TranslateLoader {

	constructor(private http: HttpClient) {
	}

	getTranslation(lang: string): Observable<any> {
		return new Observable<any>(observer => {
			this.http.get(`${environment.APP_URL}/assets/i18n/${lang}.json`).subscribe({
				next: data => {
					observer.next(data)
					observer.complete();
				},
				error: () => {
					observer.next({});
					observer.complete();
				},
			});
		});
	}
}

@NgModule({
	imports: [
		CommonModule,
		FlexLayoutModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatStepperModule,
		MatOptionModule,
		MomentModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useClass: MyTranslateLoader,
				deps: [HttpClient]
			}
		}),
		MatIconModule,
		MatBadgeModule,
		FontAwesomeModule,
		HttpClientModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		FormsModule,
		IniInputModule,
		DateFromToModule,
		IniEditorModule,
		NgxMaterialTimepickerModule,
		IniDatePickerModule,
		AngularEditorModule,
		CodemirrorModule,
		SocketIoModule,
		NgxMatSelectSearchModule,
		InputMaskModule
	],
	exports: [
		FlexLayoutModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatStepperModule,
		MatOptionModule,
		TranslateModule,
		MatIconModule,
		MatBadgeModule,
		FontAwesomeModule,
		HttpClientModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		FormsModule,
		IniInputModule,
		DateFromToModule,
		MomentModule,
		NgxMaterialTimepickerModule,
		AngularEditorModule,
		CodemirrorModule,
		SocketIoModule,


		// components
		MathEditor,
		StudentFilterComponent,
		StudentImportFormComponent,
		UniStudentFilterComponent,
		IniEditorModule,
		QuestionComponent,
		VariantComponent,
		ResultType,
		IniDatePickerModule,
		DialogDraggableTitleDirective,
		EditableComponent,
		DateInputComponent,
		GroupSelectComponent,

		// dialogs
		MathEditorDialog,
		ListFormDialog,
		EstResultForm,
		EditListComponents,
		EditStudentListComponent,
		ListGroupFormComponent,
		GroupStudentsComponent,
		ConditionErrorDialog,
		AuthDialog,
		SendSmsDialog,

		// directives
		HasPermission,
		HasPermissionIf,
		LangObjectDirective,
		MatInputZero,
		FaIcon,
		ClickOutside,
		FocusableDirective,
		EditModeDirective,
		EditableOnEnterDirective,
		ViewModeDirective,
		SafeHtmlPipe,
		OtpCountdownComponent,
	],
	declarations: [
		// components
		MathEditor,
		StudentFilterComponent,
		UniStudentFilterComponent,
		QuestionComponent,
		VariantComponent,
		ResultType,
		DialogDraggableTitleDirective,
		SelectableFieldComponent,
		EditableComponent,
		DateInputComponent,
		GroupSelectComponent,

		// dialogs
		MathEditorDialog,
		ListFormDialog,
		EstResultForm,
		EditListComponents,
		EditStudentListComponent,
		ListGroupFormComponent,
		GroupStudentsComponent,
		ConditionErrorDialog,
		StudentImportFormComponent,
		CommentDialogComponent,
		SendSmsDialog,

		// directives
		HasPermission,
		HasPermissionIf,
		LangObjectDirective,
		AuthDialog,
		MatInputZero,
		FaIcon,
		ClickOutside,
		FocusableDirective,
		EditModeDirective,
		EditableOnEnterDirective,
		ViewModeDirective,
		SafeHtmlPipe,
  OtpCountdownComponent,
	],
	providers: [
		TranslateService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UrlInterceptor,
			multi: true
		}
	]
})
export class SharedModule {
	constructor(library: FaIconLibrary) {
		library.addIcons(faUniversity, faGraduationCap)
	}
}
