import { Pipe, PipeTransform } from '@angular/core';


const unpaidVacationId: number = 2;

@Pipe({
  name: 'isUnpaidVacationDisabled'
})
export class IsUnpaidVacationDisabledPipe implements PipeTransform {

  transform(settingVacationId: any, isUnpaidVacationDisabled: boolean): boolean {
    // Implement your logic here based on vacationId and isUnpaidDisabled
    // For now, let's assume you just want to check if it's unpaid
    return settingVacationId == unpaidVacationId && isUnpaidVacationDisabled; // or modify according to your logic
  }
}