import {Component, forwardRef, Input, ViewChild} from "@angular/core";
import {FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IniInput} from "./ini.input";
import {Observable} from "rxjs";
import {AutocompleteNamesService} from "./autocomplete-names.service";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from "@angular/material/autocomplete";

@Component({
    selector: "book-input-multiple",
    templateUrl: "./book-multiple-input.html",
    styleUrls: ["./book-multiple-input.scss"],
    providers:[{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BookMultipleInput),
        multi: true
    }]
})
export class BookMultipleInput extends IniInput { /**/
    outputCtrl: FormControl;
    /**/@ViewChild('input', {static: false, read: MatAutocompleteTrigger}) autoComplete: MatAutocompleteTrigger;
	@Input("placeholder") override placeholder = "";
	@Input("type") override type = "old";
	@Input("lang") override lang: string = "ka";
    lastSearchText = "";
    constructor(
        private service: AutocompleteNamesService
    ) {
        super();
        this.outputCtrl = new FormControl([]);
    }

    remove(index) {
        this.outputCtrl.value.splice(index, 1);
        this.onChange(this.outputCtrl.value);
    }

    optionSelected(selected: MatAutocompleteSelectedEvent) {
        const outputValues: any[] = this.outputCtrl.value;
        let exists = false;
        let index = -1;
        for (const book of outputValues) {
            index++;
            if (book.id == selected.option.value.id) {
                exists = true;
                break;
            }
        }
        if (exists) {
            outputValues.splice(index, 1);
        } else {
            outputValues.push(selected.option.value);
        }
        this.formControl.setValue(this.lastSearchText);
        this.onChange(this.outputCtrl.value);
        setTimeout(() => {
            this.autoComplete.openPanel();
        });
    }

	override search(q: string): Observable<any[]> {
        if (typeof q == "string") {
            this.lastSearchText = q;
        }
        return this.service.getBookNames(q);
    }

    // noinspection JSUnusedGlobalSymbols
    isSelected(value) {

        return this.outputCtrl.value.indexOf(value) >= 0;
    }

    isSelectedItem(item) {
        if (typeof this.outputCtrl.value == "object" && typeof this.outputCtrl.value.length != "undefined") {
            for (const book of this.outputCtrl.value) {
                if (book.id == item.id) {
                    return true;
                }
            }
        }
        return false;
    }

	override writeValue(obj: any): void {
        if (typeof obj == "object" && typeof obj.length != "undefined") {
            this.outputCtrl.patchValue(obj);
        } else {
            this.formControl.patchValue(obj);
        }
    }

}
