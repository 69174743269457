import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {PollAlertComponent} from "../../theme/components/poll-alert/poll-alert.component";
import {UserService} from "../../core/services/user.service";

declare const JSON: any;

@Injectable()
export class PollAlertService {

	menuHide = false;
	dialogRef: any = false;

	constructor(
		private http: HttpClient,
		private dialog: MatDialog,
		private router: Router,
		private userService: UserService
	) {
	}

	checkPollAlert(event) {
		if (this.menuHide && !this.isPollResponsePage(event) && !this.isThisPage('login', event)) {
			this.router.navigate(['/poll-response']).then(() => {
			});
		}
	}

	isProduction() {
		return (window.location.hostname+'').includes('local') == false;
	}

	getPollForAlert(necessary = false, authAction = false) {
		if (this.userService.is_logged && this.isProduction()) {
			if (necessary || (!this.isPollResponsePage() && this.dialogRef == false)) {
				this.http.post<any>('/poll/getPollForAlert', {
					authAction: authAction
				})
					.subscribe(res => {
						this.saveConfig(res);
						this.menuVisibility();
						if (res.status) {
							this.showAlert(res);
						}
					})
			}
		}
	}

	showAlert(data) {
		if (this.dialogRef) return;
		this.dialogRef = this.dialog.open(PollAlertComponent, {
			data: data,
			width: '300px',
			disableClose: true
		});

		// noinspection JSDeprecatedSymbols
		this.dialogRef.afterOpen().subscribe(() => {
			this.dialogRef = true;
		});
		this.dialogRef.afterClosed().subscribe(() => {
			this.dialogRef = false;
			this.saveAlertSeen();
		})
	}


	menuVisibility() {
		try {
			let config = JSON.parse(this.getConfig());
			this.menuHide = config.status == true && config.configuration.required_level == 'high';
		} catch (ex) {

		}
	}

	isPollResponsePage(rout = null) {
		let rt = rout != null ? rout : this.router;
		let splitUrl = rt.url.split('/');
		return splitUrl[1] == 'poll-response';

	}

	isThisPage(page, rout = null) {
		let rt = rout != null ? rout : this.router;
		let splitUrl = rt.url.split('/');
		return splitUrl[1] == page;

	}

	saveConfig(configuration) {
		localStorage.setItem('pollConfiguration', JSON.stringify(configuration));
	}

	getConfig() {
		return localStorage.getItem('pollConfiguration',);
	}

	saveAlertSeen() {
		this.http.post<any>('/poll/saveAlertSeen', {})
			.subscribe(() => {
			})
	}


}