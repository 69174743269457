import {Component} from "@angular/core";
import {ChatService} from "../../services/chat-service";
import {MessageSeenType} from "../../interfaces/MessageSeenType";

@Component({
  selector: 'chat-budget',
  templateUrl: './chat-budget.component.html',
  styleUrls: ['./chat-budget.component.scss']
})
export class ChatBudget {

  unreadMessages = 0;

  constructor(
    private chatService: ChatService,
  ) {
    this.chatService.getRooms().subscribe((chats) => {

      this.unreadMessages = chats.filter((chat) => chat.seen == MessageSeenType.ME_NOT_SEEN).length;
    });
  }

}
