import {Component, HostListener, ViewEncapsulation} from "@angular/core";
import {ChatService} from "../../services/chat-service";
import {Chat} from "../../interfaces/Chat";
import {ChatStatus} from "../../interfaces/ChatStatus";

@Component({
	selector: 'ini-chat-footer',
	templateUrl: './footer.component.html',
	styleUrls: [
		"./../../style.scss",
		"./footer.component.scss"
	],
	encapsulation: ViewEncapsulation.None
})
export class Footer {

	subs: any = [];
	inactiveChats: Chat[] = [];
	activeChats: Chat[] = [];

	constructor(
		public chatService: ChatService
	) {
		this.subs.push(
			this.chatService.getChats().subscribe(chats => {

				this.inactiveChats = chats.filter(chat => chat.status === ChatStatus.INACTIVE).reverse();
				this.activeChats = chats.filter(chat => chat.status === ChatStatus.ACTIVE).reverse();
			})
		);
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		this.chatService.calculateChatLimits();
	}

	addChat() {
		if (this.emptyChatExists()) {
			this.clearEmptyChat();
		} else {
			this.addEmptyChat();
		}
	}

	addEmptyChat = () => this.chatService.addEmptyChat();
	emptyChatExists = () => !!this.activeChats.find(chat => chat.id == -1);
	clearEmptyChat = () => this.chatService.closeChat(-1);

}
