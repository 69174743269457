import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StudentsService} from "../../../students.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ErrorsDialog} from "../../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: "student-action-form-dialog",
	templateUrl: "./student-action-form.dialog.html"
})
export class StudentActionFormDialog implements OnInit, OnDestroy {

	form: FormGroup;
	subscriptions: any[] = [];

	constructor(
		public ref: MatDialogRef<StudentActionFormDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public studentService: StudentsService,
		public fb: FormBuilder,
		public error: ErrorsDialog
	) {
		this.form = fb.group({
			studentId: ["", Validators.required],
			sub: ["", Validators.required],
			type: ["", Validators.required],
			reasonType: ["", Validators.required],
			reason: ["", ],
			totalExamCount: [""],
			welcomeName: [""],
			welcomeIcon: [""],
			welcomeEndDate: [""],
		});

		this.subscriptions.push(this.form.get('sub').valueChanges.subscribe(value => {

			if (value == 'examAccess') {
				this.form.get('type').setValue('examAccess');
				this.form.get('reasonType').setValue(1);
				this.form.get('totalExamCount').setValidators(Validators.required);
			}if (value == 'welcomeAccess') {
				this.form.get('type').setValue('welcomeAccess');
				this.form.get('reasonType').setValue(3);
				this.form.get('welcomeName').setValidators(Validators.required);
				this.form.get('welcomeIcon').setValidators(Validators.required);
				this.form.get('welcomeEndDate').setValidators(Validators.required);
			} else {
				if (this.data.type) {
					this.form.get('type').setValue(this.data.type);
				} else {
					this.form.get('type').setValue('');
				}
				if (this.data.reasonType) {
					this.form.get('reasonType').setValue(this.data.reasonType);
				} else {
					this.form.get('reasonType').setValue('');
				}
				this.form.get('totalExamCount').clearValidators();
			}
			this.form.updateValueAndValidity();
		}));
	}

	ngOnInit(): void {
		this.form.patchValue({
			studentId: this.data.studentId
		});
		if (this.data.sub) {
			this.form.patchValue({
				sub: this.data.sub
			});
		}
	}

	cancel() {
		this.ref.close(false);
	}

	ok() {
		this.studentService.addAction(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		});
	}

	ngOnDestroy(): void {
		for (let subscription of this.subscriptions) {
			try {

				subscription.unsubscribe();
			} catch (e) {

			}
		}
	}




}
