import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BooksService} from "../books.service";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";

@Component({
    selector: "book-form-dialog",
    templateUrl: "./book-form.dialog.html"
})
export class BookFormDialog implements OnInit {

    form: FormGroup;
	public eduLevels = [
		{id: 1, name: "ბაკალავრი"},
		{id: 2, name: "მაგისტრი"},
		{id: 3, name: "დოქტორი"},
		{id: 4, name: "ერთსაფეხურიანი"},
		{id: 5, name: "პროფესიული"},
		{id: 6, name: "რეზიდენტი"}
	];

    constructor(
        private ref: MatDialogRef<BookFormDialog>,
        private service: BooksService,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private fb: FormBuilder,
        private error: ErrorsDialog
    ) {
        this.form = fb.group({
            id: "",
            name: ["", Validators.required],
            code: "",
            description: "",
            credit: ["", Validators.required],
            type: ["", Validators.required],
            status: ["", Validators.required],
	        lang: [""],
	        langCode: ["", Validators.required],
	        nashromi_praqtika_type: [""],
	        medic: false,
			level: ['']
        });
    }

    ngOnInit(): void {
        this.form.patchValue({
            id: this.data.id,
            name: this.data.name,
            code: this.data.code,
            description: this.data.description,
            credit: this.data.credit,
            type: this.data.type == null || this.data.type == "" ? 1 : this.data.type,
            status: this.data.status,
	        lang: this.data.lang,
	        langCode: this.data.langCode,
	        nashromi_praqtika_type: this.data.nashromi_praqtika_type,
	        medic: this.data.medic,
        });

        this.form.get('type').valueChanges.subscribe(value => {
        	if (value == 2) this.form.get('lang').setValidators(Validators.required);
        	else this.form.get('lang').clearValidators();
        	this.form.get('lang').updateValueAndValidity();
        });
    }

    cancel() {
        this.ref.close(false);
    }

    ok() {
        this.service.add(this.form.value).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(true);
            } else {
                this.error.show(response);
            }
        });
    }
}
