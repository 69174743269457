import {Component, forwardRef, Input} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable} from "rxjs";
import {AutocompleteNamesService} from "./autocomplete-names.service";
import {IniInput} from "./ini.input";


@Component({
    selector: "student-input",
    templateUrl: "./auto-complete-form.html",
    providers:[{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => StudentInput),
        multi: true
    }]
})
export class StudentInput extends IniInput { /**/

	@Input("placeholder") override placeholder = "";
	@Input("type") override type = "old";
	@Input("lang") override lang: string = "ka";

    constructor(
        private service: AutocompleteNamesService
    ) {
        super();
    }

	override search(q: string): Observable<any[]> {

        if (this.type == "old") {
            return this.service.getStudentNames(q);
        } else {
            return this.service.getNewStudentNames(q);
        }
    }

}
