import {AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ListService} from "../../list.service";
import {ErrorsDialog} from "../../../../../shared/error-dialogs/errors.dialog";
import {Student, StudentFilter} from "../../../../students/students.models";
import {StudentsService} from "../../../../students/students.service";
import {ConfirmDialog} from "../../../../../theme/components/confirmDialog/confirm.dialog";
import {ConditionErrorDialog} from "./condition-error-dialog/condition-error-dialog";
import {UniConfig} from "../../../../../../uni.config";
import { MatPaginator } from "@angular/material/paginator";

@Component({
    selector: "group-students-component",
    templateUrl: "./group-students-component.html",
    styleUrls: ["./group-students-component.scss"]
})
export class GroupStudentsComponent implements OnInit, AfterViewInit {

    uniConfig = UniConfig;

    constructor(
        private ref: MatDialogRef<GroupStudentsComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private service: ListService,
        private error: ErrorsDialog,
        public studentService: StudentsService,
        private dialog: MatDialog
    ) {
        this.addEventEmmiter.subscribe(() => {

        });
        this.getStudentsEventEmmiter.subscribe(() => {
            this.getStudents();
        });
        this.actionDialog.subscribe(() => {

        });
        this.selectAllEmmiter.subscribe((result) => {
            if (result == "select") {
                this.studentService.getStudentSelection(this.filter).subscribe(response => {
                    if (response.result == "yes") {
                        this.selectedStudent = response.data;
                        this.checkSelectedStudentCount();
                    }
                });
            } else {
                this.selectedStudent = {};
                this.checkSelectedStudentCount();
            }
        });
        this.getStudents();
    }

    searchBarConfig: any = {
        withAction: true,
        withSelectAllButton: true,
        showAddButton: false,
        showExportButton: false,
    };
    filter = new StudentFilter();
    addEventEmmiter = new EventEmitter<string>();
    // noinspection JSUnusedGlobalSymbols
    exportToExcelEventEmmiter = new EventEmitter<string>();
    getStudentsEventEmmiter = new EventEmitter<string>();
    actionDialog = new EventEmitter<string>();
    selectAllEmmiter = new EventEmitter<string>();
    statuses = Student.statuses();
    displayedColumns = [
        "id",
        "name",
        "personalNumber",
        "schoolName",
        "specialName",
        "semester",
        "status"
    ];

    selectedStudent = {};
    selectedStudentCount = 0;

    page = 1;
    limit = 15;
    totalStudents = 0;
    students = [];

    @ViewChild("paginator", {static: false}) paginator: MatPaginator;

    selectStudent(student, ev) {
        if (typeof this.selectedStudent[student.id] != "undefined") {
            delete (this.selectedStudent[student.id]);
        } else {
            if (ev.ctrlKey) {
                this.selectedStudent[student.id] = student.firstName + " " + student.lastName;
            } else {
                this.selectedStudent = {};
                this.selectedStudent[student.id] = student.firstName + " " + student.lastName;
            }
        }
        this.checkSelectedStudentCount();
    }

    checkSelectedStudentCount() {
        let n = 0;
        for (const a in this.selectedStudent) {
            n++;
        }
        this.selectedStudentCount = n;

    }

    getStudents() {
        this.studentService.list(this.filter, this.page, this.limit).subscribe(response => {
            this.students = response.data;
            this.totalStudents = response.total;
        });
    }

    cancel() {
        this.ref.close(false);
    }

    ok(canRewrite = "confirm", ignoreConditions=false) {
        this.service.addStudents({
            groupId: this.data.id,
            students: this.selectedStudent,
	        ignoreConditions: ignoreConditions,
	        canRewrite: canRewrite
        }).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(true);
            } else if (response.error == "studentAlreadyExists") {
                const ref = this.dialog.open(ConfirmDialog, {
                    data: {
                        title: "სტუდენტების დამატება ჯგუფში",
                        text: "არჩეული სტუდენტები არის ამავე უწყისის სხვა ჯგუფში. გსურთ გადმოყვანა?",
                        ok: "დიახ",
                        cancel: "არა"
                    }
                });
                ref.afterClosed().subscribe(result => {
                    if (result) {
                        this.ok("yes");
                    } else {
                        this.ok("no");
                    }
                });
            } else {
                if (typeof response.error == "object" && typeof response.error.type == "string") {
                    this.dialog.open(ConditionErrorDialog, {
                        data: response.error
                    }).afterClosed().subscribe(result => {
                    	if (result) {
                    		this.ok(canRewrite, true);
	                    }
                    });
                } else {
                    this.error.show(response);
                }
            }
        });
    }

    getSelectedStudents() {
        this.service.getSelectedStudents({
            groupId: this.data.id
        }).subscribe(response => {
            this.selectedStudent = response.data;
        });
    }
    ngAfterViewInit(): void {
        this.paginator.page.subscribe(res => {
            this.page = res.pageIndex + 1;
            this.limit = res.pageSize;
            this.getStudents();
        });
    }

    ngOnInit(): void {
        this.getSelectedStudents();
    }

}
