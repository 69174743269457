import {Component, ViewEncapsulation} from "@angular/core";

@Component({
    selector: "permission-tabs-component",
    templateUrl: "./permission.tabs.component.html",
    styleUrls: ["./permission.tabs.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class PermissionTabsComponent {

    devMode = false;



}