import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "../auth/auth.service";
import {inject} from "@angular/core";

const isAuth = () => {
	// todo: late implementation
	return false;
}

export const AuthGuard = (activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) => {
	const authService = inject(AuthService)
	const router = inject(Router)

	if (routerState.url !== "/login" && !authService.isAuth()) {
		router.navigate(["/login"]).then(() => {});
		return false;
	}
	return true;
}



// @Injectable()
// export class AuthGuard {
//
//     constructor(
//         protected router: Router,
//         protected auth: AuthModel
//     ) {
//
// 	}
//
// 	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
//         if (state.url !== "/login" && !this.auth.isAuth) {
//             this.router.navigate(["/login"]).then(() => {});
//             return false;
//         }
//
//         return true;
//     }
//
//
// }
