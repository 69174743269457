import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, FormArray, FormBuilder, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
	selector: "ini-selectable-field",
	templateUrl: "./selectable-field.component.html",
	styleUrls: ["./selectable-field.component.scss"],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SelectableFieldComponent),
		multi: true
	}]
})
export class SelectableFieldComponent implements ControlValueAccessor, OnInit {

	public onChange: (value: any) => void;
	form: FormArray;

	@Input("data") data: any[];
	@Input("component") component: any;
	value = [];
	indexNames = [
		"A",
		"B",
		"C",
		"D",
		"E",
		"F",
		"G",
		"H",
		"I",
		"J",
		"K",
		"L",
		"M",
		"N",
		"O",
		"P",
		"Q",
		"R",
		"S",
		"T",
		"U",
		"V",
		"W",
		"X",
		"Y",
		"Z"
	];

	constructor(
		private fb: FormBuilder
	) {
		this.form = fb.array([]);

	}

	items() {
		return this.form.controls;
	}

	calcValue() {
		for (let i = 0; i < this.form.controls.length; i++) {
			this.value[i] = this.form.value[i].value ? 1 : 0;
		}
	}

	setValue() {
		this.calcValue();
		this.onChange(JSON.stringify(this.value));
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
	}

	setDisabledState(isDisabled: boolean): void {
		if (isDisabled) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	writeValue(obj: any): void {
		//
		if (typeof obj == "string" && obj != "") {
			let values = JSON.parse(obj);
			for (let i = 0; i < this.form.controls.length; i++) {
				if (typeof values[i] != "undefined" && values[i] != null) {
					this.form.controls[i].patchValue({
						value: values[i]
					});
				}
			}
		} else if (typeof obj == "object" && obj != null) {
			this.form.patchValue(obj);
		}
		// */
	}

	ngOnInit(): void {
		//
		for (let i=0; i<this.component.weight; i++) {
			let group = this.fb.group({
				name: this.indexNames[i],
				value: false
			});
			this.form.push(group);
		}
		this.calcValue();

	}

}