import {Student} from "../students.models";
import {Education} from "../student-details/education/education.models";
import {EducationInfo} from "../student-details/education-info/list/education-info-list.models";

export class StudentExportForm {

    info: Student = new Student();
    education: Education = new Education();
    educationInfo: EducationInfo = new EducationInfo();


}