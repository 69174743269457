import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ListService} from "../../list.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ErrorsDialog} from "../../../../../shared/error-dialogs/errors.dialog";
import {UniConfig} from "../../../../../../uni.config";

@Component({
    selector: "list-group-profs",
    templateUrl: "./list-group-profs.html"
})
export class ListGroupProfs implements OnInit {

    form: FormGroup;
    isProfTerm = false;

    constructor(
        private ref: MatDialogRef<ListGroupProfs>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        private service: ListService,
        private fb: FormBuilder,
        private error: ErrorsDialog
    ) {
        this.isProfTerm = typeof UniConfig.profTerm != "undefined" && UniConfig.profTerm == 2;
        this.form = fb.group({
            id: "",
            listId: ["", Validators.required],
            groupId: ["", Validators.required],

            profId: ["", Validators.required],
            prof: "",
            type: ["", Validators.required]
        });
        this.form.get("prof").valueChanges.subscribe((value) => {
            if (typeof value == "object" && typeof value.id != "undefined") {
                this.form.patchValue({
                    profId: value.id
                });
            } else {
                this.form.patchValue({
                    profId: ""
                });
            }
        });
    }

    cancel() {
        this.ref.close(false);
    }

    save() {
        this.service.addOrRemoveProf(this.form.value).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(true);
            } else {
                this.error.show(response);
            }
        });
    }

    ngOnInit(): void {
        this.form.patchValue(this.data);
    }

}
