import {Directive, ElementRef, Input, OnDestroy, OnInit} from "@angular/core";
import {PermissionService} from "../services/permission.service";

@Directive({
    selector: "[hasPermission]"
})
export class HasPermission implements OnInit,OnDestroy {

    constructor(
        private elRef: ElementRef,
        private service: PermissionService,
    ) {
        this.element = elRef.nativeElement;
    }

	permissionUserId: any = null;

    private element: HTMLInputElement;
    @Input("hasPermission") permissionName: any;

    subscriber: any;

    // noinspection JSUnusedLocalSymbols
    private elementOldStyle = "initial";

    ngOnInit(): void {
        this.render();
        this.subscriber = this.service.onChange.subscribe(() => this.render());
    }

    render() {
        /*
        if (!this.service.can(this.permissionName)) {
            this.elementOldStyle = this.element.style.display;
            this.element.style.display = "none";
        } else if (this.element.style.display != this.elementOldStyle) {
            this.element.style.display = this.elementOldStyle;
        }
        */

	    let permissionName = this.permissionName;

	    if (typeof permissionName == 'string' && permissionName.indexOf(':') != -1) {
		    this.permissionUserId = permissionName.split(':')[1];
		    permissionName = permissionName.split(':')[0];
	    }

        if (!this.service.can(permissionName, this.permissionUserId)) {
            this.element.className += " permissionHidden ";
        } else {
            this.element.className = (this.element.className+"").replace("permissionHidden", "");
        }

    }

    ngOnDestroy(): void {
        this.subscriber.unsubscribe();
    }



}
