import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: "login-timeout-dialog",
	templateUrl: "./login-timeout.dialog.html"
})
export class LoginTimeoutDialog implements OnInit, OnDestroy {

	timer: any = 0;
	seconds: any = 0;

	constructor(
		private ref: MatDialogRef<LoginTimeoutDialog>,
		@Inject(MAT_DIALOG_DATA) private data
	) {
		this.seconds = this.getLeftSeconds();
		this.ref.disableClose = true;
		this.startTimer();
	}

	startTimer() {
		this.clearTimer();
		this.timer = setTimeout(() => {
			this.seconds--;
			if (this.seconds > 0) {
				this.startTimer();
			} else {
				this.ref.disableClose = false;
				this.ref.close(false);
			}
		}, 1000);
	}

	clearTimer() {
		try {
			clearTimeout(this.timer);
		} catch (ex) {}
	}

	getLeftSeconds() {
		let current = Math.round(new Date().getTime() / 1000);
		return  this.data.lastUpdate + this.data.loginTimeout - current;
	}

	ngOnInit(): void {

	}

	ok() {
		this.ref.disableClose = false;
		this.ref.close(true);
	}

	ngOnDestroy(): void {
		this.clearTimer();
	}



}
