import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {UniConfig} from "../../../uni.config";

@Component({
    selector: "result",
    template: `<span [ngStyle]="{'color': color}">
        <span style="font-size: 12px; padding-right: 4px; font-family: Arial,serif;" *ngIf="score!=-1 && !isResultHidden" [innerHTML]="score"></span>
        <b *ngIf="result != 'done'" [innerHTML]="result"></b>
        <i *ngIf="result == 'done'"><mat-icon style="color: #00AA00">done</mat-icon></i>
    </span>`
})
export class ResultType implements OnInit, OnChanges {

    @Input("value") result: any = "A";
    @Input("score") score = -1;
    color = "red";

    isResultHidden = UniConfig.yleoba_n1000;

    ngOnInit(): void {
        this.parseColors();
    }

    parseColors() {
        if (typeof this.result == "object" && this.result != null && typeof this.result.changingThisBreaksApplicationSecurity != "undefined") {
            this.result = this.result.changingThisBreaksApplicationSecurity;
        }
        if (this.result == null) { return; }
        switch ((this.result+"").toLowerCase()) {
            default:
                this.color = "#000000";
                break;
            case "a":
            case "b":
                this.color = "#00AA00";
                break;
            case "c":
            case "d":
                this.color = "#0CAA77";
                break;
            case "e":
                this.color = "#60a2aa";
                break;
            case "t":
                this.color = "#25c9ff";
                break;
            case "s":
                this.color = "#25c9ff";
                break;
            case "f":
                this.color = "#FF0000";
                break;
            case "fx":
                this.color = "#FF6600";
                break;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.parseColors();
    }

}
