import {NotificationRecipient} from "./notification-recipient";

export class NotificationRecipientGroup {

    constructor(public type="Group") {

    }
    addresses: NotificationRecipient[]= [];
    filter: any = {};
    total: number = 0;
}