import {Component, OnDestroy, OnInit} from "@angular/core";
import {StudentsService} from "../../students.service";
import {ActivatedRoute} from "@angular/router";
import {EducationInfo} from "../education-info/list/education-info-list.models";
import {UniConfig} from "../../../../../uni.config";
import moment from "moment";
import {UserService} from "../../../../core/services/user.service";

@Component({
	selector: "student-tables-component",
	templateUrl: "./student-tables.component.html",
	styleUrls: ["./student-tables.component.scss"]
})
export class StudentTablesComponent implements OnInit, OnDestroy {

	isProfTerm = typeof UniConfig.profTerm != "undefined" && UniConfig.profTerm == 2;
	studentId: number;
	eduId: number;
	educations: EducationInfo[] = [];
	items:any[] = [];
	subs = [];
	serverTime = 0;
	timer = null;
	types = {
		lecture: "ლექცია",
		seminar: "სემინარი",
		both: "ლექცია/სემინარი"
	};

	days = [
		"ორშაბათი",
		"სამშაბათი",
		"ოთხშაბათი",
		"ხუთშაბათი",
		"პარასკევი",
		"შაბათი",
		"კვირა"
	];

	constructor(
		public studentService: StudentsService,
		private router: ActivatedRoute,
		public userService: UserService
	) {

	}

	isCurrent(item) {
		//
		let current = moment(this.serverTime*1000).format("Y-MM-DD hh:mm");
		let startDate = current.substring(0,10)+" "+this.h(item.data.h)+":"+this.h(item.data.m);

		if (parseInt(moment(this.serverTime*1000).format("d")) != parseInt(moment(startDate).format("d"))) {
			return false;
		}
		let t = moment(current);

		t.add(item.data.duration, "m");
		let endDate = t.format("Y-MM-DD hh:mm");

		return (
			startDate<=current && endDate>=current
		);
	}

	h(n) {
		if (parseInt(n)<10) {
			return "0"+n;
		}
		return n;
	}

	getItems() {
		this.studentService.getStudentTables({
			studentId: this.studentId,
			eduId: this.eduId
		}).subscribe(response => {
			if (response.result == "yes") {
				this.serverTime = response.time;
				this.items = response.data;
			}
		});
	}

	getEducations() {
		this.studentService.getEducations({
			id: this.studentId
		}).subscribe(response => {
			if (response.result == "yes") {
				this.educations = response.data;
				for (let edu of this.educations) {
					this.eduId = edu.id;
				}
				this.getItems();
			}
		});
	}

	ngOnInit(): void {
		this.subs.push(this.router.parent.params.subscribe(params => {

			this.studentId = params.studentId;
			this.getEducations();
		}));
		/*
		this.timer = setInterval(() => {
			this.serverTime++;
		}, 1000);
		// */
	}

	ngOnDestroy(): void {
		try {
			clearInterval(this.timer);
		} catch (e) {}
		for (let sub of this.subs) {
			try {
				sub.unsubscribe();
			} catch (ex) {}
		}
	}



}
