import {Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {AutocompleteNamesService} from "./autocomplete-names.service";
import {MatDialog} from "@angular/material/dialog";
import {SeminarInputDialog} from "./seminar-input-dialog";

@Component({
	selector: "seminar-input",
	templateUrl: "./seminar-input.html",
	providers:[{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SeminarInput),
		multi: true
	}]
})
export class SeminarInput implements ControlValueAccessor {

	@Input("placeholder") placeholder = "სემინარი";
	public onChange: (value: any) => void;

	data: any = {
		groupId: 0,
		bookId: 0,
		seminarId: 0,
		name: ""
	};

	constructor(
		private service: AutocompleteNamesService,
		private dialog: MatDialog
	) {

	}

	select() {
		let ref = this.dialog.open(SeminarInputDialog, {
			data: this.data,
			width: "500px"
		});
		ref.afterClosed().subscribe(data => {
			if (typeof data != "undefined" && data != null && data != false) {
				this.data = data;
				this.onChange(JSON.stringify(this.data));
			}
		});
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
	}

	writeValue(value: any): void {

		if (typeof value != "object" && value != null && value+"" != "NaN" && value != '') {
			this.data = JSON.parse(value);
		}
	}

}