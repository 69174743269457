import {HttpClient} from "@angular/common/http";
import {AuthModel} from "../models/auth.model";
import {Injectable} from "@angular/core";
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router} from "@angular/router";
import {PermissionService} from "../services/permission.service";
import {UserService} from "../services/user.service";
import {Observable} from "rxjs";

@Injectable()
export class AuthService {

	constructor(
		private http: HttpClient,
		private auth: AuthModel,
		private router: Router,
		private userService: UserService,
		private permissionService: PermissionService
	) {

	}

	isAuth() {
		return this.auth.isAuth;
	}

	initPermissions() {
		this.permissionService.initPermissions();
	}


	updateZoomLink(data): Observable<any> {
		return this.http.post<any>("/isOnline/update", data);
	}

	getZoomLinkStatus(): Observable<any> {
		return this.http.post<any>("/isOnline/getStatus", {});
	}


	tryAuth(loginData): Promise<any> {
		return new Promise((serve) => {
			return this.http.post<any>('/auth/login/new', loginData).subscribe(res => {
				if (res.res == 'yes') {
					if (this.tokenInit(res.token)) {
						localStorage.setItem('token', res.token);
						localStorage.setItem('masterToken', res.token);
						serve({
							res: "yes"
						});
					}
					serve({
						res: "no"
					});
				} else {
					serve(res);
				}
			});
		})

	}

	tokenInit(token) {
		if (token != '' && token != null && typeof token != 'undefined') {
			try {
				const helper = new JwtHelperService();
				const decodedToken = helper.decodeToken(token);
				this.userService.initUser(token, decodedToken);
				//this.permissionService.initPermissions(decodedToken);
				//this.roleService.initRoles(decodedToken);
				return true;
			} catch (ex) {
				this.userService.clearUser();
			}
		} else {
			this.userService.clearUser();
		}
		return false;
	}

	logout() {
		this.http.get<any>('/auth/logout').subscribe();
		this.userService.clearUser();
		// noinspection JSIgnoredPromiseFromCall
		this.router.navigate(['/login']);
		return new Observable(serve => {
			serve.next();
			serve.complete();
		});
	}

	_onInit(){
		const token = localStorage.getItem('token');
		if (token != null) {
			this.tokenInit(token);
			this.permissionService.initPermissions();
		}
	}
}
