import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable()
export class SmsService {

	constructor(
		private http: HttpClient
	) {
	}

	send(data):Observable<SmsResponse> {
		return this.http.post<SmsResponse>("/sms/send", data);
	}

  getSmsTemplates(): Observable<any[]> {
    return this.http.post<any[]>("/sms/getSmsTemplates", {});
  }

}
export interface SmsResponse {
	result: string;

}