import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";
import {BooksService} from "../../books.service";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BookComponent} from "../book.component";
import {EstComponent} from "../../../components/est.component";
import {ConfirmDialog} from "../../../../theme/components/confirmDialog/confirm.dialog";
import {UniConfig} from "../../../../../uni.config";
import {BookComponentAdditionalDataDialog} from "./book-component-additional-data/book-component-additional-data-dialog";


@Component({
    selector: "book-component-form",
    templateUrl: "./book-component-form.html",
    styleUrls: ["./book-component-form.scss"]
})
export class BookComponentForm implements OnInit, OnDestroy {


	weeks = [];
	maxWeek = 20;
	form: FormGroup;
	components: EstComponent[] = [];
	componentsByCoId: EstComponent[] = [];
	selectedComponent: EstComponent = new EstComponent();
	subs = [];
	component: BookComponent;
	otherComponents: BookComponent[] = [];

	totalLimit = 0;

	lockWatcher = false;
	groups: any[] = [];

    constructor(
        private ref: MatDialogRef<BookComponentForm>,
        @Inject(MAT_DIALOG_DATA) private componentData: any,
        private error: ErrorsDialog,
        private service: BooksService,
        private fb: FormBuilder,
        private dialog: MatDialog
    ) {
        this.component = componentData.component;
        this.otherComponents = componentData.otherComponents;
        for (let i=0; i<this.maxWeek; i++) {
	        this.weeks.push({
		        id: i+1,
		        name: this.romanize(i+1)
	        });
        }
        this.form = fb.group({
            id: "",
            listId: "",
            groupId: "",
            totalLimit: ["", Validators.required],
            bookId: ["", Validators.required],
            coId: ["", Validators.required],
            formula: ["", Validators.required],
            name: ["", Validators.required],
            minLimit: ["", Validators.required],
            weight: ["", Validators.required],
            maxScore: ["", Validators.required],
            //minValue: ["", Validators.required],
            items: fb.array([]),
            resultAs: "",
            position: "",
            componentCount: "",
            customData: "",
            groupName: "",
	        week: ""
        }, {
            validator: BookComponentForm.sumOfWeightIsOk
        });
        this.calcTotalLimit();
    }



	romanize (num) {
		if (isNaN(num))
			return NaN;
		let digits = String(+num).split(""),
			key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
				"","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
				"","I","II","III","IV","V","VI","VII","VIII","IX"],
			roman = "",
			i = 3;
		while (i--)
			roman = (key[+digits.pop() + (i * 10)] || "") + roman;
		return Array(+digits.join("") + 1).join("M") + roman;
	}

    static sumOfWeightIsOk(group: FormGroup) {
        if (typeof group.value) {
            let n:any = 0;
            if (group.value.items.length == 0) {
                return null;
            }
            for (let i=0; i<group.value.items.length; i++) {
                group.get("weight").setErrors(null);
                n += group.value.items[i].weight;
            }
            //
            if (parseInt(n).toFixed(3) != parseInt(group.value.weight).toFixed(3)) {
                group.get("weight").setErrors({
                    SumOfWeightNotEqual: true
                });
            } else {
                group.get("weight").setErrors(null);
            }
        }
        return null;
    }

    calcTotalLimit() {
        if (!UniConfig.checkSumOfWeight) {
            this.form.patchValue({
                totalLimit: "1"
            });
            return;
        }
        setTimeout(() => {
            let totalLimit = 0;
            const skipComps = {};

            const o = [];
            // noinspection JSUnusedLocalSymbols
            const ids = [];
            for (const com of this.otherComponents) {
                if (typeof this.componentsByCoId[com.coId] != "undefined" && (this.componentsByCoId[com.coId].type == 1 || this.componentsByCoId[com.coId].type == 2)) {
                    o.push(com);
                }
            }
            let inserted = false;
            for (let i=0; i<o.length; i++) {
                if (o[i].id == this.form.value.id) {
                    o[i] = this.form.value;
                    inserted = true;
                    break;
                }
            }
            if (!inserted) {
                if (typeof this.componentsByCoId[this.form.value.coId] != "undefined" && (
                    this.componentsByCoId[this.form.value.coId].type == 1
                    || this.componentsByCoId[this.form.value.coId].type == 2
                )) {
                    o.push(this.form.value);
                }
            }

            for (const com of o) {
                if (
                    this.componentsByCoId != null
                    && typeof this.componentsByCoId[com.coId] != "undefined"
                    && typeof skipComps[com.coId] == "undefined"
                ) {
                    const component: EstComponent = this.componentsByCoId[com.coId];
                    if (typeof component.required == "object" && typeof component.required.length != "undefined") {
                        totalLimit += parseFloat(com.weight);
                        if (component.required.length > 0) {
                            for (const coId of component.required) {
                                skipComps[coId] = true;
                            }
                        }
                    }
                }
            }

            this.form.patchValue({
                totalLimit: totalLimit > 100 ? "" : "1"
            });
            this.totalLimit = totalLimit;
        }, 100);

    }

    resetValidators(c) {
        if (this.form.value.name == "") {
            this.form.patchValue({
                name: c.name
            });
        }
        if (c.type+"" == "1" || c.type+"" == '2') {
            // შეფასებითი

            this.setValidator(this.form.get("weight"), true);
            this.setValidator(this.form.get("maxScore"), true);

            const items = this.getTimes();
            if (c.type+"" == "1") {
                for (const f of items) {
                    this.setValidator(f.get("weight"), true);
                    this.setValidator(f.get("maxValue"), true);
                    this.setValidator(f.get("minLimit"), true);
                }
            } else if (c.type+"" == "2") {
                for (const f of items) {
                    this.setValidator(f.get("weight"), false);
                    this.setValidator(f.get("maxValue"), false);
                    this.setValidator(f.get("minLimit"), false);
                }
            }

        } else if (c.type+"" == "3") {
            // ჯამური
            this.setValidator(this.form.get("weight"), false);
            this.setValidator(this.form.get("maxScore"), false);
            const items = this.getTimes();
            for (const f of items) {
                this.setValidator(f.get("weight"), false);
                this.setValidator(f.get("maxValue"), false);
                this.setValidator(f.get("minLimit"), false);
            }
        }
    }

    setValidator(f, validate = true) {
        if (validate) {
            const oldValue = f.value;
            f.setValidators(Validators.required);
            f.reset();
            f.patchValue(oldValue);
        } else {
            const oldValue = f.value;
            f.clearValidators();
            f.reset();
            f.patchValue(oldValue);
        }
    }

    errors() {
        const o = [];
        for (const f in this.form.controls) {
            if (typeof this.form.controls[f].errors != "undefined") {
                o.push({
                    name: f,
                    errors: this.form.controls[f].errors
                });
            }
        }
        for (const a of this.getTimes()) {
            o.push({
                name: "weight",
                errors: a.get("weight").errors
            });
            o.push({
                name: "maxValue",
                errors: a.get("maxValue").errors
            });
            o.push({
                name: "minLimit",
                errors: a.get("minLimit").errors
            });
        }
        return o;
    }

    addTime(fromWatcher=false) {
        let groupName = this.form.value.groupName;
        if (typeof groupName == "undefined" || groupName == null || groupName == '' || groupName == 'დაუჯგუფებელი') {
            groupName = 0;
        }
        const f = this.form.get("items") as FormArray;
        const v = this.createComponentItem(groupName);
        f.push(v);
        this.resetValidators(this.selectedComponent);
        if (!fromWatcher) {
            this.form.patchValue({
                componentCount: f.length
            });
        }
    }

    setGroup(groupName) {
        this.form.patchValue({
            groupName: groupName
        });
    }

    createComponentItem(groupName = 0) {
        const group: any = this.fb.group({
            name:"",
            data:"",
            weight: ["", Validators.required],
            maxValue: ["", Validators.required],
            minLimit: ["", Validators.required],
            group: ""
        });
        group.patchValue({
            data: {},
            group: groupName
        });
        group.get("weight").valueChanges.subscribe(() => {
            setTimeout(() => {
                let sum = 0;
                for (let item of this.form.value.items) {
                    if (typeof item.weight != "undefined" && item.weight != null) {
                        sum += parseFloat(item.weight);
                    }
                }
                this.form.patchValue({
                    "weight": sum
                });
                this.calcTotalLimit();
            }, 300);
        });
        return group;
    }

    getTimes(group:any=''): FormControl[] {
        if (group == '') {
            return this.form.get("items")['controls'];
        }
        return this.form.get("items")['controls'].filter(item => {
            if ((group == 0) && (typeof item.value.group == "undefined" || item.value.group == null || item.value.group == '' || item.value.group == 0)) {
                return group == 0;
            } else {
                return group == item.value.group;
            }
        });
    }


    cancel() {
        this.ref.close(false);
    }

    ok() {
        if (this.component.editMode == "list") {
            this.service.addOrEditListComponent(this.form.value).subscribe(response => {
                if (response.result == "yes") {
                    this.ref.close(true);
                } else {
                    this.error.show(response);
                }
            });
        } else {
            this.service.addOrEditBookComponent(this.form.value).subscribe(response => {
                if (response.result == "yes") {
                    this.ref.close(true);
                } else {
                    this.error.show(response);
                }
            });
        }
    }

    addComponentActiveList() {
        const ref = this.dialog.open(ConfirmDialog, {
            data: {
                title: "კომპონენტის დამატება ყველა აქტიურ უწყისში",
                text: "ნამდვილად გსურთ კომპონენტის დამატება ყველა აქტიურ უწყისში?",
                ok: "დიახ",
                cancel: "არა"
            }
        });
        ref.afterClosed().subscribe(result => {
            if (result) {
                this.service.addOrEditBookComponent(this.form.value).subscribe(response => {
                    if (response.result == "yes") {
                        this.setComponentForLists(response.id);
                    } else {
                        this.error.show(response);
                    }
                });
            }
        });
    }

    setComponentForLists(id) {
        this.service.setComponentForLists({
            id
        }).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(true);
            } else {
                this.error.show(response);
            }
        });
    }

    getAllComponents(callback) {
        this.service.getAllComponents().subscribe(response => {
            if (response.result == "yes") {
                this.components = response.data;
                for (const com of this.components) {
                    this.componentsByCoId[com.id] = com;
                }
                this.calcTotalLimit();
                callback();
            }
        });
    }

    removeComponentItem(i, fromWatcher = false) {
        const f = this.form.get("items") as FormArray;
        f.removeAt(i);
        if (!fromWatcher) {
            this.form.patchValue({
                componentCount: f.length
            });
        }
    }

    additionalProperty(item) {
        //
        let ref = this.dialog.open(BookComponentAdditionalDataDialog, {
            data: {
                item: item.value,
                components: this.components,
                componentData: this.componentData.otherComponents
            }
        });
        ref.afterClosed().subscribe(response => {
            if (typeof response == "object" && response != null) {
                item.patchValue(response);
            }
        });
    }

    ngOnInit(): void {
        this.getAllComponents(() => {
            for (const c of this.components) {
                if (c.id == this.component.coId) {
                    this.selectedComponent = c;
                    break;
                }
            }
            if (typeof this.component.formula != "undefined" && this.component.formula != null) {
                this.component.formula = parseFloat(this.component.formula);
            }
            this.form.patchValue(this.component);
            const f = this.form.get("items") as FormArray;
            while (f.length !== 0) {
                f.removeAt(0);
            }
            if (typeof this.component.items != "undefined") {
                this.form.patchValue({
                    componentCount: this.component.items.length
                });
                for (const a of this.component.items) {
                    const b: any = this.createComponentItem();
                    b.patchValue(a);
                    f.push(b);
                }
            }
            this.resetValidators(this.selectedComponent);

            this.form.get("coId").valueChanges.subscribe((value: number) => {
                for (const c of this.components) {
                    if (c.id == value) {
                        this.selectedComponent = c;
                        this.resetValidators(c);
                        this.form.patchValue({
                            name: c.name
                        });
                    }
                }
                this.calcTotalLimit();
            });
            this.form.get("weight").valueChanges.subscribe(() => {
                this.calcTotalLimit();
            });

            this.form.get("componentCount").valueChanges.subscribe((value) => {
                if (this.lockWatcher) {
                    return;
                }
                this.lockWatcher = true;
                if (value < this.form.value.items.length) {
                    const min =this.form.value.items.length;
                    for (let i = min; i>=value; i--) {
                        this.removeComponentItem(i, true);
                    }
                } else if (value > this.form.value.items.length) {
                    for (let i = this.form.value.items.length; i<value; i++) {
                        this.addTime(false);
                    }
                }
                this.lockWatcher = false;
            });
            this.form.get("items").valueChanges.subscribe(items => {
                this.calcGroups(items);
            });
            this.calcGroups(this.form.value.items);
        });
    }

    calcGroups(items) {
        let groups = [];
        let g = {};
        for (let item of items) {
            let group = 0;
            if (typeof item.group != "undefined" && item.group != null && item.group != '') {
                group = item.group;
            }
            g[group] = true;
        }
        for (let group in g) {
            groups.push(group);
        }
        this.groups = groups;
    }

    downValues(valueName, value) {
        const f = this.form.get("items") as FormArray;
        const v = {};
        v[valueName] = value;
        for (let i=0; i<f.length; i++) {
            f.controls[i].patchValue(v);
        }
    }


    ngOnDestroy(): void {
        for (const sub of this.subs) {
            try {
                sub.unsubscribe();
            } catch (ex) {}
        }
    }

}
