import {Component, Inject} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StudentsService} from "../../../../students.service";
import {ErrorsDialog} from "../../../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: 'student-sms-confirm-dialog',
	templateUrl: './student-sms-confirm.dialog.html',
	//styleUrls: ['./student-sms-confirm.dialog.scss']
})

export class StudentSmsConfirmDialog {

	form: FormGroup;
	smsForm: FormGroup;
	codeInputShow = false;
	smsProgress = 0;
	sendCodeShow = true;
	checkCodeShow = false;
	sendCodeDisabled = false;
	smsTime = 0;
	smsTimer = null;
	code = 0;


	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public fb: FormBuilder,
		public service: StudentsService,
		public ref: MatDialogRef<StudentSmsConfirmDialog>,
		public errors: ErrorsDialog,
	) {
		this.form = fb.group({
			student_id: data.student_id,
			action: data.action,
			text: data.text,
			mode: data.mode,
		});

		this.smsForm = fb.group({
			student_id: data.student_id,
			code_valid: [false, Validators.requiredTrue],
			code: ["", Validators.required],
			smsId: ["", Validators.required]
		})
	}

	check(): void {


		if (this.smsForm.value.code == this.code) {
			this.smsForm.patchValue({
				code: this.code,
				code_valid: true,
			});
			this.checkCodeShow = false;
		} else {
			this.errors.show({
				error: 'კოდი არასწორია'
			});
		}
	}

	ok(): void {
		this.service.studentSmsConfirmed(this.smsForm.value)
			.subscribe(response => {
				if (response.result == 'yes') this.ref.close(true);
				else this.errors.show(response.error);
			});
	}

	send(): void {
		this.sendCodeDisabled = true;
		this.checkCodeShow = true;
		this.service
			.sendSmsOnlyStudent(this.form.value)
			.subscribe((res) => {
				if (res.result == "yes") {
					this.smsTime = res.smsTime;
					this.codeInputShow = true;
					this.code = res.code;
					this.smsForm.patchValue({
						smsId: res.smsId
					})
					//this.startInterval();
				} else {
					this.sendCodeDisabled = false;
					this.clear();
					this.errors.show(res);
				}
			});
	}

	cancel(): void {
		this.ref.close(false);
	}

	// startInterval() {
	// 	this.smsTimer = setInterval(() => {
	// 		if (this.smsProgress >= 100) {
	// 			this.clear();
	// 			this.sendCodeDisabled = false;
	// 			this.checkCodeShow = false;
	// 			this.codeInputShow = false;
	// 			return false;
	// 		}
	// 		this.smsTime += 0.2;
	// 		if (this.smsTime > 0) {
	// 			this.smsProgress = this.round((this.smsTime / 60) * 100, 2);
	// 		}
	// 	}, 200);
	// }

	clear() {
		try {
			clearInterval(this.smsTimer);
			this.smsTime = 0;
			this.smsProgress = 0;
		} catch (e) {}
	}

	round(a: number, b: number) {
		return parseFloat(Number(this.toInt(a)).toFixed(b));
	}

	toInt(a: any) {
		let b: any = parseFloat(a);
		if (b + "" == "NaN") {
			return 0;
		} else {
			return b;
		}
	}


}