import {Injectable} from "@angular/core";
import {HttpClient, HttpEvent} from "@angular/common/http";
import {Observable} from "rxjs";
import {StructureResponse} from "../structure/structure.response";
import {Book} from "./book";
import {BooksResponse} from "./books.response";

@Injectable()
export class BooksService {

    constructor(
        private http: HttpClient
    ) {

    }

    add(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/addOrEdit", data);
    }

    edit(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/addOrEdit", data);
    }

    remove(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/remove", data);
    }

    autoCompleteByName(q): Observable<Book[]> {
        return this.http.post<Book[]>("/books/autoCompleteByName", {
            q
        });
    }

    getItems(filter, page, limit, total= 0): Observable<StructureResponse> {
        return this.http.post<StructureResponse>("/books/items/?page="+page+"&limit="+limit+(total == 0 ? "&withTotal=yes" : ""), filter);
    }
	linkSubBook(filter, page, limit, total= 0): Observable<StructureResponse> {
        return this.http.post<StructureResponse>("/books/linkSubBook/?page="+page+"&limit="+limit+(total == 0 ? "&withTotal=yes" : ""), filter);
    }
	getSubItems(filter, page, limit, total= 0): Observable<StructureResponse> {
        return this.http.post<StructureResponse>("/books/getSubItems/?page="+page+"&limit="+limit+(total == 0 ? "&withTotal=yes" : ""), filter);
    }
	getSubLinks(filter, page, limit, total= 0): Observable<StructureResponse> {
        return this.http.post<StructureResponse>("/books/getSubLinks/?page="+page+"&limit="+limit+(total == 0 ? "&withTotal=yes" : ""), filter);
    }

    addOrEditConditionGroup(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/addOrEditConditionGroup", data);
    }

	createLinkSubBook(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/createLinkSubBook", data);
    }
	removeSubLink(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/removeSubLink", data);
    }

    removeConditionGroup(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/removeConditionGroup", data);
    }
	removeSubBook(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/removeSubBook", data);
    }
	subAddOrEdit(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/subAddOrEdit", data);
    }

    setNewLimit(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/setNewLimit", data);
    }

    conditions(bookId): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/conditions", {
            bookId
        });
    }

    addCondition(group, book): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/addCondition", {
            groupId: group.id,
            bookId: book.id,
            bookName: book.name
        });
    }

    removeCondition(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/removeCondition", data);
    }


    addProgram(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/addProgram", data);
    }

    removeProgram(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/removeProgram", data);
    }

    exportToExcel(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/exportToExcel", data);
    }

    getBookComponents(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/components/getBookComponents", data);
    }

    addOrEditBookComponent(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/components/addOrEditBookComponent", data);
    }

    addOrEditListComponent(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/lists/addOrEditListComponent", data);
    }

    removeBookComponent(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/components/removeBookComponent", data);
    }

    getAllComponents(): Observable<BooksResponse> {
        return this.http.get<BooksResponse>("/components/getAllComponents");
    }

    getGroupComponents(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/lists/getGroupComponents", data);
    }

    removeListComponent(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/lists/removeListComponent", data);
    }

    setComponentActiveList(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/setComponentActiveList", data);
    }

    setComponentForLists(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/setComponentForLists", data);
    }

    getSyllabuses(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/getSyllabuses", data);
    }

    removeSyllabus(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/removeSyllabus", data);
    }

    uploadSyllabus(bookId, fd): Observable<any> {
        return this.http.post<BooksResponse>("/book/uploadSyllabus/?bookId="+bookId, fd, {
            reportProgress: true,
            observe: 'events'
        });
    }

    addSyllabus(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/addSyllabus", data);
    }

    addBookProf(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/addBookProf", data);
    }

    removeBookProf(data): Observable<BooksResponse> {
        return this.http.post<BooksResponse>("/books/removeBookProf", data);
    }

    getBookProfs(data): Observable<any> {
        return this.http.post<any>("/books/getBookProfs", data);
    }

	addRuleStudents(data): Observable<any> {
		return this.http.post<any>("/books/addRuleStudents", data);
	}

	getRuleStudents(data): Observable<any> {
		return this.http.post<any>("/books/getRuleStudents", data);
	}

	clearRuleStudents(data): Observable<any> {
		return this.http.post<any>("/books/clearRuleStudents", data);
	}

	getBookLogs(data, page, limit): Observable<any> {
		return this.http.post("/books/logs/?page=" + page + "&limit=" + limit, data);
	}
}


