import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ConfirmDialog} from "../../../../../theme/components/confirmDialog/confirm.dialog";

@Component({
	selector: "book-component-additional-data-dialog",
	templateUrl: "./book-component-additional-data-dialog.html",
	styleUrls: ["./book-component-additional-data-dialog.scss"]
})
export class BookComponentAdditionalDataDialog implements OnInit {

	form: FormGroup;
	groups = [];

	constructor(
		private ref: MatDialogRef<BookComponentAdditionalDataDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private dialog: MatDialog
	) {
		this.form = fb.group({
			component: ["", Validators.required],
			from: ["", Validators.required],
			to: "",
			type: ["", Validators.required],
			argument: ["", Validators.required],
			groupType: ["", Validators.required]
		});
		this.form.get("component").valueChanges.subscribe(component => {
			this.genComponentGroups(component);
		});

	}

	genComponentGroups(component) {
		const coId = this.componentIdByCode(component);
		const data = this.getComponentDataById(coId);
		if (data) {
			let g = {};
			let groups = [];
			for (let item of data.items) {
				let groupName = "";
				if (typeof item.group == "undefined" || item.group == null || item.group == '' || item.group == 0) {
					groupName = "-";
				} else {
					groupName = item.group;
				}
				g[groupName] = true;
			}
			for (let item in g) {
				groups.push(item);
			}
			this.groups = groups;
		}
	}

	clear() {
		let ref = this.dialog.open(ConfirmDialog, {
			data: {
				title: "ვალიდაცია",
				text: "ნამდვილად გსურთ ვალიდაციის დასუფთავება",
				ok: "დიახ",
				cancel: "არა"
			}
		});
		ref.afterClosed().subscribe(result => {
			if (result) {
				this.ref.close({
					data: {}
				});
			}
		});
	}

	cancel() {
		this.ref.close(false);
	}

	ok() {
		this.ref.close({
			data: this.form.value
		});
	}

	componentNameByCode(code) {
		for (let comp of this.data.components) {
			if (comp.code == code) {
				return comp.name;
			}
		}
		return "";
	}
	componentIdByCode(code) {
		for (let comp of this.data.components) {
			if (comp.code == code) {
				return comp.id;
			}
		}
		return "";
	}

	getComponentDataById(coId) {
		for (let com of this.data.componentData) {
			if (com.coId == coId) {
				return com;
			}
		}
		return false;
	}

	ngOnInit(): void {
		this.form.patchValue(this.data.item.data);
		if (typeof this.form.value.groupType == "undefined" || this.form.value.groupType == null || this.form.value.groupType == '') {
			this.form.patchValue({
				groupType: "other"
			});
		}
	}

}