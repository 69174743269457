import {Component, ElementRef, forwardRef, Input, OnInit, ViewChild} from "@angular/core";
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Observable} from "rxjs";
import moment from "moment";

//declare let m: any;

@Component({
	selector: "date-input",
	templateUrl: "./date-input.component.html",
	styleUrls: ["./date-input.component.scss"],
	providers:[{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DateInputComponent),
		multi: true
	}]
})
export class DateInputComponent implements OnInit, ControlValueAccessor {

	separator = "/";

	form: FormGroup;
	auto_correct = true;
	public onChange: (value: any) => void = () => {};
	@Input("placeholder") placeholder = "";
	@ViewChild("d", {static: false}) d: ElementRef;
	@ViewChild("m", {static: false}) m: ElementRef;
	@ViewChild("y", {static: false}) y: ElementRef;

	isError = false;
	min = 1960;
	max = 2040;

	constructor(
		private fb: FormBuilder
	) {
		this.form = fb.group({
			d: "",
			m: "",
			y: ""
		});
	}

	setDisabledState(state: boolean): void {
		if (state) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	validate() {
		return new Observable(serve => {
			setTimeout(() => {
				this.isError = false;
				const y = this.form.value.y;

				if (y == null || (y+"").length !=4 || parseInt(y)<this.min || parseInt(y)>this.max) {
					this.isError = true;
				}
				serve.next(this.isError);
			}, 100);
		});
	}

	check(field) {
		setTimeout(() => {
			let v:any = "";
			switch (field) {
				default:
					// continue
					break;
				case "d":
					v = parseInt(this.form.value.d);
					if (v == null || v+"" == "NaN") return;
					if (v<10) {
						v = "0"+parseInt(v);
					}
					if (v+"" !== this.form.value.d+"") {
						this.auto_correct = false;
						this.form.patchValue({
							d: v
						});
						this.auto_correct = true;
					}
					break;
				case "m":
					v = parseInt(this.form.value.m);
					if (v == null || v+"" == "NaN") return;
					if (v<10) {
						v = "0"+parseInt(v);
					}
					if (v+"" !== this.form.value.m+"") {
						this.auto_correct = false;
						this.form.patchValue({
							m: v
						});
						this.auto_correct = true;
					}
					break;
			}
		}, 300);

	}

	change() {
		setTimeout(() => {
			this.validate().subscribe(error => {
				if (!error) {
					this.onChange([this.form.value.d, this.form.value.m, this.form.value.y].join(this.separator));
				}
			});
		}, 250);
	}

	ngOnInit(): void {
		let old_d = "";
		this.form.get("d")?.valueChanges.subscribe((v:any) => {
			if (!this.auto_correct) return;
			const maxLength=2;
			const max = 31;
			if (v != null && v+"" != "NaN") {
				if ((v + "").length == maxLength) {
					if (this.d.nativeElement.matches(':focus')) {
						this.switchTo("m");
					}
				} else if ((v + "").length > maxLength) {
					v = parseInt((v + "").substring(0, maxLength));
					setTimeout(() => {
						this.auto_correct = false;
						this.form.patchValue({
							d: v
						});
						this.auto_correct = true;
					}, 100);
				}
			}
			if (v != null && v+"" != "NaN" && parseInt(v)>max) {
				v = old_d;
				setTimeout(() => {
					this.auto_correct = false;
					this.form.patchValue({
						d: v
					});
					this.auto_correct = true;
				}, 100);
			} else {
				old_d = v;
			}
			this.change();
		});
		let old_m = "";

		this.form.get("m")?.valueChanges.subscribe((v: any) => {
			if (!this.auto_correct) return;
			v = parseInt(v);
			const maxLength=2;
			const max = 12;
			if (v == null || v+"" == "NaN" || (v+"").length == 0) {
				if (this.m.nativeElement.matches(':focus')) {
					this.switchTo("d");
				}
			} else if ((v+"").length == maxLength) {
				if (this.m.nativeElement.matches(':focus')) {
					this.switchTo("y");
				}
			} else if ((v+"").length > maxLength) {
				setTimeout(() => {
					this.auto_correct = false;
					v = parseInt((v+"").substring(0, maxLength));
					this.form.patchValue({
						m: v
					});
					this.auto_correct = true;
				}, 100);

			}
			if (v != null && v+"" != "NaN" && parseInt(v)>max) {
				v = old_m;
				if (parseInt(v)<10) {
					v = "0"+parseInt(v);
				}
				setTimeout(() => {
					this.auto_correct = false;
					this.form.patchValue({
						m: v
					});
					this.auto_correct = true;
				}, 100);
			} else {
				old_m = v;
			}
			this.change();
		});
		this.form.get("y")?.valueChanges.subscribe((v:any) => {
			if (!this.auto_correct) return;
			v = parseInt(v);
			const maxLength=4;
			if (v == null || v+"" == "NaN" || (v+"").length == 0) {
				if (this.y.nativeElement.matches(':focus')) {
					this.switchTo("m");
				}
			} else if ((v+"").length == maxLength) {
				//this.y.nativeElement.focus();
			} else if ((v+"").length > maxLength) {
				setTimeout(() => {
					this.auto_correct = false;
					this.form.patchValue({
						y: (v+"").substring(0, maxLength)
					});
					this.auto_correct = true;
				}, 100);
			}
			this.change();
		});
	}

	switchTo(field) {
		if (field == "d") {
			this.d.nativeElement.focus();
		} else if (field == "m") {
			this.m.nativeElement.focus();
		} else if (field == "y") {
			this.y.nativeElement.focus();
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
	}

	writeValue(v: any): void {
		let m: any = "";
		try {
			if (typeof v == "string") {
				m = moment(v, "DD/MM/YYYY");
			} else if (v != null && typeof v == "object") {
				m = moment(v);
			}
			const c = m.format("L").split("/");
			this.auto_correct = false;
			this.form.patchValue({
				d: c[1],
				m: c[0],
				y: c[2]
			});
			this.auto_correct = true;
		} catch (e) {

		}
	}

	// nm(v) {
	// 	if (parseInt(v)<10) {
	// 		return "0"+parseInt(v);
	// 	}
	// 	return v;
	// }

}
