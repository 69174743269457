import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TestingResponse} from "./testing.response";
import {TestingBooksFilter} from "./books/list/testing.books.filter";
import {TestingProf} from "./books/details/testing.prof";

@Injectable()
export class TestingService {

	examTypes = [
		{
			id: 1,
			name: 'შუალედური გამოცდა'
		},
		{
			id: 7,
			name: 'შუალედურის აღდგენა'
		},
		{
			id: 2,
			name: 'შუალედურის გადაბარება'
		},
		{
			id: 3,
			name: 'დასკვნითი გამოცდა'
		},
		{
			id: 8,
			name: 'დასკვნითის აღდგენა'
		},
		{
			id: 4,
			name: 'დასკვნითის გადაბარება'
		},
		{
			id: 9,
			name: 'სხვა'
		}
	];

    constructor(
        private http: HttpClient
    ) {

    }

    getTemplates(filter:TestingBooksFilter, page:number=1, limit:number=10):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getTemplates?page="+page+"&limit="+limit, filter);
    }

    getBooks(filter:TestingBooksFilter, page:number=1, limit:number=10):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/books?page="+page+"&limit="+limit, filter);
    }
	addTime(data:any):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/addTime", data);
    }

    getBook(bookId):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getBook", {
            id: bookId
        });
    }

    toggleBookHidden(bookId, hidden):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/toggleBookHidden", {
            id: bookId,
            hidden: hidden
        });
    }

    getTemplateDurations(q):Observable<number[]> {
        return this.http.post<number[]>("/testing/getTemplateDurations", {
            q: q
        });
    }

	setTestingWordAnswer(data):Observable<any> {
		return this.http.post<any>("/testing/setTestingWordAnswer", data);
	}

    getTemplateProf(q):Observable<number[]> {
        return this.http.post<number[]>("/testing/getTemplateProf", {
            q: q
        });
    }

    exportBooks(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/exportBooks", data);
    }


    getProfs(item):Observable<TestingProf[]> {
        let value = "";
        if (typeof item == "object" && typeof item.name != "undefined") {
            value = item.name;
        } else {
            value = item;
        }
        return this.http.post<TestingProf[]>("/testing/getProfs", {
            name: value
        });
    }

    setTemplate(data:any):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setTemplate", data);
    }
    removeQuestion(data:any):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/removeQuestion", data);
    }

    getTemplate(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getTemplate", item);
    }
    getRule(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getRule", item);
    }

    getBookProfs(id):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getBookProfs", {
            id: id
        });
    }

    addSuperProf(prof, bookId):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/addSuperProf", {
            id: prof.id,
            name: prof.name,
            bookId: bookId
        });
    }

    getSuperProf(filter, page, limit):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getSuperProf/?page="+page+"&limit="+limit, filter);
    }

    removeSuperProf(prof):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/removeSuperProf/", prof);
    }

    getRules(filter, page, limit):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getRules/?page="+page+"&limit="+limit, filter);
    }

    setQuestion(question):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setQuestion/", question);
    }

    uploadQuestionImage(files: FileList):Observable<TestingResponse> {
        let fd = new FormData();
        fd.append("file", files[0], files[0].name);
        return this.http.post<TestingResponse>("/testing/uploadQuestionImage", fd);
    }

    uploadQuestionAudio(files: FileList):Observable<TestingResponse> {
        let fd = new FormData();
        fd.append("file", files[0], files[0].name);
        return this.http.post<TestingResponse>("/testing/uploadQuestionAudio", fd);
    }

    uploadProgress(files):Observable<any> {
        let fd = new FormData();
        fd.append("file", files[0], files[0].name);
        return this.http.post<TestingResponse>("/testing/uploadQuestionAudio", fd, {
            reportProgress: true,
            observe: 'events'
        });
    }

    getQuestions(filter, page, limit):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getQuestions/?page="+page+"&limit="+limit, filter);
    }

    getVariants(id):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getVariants", {
            id: id
        });
    }

    removeTemplate(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/removeTemplate", item);
    }

    importTemplate(bookId, templateId) {
        return this.http.post<TestingResponse>("/testing/importTemplate", {
            bookId: bookId,
            templateId: templateId
        });
    }

    getSavedTemplates():Observable<TestingResponse> {
        return this.http.get<TestingResponse>("/testing/getSavedTemplates");
    }

    setExamType(data: any):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setExamType", data);
    }

    getExamType(data: any):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getExamType", data);
    }

    getTests(filter, page, limit):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/examList/?page="+page+"&limit="+limit, filter);
    }

    getTest(filter):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getTest", filter);
    }

    sendResult(testId, data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/submitResult", {
            id: testId,
            data: data
        });
    }

    getRegistryTests(filter, page, limit):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getRegistryTests/?page="+page+"&limit="+limit, filter);
    }

    addRegistryTesting(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/addRegistryTesting", data);
    }

    editRegistryTesting(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/editRegistryTesting", data);
    }

    removeRegistryTesting(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/removeRegistryTestings", data);
    }

    getRegistryStudents(data, page, limit):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getRegistryStudents/?page="+page+"&limit="+limit, data);
    }

    removeRegistryStudents(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/removeRegistryStudents", data);
    }

    addRegistryStudents(id, students):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/addRegistryStudents", {
            id: id,
            students: students
        });
    }

    setAllStudent(id, filter):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setAllStudent", {
            id: id,
            filter: filter
        });
    }

    getBookNames(q):Observable<any[]> {
        return this.http.post<any[]>("/testing/getBookNames", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    getStudentNames(q):Observable<any[]> {
        return this.http.post<any[]>("/testing/getStudentNames", {
            q: typeof q == "object" && q != null ? q.name : q
        });
    }

    returnToProf(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/returnToProf", item);
    }

    setGroupActionExamType(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setGroupActionExamType", item);
    }

    checkRuleQuestion(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/checkRuleQuestion", item);
    }

    searchStudent(q):Observable<any> {
        return this.http.post("/testing/searchStudent", {
            q: typeof q == "object" ? q.name : q
        });
    }

    exportExamList(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/exportExamList", item);
    }
    getExamExportStatus(item):Observable<any> {
        return this.http.post<any>("/testing/getExamExportStatus", item);
    }

    getProfNames(item):Observable<any[]> {
        return this.http.post<any[]>("/testing/getProfNames", item);
    }

    setTestingProf(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setTestingProf", item);
    }

    checkCanRemoveRegistryTest(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/checkCanRemoveRegistryTest", item);
    }

    exportToExcelRegistryStudents(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/exportToExcelRegistryStudents", item);
    }

    auditoriums(filter, page, limit):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/auditoriums/?page="+page+"&limit="+limit, filter);
    }

    addOrEditAuditorium(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/addOrEditAuditorium", item);
    }

    removeAuditorium(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/removeAuditorium", item);
    }

    setQuestionStatus(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setQuestionStatus", item);
    }

    copyRuleQuestions(item):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/copyRuleQuestions", item);
    }

    abuses(filter, page, limit):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/abuses/?page="+page+"&limit="+limit, filter);
    }

    abuseResponse(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/abuses/response", data);
    }

    isManagerProf(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/isManagerProf", data);
    }

    getTestingParams(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getTestingParams", data);
    }

    setTestingParams(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setTestingParams", data);
    }

    abuseForward(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/abuseForward", data);
    }

    getTestLogs(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/getTestLogs", data);
    }

    uploadQuestions(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/uploadQuestions", data);
    }

    importQuestions(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/importQuestions", data);
    }

    setStatus(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/setStatus", data);
    }

    getConfig(data):Observable<any> {
        return this.http.post<TestingResponse>("/testing/getConfig", data);
    }

    setConfig(data):Observable<any> {
        return this.http.post<TestingResponse>("/testing/setConfig", data);
    }

    runTestExport(data):Observable<TestingResponse> {
        return this.http.post<TestingResponse>("/testing/runTestExport", data);
    }

	getAccessStudents(filter, page, limit):Observable<TestingResponse> {
		return this.http.post<TestingResponse>("/testing/getAccessStudents?page="+page+"&limit="+limit, filter);
	}

	acceptStudentAccess(data):Observable<any> {
		return this.http.post<TestingResponse>("/testing/acceptStudentAccess", data);
	}

	getCurrentAccessStudents(filter, page, limit):Observable<TestingResponse> {
		return this.http.post<TestingResponse>("/testing/getCurrentAccessStudents?"+page+"&limit="+limit, filter);
	}

	endAccessTesting(data):Observable<any> {
		return this.http.post<TestingResponse>("/testing/endAccessTesting", data);
	}

	generateFileDownloadUrl(fileId: string): Observable<any> {
		return this.http.get<any>(`/testing/sharepoint/files/${fileId}/download-url`);
	}

}
