import {Component, Injectable, ViewEncapsulation} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {AuthModel} from "../../../core/models/auth.model";
import {AuthService} from "../../../core/auth/auth.service";
import {UserPasswordDialog} from "./user-password-dialog/user-password-dialog";
import {Router} from "@angular/router";
import {UserService} from "../../../core/services/user.service";

@Component({
	selector: 'app-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
@Injectable()
export class UserMenuComponent {
	//public user:  //User = new User('/assets/img/users/user.jpg');

	user: any = null;

	constructor(
		private userService: UserService,
		private dialog: MatDialog,
		public auth: AuthModel,
		private authService: AuthService,
		private router: Router
		//public permissionService: PermissionService
	) {
		this.user = userService.user;
		//this.permissionService.initPermissions();
	}

	openProfileDialog() {
		let route = this.user.guard == "custom" ? 'administrative-personal' : 'academic-personal';

		this.router.navigate(['hr', route, 'details', this.user.hrUserId, 'routes'])
			.then(m => {

			});
	}

	openPasswordDialog() {
		this.dialog.open(UserPasswordDialog, {
			width: "300px",
		});

	}

	goToSavedDevices(): void {
		this.router.navigate(['/my-devices'])
			.then(() => {
			});
	}

	gotToNotifications(): void {
		this.router.navigate(['/user/notifications/settings'])
			.then(() => {
			});
	}

	gotToStatements(): void {
		this.router.navigate(['/notifications'])
			.then(() => {
			});
	}

	logout() {
		this.authService.logout().subscribe();
	}

}
