import {Component, forwardRef, Input} from "@angular/core";
import { NG_VALUE_ACCESSOR} from "@angular/forms";
import {IniInput} from "./ini.input";
import {Observable} from "rxjs";
import {AutocompleteNamesService} from "./autocomplete-names.service";
import {BookInput} from "./book-input";

@Component({
	selector: "sub-book-input",
	templateUrl: "./auto-complete-book.html",
	providers:[{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SubBookInput),
		multi: true
	}]
})
export class SubBookInput extends IniInput { /**/

	@Input("placeholder") override placeholder = "";
	@Input("type") override type = "old";
	@Input("lang") override lang: string = "ka";

	constructor(
		private service: AutocompleteNamesService
	) {
		super();
	}

	override search(q: string): Observable<any[]> {
		return this.service.getSubBookNames(q);
	}


}
