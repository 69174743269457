import {Component} from "@angular/core";

@Component({
    selector: "ini-editor-preload-dialog",
    template: `<h3 mat-dialog-title>Loading...</h3>
    <div mat-dialog-content>
        <br />
        <br />
        <br />
        <span i18n translate>იტვირთება...</span>
        <br />
        <br />
        <br />
        <br />
    </div>
    `
})
export class IniEditorPreloadDialog {




}