import {AfterContentInit, Component, Input} from "@angular/core";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {Config} from "../../config";

@Component({
	selector: 'chat-icon',
	templateUrl: './chat-icon.component.html',
	styleUrls: ['./chat-icon.component.scss']
})
export class ChatIconComponent implements AfterContentInit {


	@Input() icons: string | string[];
	@Input() withShadow: boolean = false;
	@Input() mini: boolean = false;
	iconArray: SafeUrl[] = [];
	multi = false;
	defaultUserIcon = Config.defaultUserIcon;

	constructor(
		private sanitizer: DomSanitizer
	) {
	}

	ngAfterContentInit(): void {
		let icons = this.icons;
		if ((icons+'').indexOf(', ') > -1) {
			icons = (icons+'').split(', ');
		}
		let iconArray = [];
		//this.multi = typeof icons != "string";
		iconArray = (typeof icons == "string" ? [icons as string] : icons);
		if (iconArray.length > 3) {
			iconArray = iconArray.slice(0, 3);
		}

		iconArray = iconArray.filter(icon => (icon+'').indexOf('data:image') == -1);
		if (iconArray.length == 0) {
			iconArray = [
				Config.defaultUserIcon
			];
		}
		this.iconArray = iconArray.map(icon => this.sanitizer.bypassSecurityTrustUrl(icon));
		this.multi = this.iconArray.length > 1;
	}


}
