import {ConfirmComponent} from "../components/dialogs/confirm/confirm.component";
import {Injectable} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";

@Injectable()
export class ConfirmService {
  constructor(
      private dialog:MatDialog
  ){}

  deleteConfirm(text = '',width = 238){
    text = text == '' ? 'ნამდვიალდ გსურთ წაშლა?' : text;
    return this.dialog.open(ConfirmComponent, {
      data: text,
      width: width+'px',
      disableClose: true
    }).afterClosed();
  }

}