import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FinancesNewService} from "../../module/finances-new.service";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";
import {debounceTime} from "rxjs/operators";


@Component({
	selector: 'finances-student-events-dialog',
	templateUrl: './finances-event-form.dialog.html',
})
export class FinanceEventFormComponent implements OnInit, OnDestroy {

	form: FormGroup;
	subs = [];

	currencyTypes: { id: number, name: string }[] = [
		{id: 1, name: "GEL"},
		{id: 2, name: "USD"},
		{id: 3, name: "EUR"}
	];
	// accountTypes: { id: number, name: string }[] = [
	// 	{id: 1, name: "ძირითადი ანგარიში"},
	// 	{id: 2, name: "დამ.კრედიტის ანგარიში"}
	// ];

	constructor(
		private financeService: FinancesNewService,
		private fb: FormBuilder,
		private ref: MatDialogRef<FinanceEventFormComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private error: ErrorsDialog
	) {
		this.form = fb.group({
			action: ["", Validators.required],
			currency: ["", Validators.required],
			student: ["", Validators.required],
			amount: ["", [Validators.required, Validators.min(0.01)]],
			comment: ["", Validators.required],
			bankTransactionId: [""],
			convertedAmount: ["", ],
			currencyRate: ["", ],
			sourceType: ["", Validators.required],
			sourceId: [""],
			transactDate: [""],
			year: [""],
			month: [""],
			day: [""],
			updateLog: [false],
			isGrantTransaction: [false]
		});
	}

	cancel() {
		this.ref.close(false);
	}

	ok() {
		this.financeService.addStudentEvent(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		})
	}

	ngOnInit() {
		// noinspection DuplicatedCode
		if (typeof this.data != "undefined" && this.data != null) {
			this.form.patchValue(this.data);
		}

		this.subs.push(this.form.get('currency').valueChanges
			.subscribe(value => {
				this.updateFormValidity(value);
				if (value === 1) return;
				this.getUSDCurrency();
			}));

		this.subs.push(this.form.get('amount').valueChanges.pipe(debounceTime(500))
			.subscribe(() => {
				this.reCalculateCurrency('amount');
			}));

		this.subs.push(this.form.get('convertedAmount').valueChanges.pipe(debounceTime(500))
			.subscribe(() => {
				this.reCalculateCurrency('convertedAmount');
			}));

		this.subs.push(this.form.get('currencyRate').valueChanges.pipe(debounceTime(500))
			.subscribe(() => {
				this.reCalculateCurrency('currencyRate');
			}));
	}

	// noinspection DuplicatedCode
	updateFormValidity(currency: number): void{
		if (currency == 2) {
			this.form.get('convertedAmount').setValidators(Validators.required);
			this.form.get('currencyRate').setValidators(Validators.required);
		}

		if (currency == 1) {
			this.form.get('convertedAmount').clearValidators();
			this.form.get('currencyRate').clearValidators();
		}

		this.form.get('convertedAmount').updateValueAndValidity();
		this.form.get('currencyRate').updateValueAndValidity();
	}

	getUSDCurrency(): void {
		this.financeService.getUSDCurrency()
			.subscribe(response => {
				this.form.patchValue({
					currencyRate: response.currency,
				});
			});
	}

	ngOnDestroy(): void {
		for (let i = 0; i < this.subs.length; i++) {
			this.subs[i].unsubscribe();
		}
	}

	// noinspection DuplicatedCode
	reCalculateCurrency(name): void {
		const currencyRate = this.form.value.currencyRate;

		if (name == 'amount') {
			let amount = this.form.value[name];
			const amountInGel = currencyRate * amount;
			this.form.get('convertedAmount').setValue(amountInGel, {emitEvent:false});
		}

		if (name == 'convertedAmount') {
			let convertedAmount = this.form.value[name];
			const amountInUsd = convertedAmount / currencyRate;
			this.form.get('amount').setValue(amountInUsd, {emitEvent:false});
		}

		if (name == 'currencyRate') {
			const amount = this.form.value['amount'];
			const amountInGel = currencyRate * amount;
			this.form.get('convertedAmount').setValue(amountInGel, {emitEvent:false});
		}

	}
}
