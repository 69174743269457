import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {MAT_INPUT_VALUE_ACCESSOR} from "@angular/material/input";

@Directive({
	selector: 'input[matInputZero]',
	providers: [
		{provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputZero}
	]
})
export class MatInputZero {
	// tslint:disable-next-line:variable-name
	private _value: string | null;

	constructor(private elementRef: ElementRef<HTMLInputElement>,
	) {
	}


	get value(): string | null {
		return this._value;
	}

	@Input('value')
	set value(value: string | null) {
		this._value = value;
		this.formatValue(value);
	}

	private formatValue(value: string | null) {
		if (value !== null) {
			if (parseInt(value) < 10) {
				if (value[0] != "0") this.elementRef.nativeElement.value = "0" + value;
			} else {
				this.elementRef.nativeElement.value = value;
			}
		} else {
			this.elementRef.nativeElement.value = '';
		}
	}

	private unFormatValue() {
		const value = this.elementRef.nativeElement.value;
		this._value = value.replace(/[^\d.-]/g, '');
		if (value) {
			this.elementRef.nativeElement.value = this._value;
		} else {
			this.elementRef.nativeElement.value = '';
		}
	}

	@HostListener('input', ['$event.target.value'])
	onInput(value) {
		// here we cut any non numerical symbols
		this._value = value.replace(/[^\d.-]/g, '');
	}

	@HostListener('blur')
	_onBlur() {
		this.formatValue(this._value); // add commas
	}

	@HostListener('focus')
	onFocus() {
		this.unFormatValue(); // remove commas for editing purpose
	}

}