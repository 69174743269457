import {PermissionModel} from "../models/permission.model";
import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PermissionResponse} from "../models/permission.response";
import {UserService} from "./user.service";
import {UserData} from "./user.data";

@Injectable()
export class PermissionService {
	onChange: EventEmitter<string> = new EventEmitter();
	user: UserData;

	constructor(
		private permission: PermissionModel,
		private http: HttpClient,
		private userService: UserService
	) {
		this.user = this.userService.user;
		if (Object.keys(this.user).length === 0) this.userService.handleToken();
		this.user = this.userService.user;
	}

	readPermissionsViaRoles(): Observable<PermissionResponse> {
		return this.http.get<PermissionResponse>('/getPermissionsViaRoles');
	}

	setPermissions(perms) {
		localStorage.setItem("permissions", JSON.stringify(perms));
	}

	readPermissions(): Observable<any> {
		return new Observable<PermissionModel[]>(observer => {
			//const permissions = localStorage.getItem('permissions');
			const permissions = null;
			// noinspection PointlessBooleanExpressionJS
			if (permissions == null) {
				this.readPermissionsViaRoles().subscribe(response => {
					if (response.result == "yes") {
						this.setPermissions(response.items);
						observer.next(response.items);
					}
				});
			} else {
				setTimeout(() => {
					observer.next(JSON.parse(permissions));
				}, 2000);
			}
		});
	}

	initPermissions() {
		this.permission.permissionArray = [];
		this.readPermissions().subscribe(perms => {
			if (perms.length > 0) {
				for (const perm of perms) {
					this.permission.permissionArray.push(perm.name);
				}
				this.publish();
			}
		});
	}


	publish() {
		this.onChange.emit("changed");
	}

	can(perms: string, personId: number = null): boolean {
		if (this.permission.permissionArray.indexOf("super") > -1) {
			return true;
		}
		if (personId != null && this.user.hrUserId == personId) {
			return true;
		}
		if (perms != "") {
			return (this.permission.permissionArray.indexOf(perms) > -1);
		}
		return false;
	}

	isMyItem(userId, guard) {
		if (this.canEditDoc()) return true;

		if (this.isSuper()) {
			return true;
		} else {
			let fixGuard = this.user.guard == 'custom' ? 'admin' : this.user.guard;
			return userId == this.user.id && guard == fixGuard;
		}
	}

	isSuper() {
		return this.permission.permissionArray.indexOf("super") > -1;

	}

	canEditDoc(): boolean {
		return this.permission.permissionArray.indexOf('menu.documents.editAll') > -1;
	}
}

