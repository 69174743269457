import {AfterContentInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {Chat} from "../../interfaces/Chat";
import {ChatService} from "../../services/chat-service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ChatEvent} from "../../interfaces/ChatEvent";
import {ChatEventType} from "../../interfaces/ChatEventType";
import {ChatMessageType, MessagePosition} from "../../interfaces/ChatMessage";
import {MessageSeenType} from "../../interfaces/MessageSeenType";
import {UserService} from "../../services/user.service";
import {ChatUser} from "../../interfaces/ChatUser";
import {MatDialog} from "@angular/material/dialog";
import {EmojiComponent} from "../emoji/emoji.component";
import {ChatUserType} from "../../interfaces/ChatUserType";
import {ImageSliderDialogComponent} from "./image-slider-dialog/image-slider-dialog";
import {ConfirmDialog} from "../../../../theme/components/confirmDialog/confirm.dialog";
import {SlickCarouselComponent} from "ngx-slick-carousel";
import moment from "moment";

@Component({
	selector: "ini-chat-component",
	templateUrl: "./chat.component.html",
	styleUrls: ["./chat.component.scss"]
})
export class ChatComponent implements OnInit, OnDestroy, AfterContentInit {

	@Input() chat: Chat;
	@ViewChild("textInput") textInput: ElementRef;
	@ViewChild('chatMessagesWrapper') chatMessagesWrapper: ElementRef;
	@ViewChild('slickModal') slickModal: SlickCarouselComponent;
	focused = false;
	form: FormGroup;
	subs: any = [];
	lastSeenMessageId: number | string = -1;
	realLastSeenMessageId: number = -1;
	columnsHeight = 28;
	members: ChatUser[] = [];
	searchedMembers: ChatUser[] = [];
	height = 340;
	showMembers: boolean = false;
	isAdmin: boolean = false;
	slides = [];
	isMobile = false;


	constructor(
		public chatService: ChatService,
		public fb: FormBuilder,
		public userService: UserService,
		public dialog: MatDialog
	) {
		this.form = this.fb.group({
			text: "",
			to: "",
		});
		this.form.get('text')?.valueChanges.subscribe((value) => {
			const columns = ((value + '').split('\n').length || 1);
			this.columnsHeight = (columns > 4 ? 4 : columns) * 28;
		});
		this.addToListener();
		this.isMobile = this.chatService.isMobile();

		this.height = this.isMobile ? window.innerHeight - 100 : 340;
	}

	selectEmoji(event: any, inputField: any) {
		const x = event.target.getBoundingClientRect().left - 284;
		this.dialog.open(EmojiComponent, {
			backdropClass: 'ini-chat-backdrop',
			panelClass: 'ini-chat-emoji-dialog',
			position: {
				bottom: '70px',
				left: x + 'px'
			},
			data: {
				field: inputField
			}
		}).afterClosed().subscribe(() => {
			// if (emoji) {
			// 	this.form.patchValue({
			// 		text: this.form.value.text + emoji
			// 	})
			// 	//this.form.get('text')?.setValue(this.form.value.text + emoji.native);
			// }
		});
	}

	onFileSelected(event: Event) {
		const files = (event.target as HTMLInputElement).files;
		if (files && files.length > 0) {
			let size = 0
			for (let i=0; i<files.length; i++) {
				size += files[i].size;
			}
			if (size > 5000000) {
				alert('Maximum 5MB file size allowed');
				return;
			}
			this.chatService.sendFiles(this.chat.id, files);
		}
	}

	addToListener() {
		this.form.get('to')?.valueChanges.subscribe((value) => this.searchUsers(value));
	}

	onScrollUp() {
		this.chatService.getMessages(this.chat.id, ++this.chat.loadedPage);
	}

	sendMessage() {
		this.chatService.sendMessage(this.chat.id, this.textInput.nativeElement.value);
		this.form.reset();
		this.columnsHeight = 28;
		this.focusInput();
		return false;
	}

	sendLike() {
		this.chatService.sendMessage(this.chat.id, '👍');

	}

	openSlider(currentSlide) {
		this.dialog.open(ImageSliderDialogComponent, {
			width: '80%',
			panelClass: 'ini-chat-image-slider-dialog',
			backdropClass: 'ini-chat-image-backdrop',
			data: {
				slides: this.slides,
				index: this.slides.indexOf(currentSlide)
			}
		})
	}

	setFocused = (focused: boolean) => {
		this.focused = focused
		this.seenMessages();
	};

	focusInput() {
		if (this.showMembers) return;
		//this.textInput.nativeElement.focus();
	}

	close() {
		this.chatService.closeChat(this.chat.id);
	}

	minimize() {
		this.chatService.minimizeChat(this.chat.id);
	}

	onEvent(event: ChatEvent) {
		switch (event.type) {
			case ChatEventType.MESSAGES:
				if (event.messages) {
					this.chat.messages = [...event.messages, ...this.chat.messages];
					this.parseChatMessages();
					this.scrollDown();
				}
				break;
			case ChatEventType.SEND_FILES:
				if (event.messageId) {
					this.chat.messages.push({
						id: event.messageId,
						text: '0',
						time: new Date(),
						user: this.userService.user,
						seen: MessageSeenType.ME_NOT_SEEN,
						type: ChatMessageType.PROGRESS
					});
					this.parseChatMessages();
					this.scrollDown();
				}
				break;
			case ChatEventType.FILE_UPLOAD_PROGRESS:
				if (event.messageId) {
					const message = this.chat.messages.find(message => message.id == event.messageId);
					if (message) {
						message.text = (event.progress || 0) + '';
					}
				}
				break;
			case ChatEventType.FILE_UPLOAD_FINISH:
				if (event.messageId) {
					this.chat.messages = this.chat.messages.filter(message => message.id != event.messageId);
					this.parseChatMessages();
					this.scrollDown();
				}
				break;
			case ChatEventType.MESSAGE_RECEIVED:
				if (event.message) {

					this.chat.messages.push(event.message);
					this.lastSeenMessageId = event.message.id;
					this.parseChatMessages();
					this.scrollDown();
					if (this.focused) {
						this.seenMessages();
					}
				}
				break;
			case ChatEventType.UPDATE_MESSAGE_SEEN:
				if (event.seenMessageIds && event.seenBy) {
					this.chat.messages.forEach(message => {
						if (typeof message.id == "number" && event.seenMessageIds?.includes(message.id)) {
							if (!message?.seenUsers) {
								message.seenUsers = [];
							}
							if (event.seenBy && !message.seenUsers.find(seenUser => seenUser.id == event.seenBy?.id && seenUser.type == event.seenBy.type)) {
								message.seenUsers = [...message.seenUsers, event.seenBy];
							}
							if (message.user.id == this.userService.user.id && message.user.type == this.userService.user.type) {
								// my message
								message.seen = message.seenUsers.find(seenUser => !(seenUser.id == this.userService.user.id && seenUser.type == this.userService.user.type)) ? MessageSeenType.USER_SEEN : MessageSeenType.USER_NOT_SEEN;
							} else {
								// other message
								message.seen = message.seenUsers.find(seenUser => seenUser.id == this.userService.user.id && seenUser.type == this.userService.user.type) ? MessageSeenType.ME_SEEN : MessageSeenType.ME_NOT_SEEN;
							}
						}
					});
					this.parseChatMessages();
				}
				break;
			default:
			// continue
		}
	}

	seenMessages() {
		let unseenMessageIds = this.chat.messages.filter(message => {
			if (message.user.id == this.userService.user.id && message.user.type == this.userService.user.type) {
				return false;
			}
			return message.seen == MessageSeenType.ME_NOT_SEEN;
		}).map(message => message.id) as number[];
		if (unseenMessageIds.length > 0) {
			this.chatService.seenMessages(this.chat.id, unseenMessageIds);
		}
	}

	scrollDown() {
		setTimeout(() => {
			if (this.chatMessagesWrapper && this.chatMessagesWrapper.nativeElement) {
				this.chatMessagesWrapper.nativeElement.scrollTop = this.chatMessagesWrapper.nativeElement.scrollHeight;
				if (this.focused) {
					setTimeout(() => {
						this.focusInput();
					}, 1000);
				}
			}
		}, 100);
	}

	setMessageRealPositions(indexes: number[]) {
		if (indexes.length === 1) {
			this.chat.messages[indexes[0]].realPosition = MessagePosition.SOLO;
		} else if (indexes.length === 2) {
			this.chat.messages[indexes[0]].realPosition = MessagePosition.START;
			this.chat.messages[indexes[1]].realPosition = MessagePosition.END;
		} else {
			this.chat.messages[indexes[0]].realPosition = MessagePosition.START;
			this.chat.messages[indexes[indexes.length - 1]].realPosition = MessagePosition.END;
			for (let i = 1; i < indexes.length - 1; i++) {
				this.chat.messages[indexes[i]].realPosition = MessagePosition.MIDDLE;
			}
		}
	}

	setMessagePositions(indexes: number[]) {
		if (indexes.length === 1) {
			this.chat.messages[indexes[0]].position = MessagePosition.SOLO;
		} else if (indexes.length === 2) {
			this.chat.messages[indexes[0]].position = MessagePosition.START;
			this.chat.messages[indexes[1]].position = MessagePosition.END;
		} else {
			this.chat.messages[indexes[0]].position = MessagePosition.START;
			this.chat.messages[indexes[indexes.length - 1]].position = MessagePosition.END;
			for (let i = 1; i < indexes.length - 1; i++) {
				this.chat.messages[indexes[i]].position = MessagePosition.MIDDLE;
			}
		}
	}

	parseChatMessages() {
		let group: number[] = [];
		let realGroup: number[] = [];
		this.slides = [];

		for (let i = 0; i < this.chat.messages.length; i++) {
			const message = this.chat.messages[i];
			if (message.type === ChatMessageType.IMAGE) {
				try {
					const file = JSON.parse(message.text)
					// this.slides.push(Config.storageUrl + file.filename);
					this.slides.push(file.filename);
				} catch (e) {}
			}

			if (i) {
				const isTimeGrouped = moment(message.time).diff(this.chat.messages[i - 1].time, "minutes") < 30;
				const isUserGrouped = message.user.id === this.chat.messages[i - 1].user.id;
				if (isTimeGrouped && isUserGrouped) {
					realGroup.push(i);
				} else {
					this.setMessageRealPositions(realGroup);
					realGroup = [i];
				}
				if (isTimeGrouped) {
					group.push(i);
				} else {
					this.setMessagePositions(group);
					group = [i];
				}
			} else {
				group.push(i);
				realGroup.push(i);
			}
			if (message.user.id == this.userService.user.id && message.seen == MessageSeenType.USER_SEEN) {
				this.lastSeenMessageId = message.id;
			}
		}
		if (group.length) {
			this.setMessagePositions(group);
		}
		if (realGroup.length) {
			this.setMessageRealPositions(realGroup);
		}
		this.realLastSeenMessageId = (this.chat.messages.length > 0 ? this.chat.messages[this.chat.messages.length - 1].id : -1) as number;
	}

	calculateHeight() {

	}

	addMember(user: ChatUser) {
		if (!this.members.find(member => member.id === user.id)) {
			this.members.push(user);
			this.calculateHeight();
		} else {
			this.removeMember(user);
		}
	}

	addMemberToChat(user: ChatUser) {
		if (!this.members.find(member => member.id === user.id)) {
			this.chatService.addMemberToChat(this.chat.id, user);
			this.form.patchValue({
				to: ''
			})
		}
	}

	removeMemberFromChat(user: ChatUser) {
		if (this.members.find(member => member.id === user.id)) {
			this.chatService.removeMemberFromChat(this.chat.id, user);
		}
	}

	removeMember(user: ChatUser) {
		this.members = this.members.filter(member => member.id !== user.id);
		this.calculateHeight();
	}

	createChat() {
		this.chatService.createChat(this.members);
		this.close();
	}

	toggleShowChatMembers() {
		this.showMembers = !this.showMembers;
	}

	searchUsers(keyword: string) {
		this.subs.push(
			this.chatService.searchUsers(keyword)
				.subscribe(users => this.searchedMembers = users)
		);
	}

	isChatMember(member: ChatUser): boolean {
		return !!this.members.find(user => user.id === member.id);
	}

	setCustomTitle(title: string) {
		this.chatService.setCustomTitle(this.chat.id, title);
		this.showMembers = false;
	}

	deleteChat() {
		this.dialog.open(ConfirmDialog, {
			data: {
				title: "წაშლა",
				text: "ნამდვილად გსურთ ჯგუფის წაშლა?",
				ok: "დიახ",
				cancel: "არა"
			}
		}).afterClosed().subscribe(result => {
			if (result) {
				this.chatService.deleteChat(this.chat.id);
				this.close();
			}
		});
	}

	lockScroll() {
		document.body.classList.add('lockScroll');
	}

	unlockScroll() {
		document.body.classList.remove('lockScroll');
	}

	ngAfterContentInit(): void {
		this.scrollDown();
		this.members = this.chat.members;
		this.isAdmin = !!this.chat.admins.find(admin => admin.id === this.userService.user.id && admin.type === this.userService.user.type);
	}

	ngOnInit(): void {
		this.subs.push(
			this.chatService.subscribeChat(this.chat.id).subscribe(e => this.onEvent(e))
		);
		this.chatService.getMessages(this.chat.id, this.chat.loadedPage);
		this.lockScroll();
	}

	ngOnDestroy(): void {
		for (let sub of this.subs) {
			try {
				sub.ubsubscribe();
			} catch (ex) {
			}
		}
		this.unlockScroll();
	}

	userTypeTitle(type) {
		switch (type) {
			case ChatUserType.USER:
				return 'ადმინისტრაცია';
			case ChatUserType.PROFESSOR:
				return 'პროფესორი';
			default:
				return 'სტუდენტი';
		}
	}
}
