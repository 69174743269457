import {ChatUser} from "./ChatUser";
import {MessageSeenType} from "./MessageSeenType";
import moment from "moment";
import {ChatUserType} from "./ChatUserType";


export interface ChatMessage {
  id: number | string;
  text: string;
  user: ChatUser;
  time: Date | moment.Moment;
  type: ChatMessageType;
  seen: MessageSeenType;
  seenUsers?: ChatUser[];
  position?: MessagePosition;
  realPosition?: MessagePosition;
}

export interface ChatSeenUser {
    id: number;
    type: ChatUserType;
}

export enum ChatMessageType {
  TEXT = "TEXT",
  PROGRESS = "PROGRESS",
  FILE = "FILE",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
}

export enum MessagePosition {
  START= "START",
  MIDDLE= "MIDDLE",
  END= "END",
  SOLO = "SOLO",
}
