import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {BooksService} from "../books.service";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";
import {ConfirmDialog} from "../../../theme/components/confirmDialog/confirm.dialog";
import {MatPaginator} from "@angular/material/paginator";

@Component({
	selector: "book-cat-dialog",
	templateUrl: "./book-cat-dialog.html"
})
export class BookCatDialog implements OnInit,AfterViewInit {

	items = [];
	subBook:any = "";
	page = 1;
	total = 0;
	limit = 10;
	displayedColumns = [
			"id",
		"name",
		"credit",
		"actions"
	];

	constructor(
		private ref: MatDialogRef<BookCatDialog>,
		private booksService: BooksService,
		@Inject(MAT_DIALOG_DATA) private book: any,
		private error: ErrorsDialog,
		private dialog: MatDialog
	) {

	}

	getItems() {
		this.booksService.getSubLinks({
			bookId: this.book.id
		},this.page, this.limit).subscribe(response => {
			this.items = response.data;
			this.total = response.total;
		});
	}

	remove(item) {
		this.dialog.open(ConfirmDialog, {
			data: {
				title: "წაშლა",
				text: "ნამდვილად გსურთ წაშლა?",
				ok:"დიახ",
				cancel: "არა"
			}
		}).afterClosed().subscribe(result => {
			if (result) {
				this.booksService.removeSubLink(item).subscribe(response => {
					if (response.result == "yes") {
						this.getItems();
					} else {
						this.error.show(response);
					}
				});
			}
		});
	}

	add() {
		this.booksService.createLinkSubBook({
			bookId: this.book.id,
			subBookId: this.subBook.id
		}).subscribe(response => {
			if (response.result == "yes") {
				this.getItems();
			} else {

			}
		});
	}

	cancel() {
		this.ref.close(false);
	}

	ngOnInit(): void {
		this.getItems();
	}

	/**/@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	ngAfterViewInit(): void {
		this.paginator.page.subscribe(res => {
			this.page = res.pageIndex+1;
			this.limit = res.pageSize;
			this.getItems();
		});
	}

}