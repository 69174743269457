import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TestingService} from "../../../testing.service";
import {ErrorsDialog} from "../../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: 'correct-doc-viewer-dialog',
	templateUrl: './correct-doc-viewer.dialog.html',
})

export class CorrectDocViewerDialog implements OnInit {

	docUrl: string = '';
	fileId: string = '';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private testingService: TestingService,
		public ref: MatDialogRef<CorrectDocViewerDialog>,
		public errors: ErrorsDialog,
	) {
		this.fileId = data.fileId;
	}

	ngOnInit(): void {
		this.generateDownloadUrl();
	}

	generateDownloadUrl(): void {
		this.testingService.generateFileDownloadUrl(this.fileId)
			.subscribe(response => {
				if (response.result == 'yes') this.docUrl = response.url;
				else this.errors.show({error: response.error});
			}, () => {
				this.errors.show({error: 'დაფიქსირდა შეცდომა'});
			});
	}
}