import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from "@angular/core";
declare let MathQuill: any;
declare var $: any;

@Component({
    selector: "mathEditor",
    template: `<div class='me-panel'>
        <mat-tab-group>
            <mat-tab label="ძირითადი">
                <div class='me-button-group' (click)="write(0, 1, 'Up')">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeBAMAAACoBpHTAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABhQTFRF+fn54qJQAAAAUKLi4qKBod75+d6hgaLiGg0phQAAAChJREFUeJxjYCAdMCqBgDKaKEsAiEwflqLYfYwVMClBATGKR0MSGwAAg7sS58OGdIUAAAAASUVORK5CYII=" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(1, 1, 'Left')">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEVQTFRF+fn5wX+BAAAA+d6hgaLiwfn5wX8AAH/B+fnBgX/B+fnioX+h4vn54qKhgQAAgcL5oaLi+cKBAFOhoVNQod754qJQ4qKBEjfnIQAAAGpJREFUeJxjYBgFqICRCQPgUMnMQryhrMSqhBjKhrCcnYChCKM5OPEbSkApFzcPkORlJayUg4+JH0gKMBBWysAgKMQDM5SQUi5hEZihBL0lyi4mQqRSDj54GBIMLLihRIUrFBAVW6NgcAMAp8EC4aLXcQYAAAAASUVORK5CYII=" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(2, 1, 'Left')">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAFRQTFRF+fn5+fnioVMAAAAAAACBwfn5+cKBAFOh4vn5AH/BwX8AUKLi+d6hUACBwX+BgaLiUAAAgcL5AABQod754qKBoX+h4qKh+fnBgQAAoaLioVNQ4qJQjoCp+wAAAIhJREFUeJztkkkOgCAMRcEqgvOMw/3vqWg1RkPavf4FC/J46YAQfzAygDDioSrWyjC1CrjkliRlo1nuii3glafSHGhW0soKoHZS5hhQ6p5hmtaL7tJbEZ0XPSAClb3eziGiUTu6puwkaHTfLEpJVM4tSum2FnPek6gdz/9CD+u6Zs0VUda2vpcVYBIGhlNms08AAAAASUVORK5CYII=" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(3, 1, 'Left')">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEVQTFRF+fn5+fnBgQAAAAAAAH/BgX/BwX+Bwfn5+d6hgaLiwX8A+fnioX+h4vn54qKhgcL5oaLi+cKBAFOhoVNQod754qJQ4qKBVlcs2QAAAHhJREFUeJzlkUkOgCAMRUEQVMQRvf9RJWFywnar/kXD4uXRgZDvhhbMh4NoeX68BRWSXXKPVjXaKiQWddIm/X1c9f4ETprU+Ul9p8+oarWtnYRR20Rv60AQVjJOOkghVM0mSMGxFr4aJEqLuENwWVGK2qtP9lq/zgY/rgONy7ea2QAAAABJRU5ErkJggg==" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(4, 1, 'Up')">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAD9QTFRF+fn5+cKBAAAAAABQod75+d6hUH/B4qJQAACBwfn5+fnBgQAAgcL5UACBwX8AwX9QUKLiAH/B+cKhAFOh4vn5sh642wAAAGFJREFUeJxjYBjEgJGJiZmFKJWsbAwM7BycRBkKNJKLm4dYN7DyEmUqCPDhMJSPCQmA1bDjsp4fybtgRYwCuGxDV8ooyMAghD200JQygtwhLEKcA3CDUaUkKMVMA6NgcAEAHq8DEMpjzIEAAAAASUVORK5CYII=" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(5, 1, 'Down')">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAD9QTFRF+fn5wX8AAAAAgcL5wX9Qod754qJQ+cKBAABQ+d6hUH/BAACBwfn5+fnBgQAAUACBUKLiAH/B+cKhAFOh4vn5y5upTwAAAFxJREFUeJxjYBhEgJEJCTDjVcrCimCzjSqlmVJ2JiYOVqKUcnIBKW4eYpSyA43k5cPuECxpgJMfu6nYdOP3HRIgEA5IgF2AaJWCDAxCOEILTSXId8IixBo8CmgPAP8vAiZ5muj2AAAAAElFTkSuQmCC" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(6, 1, 'Left')">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEtQTFRF+fn5+cKBUKLi4qJQgcL5+d6hUH/BwX9Qod75UAAAAAAAAFOh4vn5UFOh+fnBgVOh+fnioVNQAH/BwX8AoVMAgQCBwfn5wX+BgaLik+RTxgAAAIFJREFUeJztkkkOgCAQBAcVNxD35f8vNcBBwzSJHkyMsY9FpTPAEL0nIgEwzQCUOSwoygiTVX1EaUsazUodOleb1pKuZ7XpEE7hVRrZFTwBqu84pZtiqlA6VLMH1PisN1R2LRLXH8vMC1TBF/jF4CpaDXfCd2BduEpyAxAu4Z9PZQcD2QZ6WNMUYAAAAABJRU5ErkJggg==" alt=""/>
                </div>
            </mat-tab>
            <mat-tab label="სიმბოლოები">
                <div class='me-button-group' (click)="write(7)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeBAMAAACoBpHTAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAC1QTFRF+fn54qJQgcL5+d6hUH/B+fnioVMAUACBwfn5gaKhUAAAwfni+cKBAACBUKLiI2LkUgAAAEtJREFUeJxjYBhOgFEBSJigi7ImMTAwV2AoDlNgMG/AEGVNwqIUqHg6plKgqYuwucJ8mwIWpRUgZ2AobQA5Aw3w3AA6Iw+bySMDAAAT5wnMCH5P+gAAAABJRU5ErkJggg==" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(8)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeBAMAAACoBpHTAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABJQTFRF+fn5+cKBAAAAgcL54qJQAH/BmF16KQAAAB5JREFUeJxjYBhegFGZeFFKgZMSCATQwGR6+mLwAQBnSAHNmlAweAAAAABJRU5ErkJggg==" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(9)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeBAMAAACoBpHTAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABVQTFRF+fn5+fnioVOBwfn54qJQAAAAAH/BB5aL6wAAABpJREFUeJxjYBiqgFGZfqKuoSCQMCjdMPwAAFnZBgVYU1vSAAAAAElFTkSuQmCC" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(10)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAKhQTFRF+fn5+dqxcEcgAAAAAABQod75+fnioV9Qiaq5uZJhQHqpubq5uY9hAC9wobq5ubrJ4vH5+dapocr56a5oUKLi4qJQAFOh4vn52ZZAcLbx+fnpsWcAgcL5wX8AAH/B+eWpYQBQ+fnxuXIXAABwufH5oVMAAHK58fn5+da5ud758bp4FwAAqVsAAAAgUHJ4YWOh2fn56bJwIAAgaLLp8cqRUCMAACNQkcrxlX3HMwAAAG1JREFUeJxjYBgFIwMwMjGDAQsrIZVs7BycXNw8vHz8AoSUCgoxCIswiIqJE+UECUkGCeKUSknLMMjKEWWovAKrlDRRShWVlFXYVNXUNTQJKtXSFhNn09HV0zcgqNTQyJiBwcTUzJwYJ4yCQQsANvsG6rYqoXIAAAAASUVORK5CYII=" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(11)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeBAMAAACoBpHTAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAACpQTFRF+fn5+fnBgQAAAAAAAABQod75+fnioVNQgQCBwfn5+d6hUH/B+cKBgcL5oeY1wwAAACZJREFUeJxjYBhMgFHZFYtoeoDEBEzR1Qw8F4gVxW4CdttGwUADAPAxBp+BXONrAAAAAElFTkSuQmCC" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(12)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAD9QTFRF+fn5+d6hUH/B+fnioVNQod75+cKBUKLi+fnBgQCBwfn54qJQgcL5gQAAAAAAAABQwX9QAH/BoVOBUFOh4vn5GkluvwAAAEtJREFUeJxjYBgFlANGJmJVMrOwEquUjZ1YlRycXMQq5eYhbBovHwLwE+NcAeL9JEisSmYhYvxEolupG1BQwCgsQqxSWgTUKKAOAACvYgKmNIca+gAAAABJRU5ErkJggg==" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(13)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADxQTFRF+fn5+cKBAAAAAACBwfn5+fnBgQAAAABQod75+fnioVMAgcL5wX8AUKLi+d6hUAAAAH/BAFOh4vn54qJQt3fSSAAAAE5JREFUeJxjYBgFgwswMjGzEK2YlY2dg2jFnFxM3MS7g4c0xbxEq+XjFyBOISOToBBRCoWZiDRRmFhnEu0fYVJCiRZAmAkBiAyFUYANAACWFgGHJZv9qQAAAABJRU5ErkJggg==" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(14)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADZQTFRF+fn5+fnioVMAAAAAUKLi+fnBgQAAAABQod75AACBwfn5+d6hUAAA+cKBAFOh4vn54qJQAH/BnrbbHgAAAExJREFUeJxjYBgFgxMwMjGzEKWQlY2dg0iFnFxEKeTmIVIhLzMfP1EKgSqJ8wwYCJCmWJB4xcQ7mAEUDCQpJsEZ1AMCzAgwIA4YNgAA7mMBtErCR58AAAAASUVORK5CYII=" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(15)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADxQTFRF+fn5+cKBAAAAAACBwfn5+fnBgQAAAABQod75+fnioVMAgcL5wX8AUKLi+d6hUAAAAH/BAFOh4vn54qJQt3fSSAAAAEZJREFUeJxjYBgFgwswMjGzEK2YlY2dg2jFnFxM3MS7g4c0xbxEq+XjFyBOISOToBBRCoWZiDRRmFhnEu0fYVJCaRQMYgAAThkBThtuCkQAAAAASUVORK5CYII=" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(16)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAADZQTFRF+fn5+fnioVMAAAAAUKLi+fnBgQAAAABQod75AACBwfn5+d6hUAAA+cKBAFOh4vn54qJQAH/BnrbbHgAAAERJREFUeJzt0LcBACAIRFEUc3b/ZQ0THJ2Fv37FAdHv2ZRmA0HrfABhTBDMBYSVW4fgltgxtyHDE8f4YDpvEGHBjN9jLZaOAXIbipIuAAAAAElFTkSuQmCC" alt=""/>
                </div>
            </mat-tab>
            <mat-tab label="გეომეტრია">
                <div class='me-button-group' (click)="write(17)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAD9QTFRF+fn5+cKBAH/B+d6hUFOh4vn5+fnBgQCBwfn5+fnioVNQod75oVMAgcL5wX8AUKLi4qJQAFOhUACBgQBQAAAAIyeWFgAAAE1JREFUeJzt0LESwBAQANFDIkhwwf9/q1KVmS1T2PpVK7L7ylgq3XFC6a8AZUw3lU+GUkqlUrHkm8xLN7lGN/mON+GhUvBQHSv8YffDJgsgAiM3onsxAAAAAElFTkSuQmCC" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(18)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeBAMAAACoBpHTAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABhQTFRF+fn5+fnioVOBwfn5+fnBgVOh4vn53d3dwdrFvgAAABlJREFUeJxjYKAaYFRmCRsVHXqiAwnKsQEAzIMTnDOiyjcAAAAASUVORK5CYII=" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(19)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeBAMAAACoBpHTAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAABtQTFRF+fn5+fnioVOBwfn5+fnBgQAAAAAAgcL53d3dvcdSKgAAAB5JREFUeJxjYBhMgFF5VJSBJSwNBNKxKR9uoAMbAADQgw+YZ0wwFQAAAABJRU5ErkJggg==" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(20)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeCAMAAABt9nzSAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAEJQTFRF+fn5wcLB+cKBAAAAgcL5+fnioVNQocKhUACBwfn54qJQAH/B+d6h+fnBgQBQod75oVMAAFOh4vn5wX8AUKLigQCBOQIFPAAAAFxJREFUeJxjYBgF9AOMRKtkYmYhUiUrGzsHJ3FKubhBiBjAAzSSl4+fGOsFQA5lEhQirBSqSFiEoEqY1byiBH0GN42gzxBuhLgZN0CW58EfuFzMyIC4wB0FgxEAAAmVAmpRMOXFAAAAAElFTkSuQmCC" alt=""/>
                </div>
                <div class='me-button-group' (click)="write(21)">
                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAeBAMAAACoBpHTAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAACRQTFRF+fn5+fnioVMAAAAAAH/B+cKBoVNQod75+fnBgQBQwX8AAABQvLGApwAAADRJREFUeJxjYBipgFHZGAhM0ERZHUBS6WiiYgUwKWQQApdCAhzT4VJIgAtkl/F28l07kgAAT6oGKjzOd8YAAAAASUVORK5CYII=" alt=""/>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class='me-wrapper'>
        <span #textareaElement class="me-textarea"></span>
    </div>`,
    styles: [
        '.me-button-group { display: inline-block; box-shadow: 0 0 1px rgba(0,0,0,0.3); cursor: pointer; opacity: 0.9; margin: 2px; }',
        '.me-button-group:hover { box-shadow: 0 0 1px rgba(0,0,0,0.7); opacity: 1;}',
        '.me-panel { background: #FFFFFF }',
        '.me-wrapper { background: #FFFFFF }',
        '.me-textarea { display: block; font-size: 16px; }'
    ]
})
export class MathEditor implements AfterViewInit {

    MQ = MathQuill.getInterface(2);

    /**/@ViewChild('textareaElement', {static: false}) textareaElement: ElementRef;
    mathField: any;
    @Input() value: string;
    @Output() valueChange = new EventEmitter();
    @Input() height: number;

    constructor() {

    }

    commands = [
        '\\frac{}{}',
        '\\sqrt{}',
        '\\sqrt[3]{}',
        '\\sqrt[{}]{}',
        '\\^{}',
        '\\_{}',
        '\\left(\\right)',

        '\\times',
        '\\div',
        '\\pm',
        '\\pi',
        '\\degree',
        '\\neq',
        '\\geq',
        '\\leq',
        '\\gt',
        '\\lt',

        '\\angle',
        '\\parallel',
        '\\perpendicular',
        '\\triangle',
        '\\parallelogram'
    ];

    command(index) {
        // noinspection TypeScriptValidateJSTypes
        this.mathField.cmd(this.commands[index]).focus();
    }
    write(index, mover=0, moveTo='') {
        this.mathField.write(this.commands[index]).focus();
        for(let i=1; i<=mover; i++){
            this.mathField.keystroke(moveTo);
        }
    }

    ngAfterViewInit(): void {
        this.mathField = this.MQ.MathField(this.textareaElement.nativeElement, {
            spaceBehavesLikeTab: true,
            handlers: {
                edit: () => {
                    this.value = this.mathField.latex();
                    this.valueChange.emit(this.value);
                }
            }
        });
        this.mathField.write(this.value);
        if (typeof this.height != "undefined" && this.height != null) {
            $(this.textareaElement.nativeElement).css({
                height: this.height
            });

        }
    }



}
