import {AfterContentInit, Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'ini-chat-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.scss']
})
export class EmojiComponent implements AfterContentInit {

  constructor(
    private ref: MatDialogRef<EmojiComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  addEmoji(event: any) {
    if (event.emoji) {
      //this.ref.close(event.emoji);
      this.data.field.focus();
      this.typeInTextarea(event.emoji.native, this.data.field);
    }
  }

  typeInTextarea(newText: string, el: any) {
    const [start, end] = [el?.selectionStart, el?.selectionEnd];
    el?.setRangeText(newText, start, end, 'end');
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.data.field.focus();
    }, 300);
  }

}
