import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FinancesNewService} from "../../../module/finances-new.service";

@Component({
	selector: "finances=student-job-component",
	templateUrl: "./finances-student-job.component.html"
})
export class FinancesStudentJobComponent implements OnInit, OnDestroy {

	timer = null;
	pid = 0;
	canDownload = true;
	closeAfterDone = false;
	constructor(
		private ref: MatDialogRef<FinancesStudentJobComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private service: FinancesNewService,
	) {
		if (typeof data == "object") {

			if (typeof data.canDownload != "undefined") {
				this.canDownload = data.canDownload;
			}
			if (typeof data.pid != "undefined") {
				this.pid = data.pid;
			}
			if (typeof data.closeAfterDone != "undefined") {
				this.closeAfterDone = data.closeAfterDone;
			}
		} else {
			this.pid = data;
		}
	}

	close() {
		this.ref.close();
	}

	progress = 0;
	inProgress = true;
	link = "";
	check() {
		this.service.getJobStatus({
			pid: this.pid
		}).subscribe(response => {
			if (response.data.status == "finished") {
				if (this.closeAfterDone) {
					this.ref.close(true);
					return;
				}
				this.link = response.data.output.address;
				this.inProgress = false;

			} else {
				if (response.data.progress_max > 0) {
					this.progress = Math.round(100 / response.data.progress_max * response.data.progress_now);
				}
			}

		});
	}

	ngOnInit(): void {
		this.timer = setInterval(() => {
			this.check();
		}, 5000);
		this.check();
	}

	ngOnDestroy(): void {
		try {
			if (this.timer != null) {
				clearInterval(this.timer);
			}
		} catch (Ex) {}
	}


}