import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Socket} from "ngx-socket-io";
import {UniConfig} from "../../../../uni.config";

//declare let window: any;

@Injectable()
export class ListService extends Socket  {

    constructor(
        private http: HttpClient
    ) {
        super({
            url: UniConfig.listSocketUrl,
	        options: {
		        transports: ["websocket"]
	        }
        });
        //super({url: (window.location.protocol == "https:" ? "wss" : "ws")+"ws://"+window.location.hostname+":3030"});
    }


    getLists(filter: any, page: number, limit: number): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/getLists/?page="+page+"&limit="+limit, filter);
    }

    addOrEdit(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/addOrEdit", data);
    }

    getGroups(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/getGroups", data);
    }

    addOrEditGroup(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/addOrEditGroup", data);
    }

    addStudents(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/addStudents", data);
    }

    getSelectedStudents(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/getSelectedStudents", data);
    }

    getStudents(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/getStudents", data);
    }

    removeStudent(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/removeStudent", data);
    }

    getListDetails(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/getListDetails", data);
    }

    setEst(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/setEst", data);
    }

    getGroupProfs(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/getGroupProfs", data);
    }

    removeListProf(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/removeListProf", data);
    }

    addOrRemoveProf(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/addOrRemoveProf", data);
    }

    removeList(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/removeList", data);
    }

    setListStatus(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/lists/setListStatus", data);
    }

    genComponentsForBooks(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/books/genComponentsForBooks", data);
    }
    exportStudentComponents(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/books/exportStudentComponents", data);
    }
    exportListStudents(data): Observable<ListResponse> {
        return this.http.post<ListResponse>("/books/exportListStudents", data);
    }

    getTasks(filter, page, limit):Observable<any> {
        return this.http.post<any>("/tasks/getListTasks/?page="+page+"&limit="+limit, filter);
    }

    removeTasks(item):Observable<any> {
        return this.http.post<any>("/lists/removeTask", item);
    }

    // noinspection JSUnusedGlobalSymbols
	uploadFiles(form):Observable<any> {
        return this.http.post<any>("https://seu1.ini.ge:3200/upload", form, {
	        reportProgress: true,
	        observe: 'events'
        });
    }

    saveTask(data):Observable<any> {
        return this.http.post<any>("/lists/saveTask", data);
    }
    getListPrintDetails(data):Observable<any> {
        return this.http.post<any>("/lists/getListPrintDetails", data);
    }

    addElEvent(data: any) {
        return this.http.post<any>("/el-learning/addElEvent", data);
    }

    getElEventList(filter: any, page: number, limit: number) {
        return this.http.post<any>("/el-learning/getElEventList/?page="+page+"&limit="+limit, filter);
    }

	getBookGroupForList(data): Observable<any> {
		return this.http.post<ListResponse>("/lists/getBookGroupForList", data);
	}

	addExamListDate(data: any) {
		return this.http.post<any>("/lists/addExamListDate", data);
	}

	getExamListDate(data: any) {
		return this.http.post<any>("/lists/getExamListDate", data);
	}

	removeExamListDate(data: any) {
		return this.http.post<any>("/lists/removeExamListDate", data);
	}

	getregistrationBookGroupsForMedic(data): Observable<ListResponse> {
		return this.http.post<ListResponse>("/lists/getregistrationBookGroupsForMedic", data);
	}

	signList(data): Observable<ListResponse> {
    	return this.http.post<ListResponse>("/lists/sign", data);
	}

	sendSmsForListSign(data): Observable<ListResponse> {
    	return this.http.post<ListResponse>("/lists/sendSmsForListSign", data);
	}

	getSignedProfs(data): Observable<any> {
    	return this.http.post<any>("/lists/getSignedProfs", data);
	}

	getSignConfig(): Observable<any> {
    	return this.http.post<any>("/lists/getSignConfig", {});
	}

	changeSignConfig(data): Observable<ListResponse> {
    	return this.http.post<ListResponse>("/lists/changeSignConfig", data);
	}

	generateListChart(data): Observable<any> {
    	return this.http.post<any>("/lists/generateChartReport", data);
	}

	unsignList(data): Observable<any> {
		return this.http.post<any>("/lists/unsignList", data);
	}

	zoomFiles(data): Observable<any> {
    	return this.http.post<any>("/lists/zoomFiles", data);
	}

	resetList(data): Observable<any> {
    	return this.http.post("/lists/reset", data);
	}

}
export class ListResponse {
    result: string;
    data: any[];
    total: number;
    items: any;
    error: any;
    errors: string[];
    link: string;
    permissions: any;
	isMedicine:any;
	groups: any;
}

export const Weeks = [
	{id: 1, name: 'I'},
	{id: 2, name: 'II'},
	{id: 3, name: 'III'},
	{id: 4, name: 'IV'},
	{id: 5, name: 'V'},
	{id: 6, name: 'VI'},
	{id: 7, name: 'VII'},
	{id: 8, name: 'VIII'},
	{id: 9, name: 'IX'},
	{id: 10, name: 'X'},
	{id: 11, name: 'XI'},
	{id: 12, name: 'XII'},
	{id: 13, name: 'XIII'},
	{id: 14, name: 'XIV'},
	{id: 15, name: 'XV'},
	{id: 16, name: 'XVI'},
	{id: 17, name: 'XVII'},
	{id: 18, name: 'XVIII'},
	{id: 19, name: 'XIX'},
	{id: 20, name: 'XX'},
];
