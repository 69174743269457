import {Component, Inject} from "@angular/core";
import {EstViewComponent} from "../est/view/est-view.component";
import {ListService} from "../list.service";
import {ActivatedRoute} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {FormBuilder} from "@angular/forms";
import {PermissionService} from "../../../../core/services/permission.service";
import {ComponentService} from "../../../components/component.service";
import {UserService} from "../../../../core/services/user.service";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: "export-student-component-dialog",
	templateUrl: "./export-student-component.dialog.html",
	styleUrls: ["./export-student-component.dialog.scss"]
})
export class ExportStudentComponentDialog extends EstViewComponent {

	selectedComponents:any = {};

	constructor(
		public override service: ListService,
		public override router: ActivatedRoute,
		public override dialog: MatDialog,
		public override sanitizer: DomSanitizer,
		public override fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) private dialogData: any,
		public override permissionService: PermissionService,
		public override title: Title,
		public override componentService: ComponentService,
		public override userService: UserService,
		public override errors: ErrorsDialog,
	) {
		super(service, router, dialog, sanitizer, fb, permissionService, title, componentService,userService, errors);
	}

	getProfNames(profs)
	{
		let profNames = "";
		for (let prof of profs){
			profNames += prof.name+",";
		}
		return profNames;
	}

	export() {
		let items = [];
		for (let group of this.listData.groups) {
			if (typeof this.selectedComponents[group.id] != "undefined") {
				let groupIsSelected = false;
				let groupStudents = {
					groupName: group.name,
					profNames: this.getProfNames(group.profs),
					students: []
				};
				for (let student of this.groupStudents[group.id]) {
					let studentData = {
						student: student.name,
						studentPersonalNumber: student.personalNumber,
						studentMail: student.mail,
						studentPhone: student.phone,
						components: []
					};
					for (let com of group.components) {
						if (typeof this.selectedComponents[group.id][com.id] != "undefined" && this.selectedComponents[group.id][com.id]) {
							groupIsSelected = true;
							// noinspection DuplicatedCode
							if(com.items.length > 1){
								for(let i in com.items){
									let subName = com.items.name == null || com.items.name == '' || typeof com.items.name == 'undefined' ? parseInt(i)+1 : com.items.name;
									studentData.components.push({
                    name: com.name + " - "+ subName,
                    value: typeof this.est[student.id][com.coId].items != "undefined" && typeof this.est[student.id][com.coId].items[i] != "undefined" ? this.est[student.id][com.coId].items[i].result : "",
                    isOk: this.estData(student, group, com)
                  });
								}
							}else {
                studentData.components.push({
                  name: com.name,
                  value: typeof this.est[student.id][com.coId] == "object" ? this.est[student.id][com.coId].changingThisBreaksApplicationSecurity : this.est[student.id][com.coId],
                  isOk: this.estData(student, group, com)
                });
              }
						}
					}

					groupStudents.students.push(studentData);
				}
				if (groupIsSelected) {
					items.push(groupStudents);
				}
			}
		}


		this.service.exportStudentComponents({
			data: items
		}).subscribe(response => {
			if (response.result == "yes") {
				window.location.href = response.link;
			}
		});

	}

	override afterLoad() {
		for (let group of this.listData.groups) {
			if (typeof this.selectedComponents[group.id] == "undefined") {
				this.selectedComponents[group.id] = {};
			}
			for (let com of group.components) {
				if (typeof this.selectedComponents[group.id][com.id] == "undefined") {
					this.selectedComponents[group.id][com.id] = false;
				}
			}
		}
	}

	override ngOnInit(): void {
		this.listId = this.dialogData.id;
		this.getListDetails();
		this.socketSubscribe();
		this.searchForm.patchValue({
			name: "",
			groupName: "",
		});
	}

}
