import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import {LangService} from "../../../shared/services/lang.service";
import {Language} from "../../../pages/lang/language";
import {TranslateService} from "@ngx-translate/core";
import {PermissionService} from "../../../core/services/permission.service";

@Component({
	selector: 'app-flags-menu',
	templateUrl: './flags-menu.component.html',
	styleUrls: ['./flags-menu.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FlagsMenuComponent implements OnInit {

	public settings: Settings;
	constructor(
		public appSettings:AppSettings,
		private service: LangService,
		private translate: TranslateService,
		private permissionService: PermissionService
	){
		this.settings = this.appSettings.settings;
	}

	renewPermissions() {
		this.permissionService.initPermissions();
	}

	languages: Language[] = [];
	ngOnInit() {
		this.getLanguages();
	}

	setLocale(locale) {
		localStorage.setItem("lang", locale);
		this.translate.use(locale);
	}

	getLanguages() {
		this.service.getLanguages().subscribe(response => {
			this.languages = response.languages;
		});
	}




}
