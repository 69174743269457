import {Component, Input} from "@angular/core";
import {ChatMessage} from "../../../../interfaces/ChatMessage";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
	selector: 'ini-chat-message-progress',
	templateUrl: 'message.progress.component.html',
	styleUrls: [
		'../text/message-text.component.scss',
		'message.progress.component.scss'
	]
})
export class MessageProgressComponent {

	@Input() message: ChatMessage;
	me = true;

	constructor(
		public sanitizer: DomSanitizer,
	) {

	}

	trustUrl(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	progress() {
		return parseInt(this.message.text);
	}
}
