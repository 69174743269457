import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Config} from "../config";

@Injectable()
export class ChatInterceptor implements HttpInterceptor {

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.substring(0, 7) == "chat://") {
			let headers: HttpHeaders = request.headers;
			headers = headers.append('ref', window.location.host);
			request = request.clone({
				url: request.url.replace('chat://', Config.api_url + '/'),
				headers
			});
			console.log(headers)
		}
		return next.handle(request);
	}
}