import {
    AfterViewInit,
    Component,
    forwardRef,
    Inject,
    Input,
    Output,
    ViewChild,
    ViewEncapsulation
} from "@angular/core";
import {iniEditorConfig, IniEditorConfig} from "./config";
import {DOCUMENT} from "@angular/common";
import {IniEditorCommand} from "./ini.editor.command";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

declare let document: any;
declare let window: any;


@Component({
    selector: "ini-editor",
    templateUrl: "./ini.editor.component.html",
    styleUrls: ["./ini.editor.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers:[{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => IniEditorComponent),
        multi: true
    }]
})
export class IniEditorComponent implements ControlValueAccessor, AfterViewInit {

    constructor(
        @Inject(DOCUMENT) private _document: any
    ) {

    }

    private onChange: (value: string) => void;
    private onTouched: () => void;

    @Input() config: IniEditorConfig = iniEditorConfig;
    /**/@ViewChild('editor', {static: false}) textArea: any;


    annotationClass = "annotationClass";
    annotationName = "annotationName";

    @Output() html;

    tmp_val = "";

    onPaste(e: ClipboardEvent) {
        const clipboardData = e.clipboardData || window.clipboardData;
        e.stopPropagation();
        e.preventDefault();

        // noinspection UnnecessaryLocalVariableJS
        const pastedText = clipboardData.getData('text');
        document.execCommand("insertHTML", false, pastedText);
        return pastedText;
    }

    // noinspection JSUnusedGlobalSymbols
    onInput(ev) {

    }

    isArea(el = null, c=true): boolean {
        let element = null;
        if (c) {
            const range = this._document.getSelection().getRangeAt(0);
            element = range.commonAncestorContainer;
        } else {
            element = el;
        }
        if (element == null) {
            return false;
        }
        if (typeof element.className != "undefined") {
            if ((element.className+"").indexOf('ini-editor-textarea') != -1) {
                return true;
            }
        }
        if (typeof element.parentNode != "undefined" || element.parentNode != null) {
            return this.isArea(element.parentNode, false);
        }
        return false;
    }

    execute(command: IniEditorCommand) {

        if (!this.isArea() && command.action != "change") { return; }

        switch (command.action) {
            default:
                // continue
                this._document.execCommand(command.action, false, command.data);
                if (command.action == "fontSize") {
                    // noinspection JSSwitchVariableDeclarationIssue
                    const listId: any = window.getSelection().focusNode.parentNode;
                    switch (command.data) {
                        default:
                            listId.style.fontSize = command.data+"px";
                            break;
                        case 7:
                            listId.style.fontSize = "large";
                            break;
                        case 1:
                            listId.style.fontSize = "small";
                            break;
                    }
                }
                break;
            case "divStyle":
                this._document.execCommand("formatBlock", false, "div");
                const listId = window.getSelection().focusNode.parentNode;
                //listId.style = "";
                if (typeof command.data == "object") {

                    for (const i in command.data) {
                        listId.style[i] = command.data[i];
                    }
                }
                listId.className = command.action;
                break;
            case "tag":
                if (window.getSelection) {
                    const sel = window.getSelection();
                    if (sel.rangeCount) {
                        if (command.data == "spanRTL") {
                            this.wrapSelected("span", {
                                dir: "rtl"
                            });
                        } else if (command.data == "spanLTR") {
                            this.wrapSelected("span", {
                                dir: "ltr"
                            });
                        } else {
                            this.wrapSelected(command.data);
                        }
                    }
                }
                break;
            case "change":
                this.onContentChange(this.textArea.nativeElement.innerHTML);
                break;
        }
    }

    wrapSelected(tagName, att=null, el=null, r=null) {
        let range = null;
        let frag = null;
        let clRange = null;
        let start = null;
        let end = null;
        if (r == null) {
            range = this._document.getSelection().getRangeAt(0);
            frag = range.cloneContents();
            clRange = range.cloneRange();
            start = range.startContainer;
            end = range.endContainer;
        } else {
            range = r.range;
            frag = r.frag;
            clRange = r.clRange;
            start = r.start;
            end = r.end;
        }




        let parent = null;
        if (el == null) {
            parent = range.commonAncestorContainer;
        } else {
            parent = el;
        }

        let text, textParent, origText, prevText, nextText, childCount,
            annotationTextRange,
            span = this._document.createElement(tagName)
        ;
        if (parent.nodeType === 3) {
            if (att != null) {
                for (const i in att) {
                    if (att.hasOwnProperty(i)) {
                        span.setAttribute(i, att[i]);
                    }
                }
            }
            span.setAttribute('class', this.annotationClass);
            span.dataset.name = this.annotationName;
            span.dataset.comment = '';
            span.dataset.page = '1';

            origText = parent.textContent;
            annotationTextRange = this.validateTextRange(range, parent);

            switch (annotationTextRange) {
                case "textNotInRange":
                    return;
                case "textBeforeRangeButIntersect":
                    text = origText.substring(0, range.endOffset);
                    nextText = origText.substring(range.endOffset);
                    break;
                case "textAfterRangeButIntersect":
                    prevText = origText.substring(0, range.startOffset);
                    text = origText.substring(range.startOffset);
                    break;
                case "textExactlyInRange":
                    text = origText;
                    break;
                case "textWithinRange":
                    prevText = origText.substring(0, range.startOffset);
                    text = origText.substring(range.startOffset, range.endOffset);
                    nextText = origText.substring(range.endOffset);
                    break;
                default:
            }
            span.textContent = text;
            textParent = parent.parentElement;
            textParent.replaceChild(span, parent);
            if (prevText) {
                textParent.insertBefore(this._document.createTextNode(prevText), span);
            }
            if (nextText) {
                textParent.insertBefore(this._document.createTextNode(nextText), span.nextSibling);
            }
            return;
        }

        childCount = parent.childNodes.length;
        for (let i = 0; i < childCount; i++) {
            const elemChildNode = parent.childNodes[i];
            if( this.isUndefined(elemChildNode.tagName) ||
                ! ( (elemChildNode.tagName+"").toLowerCase() === 'span' &&
                    elemChildNode.classList.contains(this.annotationClass) ) ) {
                this.wrapSelected(tagName, att, parent.childNodes[i], {
                    range,
                    frag,
                    clRange,
                    start,
                    end
                });
            }
            childCount = parent.childNodes.length;
        }
    }

    // noinspection JSUnusedLocalSymbols
    isUndefined(tagName) {
        return false;
    }

    validateTextRange(range, elem) {
        const textRange = document.createRange();
        textRange.selectNodeContents (elem);
        if (range.compareBoundaryPoints (Range.START_TO_END, textRange) <= 0) {
            return 'textNotInRange';
        } else {
            if (range.compareBoundaryPoints (Range.END_TO_START, textRange) >= 0) {
                return 'textNotInRange';
            } else {
                const startPoints = range.compareBoundaryPoints (Range.START_TO_START, textRange),
                    endPoints = range.compareBoundaryPoints (Range.END_TO_END, textRange);

                if (startPoints < 0) {
                    if (endPoints < 0) {
                        return 'textBeforeRangeButIntersect';
                    } else {
                        return "textExactlyInRange";
                    }
                } else {
                    if (endPoints > 0) {
                        return 'textAfterRangeButIntersect';
                    } else {
                        if (startPoints === 0 && endPoints === 0) {
                            return "textExactlyInRange";
                        } else {
                            return 'textWithinRange';
                        }
                    }
                }
            }
        }
    }

    onContentChange(html) {
        if (typeof this.onChange === 'function') {
            this.onChange(html);
        }

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
       // this.config.editable = isDisabled;
    }

    writeValue(value: any): void {
        if (value === null || value === undefined || value === '' || value === '<br>') {
            value = null;
        }
        this.tmp_val = value;

    }

    ngAfterViewInit(): void {
        this.textArea.nativeElement.innerHTML = this.tmp_val;
    }




}
