import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from 'rxjs';

@Component({
	selector: 'app-otp-countdown',
	templateUrl: './otp-countdown.component.html',
	styleUrls: ['./otp-countdown.component.scss']
})
export class OtpCountdownComponent implements OnInit, OnDestroy {
	@Input() expiresAt: Date; // Expiry time received from backend
	remainingTime: number;
	private countdownSub: Subscription;

	constructor() {
	}

	ngOnInit(): void {
		this.startCountdown();
	}

	ngOnDestroy(): void {
		if (!this.countdownSub) return

		this.countdownSub.unsubscribe();
	}

	private startCountdown(): void {
		const currentTime = new Date().getTime();
		const endTime = new Date(this.expiresAt).getTime();
		const duration = (endTime - currentTime) / 1000; // Convert milliseconds to seconds

		if (duration <= 0) {
			this.remainingTime = 0;

			return;
		}

		this.remainingTime = duration;
		this.countdownSub = interval(1000).subscribe(() => {
			if (this.remainingTime > 0) {
				this.remainingTime--;
			} else {
				// Countdown finished
				this.countdownSub.unsubscribe();
			}
		});
	}

	formatTime(seconds: number): string {
		const minutes = Math.floor(seconds / 60);
		const remainingSeconds = Math.fround(seconds % 60,);
		return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds.toFixed(0)}`;
	}
}
