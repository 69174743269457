import {EventEmitter, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ChatRoom} from "../interfaces/ChatRoom";
import {ChatUser} from "../interfaces/ChatUser";
import {ChatEvent} from "../interfaces/ChatEvent";
import {ChatEventType} from "../interfaces/ChatEventType";
import {Chat, emptyChat} from "../interfaces/Chat";
import {ChatStatus} from "../interfaces/ChatStatus";
import {UserService} from "./user.service";
import {SearchMode} from "../interfaces/SearchMode";
import {Socket} from 'ngx-socket-io';
import {MessageSeenType} from "../interfaces/MessageSeenType";
import {UploaderService} from "./uploader.service";
import {FileUploadProgress, FileUploadProgressType} from "../interfaces/FileUploadProgress";
import {Config} from "../config";
import {UserSetting} from "../interfaces/UserSetting";
import {createId} from '@paralleldrive/cuid2';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ChatService extends Socket {

	users: ChatUser[] = [];
	rooms: ChatRoom[] = [];
	events: EventEmitter<ChatEvent> = new EventEmitter<ChatEvent>();
	chats: Chat[] = [];
	activeChatLimit = 2;
	subs: any = [];
	sideBarMode: SearchMode = SearchMode.INACTIVE;
	callbacks: any = {};
	connectionStatus = false;
	userSettings: UserSetting = {
		title: '',
		muted: false,
		visibility: true,
		directory: false
	};

	constructor(
		private userService: UserService,
		private http: HttpClient,
		private uploader: UploaderService
	) {
		const socketConfig = {
			url: Config.url,
			options: {
				transports: ["websocket"],
				withCredentials: true
			}
		};
		super(socketConfig);
		this.calculateChatLimits();
		this.onConnect();
	}

	isMobile = () => window.innerWidth < Config.mobileWidth;
	getRooms = (): Observable<ChatRoom[]> => this.roomObserver;
	// noinspection JSUnusedGlobalSymbols
	hideRooms = () => this.events.emit({type: ChatEventType.HIDE_ROOMS})
	// noinspection JSUnusedGlobalSymbols
	showRooms = () => this.events.emit({type: ChatEventType.SHOW_ROOMS});
	search = (text: string) => text ? this.events.emit({
		type: ChatEventType.SET_ROOMS,
		rooms: this.rooms.filter(user => user.name.toLowerCase().includes(text.toLowerCase()))
	}) : this.events.emit({type: ChatEventType.HIDE_ROOMS})
	getChats = () => this.chatObserver;
	openChat = (room: ChatRoom) => this.openChatById(room.id);
	closeChat = (chatId: number) => this.events.emit({type: ChatEventType.CLOSE_CHAT, chatId});
	minimizeChat = (chatId: number) => this.events.emit({
		type: ChatEventType.SET_CHAT_STATUS,
		chatId,
		chatStatus: ChatStatus.INACTIVE
	})
	subscribeChat = (chatId: number): Observable<ChatEvent> => new Observable((observer) => {
		this.subs.push({
			chatId,
			observer: this.events.subscribe((event: ChatEvent) => event && event.chatId === chatId && observer.next(event))
		})
	});
	clearChatSubscriber = (chatId: number) => [this.subs.forEach((sub: any) => {
		try {
			sub.chatId === chatId && sub.observer.unsubscribe();
		} catch (ex) {
		}
	}),
		this.subs = this.subs.filter((sub: any) => sub.chatId !== chatId)];
	unique = (array: any[]) => Array.from(new Set(array.map(s => s.id + '-' + s.type)))
		.map(id => array.find(s => parseInt(s.id) === parseInt((id + '').split('-')[0])));
	addEmptyChat = () => this.events.emit({type: ChatEventType.OPEN_CHAT, chat: emptyChat});
	sendFiles = (chatId: number, files: FileList) => {
		const messageId = createId();
		this.events.emit({type: ChatEventType.SEND_FILES, chatId, messageId});
		this.uploader.upload(files, chatId, 'chat')
			.subscribe((progress: FileUploadProgress) => {
				if (progress.type == FileUploadProgressType.PROGRESS) {
					this.events.emit({
						type: ChatEventType.FILE_UPLOAD_PROGRESS,
						chatId,
						messageId,
						progress: progress.progress
					});
				} else if (progress.type == FileUploadProgressType.FINISHED) {
					this.events.emit({
						type: ChatEventType.FILE_UPLOAD_FINISH,
						attachment: progress.attachment,
						chatId,
						messageId,
						progress: 100
					});
					if (progress.attachment && progress.attachment.length > 0) {
						this.uploadFile(chatId, progress.attachment[0], progress.name, progress.fileType, progress.size);
					}
				}
			});
	}


	calculateChatLimits() {
		const oldLimit = this.activeChatLimit;
		this.activeChatLimit = Math.floor((window.innerWidth - 100) / 330) || 1;
		if (oldLimit !== this.activeChatLimit) {
			this.chats = this.checkActiveChats(this.chats);
		}
	}

	checkActiveChats(chats: Chat[]) {
		// minimize limited chats
		let activeChats = 0;
		for (let i = chats.length - 1; i >= 0; i--) {
			if (activeChats >= this.activeChatLimit) {
				chats[i].status = ChatStatus.INACTIVE;
			} else if (chats[i].status === ChatStatus.ACTIVE) {
				activeChats++;
			}
		}
		return chats;
	}


	roomObserver = new Observable<ChatRoom[]>(observer => {
		observer.next(this.rooms);
		let room: ChatRoom | undefined;
		this.events.subscribe((event: ChatEvent) => {
			switch (event.type) {
				case ChatEventType.DISCONNECT:
					this.rooms = [];
					observer.next(this.rooms);
					break;
				case ChatEventType.UPDATE_MESSAGE_SEEN:
					if (event.seenBy.id == this.userService.user.id && event.seenBy.type == this.userService.user.type) {
						const room = this.rooms.find(room => room.id === event.chatId);
						if (room) {
							room.seen = MessageSeenType.ME_SEEN;
							observer.next(this.rooms);
						}
					}
					break;
				case ChatEventType.MESSAGE_RECEIVED:
					if (event.chatId) {
						let chat = this.rooms.find(room => room.id === event.chatId);
						if (chat && event.message) {
							chat.lastMessage = event.message.text;
							chat.lastMessageAuthor = event.message.user;
							chat.lastMessageTime = event.message.time;
							chat.seen = (event.message.user.id === this.userService.user.id && event.message.user.type == this.userService.user.type) ? MessageSeenType.USER_NOT_SEEN
								: MessageSeenType.ME_NOT_SEEN;
							observer.next(this.rooms);
						}
					}
					break;
				case ChatEventType.UPDATE_CHAT_ROOM:
					if (event.room) {
						room = this.rooms.find(room => room.id === event.room?.id);
						if (room) {
							room.name = event.room.name;
							room.icon = event.room.icon;
							room.seen = event.room.seen;
							room.seenUsers = event.room.seenUsers;
							room.lastMessage = event.room.lastMessage;
							room.lastMessageAuthor = event.room.lastMessageAuthor;
							room.lastMessageTime = event.room.lastMessageTime;
							observer.next(this.rooms);
						}
					}
					break;
				case ChatEventType.SHOW_ROOMS:
					observer.next(this.rooms);
					break;
				case ChatEventType.HIDE_ROOMS:
					observer.next([]);
					break;
				case ChatEventType.SET_ROOMS:
					if (event.rooms) {
						this.rooms = event.rooms;
						observer.next(event.rooms);
					}
					break;
				case ChatEventType.LEAVE_CHAT:
					if (typeof event.chatId != 'undefined') {
						this.rooms = this.rooms.filter(room => room.id !== event.chatId);
					}
					break;
				case ChatEventType.ADD_MEMBER_TO_CHAT:
					room = this.rooms.find(room => room.id === event.chatId);
					if (room && event.user) {
						room.users.push(event.user);
						room.users = this.unique(room.users);
						observer.next(this.rooms);
					}
					break;
				case ChatEventType.REMOVE_MEMBER_FROM_CHAT:
					room = this.rooms.find(room => room.id === event.chatId);
					const chatUser = event.user;
					if (room && chatUser) {
						if (chatUser.id != this.userService.user.id) {
							room.users = this.unique(
								room.users.filter(user => !(user.id == chatUser.id && user.type == chatUser.type))
							);
							observer.next(this.rooms);
						}
					}
					break;
				default:
				// continue
			}
		});
	});


	chatObserver = new Observable<Chat[]>(observer => {
		//observer.next(this.chats);
		let chat: Chat | undefined;
		this.events.subscribe((event: ChatEvent) => {
			switch (event.type) {
				case ChatEventType.DISCONNECT:
					this.chats = [];
					observer.next(this.chats);
					break;
				case ChatEventType.MESSAGE_RECEIVED:
					if (event.chatId) {
						const currentChat = this.chats.find(chat => chat.id === event.chatId);
						if (currentChat && event.message) {
							currentChat.lastMessage = event.message.text;
							currentChat.lastMessageAuthor = event.message.user;
							currentChat.lastActiveDate = event.message.time;
							currentChat.seen = event.message.user.id === this.userService.user.id && event.message.user.type == this.userService.user.type
								? MessageSeenType.USER_NOT_SEEN
								: MessageSeenType.ME_NOT_SEEN;
							observer.next(this.chats);
						}
						if (!(event.message.user.id == this.userService.user.id && event.message.user.type == this.userService.user.type)) {
							this.playRingtone();
						}
					}
					break;
				case ChatEventType.SET_ROOMS:
					this.chats = this.chats.filter(chat => event.rooms.find(room => room.id === chat.id || chat.id < 0));
					observer.next(this.chats);
					break;
				case ChatEventType.UPDATE_CHAT_ROOM:
					if (event.room) {
						const chat = this.chats.find(chat => chat.id === event.room?.id);
						if (chat) {
							chat.title = event.room.name;
							chat.icon = event.room.icon;
							chat.seen = event.room.seen;
							chat.seenUsers = event.room.seenUsers;
							chat.lastMessage = event.room.lastMessage;
							chat.lastMessageAuthor = event.room.lastMessageAuthor;

							observer.next(this.chats);
						}
					}
					break;
				case ChatEventType.CLOSE_CHAT:
					if (typeof event.chatId !== 'undefined') {
						this.chats = this.chats.filter(chat => chat.id !== event.chatId);
						this.clearChatSubscriber(event.chatId);
						observer.next(this.chats);
					}
					break;
				case ChatEventType.OPEN_CHAT:
					if (event.chat) {

						// add chat
						const exists = this.chats.find(chat => chat.id === event.chat?.id);
						if (exists) {
							this.chats = this.chats.filter(chat => chat.id !== event.chat?.id);
						}
						this.chats.push({
							...event.chat,
							status: ChatStatus.ACTIVE
						});

						// resort chats
						this.chats = this.checkActiveChats(this.chats);

						// serve
						observer.next(this.chats);
					}
					break;
				case ChatEventType.SET_CHAT_STATUS:
					chat = this.chats.find(chat => chat.id === event.chatId);
					if (chat) {
						chat.status = event.chatStatus ? event.chatStatus : ChatStatus.INACTIVE;
						observer.next(this.chats);
					}
					break;
				case ChatEventType.ADD_MEMBER_TO_CHAT:
					chat = this.chats.find(chat => chat.id === event.chatId);
					if (chat && event.user) {
						chat.members.push(event.user);
						chat.members = this.unique(chat.members);
						observer.next(this.chats);
					}
					break;
				case ChatEventType.REMOVE_MEMBER_FROM_CHAT:
					chat = this.chats.find(chat => chat.id === event.chatId);
					const chatUser = event.user;
					if (chat && chatUser) {
						if (chatUser.id == this.userService.user.id && chatUser.type == this.userService.user.type) {
							this.leaveChat(chat.id);
						} else {
							chat.members = this.unique(chat.members.filter(user => !(user.id == chatUser.id && user.type == chatUser.type)));
							observer.next(this.chats);
						}
					}
					break;
				case ChatEventType.LEAVE_CHAT:
					if (typeof event.chatId !== 'undefined') {
						this.closeChat(event.chatId);
					}
					break;
				default:
				// continue
			}
		});
	});

	leaveChat(chatId: number) {
		this.events.emit({
			type: ChatEventType.LEAVE_CHAT,
			chatId
		});
	}

	onConnect() {
		this.on('disconnect', () => {
			this.connectionStatus = false;
			this.events.emit({
				type: ChatEventType.DISCONNECT
			});
		});
		this.on(ChatEventType.CONNECT, () => {
			this.emit(ChatEventType.AUTH, {
				token: this.userService.getToken()
			});
		});
		this.on(ChatEventType.AUTH_SUCCESS, async ({user}: { user: ChatUser }) => {
			this.connectionStatus = true;
			this.userService.setUser(user);
			this.userSettings = await this.getUserSettings();
			await this.getChatRooms();
		})
		this.on(ChatEventType.CALLBACK, (data: ChatEvent) => {

			if (data.eventId && this.callbacks[data.eventId]) {
				this.callbacks[data.eventId](data);
				delete this.callbacks[data.eventId];
			}
		});
		[
			ChatEventType.SET_ROOMS,
			ChatEventType.OPEN_CHAT,
			ChatEventType.MESSAGE_RECEIVED,
			ChatEventType.MESSAGES,
			ChatEventType.UPDATE_CHAT_ROOM,
			ChatEventType.UPDATE_MESSAGE_SEEN,
			ChatEventType.ADD_MEMBER_TO_CHAT,
		].forEach((eventType: any) => {

			this.on(eventType, (e: ChatEvent) => {

				this.events.emit({
					...e,
					type: eventType,
				})
			});
		});

		this.on('ping', () => this.emit('pong'));
	}

	async getChatRooms() {
		this.http.get('chat://getRooms').subscribe((rooms: ChatRoom[]) => {
			this.events.emit({
				type: ChatEventType.SET_ROOMS,
				rooms
			});
		});
	}

	async getUserSettings(): Promise<UserSetting> {
		return new Promise<UserSetting>(async (serve) => {
			this.userService.getUserSetting().subscribe(settings => serve(settings));
		});
	}

	playRingtone() {
		if (this.userSettings.muted) return;
		const audio = new Audio();
		audio.src = 'assets/sounds/ringtone.mp3';
		audio.load();
		audio.play().then(() => {
		});
	}

	openChatById = (chatId: number) => {
		this.http.get('chat://get-chat/' + chatId).subscribe((chat: Chat) => {
			this.events.emit({
				type: ChatEventType.OPEN_CHAT,
				chat
			});
		});
	}
	getMessages = (chatId: number, page: number = 1) => {
		this.http.get('chat://get-messages/' + chatId + '/' + page).subscribe((messages: any) => {
			console.log('messages: ', messages)
			this.events.emit({
				type: ChatEventType.MESSAGES,
				chatId,
				messages
			});
		});
	};

	sendMessage = (chatId: number, text: string) => {
		if (chatId && text) {
			this.http.post('chat://send-message', {chatId, text}).subscribe(() => {});
		}
	}

	uploadFile(chatId: any, filename: string, originalname: string, mimetype: string, size: any) {
		this.http.post('chat://send-file', {chatId, filename, originalname, mimetype, size}).subscribe(() => {});
	}

	async getUserDirectories(): Promise<any[]> {
		return new Promise(serve => {
			this.http.get('chat://get-user-directories').subscribe((directories: any) => serve(directories));
		});
	}

	createChat = (members: ChatUser[]) => {
		this.http.post('chat://create-chat', {members}).subscribe((chat: Chat) => {
			this.events.emit({
				type: ChatEventType.OPEN_CHAT,
				chat
			});
		});
	}

	setSettings(settings: any): Promise<UserSetting> {
		return new Promise(serve => {
			this.http.post('chat://set-settings', { settings }).subscribe(() => serve(settings));
		})
	}

	removeMemberFromChat(chatId: number, member: ChatUser) {
		this.http.post('chat://remove-member', {chatId, member}).subscribe(() => {
			this.events.emit({
				type: ChatEventType.REMOVE_MEMBER_FROM_CHAT,
				chatId,
				user: member
			});
		});
	}

	setCustomTitle(chatId: number, title: string) {
		this.http.post('chat://set-custom-title', {chatId, title}).subscribe(() => {});
	}

	searchUsers(keyword: string): Observable<ChatUser[]> {
		return new Observable<ChatUser[]>(observer => {
			if (keyword) {
				this.http.post('chat://search-users', { keyword }).subscribe((users: ChatUser[]) => {
					observer.next(users);
					observer.complete();
				});
			} else {
				observer.next([]);
				observer.complete();
			}
		});
	}

	addMemberToChat(chatId: number, member: ChatUser) {
		this.http.post('chat://add-member', {chatId, member}).subscribe(() => {
			this.events.emit({
				type: ChatEventType.ADD_MEMBER_TO_CHAT,
				chatId,
				user: member
			});
		});
	}

	seenMessages = (chatId: number, unseenMessageIds: number[]) => {
		this.http.post('chat://seen-chat', {chatId, unseenMessageIds}).subscribe(() => { });
	}

	deleteChat = (chatId: number) => {
		this.http.post('chat://delete-chat', {chatId}).subscribe(() => {});
	}


}
