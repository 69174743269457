import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ComponentResponse} from "../../pages/components/list/component.response";

@Injectable()
export class SettingService {


	constructor(
		private http: HttpClient
	) {
	}

	getSettings(type):Observable<ComponentResponse> {
		return this.http.post<ComponentResponse>("/components/getSettings", {
			type: type
		});
	}
	setSettings(data):Observable<ComponentResponse> {
		return this.http.post<ComponentResponse>("/components/setSettings", data);
	}
	getEnv(key):Observable<ComponentResponse> {
		return this.http.post<ComponentResponse>("/components/getEnv", {
			key: key
		});
	}
}