import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";

@Injectable()
export class FinancesNewService {
	constructor(
		private http: HttpClient
	) {
	}

	private subject: Subject<any> = new Subject<any>();


	sendUpdateCall() {
		// @ts-ignore
		this.subject.next();
	}

	getUpdateCall(): Observable<any> {
		return this.subject.asObservable();
	}

	getDetails(studentId: number): Observable<any> {
		return this.http.post<any>("/students/getDetails", {studentId});
	}

	getStudentLearningYear(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/learningYears", data);
	}

	getStudentGraph(data): Observable<FinanceGraphResponse> {
		return this.http.post<FinanceGraphResponse>("/newFinance/student/getGraph", data);
	}

	saveStudentGraph(data): Observable<DefaultResponse> {
		return this.http.post<DefaultResponse>("/newFinance/student/saveGraph", data);
	}

	getLearningYears(): Observable<any> {
		return this.http.post<any>("/newFinance/getLearningYears", {});
	}

	createStudentCard(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/createCard", data);
	}

	updateItem(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/updateItem", data);
	}

	addStudentEvent(data): Observable<DefaultResponse> {
		return this.http.post<DefaultResponse>("/newFinance/student/addEvent", data);
	}

	disableEvent(data): Observable<DefaultResponse> {
		return this.http.post<DefaultResponse>("/newFinance/student/disableEvent", data);
	}

	getStudentEvents(data, page, limit): Observable<any> {
		return this.http.post<any>("/newFinance/student/getEvents/?page=" + page + "&limit=" + limit, data);
	}

	getUSDCurrency(): Observable<any> {
		return this.http.post<any>("/newFinance/getCurrency", {});
	}

	reCalculateCard(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/reCalculateCard", data);
	}

	getStudentBalance(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/getBalance", data);
	}

	updateFinanceVerification(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/updateFinanceVerification", data);
	}

	getStudentGrantEvents(data, page, limit): Observable<any> {
		return this.http.post<any>("/newFinance/student/getGrantEvents/?page=" + page + "&limit=" + limit, data);
	}

	addGrantEvent(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/addGrantEvent", data);
	}

	disableGrantEvent(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/disableGrantEvent", data);
	}

	addGrantTransferEvent(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/addGrantTransfer", data);
	}

	disableGrantTransfer(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/disableGrantTransfer", data);
	}

	addOrEditDiscount(data): Observable<any> {
		return this.http.post<any>("/newFinance/discount/addOrEdit", data);
	}

	getDiscounts(data, page, limit): Observable<any> {
		return this.http.post<any>("/newFinance/discount/get/?page=" + page + "&limit=" + limit, data);
	}

	deleteDiscount(data): Observable<any> {
		return this.http.post<any>("/newFinance/discount/delete", data);
	}

	addStudentDiscount(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/addDiscount", data);
	}

	getDiscountByName(data): Observable<any> {
		return this.http.post<any>("/newFinance/discount/getByName", data);
	}

	deleteStudentDiscount(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/deleteDiscount", data);
	}

	getStudentCredit(data, page, limit): Observable<any> {
		return this.http.post<any>("/newFinance/student/getCredits/?page=" + page + "&limit=" + limit, data);
	}

	getJobStatus(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/getJobStatus", data);
	}

	copyGraph(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/copyGraph", data);
	}

	getEvents(data, page, limit): Observable<any> {
		return this.http.post<any>("/newFinance/getEvents/?page=" + page + "&limit=" + limit, data);
	}

	assimilateEvents(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/assimilateEvents", data);
	}

	getStudentLogs(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/getStudentLogs", data);
	}

	grantTemplates(): Observable<any> {
		return this.http.post<any>("/newFinance/grantTemplates", {});
	}

	editEventDate(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/editDate", data);
	}

	reports(filter, page, limit): Observable<any> {
		return this.http.get<any>("/newFinance/reports/?page=" + page + "&limit=" + limit, filter);
	}

	generateReport(data): Observable<any> {
		return this.http.post("/newFinance/generateReport", data);
	}

	transferEvent(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/transferTransaction", data);
	}

	updateCreditOptions(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/credit/update", data);
	}

	getCreditOptions(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/credit/get", data);
	}

	checkCandidate(data): Observable<any> {
		return this.http.post<any>("/student/checkCandidate", data);
	}

	deleteStudentGraph(data): Observable<any> {
		return this.http.post<any>("/newFinance/student/deleteGraph", data);
	}

	addOrEditParameter(data): Observable<any> {
		return this.http.post<any>("/newFinance/parameter/addOrEdit", data);
	}

	deleteParameter(data): Observable<any> {
		return this.http.post<any>("/newFinance/parameter/delete", data);
	}

	parameterList(filter, page, limit): Observable<any> {
		return this.http.post("/newFinance/parameters/?page=" + page + "&limit=" + limit, filter);
	}

	meta(): Observable<any> {
		return this.http.get("/educationInfo/meta");
	}

	getRescheduleList(filter, page, limit): Observable<any> {
		return this.http.post("/newFinance/reschedule/list/?page=" + page + "&limit=" + limit, filter);
	}

	addReschedule(data): Observable<any> {
		return this.http.post("/newFinance/reschedule/add", data);
	}

	removeReschedule(data): Observable<any> {
		return this.http.post("/newFinance/reschedule/remove", data);
	}

	reverseGraph(data): Observable<any> {
		return this.http.post("/newFinance/student/reverseGraph", data);
	}

	cancelCredit(data): Observable<any> {
		return this.http.post("/newFinance/student/cancelCredit", data);
	}

	refreshEvent(data): Observable<any> {
		return this.http.post("/newFinance/student/refreshEvent", data);
	}

	getStudentCards(data): Observable<any> {
		return this.http.post("/newFinance/student/getCards", data);
	}

}

// noinspection JSUnusedGlobalSymbols
export const LearningYears = [
	{id: 1, value: '2019/2020'},
	{id: 2, value: '2020/2021'},
	{id: 3, value: '2021/2020'},
];

export interface IDiscount {
	id: number;
	studentId: number;
	cardId: number;
	templateId: number;
	learningSeasonId: number;
	amount: number;
	name: string;
	yearCount: number;
}

export interface FinanceGraph {
	id: number;
	season: string;
	learningSeasonId: number;
	name: string;
	studentId: number;
	price: number;
	stateGrant: number;
	stateGrantTransferred: number;
	stateGrantLeft: number;
	stateGrantTotal: number;
	socGrant: number;
	socGrantTransferred: number;
	socGrantLeft: number;
	socGrantTotal: number;
	otherGrant: number;
	otherGrantTransferred: number;
	otherGrantLeft: number;
	otherGrantTotal: number;
	discountTotal: number;
	remaining: number;
	comment: string;
	creditPrice: number;
	creditPriceFailed: number;
	creditPriceMobility: number;
	items: FinanceGraphItems[];
	stat: any;
	discounts: IDiscount[];
	stateGrantId: "",
	socGrantId: "",
	otherGrantId: "",
	otherGrantName: "",
	otherGrantNameId: "",
	nextSeason: number;
	accrual_type?: number;
	accrual_option?: number;
	grant_status?: number;
}

export interface FinanceGraphItems {
	id: number;
	month: string;
	paymentDate: string;
	season: string;
	startBalance: number;
	price: number;
	discount: number;
	credit: number;
	other: number;
	remaining: number;
	transaction: number;
	withdrawal: number;
	lastBalance: number;
	remainingPreview: number;
	studentStatus: string;
	statusComment: number;
	day: number;
	discountComment: any;
	startBalanceComment: any;
	priceComment: any;
	creditComment: any;
	otherComment: any;
	debt: any;
	grantAmount: any;
	grantPrice: any;
	isGrantPay: any;
	rawPrice: any;
	showGrantPayColor: any;
	accrual?: number;
	balance2?: number;
	balance3?: number;
}

export interface FinanceGraphResponse {
	result: string;
	stat: any;
	studentDebt: any;
	currency: any;
	data: FinanceGraph;
}

export interface DefaultResponse {
	result: string;
	data: FinanceGraph;
	errors: string[];
	error: string;
}

export const Seasons = [
	{value: '', name: ''},
	{value: 'autumn', name: 'საშემოდგომო'},
	{value: 'spring', name: 'საგაზაფხულო'},
];

export const Months = {
	1: 'იანვარი',
	2: 'თებერვალი',
	3: 'მარტი',
	4: 'აპრილი',
	5: 'მაისი',
	6: 'ივნისი',
	7: 'ივლისი',
	8: 'აგვისტო',
	9: 'სექტემბერი',
	10: 'ოქტომბერი',
	11: 'ნოემბერი',
	12: 'დეკემბერი'
};

export const StudentStatuses = [
	{value: '', name: ''},
	{value: 'active', name: 'აქტიური'},
	{value: 'stop', name: 'შეწყვეტილი'},
	{value: 'pause', name: 'შეჩერებული'},
	{value: 'end', name: 'კურსდამთავრებული'},
];
