import {Pipe} from "@angular/core";
import moment from "moment";

@Pipe({
  name: 'amTimeAgo'
})
export class AmTimeAgoPipe {

    transform(value: Date | moment.Moment): any {

      const seconds = moment().diff(value, 'seconds');
      if (seconds <= 60) {
        return '1m';
      }
      const minutes = moment().diff(value, 'minutes');
      if (minutes <= 60) {
        return minutes + 'm';
      }
      const hours = moment().diff(value, 'hours');
      if (hours <= 24) {
        return hours + 'h';
      }
      const days = moment().diff(value, 'days');
      if (days <= 7) {
        return days + 'd';
      }
      const weeks = moment().diff(value, 'weeks');
      if (weeks <= 4) {
        return weeks + 'w';
      }
      const months = moment().diff(value, 'months');
      if (months <= 12) {
        return months + 'mo';
      }
      const years = moment().diff(value, 'years');
      return years + 'y';
    }

}
