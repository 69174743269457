import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {
    EducationInfo,
    EducationInfoMetaResponse,
    EducationInfoResponse, LangCode,
    LangCodesResponse
} from "./education-info-list.models";
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable()
export class EducationInfoListService {

    constructor(
        private http: HttpClient
    ) {}

    items(form: any): Observable<EducationInfo[]> {
        return this.http.post<EducationInfoResponse>("/educationInfo/list", form)
          .pipe(
            map(response => response.data)
          );
    }

    add(form: EducationInfo): Observable<EducationInfoResponse> {
        return this.http.post<EducationInfoResponse>("/educationInfo/add", form);
    }

    edit(form: EducationInfo): Observable<EducationInfoResponse> {
        return this.http.post<EducationInfoResponse>("/educationInfo/edit", form);
    }

    delete(id: number): Observable<EducationInfoResponse> {
        return this.http.post<EducationInfoResponse>("/educationInfo/delete", {
            id
        });
    }

    getMeta(): Observable<EducationInfoMetaResponse> {
        return this.http.get<EducationInfoMetaResponse>("/educationInfo/meta");
    }

    editAdditionalInfo(form: EducationInfo): Observable<EducationInfoResponse> {
        return this.http.post<EducationInfoResponse>("/educationInfo/editAdditional", form);
    }

	studentSync(form: any): Observable<EducationInfoResponse> {
        return this.http.post<EducationInfoResponse>("/educationInfo/studentSync", form);
    }

    getLangCodes(): Observable<LangCode[]> {
        return this.http.get<LangCodesResponse>("/educationInfo/langCodes")
          .pipe(
            map(response => response.data)
          );
    }

	getGrantTemplates(): Observable<any> {
		return this.http.get<any>("/finance/grant/getTemplates");
	}

	// getConcessionTemplates(): Observable<any> {
	// 	return this.http.get<any>("/finance/concession/getTemplates");
	// }

	getAgents(): Observable<any> {
    	return this.http.get<any>('/students/agents');
	}

	getMetaForCalendarProfUsers(): Observable<any> {
		return this.http.post<any>('/lists/getMetaForCalendarProfUsers',{});
	}

}
