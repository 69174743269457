import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ComponentResponse} from "./list/component.response";

@Injectable()
export class ComponentService {

    debugEvents: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private http: HttpClient
    ){

    }

    getComponents(filter, page, limit):Observable<ComponentResponse> {
        return this.http.post<ComponentResponse>("/components/list/?page="+page+"&limit="+limit, filter)
    }

    addOrEditComponent(data):Observable<ComponentResponse> {
        return this.http.post<ComponentResponse>("/components/addOrEdit", data);
    }

    getAllComponents():Observable<ComponentResponse> {
        return this.http.get<ComponentResponse>("/components/getAllComponents");
    }

    getScripts(data):Observable<ComponentResponse> {
        return this.http.post<ComponentResponse>("/components/getScripts", data);
    }

    getComponentData(data):Observable<ComponentResponse> {
        return this.http.post<ComponentResponse>("/components/getComponentData", data);
    }

    addOrEditFormula(data):Observable<ComponentResponse> {
        return this.http.post<ComponentResponse>("/components/addOrEditFormula", data);
    }

    setComponentData(data):Observable<ComponentResponse> {
        return this.http.post<ComponentResponse>("/components/setComponentData", data);
    }

	getSettings(type):Observable<ComponentResponse> {
        return this.http.post<ComponentResponse>("/components/getSettings", {
        	type: type
        });
    }
    setSettings(data):Observable<ComponentResponse> {
        return this.http.post<ComponentResponse>("/components/setSettings", data);
    }



}
