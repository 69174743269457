import {Injectable} from "@angular/core";
import {ErrorsDialog} from "../error-dialogs/errors.dialog";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class AlertService {
	constructor(
		public _snakbar: MatSnackBar,
		private error: ErrorsDialog
	) {
	}

	_alert(msg) {
		this.error.show({
			result: "no",
			error: msg
		});
	}

	required() {
		this._alert("გთხოვთ შეავსოთ სავალდეულო ველები");
	}

	success() {
		alert("მონაცემები წარმატებით შეინახა");
	}

	snakbar(message) {
		this._snakbar.open(message, '', {
			duration: 2000,
		});
	}

	snakbarSuccess() {
		let text = 'მონაცემები წარმატებით შეინახა';
		this._snakbar.open(text, '', {
			duration: 2000,
		});
	}

	snakbarRemove() {
		let text = 'მონაცემები წარმატებით წაიშალა';
		this._snakbar.open(text, '', {
			duration: 2000,
		});
	}

	invalidForm() {
		this._alert("გთხოვთ შეავსოთ სავალდებულო ველები");
	}

	errorsMulty(errors,kaError=null)
	{
		let ka = kaError != null ? kaError : {};
		let errorMsg = "გთხოვთ შეავსოთ ველები: <br>";
		for (let error of Object.keys(errors)){
			let err = errors[error];
			let kaMsg = ka[error];
			errorMsg += "<li>"+kaMsg+"</li>";
		}
		this._alert(errorMsg);
	}
}