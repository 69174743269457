import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Program} from "../structure/specials/program/program";
import {Student} from "../students/students.models";
import {ProgramData} from "../structure/specials/program/program-view/program.data";
import {StudentView} from "../students/student-details/result/student.view";
import {map} from "rxjs/operators";

@Injectable()
export class RegistrationService {

	days = ["ორშაბათი", "სამშაბათი", "ოთხშაბათი", "ხუთშაბათი", "პარასკევი", "შაბათი", "კვირა"];
	daysEn = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

	constructor(
		private http: HttpClient
	) {

	}

	// Groups
	getAllActiveGroups():Observable<any> {
		return this.http.post<any>("/registration/group/getActive",{});
	}
	groups(filter:any, page: Number, limit: number):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/group/list/?page="+page+"&limit="+limit, filter);
	}
	addOrEditGroup(data: any):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/group/addOrEdit", data);
	}
	removeGroup(groupId:number):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/group/remove", {
			groupId: groupId
		});
	}
	getPrograms():Observable<RegisterResponse> {
		return this.http.get<RegisterResponse>("/registration/group/programs");
	}


	// Auditoriums
	auditoriums(filter:any, page: Number, limit: number):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/auditorium/list/?page="+page+"&limit="+limit, filter);
	}
	addOrEditAuditorium(data: any):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/auditorium/addOrEdit", data);
	}
	removeAuditorium(id:number):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/auditorium/remove", {
			groupId: id
		});
	}
	getAirAuditories(data: any):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/auditorium/getAirAuditories", data);
	}
	removeAirAuditory(data: any):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/auditorium/removeAirAuditory", data);
	}

	// Books
	getBooks(filter:any, page: Number, limit: number):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/book/list/?page="+page+"&limit="+limit, filter);
	}
	getSeminarOrLecture(filter:any, page: Number, limit: number):Observable<RegisterResponse> {
		filter.registrationGroupId = filter.groupId
		return this.http.post<RegisterResponse>("/registration/book/seminarOrLecture/?page="+page+"&limit="+limit, filter);
	}
	getListSeminarOrLecture(filter:any, page: Number, limit: number):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/book/getListSeminarOrLecture/?page="+page+"&limit="+limit, filter);
	}
	addOrEditBookGroup(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/book/addOrEdit", data);
	}
	removeBookGroup(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/book/remove", data);
	}
	removeAdditionalGroup(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/book/removeAdditionalGroup", data);
	}
	addBook(data) {
		return this.http.post<RegisterResponse>("/registration/book/addBook", data);
	}
	removeBook(data) {
		return this.http.post<RegisterResponse>("/registration/book/removeBook", data);
	}
    sendSms(data):Observable<RegisterResponse> {
	    return this.http.post<RegisterResponse>("/sms/sendArchGroupSms", data);
    }

	// Registration
	getStudentInfo(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/student/getInfo", data);
	}
	getStudentData(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/student/getData", data);
	}
	getStudentMiniData(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/student/getMiniData", data);
	}
	getProgramData(data):Observable<ProgramData> {
		return this.http.post<ProgramData>("/programs/get", data);
	}
	getBookResults(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/programs/getBookResults", data);
	}
	books: any = {
		registeredBooks: [],
		credit: 0
	};
	onReadRegistrationBooks: EventEmitter<string> = new EventEmitter<string>();
	getRegistrationBooks(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/student/getRegistrationBooks", data).pipe(map(e => {
			return this.parseRegisteredBooks(e);
		}));
	}
	getRegistrationBooks2(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/student/getRegistrationBooks2", data).pipe(map(e => {
			return this.parseRegisteredBooks(e);
		}));
	}
	parseRegisteredBooks(e) {
		if (typeof e.items != "undefined") {
			this.books.registeredBooks = e.items;
			this.books.credit = 0;
			for (let item of this.books.registeredBooks) {
				this.books.credit += item.book.credit;
			}
			this.onReadRegistrationBooks.emit("read");
		}
		return e;
	}
	register(data) {
		return this.http.post<RegisterResponse>("/registration/student/register", data);
	}
	cancelRegistration(data) {
		return this.http.post<RegisterResponse>("/registration/student/cancelRegistration", data);
	}
  cancelRegistrationInGroup(data) {
    return this.http.post<RegisterResponse>("/registration/student/cancelRegistrationInGroup", data);
  }
	checkConditions(data) {
		return this.http.post<RegisterResponse>("/registration/student/checkConditions", data);
	}
	getStudentList(page, limit, filter) {
		return this.http.post<RegisterResponse>("/registration/student/getStudentList/?page="+page+"&limit="+limit, filter);
	}
	getAllSelectablePrograms() {
		return this.http.get<RegisterResponse>("/registration/getAllSelectablePrograms");
	}
	getConcentrationInfo(data) {
		return this.http.post<RegisterResponse>("/registration/getConcentrationInfo", data);
	}
	setConcentration(data) {
		return this.http.post<RegisterResponse>("/registration/setConcentration", data);
	}

	getStudentLimits(filter, page, limit) {
		return this.http.post<RegisterResponse>("/registration/getStudentLimits/?page="+page+"&limit="+limit, filter);
	}

	removeStudentLimits(data) {
		return this.http.post<RegisterResponse>("/registration/removeStudentLimits", data);
	}
	addOrStudentLimit(data) {
		return this.http.post<RegisterResponse>("/registration/addOrStudentLimit", data);
	}

	getBookGroupBrothers(filter, page, limit) {
		return this.http.post<RegisterResponse>("/registration/getBookGroupBrothers/?page="+page+"&limit="+limit, filter);
	}

	addBookGroupBrother(data) {
		return this.http.post<RegisterResponse>("/registration/addBookGroupBrother", data);
	}

	removeBookGroupBrother(data) {
		return this.http.post<RegisterResponse>("/registration/removeBookGroupBrother", data);
	}

	getStudentList2(data, page, limit) {
		return this.http.post<RegisterResponse>("/registration/student/getStudentList2/?page="+page+"&limit="+limit, data);
	}

	exportStudentList(data) {
		return this.http.post<RegisterResponse>("/registration/student/exportStudentList", data);
	}

	getTables(filter, page, limit) {
		return this.http.post<RegisterResponse>("/registration/tables/get/?page="+page+"&limit="+limit, filter);
	}

	addRegistrationTable(data) {
		return this.http.post<RegisterResponse>("/registration/tables/addOrEdit", data);
	}

	getAuditoriums() {
		return this.http.get<RegisterResponse>("/registration/tables/getAuditoriums");
	}

	removeRegistrationTable(data) {
		return this.http.post<RegisterResponse>("/registration/tables/remove", data);
	}
	getHistory(data) {
		return this.http.post<RegisterResponse>("/registration/history/get", data);
	}
	getSubBooks(listId):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/registration/books/getSubBooks", {
			listId: listId
		});
	}
	exportGroupToExcel(data):Observable<RegisterResponse> {
		return this.http.post<RegisterResponse>("/student/exportGroupToExcel", data);
	}

	getEvents(data):Observable<any[]> {
		return this.http.post<any[]>("/registration/getEvents", data);
	}

	// Statistic

  getStatisticItems(data){
    return this.http.post<any>("/registration/statistic/getStatisticItems", data);
  }

  getBookProfs(book_id){
    return this.http.post<any>("/registration/statistic/getBookProfs", {
    	book_id: book_id
		});
  }

	getAdditionalGroups(data){
		return this.http.post<any>("/registration/statistic/getAdditionalGroups", data);
	}

	getBookSeminars(data){
		return this.http.post<any>("/registration/statistic/getBookSeminars", data);
	}

	getAllGroupTags(){
		return this.http.get<any>("/registration/statistic/getAllGroupTags");
	}

	getBookGroupMeta(data){
		return this.http.post<any>("/registration/statistic/getBookGroupMeta",data);
	}

	getUsedGroupTags(data)
	{
		return this.http.post<any>("/registration/statistic/getUsedGroupTags",data);
	}

	saveForCalendar(data){
		return this.http.post<any>("/registration/statistic/saveForCalendar", data);
	}

	removeForCalendar(data){
		return this.http.post<any>("/registration/statistic/removeForCalendar", data);
	}

	addRemovedCalendar(data){
		return this.http.post<any>("/registration/statistic/addRemovedCalendar", data);
	}

	getBookGroupLogs(data){
		return this.http.post<any>("/registration/statistic/getBookGroupLogs", data);
	}

	updateAllGroupStatus(data){
		return this.http.post<any>("/registration/statistic/updateAllGroupStatus", data);
	}

  // fullBooks

  getFullBooks(data){
    return this.http.post<any>("/registration/fullBooks/getFullBooks", data);
  }

	getFullBooksFolders(data){
		return this.http.post<any>("/registration/fullBooks/getFullBooksFolders", data);
	}

  getAuditoriumsForTable(day,h,m,groupId): Observable<any[]> {
    return this.http.post<any[]>("/registration/book/getAuditoriumsForTable", {
      day: day,
      h: h,
      m: m,
      groupId: groupId,
    });
  }

	getFreshmanBooks(filter:any, page: Number, limit: number):Observable<any> {
		return this.http.post<any>("/registration/group/getFreshmanList/?page="+page+"&limit="+limit, filter);
	}

	getBookByGroupId(data): Observable<any> {
		return this.http.post<any>("/registration/group/getBookByGroup", data);
	}

	addFreshmanBooks(data): Observable<any> {
		return this.http.post<any>("/registration/group/addFreshmanBook",data);
	}

	removeFreshmanBook(data): Observable<any> {
		return this.http.post<any>("/registration/group/removeFreshmanBook", data);
	}

	getSpecials(): Observable<any> {
		return this.http.get<any>("/registration/group/getSpecials");
	}

	getFreshmanStudents(filter:any, page: Number, limit: number):Observable<any> {
		return this.http.post<any>("/registration/group/getFreshmanStudents/?page="+page+"&limit="+limit, filter);
	}

	addFreshmanStudents(data): Observable<any> {
		return this.http.post<any>("/registration/group/addFreshmanStudents",data);
	}

	deleteFreshmanStudent(data): Observable<any> {
		return this.http.post<any>("/registration/group/deleteFreshmanStudent",data);
	}

	// studentFailedBooks

  getStudentFailedBooks(filter, page, limit) {
    return this.http.post<RegisterResponse>("/registration/getStudentFailedBooks/?page="+page+"&limit="+limit, filter);
  }

  removeStudentFailedBooks(data) {
    return this.http.post<RegisterResponse>("/registration/removeStudentFailedBooks", data);
  }
  addOrStudentFailedBooks(data) {
    return this.http.post<RegisterResponse>("/registration/addOrStudentFailedBooks", data);
  }

  // praktika prof
  getRegisterBookPraktikaProf(data) {
    return this.http.post<RegisterResponse>("/registration/book/getRegisterBookPraktikaProf", data);
  }
  addRegisterBookPraktikaProf(data) {
    return this.http.post<RegisterResponse>("/registration/book/addRegisterBookPraktikaProf", data);
  }
  removeRegisterBookPraktikaProf(data) {
    return this.http.post<RegisterResponse>("/registration/book/removeRegisterBookPraktikaProf", data);
  }

  setBookGroupStatus(data){
    return this.http.post<RegisterResponse>("/registration/book/setBookGroupStatus", data);
	}

  checkProfNotIsMasterStatus(data){
    return this.http.post<any>("/registration/book/checkProfNotIsMasterStatus", data);
  }

	// medicTables

	getMedicTableFolders(filter, page, limit) {
		return this.http.post<RegisterResponse>("/registration/medicTables/getMedicTableFolders?page="+page+"&limit="+limit, filter);
	}

	addMedicTableFolder(data) {
		return this.http.post<RegisterResponse>("/registration/medicTables/addMedicTableFolder", data);
	}

	removeMedicTableFolder(data) {
		return this.http.post<RegisterResponse>("/registration/medicTables/removeMedicTableFolder", data);
	}

	addMedicTableStudents(data) {
		return this.http.post<RegisterResponse>("/registration/medicTables/addMedicTableStudents", data);
	}

	getMedicTableStudents(filter, page, limit) {
		return this.http.post<RegisterResponse>("/registration/medicTables/getMedicTableStudents?page="+page+"&limit="+limit, filter);
	}

	removeMedicTableStudent(data) {
		return this.http.post<RegisterResponse>("/registration/medicTables/removeMedicTableStudent", data);
	}

	getRegistrationGroups(filter, page, limit) {
		return this.http.post<RegisterResponse>("/registration/medicTables/getRegistrationGroups?page="+page+"&limit="+limit, filter);
	}

	addMedicTableGroup(data) {
		return this.http.post<RegisterResponse>("/registration/medicTables/addMedicTableGroup", data);
	}

	getRegistrationMedicGroups(filter, page, limit) {
		return this.http.post<RegisterResponse>("/registration/medicTables/getRegistrationMedicGroups?page="+page+"&limit="+limit, filter);
	}

	removeMedicTableGroup(data) {
		return this.http.post<RegisterResponse>("/registration/medicTables/removeMedicTableGroup", data);
	}

	getSpecialBooks(data: any, page: number, limit: number): Observable<any> {
		return this.http.post<any>(`/registration/special-books/list?page=${page}&limit=${limit}`, data);
	}

	addOrEditSpecialBook(data): Observable<any> {
		return this.http.post<any>("/registration/special-books/addOrEdit", data);
	}

	removeSpecialBook(data): Observable<any> {
		return this.http.post<any>("/registration/special-books/remove", data);
	}

	getSpecialBookStudents(data: any, page: number, limit: number): Observable<any> {
		return this.http.post<any>(`/registration/special-books/students/list?page=${page}&limit=${limit}`, data);
	}

	removeSpecialBookStudent(data): Observable<any> {
		return this.http.post<any>("/registration/special-books/student/remove", data);
	}

	addSpecialBookStudent(data): Observable<any> {
		return this.http.post<any>("/registration/special-books/student/add", data);
	}

	getSpecialBooksSelection(data): Observable<any> {
		return this.http.post<any>("/registration/special-books/student/getSelection", data);
	}

	// groupTags
	getGroupTags(data: any, page: number, limit: number): Observable<any> {
		return this.http.post<any>(`/registration/getGroupTags/list?page=${page}&limit=${limit}`, data);
	}

	addGroupTag(data): Observable<any> {
		return this.http.post<any>("/registration/getGroupTags/add", data);
	}

	removeGroupTag(data): Observable<any> {
		return this.http.post<any>("/registration/getGroupTags/remove", data);
	}

	clearCache(data): Observable<any> {
		return this.http.post<any>("/registration/group/clearCache", data);
	}


}
export interface RegisterResponse {
	result: string;
	data: any[] | any;
	items: any | any[];
	error: any;
	errors: any[];
	total: number;
	program: Program;
	student: Student;
	view: StudentView;
	link: string;
	info: any;
}
