import {Component, forwardRef, Input} from "@angular/core";
import { NG_VALUE_ACCESSOR} from "@angular/forms";
import {IniInput} from "./ini.input";
import {Observable} from "rxjs";
import {AutocompleteNamesService} from "./autocomplete-names.service";

@Component({
    selector: "book-input",
    templateUrl: "./auto-complete-book.html",
    providers:[{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => BookInput),
        multi: true
    }]
})
export class BookInput extends IniInput { /**/

	@Input("placeholder") override placeholder = "";
	@Input("type") override type = "old";
	@Input("lang") override lang: string = "ka";
	@Input("onlyActive") onlyActive: boolean = false;
	@Input('multiple') override multiple = false;

    constructor(
        private service: AutocompleteNamesService
    ) {
        super();
    }

	override search(q: string): Observable<any[]> {
        return this.service.getBookNames(q, this.lang, this.onlyActive ? 'active' : '');
    }

}
