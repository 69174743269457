import {Component, Inject, OnInit} from "@angular/core";
import {BooksService} from "../books.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {Book} from "../book";
import {ConfirmDialog} from "../../../theme/components/confirmDialog/confirm.dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: "rule-students-dialog",
	templateUrl: "./rule-students-dialog.html",
	styleUrls: ["./rule-students-dialog.scss"]
})
export class RuleStudentsDialog implements OnInit {

	items = [];
	displayedColumns = [
		"studentId",
	];
    form: FormGroup;


	constructor(
		private bookService: BooksService,
		@Inject(MAT_DIALOG_DATA) public book: Book,
		private dialog: MatDialog,
        private fb: FormBuilder,
        private error: ErrorsDialog,
	) {
	    this.form = fb.group({
	        studentIds: "",
		    bookId:this.book.id
	    });
	}

	add()
	{
		this.bookService.addRuleStudents(this.form.value)
		.subscribe(response => {
			if(response.result == 'yes'){
                this.getItems();
			}else{
                this.error.show(response);
			}
		})
	}



	clear() {
		const ref = this.dialog.open(ConfirmDialog, {
			data: {
				title: "პროფესორის წაშლა",
				text: "ნამდვილად გსურთ გასუფთავება",
				ok: "დიახ",
				cancel: "არა"
			}
		});
		ref.afterClosed().subscribe(result => {
			if (result) {
				this.bookService.clearRuleStudents({bookId:this.book.id}).subscribe(response => {
					this.getItems();
				});
			}
		});
	}

	getItems() {
		this.bookService.getRuleStudents({bookId:this.book.id}).subscribe(response => {
			this.items = response;
		});
	}

	ngOnInit(): void {
		this.getItems();
	}

}