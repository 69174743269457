import {Component, Inject, OnInit, AfterViewInit, OnDestroy} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StudentsService} from "../../students.service";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Student} from "../../students.models";
import {StudentCommand} from "../student.command";
import {IniDatePickerHeader} from "../../../../core/components/ini-date-picker/ini-date-picker-header";

@Component({
	selector: "command-student-status",
	templateUrl: "./command-student-status.html"
})
export class CommandStudentStatus implements OnInit, AfterViewInit, OnDestroy {

	subs = [];
	calledFrom = '';
	studentId = 0;
	programs = [];
	showSkip = true;
	disableSaveButton: boolean = false;

	constructor(
		private ref: MatDialogRef<CommandStudentStatus>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private service: StudentsService,
		private error: ErrorsDialog,
		private fb: FormBuilder
	) {
		this.form = fb.group({
			programId: ["",],
			status: ["", Validators.required],
			category: ["", Validators.required],
			startDate: ["", Validators.required],
			execDate: ["", Validators.required],
			commandNumber: "",
			description: "",
			skipAction: false,
			applyOnAll: false,
			additionalStatus: "",
			onlyStatusChange:false
		});
		if (typeof this.data == "undefined" || this.data == null) {
			this.data = {};
		}
		if (typeof this.data.command == "undefined" || this.data.command == null) {
			this.data.command = {};
		}

		if (this.data['calledFrom'] == 'studentDetails') {
			this.studentId = this.data.students[0]
			this.calledFrom = 'studentDetails';
			this.showSkip = true;
			this.getPrograms();
		}

		if (this.data['calledFrom'] != '') {
			this.calledFrom = data['calledFrom'];
		}

		data.command.skipAction = false;
		data.command.onlyStatusChange = false;
		// this.execDate_y.setValue('');
		// this.execDate_m.setValue('');
		// this.execDate_d.setValue('');
		//
		// this.startDate_y.setValue('');
		// this.startDate_m.setValue('');
		// this.startDate_d.setValue('');


		for (let sub of [
			this.startDate_d,
			this.startDate_m,
			this.startDate_y
		]) {
			this.subs.push(
				sub.valueChanges.subscribe(() => {
					this.setDate('startDate');
				})
			);
		}



		for (let sub of [
			this.execDate_d,
			this.execDate_m,
			this.execDate_y
		]) {
			this.subs.push(
				sub.valueChanges.subscribe(() => {
					this.setDate('execDate');
				})
			);
		}


		if (data.mode == 'edit') {
			if (typeof data.command.startDate != "undefined" && data.command.startDate != null) {
				const startDates = data.command.startDate.split('-');
				this.startDate_d.setValue(startDates[2]);
				this.startDate_m.setValue(startDates[1]);
				this.startDate_y.setValue(startDates[0]);

			}

			if (typeof data.command.execDate != "undefined" && data.command.execDate != null) {
				const execDate = data.command.execDate.split('-');
				this.execDate_d.setValue(execDate[2]);
				this.execDate_m.setValue(execDate[1]);
				this.execDate_y.setValue(execDate[0]);
			}

		}


		this.form.patchValue({
			execDate: "AAAAAAAAA"
		});

		this.form.get('onlyStatusChange').valueChanges.subscribe((value) =>{
			if(value){
				this.form.get('startDate').clearValidators();
				this.form.get('execDate').clearValidators();
				this.form.get('category').clearValidators();

				this.form.patchValue({
					skipAction:false
				})
			}else{
				this.form.get('startDate').setValidators(Validators.required);
				this.form.get('execDate').setValidators(Validators.required);
				this.form.get('category').setValidators(Validators.required);
			}

			this.form.get("startDate").updateValueAndValidity();
			this.form.get("execDate").updateValueAndValidity();
			this.form.get("category").updateValueAndValidity();
			data.command.onlyStatusChange = this.form.get('onlyStatusChange').value;
		});

	}

	errors(group: FormGroup) {
		let e = [];
		for (let key in group.value) {
			if (group.get(key).errors != null) {
				let error = {};
				error[key] = group.get(key).errors;
				e.push(error);
			}
		}
		return e;
	}


	nm(n) {
		try {
			if(parseInt(n) < 10) {
				return "0"+parseInt(n);
			}
		} catch (e) {}
		return n;
	}

	setDate(name) {
		const value = [
			this[name + '_y'].value,
			this.nm(this[name + '_m'].value),
			this.nm(this[name + '_d'].value)
		].join("-");
		this.form.patchValue({
			[name]: value
		});
		this.data.command[name] = value;
	}

	form: FormGroup;

	iniDatePickerHeader = IniDatePickerHeader;

	commandStatuses = Student.eduStatuses();
	additionalStatuses = Student.additionalStatuses();
	commandCategory = [];

	commands = StudentCommand.categories();

	cancel() {
		if (this.calledFrom == 'journalForm') this.ref.close(false);
		this.ref.close(true);
	}

	ok() {
		this.disableSaveButton = true;

		if (this.calledFrom == 'journalForm') {

			let students = this.data.students;
			let command = this.data.command;
			let mode = this.data.mode;

			this.ref.close({students, command: command, mode: mode});
			return;
		}

		this.service.commandEditStatus(this.data.students, this.data.command,this.data.mode).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}

			this.disableSaveButton = false;
		}, (error) => {
			console.error(error);
			this.disableSaveButton = false;
		});
	}

	updateCategory() {
		const index = this.form.value.status;
		if (typeof this.commands[index] != "undefined") {
			this.commandCategory = this.commands[index];
		}
	}

	ngOnInit(): void {
		this.form.patchValue({
			status: '',
			category: '',
			startDate: '',
			execDate: '',
			commandNumber: '',
			description: ''
		});
	}

	ngAfterViewInit(): void{
		setTimeout(() => {
			this.updateCategory();
		});
	}


	startDate_d: FormControl = new FormControl();
	startDate_m: FormControl = new FormControl();
	startDate_y: FormControl = new FormControl();

	execDate_d: FormControl = new FormControl();
	execDate_m: FormControl = new FormControl();
	execDate_y: FormControl = new FormControl();


	ngOnDestroy(): void {
		for (let sub of this.subs) {
			try {
				sub.unsubscribe();
			} catch (e) {}
		}
	}

	getPrograms(): void {
		this.service.getStudentPrograms({studentId: this.studentId})
			.subscribe(response => {
				this.programs = response;

				if (this.programs.length > 1) {
					this.data.command.programId = this.programs[0].id;
				}
			});
	}

}
