import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import {
	Region,
	RegionResponse,
	Student, StudentDataResponse,
	StudentFilter,
	StudentResponse
} from "./students.models";
import {RegistrantAllowResponse} from "../school-leavers/school-leavers.service";


@Injectable()
export class StudentsService {

	constructor(
		private http: HttpClient
	) {
	}

	onChange: EventEmitter<string> = new EventEmitter();

	calculateStudentCards(data: any): Observable<StudentResponse> {
		return this.http.post<any>('/newFinance/student/calculateBulkCard', data);
	}

	list(filter: StudentFilter, page: number, limit: number): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/list?page=" + page + "&limit=" + limit, filter);
	}

	listUni(filter: StudentFilter, page: number, limit: number): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/listUni/?page=" + page + "&limit=" + limit, filter);
	}

	add(form: Student, ignoreValidations: boolean): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/add", {...form, ignoreValidations});
	}

	edit(form: Student, ignoreValidations: boolean): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/edit", {...form, ignoreValidations});
	}

	delete(id: number): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/delete", {
			id
		});
	}

	regions(): Observable<Region[]> {
		return this.http.get<RegionResponse>("/region/list").pipe(
			map(response => response.data)
		);
	}

	get(studentId: number): Observable<Student> {
		return this.http.post<StudentDataResponse>("/students/get", {
			studentId
		}).pipe(
			map(response => response.data)
		);
	}

	getData(studentId: number): Observable<any> {
		return this.http.post<any>("/students/get", {
			studentId
		});
	}

	getDataWithEducation(studentId: number, eduId: number): Observable<any> {
		return this.http.post<any>("/students/get", {
			eduId,
			studentId
		});
	}

	getDetails(studentId: number): Observable<any> {
		return this.http.post<StudentDataResponse>("/students/getDetails", {
			studentId
		});
	}

	getDetailsForContract(studentId: number): Observable<any> {
		return this.http.post<StudentDataResponse>("/students/getDetailsForContract", {
			studentId
		});
	}

	exportToExcel(filter: StudentFilter): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/export", filter);
	}

	getFilteredStudents(data, page, limit): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/getFilteredStudents/?page=" + page + "&limit=" + limit, data);
	}

	getStudentSelection(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/getStudentSelection", data);
	}

	commandEditStatus(students, form, mode): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/commandEditStatus", {
			students,
			data: form,
			mode: mode
		});
	}

	deleteCommand(id): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/deleteCommand", {
			id: id
		});
	}

	getCommands(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/students/getCommands", data);
	}

	getStudentStatements(filter, page, limit) {
		return this.http.post<StudentResponse>("/students/getStudentStatements/?page=" + page + "&limit=" + limit, filter);
	}

	getEducations(data) {
		return this.http.post<StudentResponse>("/registration/getEducations", data);
	}

	getStudentTables(data) {
		return this.http.post<StudentResponse>("/registration/getStudentTables", data);
	}

	getProfTables(data) {
		return this.http.post<StudentResponse>("/registration/getProfTables", data);
	}

	getActions(filter, page, limit): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/student/actions/?page=" + page + "&limit=" + limit, filter);
	}

	addAction(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/student/addActions", data);
	}

	getSendedSmsList(filter, page, limit): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/student/getSendedSmsList/?page=" + page + "&limit=" + limit, filter);
	}

	sendSmsOnlyStudent(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/student/sendSmsOnlyStudent", data);
	}

	sendAuthParamsToStudent(data: number[]): Observable<any> {
		return this.http.post<any>("/students/sendAuthParamsToStudent", data);
	}

	studentSmsConfirmed(data): Observable<any> {
		return this.http.post<any>("/student/confirmSms", data);
	}

	smsIdentificationLogs(data, page, limit): Observable<any> {
		return this.http.post("/student/smsIdentificationLogs/?page=" + page + "&limit=" + limit, data);
	}

	importStudents(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/student/import", data);
	}

	parseExcel(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/student/parseExcel", data);
	}

	getTokenUrl(): Observable<StudentResponse> {
		return this.http.get<StudentResponse>("/googleService/getTokenUrl");
	}

	makeBatchMail(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/googleService/makeBatchMail", data);
	}

	getJobStatus(item): Observable<any> {
		return this.http.post<any>("/testing/getExamExportStatus", item);
	}

	setIPAddress(data): Observable<any> {
		return this.http.post<any>("/student/setIPAddress", data);
	}

	exportGroupToExcel(data): Observable<any> {
		return this.http.post<any>("/student/exportGroupToExcel", data);
	}

	resetAllData(data): Observable<any> {
		return this.http.post<any>("/student/resetAllData", data);
	}

	addReference(data): Observable<any> {
		return this.http.post<any>("/student/addReference", data);
	}

	updateReference(data): Observable<any> {
		return this.http.post<any>("/student/updateReference", data);
	}

	getReferenceInfo(data): Observable<any> {
		return this.http.post<any>("/student/getReferenceInfo", data);
	}

	getReferences(filter, page, limit): Observable<any> {
		return this.http.post<StudentResponse>("/student/getReferences/?page=" + page + "&limit=" + limit, filter);
	}

	getReferenceDetails(data): Observable<any> {
		return this.http.post<any>("/student/getReferenceDetails", data);
	}

	getElDocuments(filter, page, limit): Observable<any> {
		return this.http.post<StudentResponse>("/student/getElDocuments/?page=" + page + "&limit=" + limit, filter);
	}

	addElDocument(data): Observable<any> {
		return this.http.post<any>("/student/addElDocument", data);
	}

	addContract(data): Observable<any> {
		return this.http.post<any>("/student/addContract", data);
	}

	getContracts(filter, page, limit): Observable<any> {
		return this.http.post<StudentResponse>("/student/getContracts/?page=" + page + "&limit=" + limit, filter);
	}

	contractNumExists(data): Observable<any> {
		return this.http.post("/schoolLeaver/contractNumExists", data);
	}

	editStudentProperty(data): Observable<any> {
		return this.http.post<any>("/student/editStudentProperty", data);
	}

	getStudentEditLogs(filter, page, limit): Observable<any> {
		return this.http.post("/student/getStudentEditLogs/?page=" + page + "&limit=" + limit, filter);
	}

	getStudentMedicMaxCreditLogs(filter, page, limit): Observable<any> {
		return this.http.post("/student/getStudentMedicMaxCreditLogs/?page=" + page + "&limit=" + limit, filter);
	}

	getEducationInfoLogs(filter, page, limit): Observable<any> {
		return this.http.post("/educationInfo/getLogs/?page=" + page + "&limit=" + limit, filter);
	}

	getStudentPrograms(data): Observable<any> {
		return this.http.post("/educationInfo/getMetaData", data);
	}

	getAgents(): Observable<any> {
		return this.http.get("/students/agents");
	}

	getMeta(data): Observable<any> {
		return this.http.post("/student/meta", data);
	}

	getMetaNew(data): Observable<any> {
		return this.http.post("/student/newMeta", data);
	}

	updateCommandVerification(data): Observable<any> {
		return this.http.patch("/student/commandVerification", data);
	}

	deleteContract(data): Observable<any> {
		return this.http.post("/student/contract/delete", data);
	}

	deleteElDocument(data): Observable<any> {
		return this.http.post("/student/elDocumentDelete", data);
	}

	// unblockGroupValidation(data): Observable<any> {
	// 	return this.http.post("/student/unblockGroupValidation", data);
	// }

	// getTagList(filter, page, limit): Observable<any> {
	// 	return this.http.post("/students/getTagList/?page="+page+"&limit="+limit, filter);
	// }

	addTag(data) {
		return this.http.post<any>("/students/addTag", data);
	}

	// editTag(data)
	// {
	// 	return this.http.post<any>("/students/editTag", data);
	// }

	removeTag(data): Observable<any> {
		return this.http.post("/students/removeTag", data);
	}

	getStudentInfoMeta(studentId) {
		return this.http.post<any>("/student/getStudentInfoMeta", {
			id: studentId
		});
	}

	getStudentFilterMeta() {
		return this.http.get<any>("/students/getStudentFilterMeta");
	}

	sendSmsToStudents(data): Observable<any> {
		return this.http.post<any>("/student/sendSmsToStudents", data);
	}

	getStudentEducations(data): Observable<any> {
		return this.http.post("/educationInfo/student/list", data);
	}

	addStudentDiplomaEnclosure(data): Observable<any> {
		return this.http.post("/diploma-enclosures/student/add", data);
	}

	addStudentDiploma(data: {
		fileUrl: string | null,
		eduId: number | string,
		studentId: number | string
	}): Observable<any> {
		return this.http.post("/diploma-enclosures/student/addDiploma", data);
	}

	getDiplomaEnclosureTemplates(data): Observable<any> {
		return this.http.post("/diploma-enclosures/templates/list/?page=1&limit=1000", data);
	}

	getStudentDiplomaEnclosures(filter, page, limit): Observable<any> {
		return this.http.post("/diploma-enclosures/student/list/?page=" + page + "&limit=" + limit, filter);
	}

	getStudentDiplomaEnclosureActions(filter, page, limit): Observable<any> {
		return this.http.post("/diploma-enclosures/actions/?page=" + page + "&limit=" + limit, filter);
	}

	getStudentDiplomaEnclosureDetails(data): Observable<any> {
		return this.http.post("/diploma-enclosures/student/template/details", data);
	}

	servOutDiplomaEnclosure(data): Observable<any> {
		return this.http.post("/diploma-enclosures/student/servOut", data);
	}

	// downloadDiplomaEnclosure(fileName: string): Observable<any> {
	// 	return this.http.get("/diploma-enclosures/student/download" + '/' + fileName);
	// }

	deleteEnclosure(data): Observable<any> {
		return this.http.post("/diploma-enclosures/student/delete", data);
	}

	deleteDiploma(data: { id: number }): Observable<any> {
		return this.http.post("/diploma-enclosures/student/deleteDiploma", data);
	}

	// viewDiplomaEnclosure(data): Observable<any> {
	// 	return this.http.post("/diploma-enclosures/student/view", data, {responseType: "blob"});
	// }

	downloadPDF(data): Observable<any> {
		return this.http.post('/diploma-enclosures/student/view', data, {responseType: "blob"});
	}

	getActiveRegistrationGroups(): Observable<any> {
		return this.http.get("/student/registration/getActiveGroups")
	}

	getChancelleryTemplates(data): Observable<any> {
		return this.http.post("/student/getChancelleryTemplates", data);
	}

	updateReferenceText(data): Observable<any> {
		return this.http.post('/student/reference/text/update', data);
	}

	addOrEditInsurance(data): Observable<any> {
		return this.http.post("/student/insurance/addOrEdit", data);
	}

	insuranceList(filter: any, page: number, limit: number): Observable<any> {
		return this.http.post("/student/insurance/list/?page=" + page + "&limit=" + limit, filter);
	}

	getInsuranceLogs(filter: any, page: number, limit: number): Observable<any> {
		return this.http.post("/student/insurance/logs/?page=" + page + "&limit=" + limit, filter);
	}

	removeInsurance(data): Observable<any> {
		return this.http.post('/student/insurance/remove', data);
	}

	getInsuranceFiles(data, page: number, limit: number): Observable<InsuranceFilesResponse> {
		return this.http.post<InsuranceFilesResponse>(`/student/insurance/files/list?page=${page}&limit=${limit}`, data);
	}

	removeInsuranceFile(data): Observable<any> {
		return this.http.post("/student/insurance/files/remove", data);
	}

	getStudentActionLogs(filter, page, limit): Observable<any> {
		return this.http.post("/student/action/logs?page=" + page + "&limit=" + limit, filter);
	}

	removeExamAccess(data): Observable<any> {
		return this.http.post("/student/action/removeAccess", data);
	}

	addMassExamAccess(data): Observable<any> {
		return this.http.post("/students/addMassExamAccess", data);
	}

	addMassAction(data): Observable<any> {
		return this.http.post("/students/addMassAction", data);
	}

	getContractData(data): Observable<any> {
		return this.http.post("/students/contract/meta", data);
	}

	signContract(data): Observable<any> {
		return this.http.post("/student/contract/sign", data);
	}


	getContractTemplates(data, page: number, limit: number): Observable<any> {
		return this.http.post(`/permission/contract/template/list?page=${page}&limit=${limit}`, data);
	}

	removeAction(data): Observable<any> {
		return this.http.post("/student/action/removeAction", data);
	}

	addStudentWelcomeAction(data): Observable<any> {
		return this.http.post("/students/addStudentWelcomeAction", data);
	}

	updateStudentDiplomaEnclosure(data): Observable<any> {
		return this.http.post("/diploma-enclosures/student/update", data);
	}

	uploadFile(data): Observable<any> {
		return this.http.post("/diploma-enclosures/templates/files/upload", data);
	}

	getStates(): Observable<any> {
		return this.http.get("/students/states");
	}

	getIndianSities(): Observable<any> {
		return this.http.get("/students/getIndianSities");
	}

	getStudentsByTag(data): Observable<any> {
		return this.http.post("/students/getStudentsByTag", data);
	}

	setTag(data): Observable<any> {
		return this.http.post("/students/setTag", data);
	}

	updateRegDetails(data): Observable<RegistrantAllowResponse> {
		return this.http.post<RegistrantAllowResponse>("/schoolLeaver/updateRegisterAllow", data);
	}

	getAbiInfo(data): Observable<any> {
		return this.http.post<any>("/students/getAbiInfo", data);
	}

	addStudentStartDateAction(data): Observable<any> {
		return this.http.post("/students/addStudentStartDateAction", data);
	}

	getStudentFields(): Observable<any> {
		return this.http.get("/student/getStudentFields");
	}


}

export const DocumentTypes = [
	{id: 1, name: 'ხელშეკრულება'},
	{id: 2, name: 'დანართი'},
	{id: 3, name: 'განცხადება'}
];

export const DocumentIdContract = [
	{id: 1, name: 'ხელშეკრულება 2250', level: [1], type: 'default', displayInSelect: false},
	{id: 2, name: 'ხელშეკრულება 2990', level: [1], type: 'default', displayInSelect: false},
	{id: 3, name: 'მაგისტრატურა 2021', level: [2], type: 'default', displayInSelect: false},

	// TODO:: display in select YES
	{id: 4, name: 'მობილობა 2021', level: [1], type: 'default', displayInSelect: false},
	{id: 5, name: 'მაგისტრატურა', level: [2], type: 'default', displayInSelect: 1},
];

export const DocumentIdEnclosure = [
	{id: 1, name: 'დანართი 2250', specials: [], type: 'default', displayInSelect: false},
	{id: 2, name: 'დანართი 2990', specials: [], type: 'default', displayInSelect: false},
	{
		id: 3,
		name: 'დანართი მაგისტრატურა - HR სეუ-ს კურსდამთავრებულთათვის',
		specials: [38],
		type: 'default',
		displayInSelect: false
	},
	{
		id: 4,
		name: 'დანართი მაგისტრატურა - განათლების ადმინისტრირება სეუ-ს კურსდამთავრებულთათვის',
		specials: [27],
		type: 'default',
		displayInSelect: false
	},
	{
		id: 5,
		name: 'დანართი მაგისტრატურა - კლინიკური ფსიქოლოგია სეუ-ს კურსდამთავრებულთათვის',
		specials: [46],
		type: 'default',
		displayInSelect: false
	},
	{
		id: 6,
		name: 'დანართი მაგისტრატურა - სამართლის სეუ-ს კურსდამთავრებულთათვის',
		specials: [39, 41],
		type: 'default',
		displayInSelect: false
	},
	{
		id: 7,
		name: 'დანართი მაგისტრატურა - საბანკო სეუ-ს კურსდამთავრებულთათვის',
		specials: [36],
		type: 'default',
		displayInSelect: false
	},
	{
		id: 8,
		name: 'დანართი მაგისტრატურა - სოც. ფსიქოლოგია სეუ-ს კურსდამთავრებულთათვის',
		specials: [28],
		type: 'default',
		displayInSelect: false
	},
	{id: 9, name: 'დანართი მაგისტრატურა HR', specials: [38], type: 'default', displayInSelect: false},
	{
		id: 10,
		name: 'დანართი მაგისტრატურა დანართი მაგისტრატურა  განათლების ადმინისტრირება',
		specials: [27],
		type: 'default',
		displayInSelect: false
	},
	{
		id: 11,
		name: 'დანართი მაგისტრატურა კლინიკური ფსიქოლოგია',
		specials: [46],
		type: 'default',
		displayInSelect: false
	},
	{id: 12, name: 'დანართი მაგისტრატურა სამართლის', specials: [39, 41], type: 'default', displayInSelect: false},
	{
		id: 13,
		name: 'დანართი მაგისტრატურა სოციალური ფსიქოლოგია',
		specials: [28],
		type: 'default',
		displayInSelect: false
	},
	{id: 14, name: 'დანართი მაგისტრატურა საბანკო', specials: [36], type: 'default', displayInSelect: false},
	{
		id: 15,
		name: 'დანართი მობილობა 2250',
		specials: [12, 13, 14, 25, 26, 27, 28, 30, 31, 33, 34, 35, 36, 37, 38, 39, 42, 44, 46],
		type: 'default',
		displayInSelect: false
	},

	{
		id: 16,
		name: 'დანართი  2250 ლარიანი - გაზაფხულის სემესტრი',
		specials: [13, 14, 25, 26, 31, 33, 34, 35, 37],
		type: 'default',
		displayInSelect: false
	},
	{
		id: 17,
		name: 'დანართი -2990ლ. გარე მობილობა-გაზაფხულის სემესტრი',
		specials: [13, 14, 25, 26, 31, 33, 34, 35, 37],
		type: 'default',
		displayInSelect: false
	},
	{id: 18, name: 'დანართი მაგისტრატურა  HR', specials: [38], type: 'default', displayInSelect: false},
	{id: 19, name: 'დანართი მაგისტრატურა  სამართლის', specials: [39], type: 'default', displayInSelect: false},
	{id: 20, name: 'დანართი მაგისტრატურა 2250', specials: [36, 28, 27], type: 'default', displayInSelect: false},

	// TODO:: display in select YES
	{id: 21, name: 'HR კურსდამთავრებულთათვის (2022)', specials: [38], type: 'default', displayInSelect: 1},
	{
		id: 22,
		name: 'განათლების ადმინისტრირება კურსდამთავრებულთათვის (2022)',
		specials: [27],
		type: 'default',
		displayInSelect: 1
	},
	{id: 23, name: 'კლინიკური ფსიქოლოგია კურსდამთავრებული (2022)', specials: [46], type: 'default', displayInSelect: 1},
	{id: 24, name: 'საბანკო კურსდამთავრებული (2022)', specials: [36], type: 'default', displayInSelect: 1},
	{id: 25, name: 'სამართლის  კურსდამთავრებულთათვის (2022)', specials: [39], type: 'default', displayInSelect: 1},
	{id: 26, name: 'სოც ფსიქოლოგია კურსდამთავრებული (2022)', specials: [28], type: 'default', displayInSelect: 1},
	{id: 27, name: 'განათლების ადმინისტრირება (2022)', specials: [27], type: 'default', displayInSelect: 1},
	{id: 28, name: 'კლინიკური ფსიქოლოგია (2022)', specials: [46], type: 'default', displayInSelect: 1},
	{id: 29, name: 'მაგისტრატურა  HR (2022)', specials: [38], type: 'default', displayInSelect: 1},
	{id: 30, name: 'საბანკო (2022)', specials: [36], type: 'default', displayInSelect: 1},
	{id: 31, name: 'სამართლის (2022)', specials: [39], type: 'default', displayInSelect: 1},
	{id: 32, name: 'სოციალური ფსიქოლოგია (2022)', specials: [28], type: 'default', displayInSelect: 1},
];

export const DocumentIdStatement = [];

export interface InsuranceFile {
	address: string;
	created_at: string;
	fileName: string;
	fileType: string;
	id: number;
	insuranceId?: number;
	size: number;
	updated_at: string;
}

export interface Insurance {
	id: number;
	studentId: number;
	dateStart: string;
	dateEnd: string;
	status: string;
	created_at: string;
	updated_at: string;
	files: InsuranceFile[];
}

export interface InsuranceFilesResponse {
	total: number;
	page: number;
	data: InsuranceFile[];
}
