import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StudentsService} from "../students.service";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
	selector: 'student-exam-access-dialog',
	templateUrl: './student-exam-access.dialog.html'
})

export class StudentExamAccessDialog {

	disableOkButton: boolean = false;

	form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		public ref: MatDialogRef<StudentExamAccessDialog>,
		public service: StudentsService,
		public errors: ErrorsDialog,
		public fb: FormBuilder,
	) {
		this.form = fb.group({
			students: ["", Validators.required],
			totalExamCount: [""],
			mode: ["", Validators.required],
		});

		this.form.patchValue(data);
	}

	ok(): void {
		this.disableOkButton = true;
		this.service.addMassExamAccess(this.form.value)
			.subscribe(response => {
				if (response.result == 'yes') this.ref.close(true);
				else this.errors.show(response);
				this.disableOkButton = false;
			}, (error) => {
				this.disableOkButton = false;
				console.error(error);
			});
	}

	cancel(): void {
		this.ref.close(true);
	}
}