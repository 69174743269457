import {Component, Inject} from "@angular/core";
import {StudentsService} from "../students.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StudentFilter} from "../students.models";
import {DOCUMENT} from "@angular/common";
import {StudentExportForm} from "./student-export-form.models";



@Component({
    selector: "student-export-form-dialog",
    templateUrl: "./student-export-form.dialog.html"
})
export class StudentExportFormDialog {

    public data= new  StudentExportForm();

    constructor(
        private service: StudentsService,
        private ref: MatDialogRef<StudentExportFormDialog>,
        @Inject(MAT_DIALOG_DATA) public filter: StudentFilter,
        @Inject(DOCUMENT) private document: any
    ) {
        this.resetForm();
    }

	infoSelectAll = false;
	selectAllInfo() {
		let data = {};
		for (let key of ['id',
			'firstName',
			'lastName',
			'firstNameEng',
			'lastNameEng',
			'personalNumber',
			'personalCardNum',
			'birthday',
			'gender',
			'regionId',
			'stateId',
			'address',
			'phone',
			'mail',
			'created_at',
			'registerStatus',
			'registrationTime',
			'refugee',
			'comment',
			'agentId',
			'medicMaxCredit',
			'country',
			'group'
		]) {
				data[key] = this.infoSelectAll;
		}
		this.data.info = {
			...this.data.info,
			...data
		}
	}

	educationSelectAll = false;
	selectAllEducation() {
		let data = {};
		for (let key of ['organisationName',
			'organisationName',
			'status',
			'level',
			'licenseNumber',
			'licenseStartYear',
			'licenseEndYear',
		]) {
			data[key] = this.educationSelectAll;
		}
		this.data.education = {
			...this.data.education,
			...data
		}
	}

	educationInfoSelectAll = false;
	selectAllEducationInfo() {
		let data = {};
		for (let key of ['schoolId',
			'schoolId',
			'specId',
			'specialChange',
			'level',
			'status',
			'method',
			'learningYear',
			'learningSeason',
			'lang',
			'langChange',
			'langLevel',
			'semester',
			'grant',
			'medalist',
			'mobileYear',
			'choose',
			'insurance',
			'insuranceStatus',
		]) {
			data[key] = this.educationInfoSelectAll;
		}
		this.data.educationInfo = {
			...this.data.educationInfo,
			...data
		}
	}

    resetForm() {
        // @ts-ignore
        this.data.info.id = false;
        // @ts-ignore
        this.data.info.firstName = false;
        // @ts-ignore
        this.data.info.lastName = false;
        // @ts-ignore
        this.data.info.firstNameEng = false;
        // @ts-ignore
        this.data.info.lastNameEng = false;
        // @ts-ignore
        this.data.info.personalNumber = false;
	    // @ts-ignore
        this.data.info.personalCardNum = false;
        // @ts-ignore
        this.data.info.birthday = false;
        // @ts-ignore
        this.data.info.gender = false;
        // @ts-ignore
        this.data.info.regionId = false;
        // @ts-ignore
        this.data.info.address = false;
        // @ts-ignore
        this.data.info.phone = false;
        // @ts-ignore
        this.data.info.mail = false;
        // @ts-ignore
        this.data.info.created_at = false;
        // @ts-ignore
        this.data.info.registerStatus = false;
        // @ts-ignore
        this.data.info.registrationTime = false;
        // @ts-ignore
        this.data.info.refugee = false;
        // @ts-ignore
        this.data.info.comment = false;
	    // @ts-ignore
	    this.data.info.agentId = false;
		// @ts-ignore
	    this.data.info.group = false;


        // @ts-ignore
        this.data.education.organisationName = false;
        // @ts-ignore
        this.data.education.status = false;
        // @ts-ignore
        this.data.education.level = false;
        // @ts-ignore
        this.data.education.licenseNumber = false;
        // @ts-ignore
        this.data.education.licenseStartYear = false;
        // @ts-ignore
        this.data.education.licenseEndYear = false;

        // @ts-ignore
        this.data.educationInfo.schoolId = false;
        // @ts-ignore
        this.data.educationInfo.specId = false;
        // @ts-ignore
        this.data.educationInfo.specialChange = false;
        // @ts-ignore
        this.data.educationInfo.level = false;
        // @ts-ignore
        this.data.educationInfo.status = false;
        // @ts-ignore
        this.data.educationInfo.method = false;
        // @ts-ignore
        this.data.educationInfo.learningYear = false;
        // @ts-ignore
        this.data.educationInfo.learningSeason = false;
        // @ts-ignore
        this.data.educationInfo.lang = false;
        // @ts-ignore
        this.data.educationInfo.langChange = false;
        // @ts-ignore
        this.data.educationInfo.langLevel = false;
        // @ts-ignore
        this.data.educationInfo.semester = false;
        // @ts-ignore
        this.data.educationInfo.grant = false;
        // @ts-ignore
        this.data.educationInfo.medalist = false;
        // @ts-ignore
        this.data.educationInfo.mobileYear = false;
        // @ts-ignore
        this.data.educationInfo.choose = false;
    }

    ok() {
        this.filter.fields = this.data;
        this.service.exportToExcel(this.filter).subscribe(response => {
            if (response.result == "yes") {
                this.document.location = response.link;
            }
        });
    }

}