import {Variant} from "../variant/variant";
import {Answer} from "../../../../tests/correct/answer";
import {TestingRule} from "../../../../questions/list/testing.rule";
import {VariantGroup} from "./variant.group";

export class Question {

    id: number;
    number: number;
    type: string;
    image: string;
    text: any;
    variants: Variant[];
    items: VariantGroup[];
    answer: Answer;
    correctAnswer: string;
    rule: TestingRule;

}
