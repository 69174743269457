import { Component, Inject, OnInit } from "@angular/core";
import { StudentsService } from "../../students.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ErrorsDialog } from "../../../../shared/error-dialogs/errors.dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
	selector: "edit-semester-dialog",
	templateUrl: "./edit-semester.dialog.html"
})
export class EditSemesterDialog implements OnInit {

	form: FormGroup;
	type: string = "";
	decrementButtonColor: string = "default";
	incrementButtonColor: string = "default";

	constructor(
		private ref: MatDialogRef<EditSemesterDialog>,
		private studentService: StudentsService,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private error: ErrorsDialog,
		private fb: FormBuilder
	) {
		this.form = fb.group({
			type: ["", Validators.required],
			students: ["", Validators.required],
			shouldIncreaseSemester: "",
			groupDay: "",
			groupMonth: "",
			groupYear: ""
		});
		this.type = this.data.type;
		switch (this.type) {
			default:
				// continue 
				break;
			case "semester":
				this.form.get("shouldIncreaseSemester").setValidators(Validators.required);
				break;
			case "group":
				break;
		}
	}

	setShouldIncreaseSemester() {
		this.form.get("shouldIncreaseSemester").setValue(true);
		this.decrementButtonColor = "default";
		this.incrementButtonColor = "primary";
	}

	setShouldDecreaseSemester() {
		this.form.get("shouldIncreaseSemester").setValue(false);
		this.incrementButtonColor = "default";
		this.decrementButtonColor = "primary";
	}

	ok() {
		this.studentService.editStudentProperty(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		})
	}

	cancel() {
		this.ref.close(false);
	}

	ngOnInit(): void {
		this.form.patchValue(this.data);
	}

}