// noinspection JSUnusedGlobalSymbols
import {EventEmitter, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {createId} from "@paralleldrive/cuid2";

declare const Resumable: any;

@Injectable()
export class FileStorage {

	static api_key = 'hM4uqYcTMKqxggj2aYGHWR2t';
	static storageServer = 'https://storage.ini.ge';

	readonly ASCII_REGEX = /^[\x00-\x7F]*$/;
	readonly MAX_SYMBOL_COUNT = 30;

	constructor(
		private http: HttpClient
	) {
	}

	status():Observable<StorageUserStatus> {
		return this.http.get<StorageUserStatus>(FileStorage.storageServer + '/status?api_key=' + FileStorage.api_key);
	}


	openFileDialog(options: FileStorageOpenDialogOptions = {}):Promise<FileList | false> {
		return new Promise(serve => {
			const input = document.createElement('input');
			input.type = 'file';
			options.multiple && (input.multiple = options.multiple);
			options.accept && (input.accept = options.accept);
			input.style.visibility = 'hidden';
			input.onchange = () => {
				if (input.files.length == 0) {
					serve(false)
				} else {
					serve(input.files);
				}
				document.body.removeChild(input);
			}
			document.body.appendChild(input);
			input.click();
		})
	}

	delete = (address):Promise<any> => new Promise(serve => this.http.delete(address).subscribe(serve))

	upload(
		files: FileList,
		folder: string = 'files',
		onProgress: (progress: EventEmitter<number>) => void = null
	) :Promise<{addresses: string[], error: string}> {
		const progress = new EventEmitter<number>();
		onProgress && onProgress(progress);
		return new Promise(serve => {
			const resumable = new Resumable({
				target: FileStorage.storageServer + '/upload',
				throttleProgressCallbacks: 1,
				generateUniqueIdentifier: (file) => {
					const relativePath = file.webkitRelativePath||file.relativePath||file.fileName||file.name;
					const size = file.size;
					return createId() + '-' + (size + '-' + relativePath.replace(/[^0-9a-zA-Z_-]/img, ''));
				},
				query: {
					api_key: FileStorage.api_key,
					folder,
				}
			});
			let addresses = [];
			let error = '';
			resumable.on('fileAdded', () => resumable.upload());
			resumable.on('fileProgress', (file) => progress.next(Math.floor(file.progress() * 100)));
			resumable.on('fileSuccess', (file, message: any) => {
				try {
					const data = JSON.parse(message);
					if (data && data.status_code == 1) {
						addresses.push(data.address);
					}
				} catch (ex) {
				}

			});
			resumable.on('fileError', (file, message) => {
				try {
					const data = JSON.parse(message);
					if (data.status_code == -3) {
						error = data.error;
						resumable.cancel();
					}
				} catch (ex) {
				}
			});
			resumable.on('complete', () => {
				if (error) {
					serve({addresses: null, error});
				} else {
					serve({addresses, error: null});
				}
				//progress.next(0);
				progress.complete();
			});
			resumable.addFiles(files);
		});
	}

	validateFiles(files): any {
		for (let i = 0; i < files.length; i++) {

			if (!this.ASCII_REGEX.test(files[i].name)) {
				return {error: 'ფაილის სახელი უნდა შეიცავდეს მხოლოდ ინგლისურ სიმბოლოებს'};
			}

			if (files[i].name.length > this.MAX_SYMBOL_COUNT) {
				return {error: `ფაილის სახელი უნდა შედგებოდეს მაქსიმუმ ${this.MAX_SYMBOL_COUNT} სიმბოლოსგან`};
			}
		}

		return true;
	}


}

export interface FileStorageOpenDialogOptions {
	multiple?: boolean;
	accept?: string;
}

export interface StorageUserStatus {
	status_code: number;
	total: number;
	used: number;
	details: StorageUserFolder[]
}

export interface StorageUserFolder {
	name: string;
	size: number;
}
