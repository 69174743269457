import {Component, forwardRef, Input, OnInit} from "@angular/core";
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {IniDatePickerHeader} from "../ini-date-picker/ini-date-picker-header";

declare let moment: any;

@Component({
    selector: "date-from-to",
    templateUrl: "./date.from.to.html",
    providers:[{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => DateFromTo),
        multi: true
    }]
})
export class DateFromTo implements OnInit, ControlValueAccessor {

    constructor(
        private fb: FormBuilder
    ) {
        this.form = fb.group({
            range: '',
            from: '',
            to: ''
        });
        this.form.get("range").valueChanges.subscribe((value) => {
            if (typeof this.onChange == "function") {
                const v = (value+" / ").split(" / ");
                this.onChange({
                    from: v[0],
                    to: v[1]
                });
            }
        });
    }

    form: FormGroup;

    private onChange: (value: any) => void;
    private onTouched: () => void;
    @Input() placeholderText: any = "";
    iniDatePickerHeader = IniDatePickerHeader;

    from = '';
    to = '';

    ngOnInit(): void {
        this.form.patchValue({
            range: '',
            from: '',
            to: ''
        });
    }

    setFrom() {
        this.from = this.form.value.from;
        this.setRange();
    }

    setTo() {
        this.to = this.form.value.to;
        this.setRange();
    }

    setRange() {
        const v = [];
        if (typeof this.from == "object") {
            v.push(moment(this.from).format("YYYY-MM-DD"));
        }
        if (typeof this.to == "object") {
            v.push(moment(this.to).format("YYYY-MM-DD"));
        }
        this.form.patchValue({
            range: v.join(" / ")
        });
        v.push('');
        v.push('');


    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.form.get("range").disable();
        } else {
            this.form.get("range").enable();
        }
    }

    writeValue(obj: any): void {

    }




}
