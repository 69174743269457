import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {MessagesResponse} from "./messages-response";
import {StatementMessage} from "./message-statement/statement-message";
import {CacheService} from "../../core/services/cache.service";

@Injectable()
export class MessagesService {

    constructor(
        private http: HttpClient,
        private cacheService: CacheService
    ) {

    }

    getUnreadMessagesCount():Observable<MessagesResponse> {
        return this.cacheService.cache("message-messagesCount", 2, this.http.get<MessagesResponse>("/messages/unreadMessages"));
    }

    private subjects: Subject<string>[] = [];

    on(eventName: string): Observable<string> {
        this.subjects[eventName] = this.subjects[eventName] || new Subject();
        return this.subjects[eventName].asObservable();
    }

    publish(eventName: string) {
        this.subjects[eventName] = this.subjects[eventName] || new Subject();
        this.subjects[eventName].next();
    }

    getMessages(filter, page, limit, total):Observable<MessagesResponse> {
        return this.http.post<MessagesResponse>("/messages/list/?page="+page+"&limit="+limit+(total==0 ? "&withTotal=yes" : ""), filter);
    }

    removeMessage(item:StatementMessage):Observable<MessagesResponse> {
        return this.http.post<MessagesResponse>("/messages/removeMessage", item);
    }

    markAsRead(id: string) {
        return this.http.post<MessagesResponse>("/messages/markAsRead", {
            id: id
        });
    }
}