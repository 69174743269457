import {Component, OnDestroy, OnInit} from "@angular/core";
import {ChatService} from "../../services/chat-service";
import {ChatRoom} from "../../interfaces/ChatRoom";
import {ChatEventType} from "../../interfaces/ChatEventType";
import {MatDialogRef} from "@angular/material/dialog";
import {UserSetting} from "../../interfaces/UserSetting";
import {UserService} from "../../services/user.service";
import {ChatUserDirectory} from "../../interfaces/ChatUserDirectory";
import {ChatUser} from "../../interfaces/ChatUser";
import {MessageSeenType} from "../../interfaces/MessageSeenType";

@Component({
	selector: 'ini-chat-side-bar',
	templateUrl: './side-bar.component.html',
	styleUrls: ['./side-bar.component.scss']
})
export class SideBar implements OnInit, OnDestroy {

	subs: any = [];
	rooms: ChatRoom[] = [];
	showSetting = false;
	isUser = false;
	userSettings: UserSetting = null;
	tabs = [
		{id: 'users', title: 'მიმოწერა'},
		{id: 'groups', title: 'ჯგუფები'},
		//{id: 'directory', title: 'ცნობარი'},
		{id: 'estListChats', title: 'უწყისები'},
	];
	currentTab = this.tabs[0].id;
	pastTab = this.tabs[0].id;
	directories: ChatUserDirectory[] = null;
	loadingDirectories = true;
	user: ChatUser = null;
	unreadMessages = {
		users: 0,
		groups: 0,
		directory: 0,
		estListChats: 0
	};
	keyword = '';
	isMobile = false;

	constructor(
		public chatService: ChatService,
		private ref: MatDialogRef<SideBar>,
		private userService: UserService
	) {
		this.isUser = this.userService.user.type == 'user';
		this.user = this.userService.user;
		this.isMobile = this.chatService.isMobile();
		this.chatService.getRooms().subscribe((chats) => {
			for (let channel of Object.keys(this.unreadMessages)) {
				this.unreadMessages[channel] = chats.filter((chat) => this.getChatType(chat) == channel && chat.seen == MessageSeenType.ME_NOT_SEEN).length;
			}
		});
	}

	getChatType(chat: ChatRoom) {
		if (chat.listId) return 'estListChats';
		if (chat.users.length == 2) return 'users';
		if (chat.users.length > 2) return 'groups';
		return 'directory';
	}

	search(keyword: string) {
		this.keyword = keyword;
		this.rooms.forEach((room) => {
			room.hidden = !this.keywordEquals(room)
		});
	}

	async openTab(tab: string) {
		this.showSetting = false;
		if (!this.directories && tab == 'directory') {
			this.loadingDirectories = true;
			let directories = await this.chatService.getUserDirectories();
			this.directories = directories.sort((a, b) => {
				if (a.name == b.name) return 0;
				return a.name > b.name ? 1 : -1;
			});
			this.loadingDirectories = false;
		}
		if (tab != 'directory') {
			this.pastTab = tab;
		}
		this.currentTab = tab;
	}

	ngOnInit(): void {
		this.subs.push(
			this.chatService.getRooms().subscribe(rooms => this.setRooms(rooms)),
			this.chatService.events.subscribe(event => event.type == ChatEventType.OPEN_CHAT && this.close())
		);
	}

	setRooms(rooms: any) {
		this.rooms = rooms.sort((a: any, b: any) => this.roomSort(a, b))
			.map((room: any) => ({
				...room,
				userCount: room.users.length,
				hidden: !this.keywordEquals(room)
			}));
	}

	keywordEquals(room: any) {
		if (this.keyword) {
			const keyword = this.keyword.toLowerCase();
			if (room.name.toLowerCase().indexOf(keyword) == -1) {
				return false;
			}
		}
		return true;
	}

	roomSort(roomA: any, roomB: any) {
		if (roomA && roomA.lastMessageTime && roomB && roomB.lastMessageTime) {
			if (roomA.lastMessageTime == roomB.lastMessageTime) return 0;
			return roomA.lastMessageTime > roomB.lastMessageTime ? -1 : 1;
		}
		return 1;
	}

	close() {
		this.ref.close();
	}

	showSettings() {
		this.userSettings = this.chatService.userSettings;
		this.showSetting = true;
	}

	hideSettings() {
		this.showSetting = false;
	}

	async saveSettings() {
		await this.chatService.setSettings(this.userSettings);
		this.chatService.userSettings = this.userSettings;
		this.hideSettings();
	}

	ngOnDestroy(): void {
		if (this.subs.length > 0) {
			this.subs.forEach((sub: any) => {
				try {
					sub && sub.unsubscribe()
				} catch (e) {
				}
			});
		}
	}
}

