import {Component, Inject, ViewEncapsulation} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: "confirm-dialog",
    templateUrl: "./confirm.dialog.html",
    styleUrls: ["./confirm.dialog.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmDialog {

    text: string | SafeHtml = "";

    constructor(
        private ref: MatDialogRef<ConfirmDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private sanitizer: DomSanitizer
    ) {
        if (typeof this.data.text == "string") {
            this.text = sanitizer.bypassSecurityTrustHtml(this.data.text);
        } else {
            this.text = this.data.text;
        }
    }

    ok() {
        this.ref.close(true);
    }


    cancel() {
        this.ref.close(false);
    }

}