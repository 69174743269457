import {Component, forwardRef, Input} from "@angular/core";
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {IniInput} from "./ini.input";
import {AutocompleteNamesService} from "./autocomplete-names.service";
import {Observable} from "rxjs";

@Component({
	selector: "school-input",
	templateUrl: "./school-input.html",
	providers:[{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => SchoolInput),
		multi: true
	}]
})
export class SchoolInput extends IniInput{ /**/

	@Input("placeholder") override placeholder = "";
	@Input("type") override type = "old";
	@Input("lang") override lang: string = "ka";

	constructor(
		private service: AutocompleteNamesService
	) {
		super();
	}

	override search(q: string): Observable<any[]> {
		return this.service.getSchoolNames(q);
	}
}
