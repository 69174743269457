import {Component, Inject} from "@angular/core";
import {StudentActionFormDialog} from "../../student-details/actions/student-action-form/student-action-form.dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StudentsService} from "../../students.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: 'student-welcome-dialog',
	templateUrl: './student-welcome.dialog.html'
})

export class StudentWelcomeDialog  {

	form: FormGroup;

	constructor(
		public ref: MatDialogRef<StudentActionFormDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public studentService: StudentsService,
		public fb: FormBuilder,
		public error: ErrorsDialog
	) {
		this.form = fb.group({
			sub: "welcomeAccess",
			type: "welcomeAccess",
			reasonType: 3,
			reason: [""],
			welcomeName: ["", Validators.required],
			welcomeIcon: ["", Validators.required],
			welcomeEndDate: ["", Validators.required]
		});
	}



	ok() {
		const students =  this.data.students;
		this.studentService.addStudentWelcomeAction({students, ...this.form.value}).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		}, (error) => {
			console.error(error);
		});
	}

	cancel()
	{
		this.ref.close();
	}

}