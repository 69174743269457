import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
	selector: 'comment-dialog-component',
	templateUrl: './comment-dialog.component.html'
})
export class CommentDialogComponent {

	form: FormGroup;

	constructor(
		public ref: MatDialogRef<CommentDialogComponent>,
		public formBuilder: FormBuilder,
	) {
		this.form = this.formBuilder.group({
			comment: ['', Validators.required],
		});
	}

	ok(): void {
		this.ref.close(this.form.value);
	}

	cancel(): void {
		this.ref.close(false);
	}
}