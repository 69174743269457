import {Directive, ElementRef, HostListener} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {SideBar} from "../side-bar/side-bar.component";
import {Footer} from "../footer/footer.component";

@Directive({
	selector: '[appChat]',
})
export class IniChatToggle {

	constructor(
		private el: ElementRef,
		private dialog: MatDialog
	) {
		el.nativeElement.style.cursor = 'pointer';

		//this.openMessenger();
		this.init();
	}

	init() {
		this.dialog.open(Footer, {
			position: {
				bottom: '0',
				right: '0',
			},
			//hasBackdrop: false,
			hasBackdrop: false,
			disableClose: true,
			panelClass: 'chat-panel-footer',
			closeOnNavigation: false,
		});
	}

	openMessenger() {
		this.dialog.open(SideBar, {
			position: {
				top: '50px',
				right: '10px',
			},
			height: 'calc(100% - 50px)',
			//hasBackdrop: false,
			backdropClass: 'ini-chat-backdrop',
			panelClass: 'chat-panel',
			closeOnNavigation: true,
		});
	}

	@HostListener('click') onMouseEnter() {
		this.openMessenger();
	}

}

