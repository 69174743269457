import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {UserService} from "../../services/user.service";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
	selector: 'prof-statement-dialog',
	templateUrl: './prof-statement.dialog.html'
})
export class ProfStatementDialog {

	smsMode = false;
	form: FormGroup;

	constructor(
		private ref: MatDialogRef<ProfStatementDialog>,
		private userService: UserService,
		private fb: FormBuilder
	) {
		this.ref.disableClose = true;
		this.form = this.fb.group({
			code: [''],
			agreed: [0]
		})
	}

	save(agreed: boolean) {
		this.form.patchValue({agreed: agreed ? 1 : 0});
		this.userService.setProfStatement(this.form.value).subscribe((response) => {
			if (response.result == 'yes') {
				this.ref.disableClose = false;
				this.ref.close();
			} else if (response.error == 'sms_required') {
				this.smsMode = true;
			} else if (response.error == 'code_invalid') {
				alert('კოდი არასწორია');
			}
		});
	}

}
