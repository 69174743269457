import { Component, ContentChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import {filter, take, switchMap} from 'rxjs/operators';

import {ViewModeDirective} from "../../directives/view-mode.directive";
import {EditModeDirective} from "../../directives/edit-mode.directive";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
	selector: 'editable',
	template: `
    <ng-container *ngTemplateOutlet="currentView"></ng-container>
  `,
	styleUrls: ['./editable.component.scss']
})
export class EditableComponent {
	@ContentChild(ViewModeDirective) viewModeTpl: ViewModeDirective;
	@ContentChild(EditModeDirective) editModeTpl: EditModeDirective;
	@Output() update = new EventEmitter();

	editMode = new Subject();
	editMode$ = this.editMode.asObservable();

	mode: 'view' | 'edit' = 'view';


	constructor(private host: ElementRef) {
	}

	ngOnInit() {
		this.viewModeHandler();
		this.editModeHandler();
	}

	toViewMode() {
		// @ts-ignore
		this.update.next();
		this.mode = 'view';
	}

	private get element() {
		return this.host.nativeElement;
	}

	private viewModeHandler() {
		fromEvent(this.element, 'dblclick').pipe(
			untilDestroyed(this)
		).subscribe(() => {
			this.mode = 'edit';
			this.editMode.next(true);
		});
	}

	// private editModeHandler() {
	// 	const clickOutside$ = fromEvent(document, 'click').pipe(
	// 		filter(({ target }) => this.element.contains(target) === false),
	// 		take(1)
	// 	)
	//
	// 	this.editMode$.pipe(
	// 		switchMapTo(clickOutside$),
	// 		untilDestroyed(this)
	// 	).subscribe(() => this.toViewMode());
	// }

	private editModeHandler() {
		const clickOutside$ = fromEvent(document, 'click').pipe(
			filter(({ target }) => this.element.contains(target) === false),
			take(1)
		);

		this.editMode$.pipe(
			switchMap(() => clickOutside$),
			untilDestroyed(this)
		).subscribe(() => this.toViewMode());
	}

	get currentView() {
		return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
	}

	ngOnDestroy() {
	}

}
