import {Pipe,PipeTransform } from '@angular/core';


@Pipe({ name: 'toKaDay'})
export class ToKaDayPipe implements PipeTransform  {
	transform(value) {
		let days = [
			"ორშაბათი",
			"სამშაბათი",
			"ოთხშაბათი",
			"ხუთშაბათი",
			"პარასკევი",
			"შაბათი",
			"კვირა"
		];
		return days[value];
	}
}