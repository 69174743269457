import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {NotificationResponse} from "./notification-response";
import {StatementNotification} from "./statement-notification";
import {NotificationFormModel} from "./notification-statement/notification-form/notification.form.model";
import {StudentResponse} from "../students/students.models";

@Injectable()
export class NotificationService {

    constructor(
        private http: HttpClient
    ) {

    }

    getNotifications(filter, page, limit, total):Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>("/notification/list/?page="+page+"&limit="+limit+(total == 0 ? "&withTotal=yes" : ""), filter);
    }

    removeNotification(item: StatementNotification):Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>("/notification/remove", item);
    }

    getGroups():Observable<NotificationResponse> {
        return this.http.get<NotificationResponse>("/notification/groups");
    }

    getStudents(filter:string):Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>("/notification/searchStudents", {
            name: filter
        });
    }
    addUser(filter:string):Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>("/notification/searchUsers", {
            name: filter
        });
    }
    getSchools():Observable<NotificationResponse> {
        return this.http.get<NotificationResponse>("/notification/getSchools");
    }
    getSpecial():Observable<NotificationResponse> {
        return this.http.get<NotificationResponse>("/notification/getSpecial");
    }

    sendNotification(data:NotificationFormModel):Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>("/notification/send", data);
    }

    sendFormData(data: FormData):Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>("/notification/attachFiles", data);
    }

    saveAsTemplate(name: string, data: any) {
        return this.http.post<NotificationResponse>("/notification/saveAsTemplate", {
            name: name,
            data: data
        });
    }

    getTemplates():Observable<any[]> {
        return this.http.get<any>("/notification/templates");
    }

    getTemplate(id: number):Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>("/notification/template", {
            id: id
        });
    }

    exportStatements(data):Observable<NotificationResponse> {
        return this.http.post<NotificationResponse>("/notification/exportStatements", data);
    }

	getProfs(filter: any, page: number, limit: number): Observable<any> {
		return this.http.post<StudentResponse>("/notification/getProfs?page="+page+"&limit="+limit, filter);
	}

	getUsers(filter: any, page: number, limit: number): Observable<any> {
		return this.http.post<StudentResponse>("/notification/getUsers?page="+page+"&limit="+limit, filter);
	}


	getLists(filter): Observable<any> {
		return this.http.post<StudentResponse>("/lists/getLists?page=1&limit=100", filter);
	}

	getListGroups(data): Observable<any> {
		return this.http.post<StudentResponse>("/lists/getGroups", data);
	}

	getStudentsForGroup(data): Observable<any> {
		return this.http.post<StudentResponse>("/lists/getStudentsForGroup", data);
	}

	getProfSelection(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/notification/getProfSelection", data);
	}

	getUserSelection(data): Observable<StudentResponse> {
		return this.http.post<StudentResponse>("/notification/getUserSelection", data);
	}

	getMyNotifications(data, page, limit): Observable<any> {
		return this.http.post("/notifications/getMyNotifications/?page=" + page + "&limit=" + limit, data);
	}
}
