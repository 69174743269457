import {Component, Inject, OnInit} from '@angular/core';
import {FinanceService} from "../../../services/finance.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../../shared/error-dialogs/errors.dialog";
import {FinanceEvent} from "../../../services/interfaces";

@Component({
	selector: 'app-finance-event-form',
	templateUrl: './finance-event-form.component.html',
	styleUrls: ['./finance-event-form.component.scss']
})
export class FinanceEventFormComponent implements OnInit {

	form: FormGroup;

	currencyTypes: {id: number, name: string}[] = [
		{id: 1, name: "GEL"},
		{id: 2, name: "USD"},
		{id: 3, name: "EUR"}
	];
	accountTypes: {id: number, name: string}[] = [
		{id: 1, name: "ძირითადი ანგარიში"},
		{id: 2, name: "დამ.კრედიტის ანგარიში"}
	];

	constructor(
		private financeService: FinanceService,
		private fb: FormBuilder,
		private ref: MatDialogRef<FinanceEventFormComponent>,
		@Inject(MAT_DIALOG_DATA) private data: FinanceEvent,
		private error: ErrorsDialog
	) {
		this.form = fb.group({
			action: ["", Validators.required],
			accountType: ["", Validators.required],
			accountCurrency: ["", Validators.required],
			student: ["", Validators.required],
			amount: ["", [Validators.required, Validators.min(0.01)]],
			comment: ["", Validators.required],
			bankTransactionId: [""],
			sourceType: ["", Validators.required],
			sourceId: [""],
			transactDate: [""],
			year: [""],
			month: [""],
			day: [""],
			updateLog: [false],
		});
	}

	cancel() {
		this.ref.close(false);
	}

	ok() {
		this.financeService.addEvent(this.form.value).subscribe(response => {
			if (response.result == "yes") {
				this.ref.close(true);
			} else {
				this.error.show(response);
			}
		})
	}

	ngOnInit() {
		if (typeof this.data != "undefined" && this.data != null) {
			this.form.patchValue(this.data);
		}
	}

}
