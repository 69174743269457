import {Component, forwardRef, Inject, Input, OnInit} from "@angular/core";
import {Question} from "./question";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DomSanitizer} from "@angular/platform-browser";
import {IniEditorConfig} from "../../../../../../core/components/ini-editor/config";
import {DOCUMENT} from "@angular/common";
import {CorrectDocViewerDialog} from "../../../../tests/correct/correct-doc-viewer/correct-doc-viewer.dialog";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

declare const top: any;

@Component({
    selector: "question-component",
    templateUrl: "./question.component.html",
    styleUrls: ["./question.component.scss"],
    providers:[{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => QuestionComponent ),
        multi: true
    }]
})
export class QuestionComponent implements ControlValueAccessor, OnInit {

    constructor(
        private sanitizer: DomSanitizer,
        @Inject(DOCUMENT) private doc: any,
        private dialog: MatDialog,
    ) {

    }

    @Input() question: Question;
    @Input() num: number;
    @Input() correctType = false;
    @Input() corrected = false;

    answers: any = [];

    editorConfig = {
        editable: false,
        spellcheck: true,
        height: '10rem',
        minHeight: '5rem',
        placeholder: 'პასუხი',
        translate: 'no',
        uploadUrl: 'v1/images', // if needed
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ]
    };

    private onChange: (value: any) => void;
    private onTouched: () => void;

    result = {
        score: '',
        comment: "",
        correctDate: ""
    };

    commentEditorConfig: IniEditorConfig = {
        height: "200px",
        editable: true
    };

    trustHtml(html) {
        while ((html+"").indexOf('<span class="math">', 0) != -1) {
            html = html.replace('<span class="math">', "<span class='math'>");
        }
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    isImage() {
        return  this.question.type == 'Opened_Image'
            || this.question.type == 'Opened_TextAndImage'
            || this.question.type == 'Closed_Image'
            || this.question.type == 'Closed_TextImage'
            || this.question.type == 'Closed_ImageVariantImage'
            ;
    }

    isText() {
        return  this.question.type == 'Opened_Text'
            || this.question.type == 'Opened_TextAndImage'
            || this.question.type == 'Closed_Text'
            || this.question.type == 'Closed_TextImage'
            || this.question.type == 'Closed_TextVariantImage'
            || this.isMixed()
            ;
    }

    isMixed() {
        return (this.question.type+"").substring(0,5) == "Mixed";
    }

    isClosed() {
        return (this.question.type+"").substring(0,6) == "Closed";
    }

    isOpened() {
        return (this.question.type+"").substring(0,6) == "Opened";
    }

    colNumber() {
        switch (this.question.variants.length) {
            default:
                return 4;
            case 4:
                return 2;
            case 2:
                return 2;
        }
    }

    // noinspection JSUnusedGlobalSymbols
    rowNumber() {
        return '1:1';
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {

    }

    writeValue(o: any): void {
        if (typeof o == "object" && o != null) {
            if (typeof o.score != "undefined") {
                this.result.score = o.score;
            }
            if (typeof o.comment != "undefined") {
                this.result.comment = o.comment;
            }
            if (typeof o.correctDate != "undefined") {
                this.result.correctDate = o.correctDate;
            }
        }
    }

    updateResult() {

        if (parseFloat(this.result.score)<0) { return; }
        if (this.question.rule.score>=this.result.score) {
            this.onChange(this.result);
            try {
                this.question.answer.score = this.result.score;
            } catch (ex) {}
        } else {
            this.onChange({
                score: -1,
                comment: this.result.comment,
                correctDate: this.result.correctDate
            });
            try {
                this.question.answer.score = 0;
            } catch (ex) {}
        }
    }

    // noinspection DuplicatedCode
  round(num, dec=2) {
        if (typeof num == "undefined" || num == null || isNaN(num)) {
            return '';
        }
        num = num+"";
        if (num.indexOf('.') == -1) {
            return num;
        }
        return num.substring(0, num.indexOf(".")+(dec+1));
    }

    ngOnInit(): void {
        if (this.question.type == "live_files" && typeof this.question.text != "object") {
            try {
                this.question.text = JSON.parse(this.question.text);
            } catch (ex) {}
        }
        if (typeof this.question.answer != "undefined") {
            for (const i in this.question.answer) {
                if (typeof this.question.answer[i] == "object" && this.question.answer[i] != null && typeof this.question.answer[i].questionId != "undefined") {
                    this.answers.push(this.question.answer[i]);
                }
            }
        }
    }

    baseName(path) {
        return path.split('/').reverse()[0];
    }

    downloadFile(file) {
        top.location.href = file.address;
    }

    downloadAnswer(file) {

        top.location.href = "https://testing.ini.ge"+file.text;
    }

	view(fileId: string): void {
		const ref: MatDialogRef<CorrectDocViewerDialog> = this.dialog.open(CorrectDocViewerDialog, {
			width: "900px",
			height: "1100px",
			data: {
				fileId: fileId
			}
		});
	}


}
