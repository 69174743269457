import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Language} from "../language";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LangService} from "../../../shared/services/lang.service";

@Component({
    selector: "./language-form-component",
    templateUrl: "./language-form.component.html",
    styleUrls: ["./language-form.component.scss"]
})
export class LanguageFormComponent implements OnInit {

    form: FormGroup;

    constructor(
        private ref: MatDialogRef<LanguageFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Language,
        private fb: FormBuilder,
        private service: LangService
    ) {
        this.form = fb.group({
            mode: '',
            code: ['', Validators.required],
            name: ['', Validators.required],
            flag: ['', Validators.required]
        });
    }


    ngOnInit(): void {
        this.form.patchValue({
            mode: typeof this.data.mode == "undefined" ? '' : this.data.mode,
            code: this.data.code,
            name: this.data.name,
            flag: this.data.flag
        });
        if (this.data.mode == "edit") {
            this.form.get("code").disable();
        }
    }

    add() {
        if (this.data.mode == "edit") {
            this.form.get("code").enable();
        }
        this.service.addLanguage(this.form.value).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(true);
            } else {
                alert("Error");
            }
        });
        if (this.data.mode == "edit") {
            this.form.get("code").disable();
        }
    }

    setFlag(files) {
        if (files.length>0) {
            const reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
                this.form.patchValue({
                    flag: reader.result
                });
            };
        }
    }
}