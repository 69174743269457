import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RegistrationService} from "../../../pages/registration/registration.service";


@Component({
	selector: 'app-GroupSelect',
	templateUrl: './groupSelect.component.html',
	styleUrls: ['./groupSelect.component.scss']
})
export class GroupSelectComponent {

	@Output() selectedGroup = new EventEmitter();
	@Input() selectedGroup2 = new EventEmitter();


	allGroups = [];
	constructor(
		private registrationService: RegistrationService
	) {
	}

	ngOnInit() {
		this.getAllGroups();
	}

	ngOnDestroy() {
	}

	getAllGroups()
	{
		this.registrationService.getAllActiveGroups().subscribe(response => {
			this.allGroups = response;
		})
	}

}