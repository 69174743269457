import {EducationInfo} from "./student-details/education-info/list/education-info-list.models";
import {ProgramView} from "./student-details/result/ProgramView";
import {StudentView} from "./student-details/result/student.view";

export class Student {
	id: number;
	photo_code: string;
	saxeli_gvari: string;
	pi_num1: string;
	firstName: string;
	lastName: string;
	firstNameEng: string;
	lastNameEng: string;
	personalNumber: string;
	personalCardNum?: string;
	studentName: string;
	birthday: string;
	gender: string;
	regionId: number;
	address: number;
	phone: number;
	phoneSecond?: number;
	mail: number;
	created_at: number;
	region: Region;
	// noinspection JSUnusedGlobalSymbols
	skipFinance: boolean;
	wtoStepAuth: boolean;
	comment: string;
	registerStatus: string;
	ban: string;
	banReason: string;
	registrationTime: string;
	refugee: number;
	educationInfo: EducationInfo;
	data: Student;
	educationView: ProgramView;
	// noinspection JSUnusedGlobalSymbols
	studentView: StudentView;
	name: string;
	country: string;
	countryEng: string;
	militaryPlace: string;
	moreInfo?: string;
	parentFirstName?: string;
	parentLastName?: string;
	parentPersonalNumber?: string;
	parentPhoneNumber?: string;
	ipAddress?: string;
	birthD?: any;
	birthM?: any;
	birthY?: any;
	verified: boolean;
	agentId: number;
	isRehabilitant: number;
	unblockGroupsValidation: boolean;
	needTestingAccess: boolean;
	endTest: boolean;
	restrictFree: boolean | number;
	tag: string
	lang: string;
	group: string;
	groupDay: string;
	groupMonth: string;
	groupYear: string;
	medicMaxCredit: string;
	stateId: number;
	stateId2: number;
	city_india: number;

	public static eduStatuses() {
		return [
			{id: "active", name: "აქტიური"},
			{id: "pause", name: "შეჩერებული"},
			{id: "stop", name: "შეწყვეტილი"},
			{id: "moved", name: "გადასული"},
			{id: "end", name: "კურსდამთავრებული"},
			{id: "abiturient", name: "აბიტურიენტი"},
			{id: "exchange", name: "გაცვლითი"},
			{id: "conditional", name: "პირობითი"},
			{id: "waiting", name: "მომლოდინე"},
			{id: "exchange/pause", name: "გაცვლითი/შეჩერებული"},
			{id: "exchange/waiting", name: "გაცვლითი/მომლოდინე"},
			{id: "exchange/stop", name: "გაცვლითი/შეწყვეტილი"},
			{id: "exchange/end", name: "გაცვლითი/დასრულებული"},
			{id: "exchange/active", name: "გაცვლითი/აქტიური"},
			//{id: "mag_candidate", name: "მაგისტრობის კანდიდატი"},
		];
	}


	static statuses() {
		return {
			end: "კურსდამთავრებული",
			moved: "გადასული",
			pause: "შეჩერებული",
			active: "აქტიური",
			stop: "შეწყვეტილი",
			pen: "---",
			mag_candidate: "მაგ.კანდიდატი",
			abiturient: "აბიტურიენტი",
			conditional: "პირობითი",
			exchange: "გაცვლითი",
			waiting: "მომლოდინე",
			observer: "დამკვირვებელი",
			'exchange/pause': "გაცვლითი/შეჩერებული",
			'exchange/waiting': "გაცვლითი/მომლოდინე",
			'exchange/stop': "გაცვლითი/შეწყვეტილი",
			'exchange/end': "გაცვლითი/დასრულებული",
			'exchange/active': "გაცვლითი/აქტიური",
		};
	}

	static additionalStatuses() {
		return [
			{id: "exchange", name: "გაცვლითი"}
		]
	}


	static statusNames() {
		const a: any = [];
		const names = Student.statuses();
		for (const i in names) {
			a.push({
				id: i,
				name: names[i]
			});
		}
		return a;
	}

	static semesters() {
		return [
			{id: 1, name: "I"},
			{id: 2, name: "II"},
			{id: 3, name: "III"},
			{id: 4, name: "IV"},
			{id: 5, name: "V"},
			{id: 6, name: "VI"},
			{id: 7, name: "VII"},
			{id: 8, name: "VIII"},
			{id: 9, name: "IX"},
			{id: 10, name: "X"},
			{id: 11, name: "XI"},
			{id: 12, name: "XII"},
			{id: 13, name: "XIII"},
			{id: 14, name: "XIV"},
			{id: 15, name: "XV"}
		];
	}

	static levels() {
		return [
			{id: "bak", name: "ბაკალავრიატი"},
			{id: "mag", name: "მაგისტრატურა"},
			{id: "doc", name: "დოქტორანტურა"},
			{id: "ert", name: "ერთსაფეხურიანი"},
			{id: "pro", name: "პროფესიული"},
			{id: "rez", name: "რეზიდენტი"},
			{id: "mos", name: "მოსწავლე"},
			{id: "pre", name: "ქართულ ენაში მომზადება"},
			{id: "int-bak-mag", name: "ინტეგრირებული საბაკალავრო-სამაგისტრო"},
			{id: "abi", name: "აბიტურიენტი"},
		];
	}

	// noinspection JSUnusedGlobalSymbols
	static levelNames() {
		const a = {};
		for (const o of this.levels()) {
			a[o.id] = o.name;
		}
		return a;
	}

	static levelNums() {
		return [
			"",
			"ბაკალავრი",
			"მაგისტრი",
			"დოქტორი",
			"ერთსაფეხურიანი",
			"პროფესიული",
			"რეზიდენტი",
			"მოსწავლე"
		];
	}
}

export class StudentResponse {
	result: string;
	data: any[];
	items: any[];
	error: string;
	errors: any;
	total: number;
	link: string;
	time: number;
	pid: number;
	smsTime: number;
	code: number;
	smsId: number;
}

export class StudentDataResponse {
	data: Student;
}

export class StudentFilter {
	id = null;
	registerStatus = "";
	registrationTime = "";
	medalist = "";
	refugee = "";
	name = "";
	firstName = "";
	lastName = "";
	personalNumber = "";
	gender = "";
	ageFrom = null;
	ageTo = null;
	learningSeason = null;
	learningYear = "";
	status = "";
	level = "";
	semester = "";
	schoolId: any = null;
	specId = "";
	choose = null;
	fields = {};
	documentId: null;
	phoneNumber: string;
	mail = "";
	bookId: number;
	ban: any;
	address: string;
	grants = {};
	// noinspection JSUnusedGlobalSymbols
	grantName: string;
	endYear: number;
	alumni = '';
	canQuestionnaireContact = "";
	financeVerified: number;
	agentId: any;
	verified: number;
	questionnaireEmployed = "";
	questionnairePosition = "";
	questionnaireInternship = "";
	questionnaireProgram = "";
	programCreditStart = "";
	programCreditEnd = "";
	multiFilter = false;
	tags = [];
	personalCardNum: "";
	financesLearningSeason: number = 0;
	hasFinanceGraph: boolean = false;
	regionId: any[] = [];
	country: any[] = [];
	group: any[] = [];
	hasInsurance: string = '';
	profBook: number;
	profGroups: any[] = [];
	stateId: any[] = [];
	photo: any = "";
	learningStartDate = "";
	smsAccept: number | null = null;
}

export class StudentFormDialogData {
	mode: string;
	ignoreValidations: boolean;
	student: Student;
}

export class Region {
	id: number;
	name: string;
}

export class RegionResponse {
	data: Region[];
}

// export class StudentCommands {
//
// }

