import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from "@angular/core";
import {ChatMessage} from "../../interfaces/ChatMessage";
import {UserService} from "../../services/user.service";

@Component({
	selector: 'ini-chat-message',
	templateUrl: './message.component.html',
	styleUrls: ['./message.component.scss']
})
export class Message implements OnInit, OnChanges {

	@Input() message: ChatMessage;
	@Input() seenStatus: boolean;
	@Output() onSlide: EventEmitter<any> = new EventEmitter<any>();
	me: boolean = false;
	seenUserIcons: string[] = [];

	constructor(
		private userService: UserService
	) {

	}

	ngOnInit(): void {
		this.me = this.userService.user.id === this.message.user.id && this.userService.user.type == this.message.user.type;
		if (this.message.seenUsers) {
			this.seenUserIcons = this.message.seenUsers.filter(seenUser => !(seenUser.id == this.userService.user.id && seenUser.type == this.userService.user.type))
													   .map(user => user.icon);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.message.seenUsers) {
			this.seenUserIcons = this.message.seenUsers.filter(seenUser => !(seenUser.id == this.userService.user.id && seenUser.type == this.userService.user.type))
													   .map(user => user.icon);
		}
	}


}
