import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Directive({
    selector: "[langObject]"
})
export class LangObjectDirective implements OnInit {

    private element: HTMLInputElement;
    @Input("langObject") source: any;
    lang = "ka";

    constructor(
        private elRef: ElementRef,
        private translate: TranslateService
    ) {
        this.element = elRef.nativeElement;
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe(result => {
            this.lang = result.lang;
            this.render();
        });
        this.lang = this.translate.currentLang;

        this.render();
    }

    render() {
        if (this.source.hasOwnProperty(this.lang)) {
            this.element.innerHTML = this.source[this.lang];
        } else if (this.source.hasOwnProperty("ka")) {
            this.element.innerHTML = this.source.ka;
        } else {
            this.element.innerHTML = "";
        }
    }

}