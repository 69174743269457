import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {PagesComponent} from './pages/pages.component';
import {LangComponent} from "./pages/lang/lang.component";
import {StructureComponent} from "./pages/structure/structure.component";
import {ComponentsTabs} from "./pages/components/components.tabs";
import {ListsDashboardComponent} from "./pages/lists/dashboard/lists-dashboard.component";
import {NotificationComponent} from "./pages/notification/notification.component";
import {TestingComponent} from "./pages/testing/testing.component";
import {BooksComponent} from "./pages/books/books.component";
import {PermissionTabsComponent} from "./pages/permissions/tabs/permission.tabs.component";
import {MessagesComponent} from "./pages/messages/messages.component";
import {RegistrationComponent} from "./pages/registration/registration.component";
import {TablesComponent} from "./pages/tables/tables.component";
import {TasksDashboard} from "./pages/tasks/tasks.dashboard";
import {PortfolioRoutesComponent} from "./pages/portfolio/routes/portfolio-routes.component";
import {FinancesRouteComponent} from "./pages/finances/module/finances.route-component";
import {RoutePage} from "./pages/route.page";
import {VoteFormComponent} from "./pages/personal-vote/vote-form/vote-form.component";
import {HrComponent} from "./pages/hr/hr.component";
import {EmptyPageComponent} from "./pages/empty-page/empty-page.component";
import {JwtHelperService} from "@auth0/angular-jwt";
import {MailboxComponent} from "./pages/mailbox/mailbox.component";
import {FinancesNewRouteComponent} from "./pages/finances-new/module/finances-new.route-component";
import {DiplomaEnclosureComponent} from "./pages/diploma-enclosure/diploma-enclosure.component";
import {AnalyticsMenu} from "./pages/analytics/analytics.menu";
import {PublicationsDashboard} from "./pages/publications/publications.dashboard";
import {MemorandumDashboard} from "./pages/memorandum/memorandum.dashboard";
import {AuthGuard} from "./core/guard/auth.guard";

let redirectLink = "hr";
const token = localStorage.getItem("token");
if (token !== null && typeof token !== "undefined" && token != "") {
	const helper = new JwtHelperService();
	const decodedToken = helper.decodeToken(token);
	if (typeof decodedToken.id !== "undefined") {
		if (decodedToken.guard == "prof") {
			redirectLink = "/lists/list";
		}
	}

	if (decodedToken.id == 1) {
		redirectLink = 'students';
	}
}

export const routes: Routes = [
	{
		path: '',
		canActivate: [
			AuthGuard
		],
		component: PagesComponent,
		children: [
			{
				path: '',
				//l_oadChildren: 'app/pages/dashboard/dashboard.module#DashboardModule',
				//data: { breadcrumb: 'მთავარი' }
				redirectTo: redirectLink,
				pathMatch: "full"
			},
			{
				pathMatch: "prefix",
				path: 'students',
				loadChildren: () => import('./pages/students/students.module').then(m => m.StudentsModule),
				data: {breadcrumb: 'სტუდენტები'}
			},
			{
				path: 'docs',
				loadChildren: () => import('./pages/docs/docs.module').then(m => m.DocsModule)
			},
			{
				path: 'lang',
				component: LangComponent,
				loadChildren: () => import('./pages/lang/lang.module').then(m => m.LangModule),
				data: {breadcrumb: 'თარგმნა'}
			},
			{
				path: 'structure',
				component: StructureComponent,
				loadChildren: () => import('./pages/structure/structure.module').then(m => m.StructureModule),
				data: {breadcrumb: 'სტრუქტურა'}
			},
			{
				path: 'components',
				component: ComponentsTabs,
				loadChildren: () => import('./pages/components/components.module').then(m => m.ComponentsModule),
				data: {breadcrumb: 'კომპონენტები'}
			},
			{
				path: 'lists',
				component: ListsDashboardComponent,
				loadChildren: () => import('./pages/lists/lists.module').then(m => m.ListsModule),
				data: {breadcrumb: 'უწყისები'}
			},
			{
				path: 'rights',
				loadChildren: () => import('./pages/rights/rights.module').then(m => m.RightsModule),
				data: {breadcrumb: 'მომხმარებლების უფლებები'}
			},
			{
				path: 'notifications',
				component: NotificationComponent,
				loadChildren: () => import('./pages/notification/notification.module').then(m => m.NotificationModule),
				data: {breadcrumb: 'განცხადებები'}
			},
			{
				path: 'messages',
				component: MessagesComponent,
				loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule),
				data: {breadcrumb: 'შეტყობინებები'}
			},
			{
				path: 'testing',
				component: TestingComponent,
				loadChildren: () => import('./pages/testing/testing.module').then(m => m.TestingModule),
				data: {breadcrumb: 'ტესტირება'}
			},
			{path: 'books', component: BooksComponent, data: {breadcrumb: 'სასწავლო კურსები'}},
			{
				path: 'event-calendar',
				loadChildren: () => import('./pages/event-calendar/event-calendar.module').then(m => m.EventCalendarModule),
				data: {breadcrumb: 'ღონისძიებათა კალენდარი'}
			},
			{
				path: 'permissions',
				component: PermissionTabsComponent,
				loadChildren: () => import('./pages/permissions/permission.module').then(m => m.PermissionModule),
				data: {breadcrumb: 'უფლებები'}
			},
			{
				path: 'observer-panel',
				loadChildren: () => import('./pages/observer-panel/observer-panel.module').then(m => m.ObserverPanelModule),
				data: {breadcrumb: 'დამკვირვებლის პანელი'}
			},
			{
				path: "mailbox",
				component: MailboxComponent,
				loadChildren: () => import("./pages/mailbox/mailbox.module").then(m => m.MailboxModule),
				data: {
					breadcrumb: ""
				}
			},
			{
				path: "sms",
				component: MailboxComponent,
				loadChildren: () => import("./pages/sms/sms.module").then(m => m.SmsModule),
				data: {
					breadcrumb: ""
				}
			},
			{
				path: "registration",
				component: RegistrationComponent,
				loadChildren: () => import("./pages/registration/registration.module").then(m => m.RegistrationModule),
				data: {
					breadcrumb: "აკადემიური რეგისტრაცია"
				}
			},
			{
				path: "tables",
				component: TablesComponent,
				data: {
					breadcrumb: "ცხრილები"
				}
			},
			{
				path: "tasks",
				component: TasksDashboard,
				loadChildren: () => import("./pages/tasks/tasks.module").then(m => m.TasksModule),
				data: {
					breadcrumb: "დავალებები"
				}
			},
			{
				path: "chancellery",
				loadChildren: () => import("./pages/chancellery/chancellery.module").then(m => m.ChancelleryModule),
				data: {breadcrumb: "კანცელარია"}
			},
			{
				path: 'portfolio',
				component: PortfolioRoutesComponent,
				loadChildren: () => import('./pages/portfolio/portfolio.module').then(m => m.PortfolioModule),
				data: {breadcrumb: 'პორტფოლიო'}
			},
			{
				path: "finances",
				component: FinancesRouteComponent,
				loadChildren: () => import("./pages/finances/module/finances.module").then(m => m.FinancesModule),
				data: {
					breadcrumb: "ფინანსები"
				}
			},
			{
				path: "finances-second",
				component: FinancesNewRouteComponent,
				loadChildren: () => import("./pages/finances-new/module/finances-new.module").then(m => m.FinancesNewModule),
				data: {
					breadcrumb: "ფინანსები"
				}
			},
			{
				path: "personal-vote",
				component: RoutePage,
				children: [
					{path: "", redirectTo: "vote-form", pathMatch: "full"},
					{path: "vote-form", component: VoteFormComponent}
				]
			},
			{
				path: 'poll',
				loadChildren: () => import('./pages/poll/poll.module').then(m => m.PollModule),
				data: {breadcrumb: 'გამოკითხვები'}
			},
			{
				path: 'poll-response',
				loadChildren: () => import('./pages/poll-response/poll-response.module').then(m => m.PollResponseModule),
				data: {breadcrumb: 'გამოკითხვები'}
			},
			{
				path: 'school-leavers',
				loadChildren: () => import('./pages/school-leavers/school-leavers.module').then(m => m.SchoolLeaversModule),
				data: {breadcrumb: 'აბიტურიენტების სია'}
			},
			{
				path: 'fellowship',
				loadChildren: () => import('./pages/fellowship/fellowship.module').then(m => m.FellowshipModule),
				data: {breadcrumb: 'სტიპენდიანთტა ცხრილი'}
			},
			{
				path: 'hr',
				component: HrComponent,
				loadChildren: () => import("./pages/hr/hr.module").then(m => m.HrModule),
				data: {breadcrumb: 'HR'}
			},
			{
				path: 'timeDemand',
				loadChildren: () => import('./pages/time-demand/time-demand.module').then(m => m.TimeDemandModule),
				data: {breadcrumb: 'საათების მოთხოვნა'}
			},
			{
				path: 'documents',
				loadChildren: () => import('./pages/documents/documents.module').then(m => m.DocumentsModule),
				data: {breadcrumb: 'დოკუმენტები'}
			},
			{
				path: "bib",
				loadChildren: () => import("./pages/bib/bib.module").then(m => m.BibModule),
				component: RoutePage,
				data: {breadcrumb: "ბიბლიოთეკა"}
			},
			{
				path: 'alumni',
				loadChildren: () => import("./pages/alumni/alumni.module").then(m => m.AlumniModule),
				data: {breadcrumb: 'კურსდამთავრებულები'}
			},
			{
				path: 'mobility',
				loadChildren: () => import("./pages/mobility/mobility.module").then(m => m.MobilityModule),
				data: {breadcrumb: 'მობილობა/აღიარება'}
			},
			{
				path: "reports",
				loadChildren: () => import("./pages/reports/reports.module").then(m => m.ReportsModule),
				component: RoutePage,
				data: {breadcrumb: "რეპორტინგი"}
			},{
				path: "el-stamp",
				loadChildren: () => import("./pages/el-stamp/el-stamp.module").then(m => m.ElStampModule),
				component: RoutePage,
				data: {breadcrumb: "ელექტრონული შტამპი"}
			},
			{
				path: "el-files",
				loadChildren: () => import("./pages/el-files/el-files.module").then(m => m.ElFilesModule),
				component: RoutePage,
				data: {breadcrumb: "ელექტრონული ფაილები"}
			},
			{
				path: "admissions",
				loadChildren: () => import("./pages/admissions/admissions.module").then(m => m.AdmissionsModule),
				component: RoutePage,
				data: {breadcrumb: "მობილობა/აღიარება"}
			},
			{
				path: "contact",
				loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule),
				component: RoutePage,
				data: {breadcrumb: 'კონტაქტი'}
			},
			{
				path: "my-devices",
				loadChildren: () => import('./pages/my-devices/my-devices.module').then(m => m.MyDevicesModule),
				component: RoutePage,
				data: {breadcrumb: 'ჩემი მოწყობილობები'}
			},
			{
				path: "user/notifications",
				loadChildren: () => import("./pages/user-notifications/user-notifications.module").then(m => m.UserNotificationsModule),
				component: RoutePage,
				data: {breadcrumb: 'შეტყობინებები'}
			},
			{
				path: "gift-laptops",
				loadChildren: () => import("./pages/gift-laptops/gift-laptops.module").then(m => m.GiftLaptopsModule),
				component: RoutePage,
				data: {breadcrumb: "საჩუქარი ლეპტოპები"}
			},
			{
				path: "diploma-enclosures",
				loadChildren: () => import("./pages/diploma-enclosure/diploma-enclosure.module").then(m => m.DiplomaEnclosureModule),
				component: DiplomaEnclosureComponent,
				data: {breadcrumb: "დიპლომის დანართი"}
			},
			{
				path: 'insurances',
				loadChildren: () => import('./pages/insurances/insurances.module').then(m => m.InsurancesModule),
				data: {breadcrumb: 'დაზღვევა'}
			},
			{
				path: 'mobility-books',
				loadChildren: () => import("./pages/mobility-books/mobility-books.module").then(m => m.MobilityBooksModule),
				data: {breadcrumb: 'მობილობის საგნები'}
			},
			{
				path: 'stepover',
				loadChildren: () => import("./pages/stepover/stepover.module").then(m => m.StepoverModule),
				data: {breadcrumb: 'step over'}
			},
			{
				path: 'analytics',
				loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsModule),
				component: AnalyticsMenu,
				data: {breadcrumb: 'ანალიტიკა'}
			},
			{
				path: "publications",
				component: PublicationsDashboard,
				loadChildren: () => import("./pages/publications/publications.module").then(m => m.PublicationsModule),
				data: {
					breadcrumb: "პუბლიკაციები"
				}
			},
			{
				path: "research",
				component: PublicationsDashboard,
				loadChildren: () => import("./pages/research/research.module").then(m => m.ResearchModule),
				data: {
					breadcrumb: "კვლევები"
				}
			},
			{
				path: "partner",
				component: PublicationsDashboard,
				loadChildren: () => import("./pages/partner/partner.module").then(m => m.PartnerModule),
				data: {
					breadcrumb: "პარტნიორები"
				}
			},
			{
				path: "pr-active",
				component: PublicationsDashboard,
				loadChildren: () => import("./pages/pr-actives/pr-actives.module").then(m => m.PrActivesModule),
				data: {
					breadcrumb: "PR აქტივობები"
				}
			},
			{
				path: "memorandum",
				component: MemorandumDashboard,
				loadChildren: () => import("./pages/memorandum/memorandum.module").then(m => m.MemorandumModule),
				data: {
					breadcrumb: "მემორანდუმი"
				}
			},
			{
				path: "exported-poll",
				component: PublicationsDashboard,
				loadChildren: () => import("./pages/exported-poll/exported-poll.module").then(m => m.ExportedPollModule),
				data: {
					breadcrumb: "ექსპორტირებული გამოკითხვა"
				}
			},
			{
				path: "LiiPrograms",
				component: PublicationsDashboard,
				loadChildren: () => import("./pages/lii-programs/lii-programs.module").then(m => m.LiiProgramsModule),
				data: {
					breadcrumb: "Lll პროგრამები"
				}
			},
			{
				path: "kpiOne",
				component: PublicationsDashboard,
				loadChildren: () => import("./pages/kpiOne/kpiOne.module").then(m => m.KpiOneModule),
				data: {
					breadcrumb: "Kpi1"
				}
			},
			{
				path: 'storage-settings',
				component: RoutePage,
				loadChildren: () => import("./pages/storage-settings/storage-settings.module").then(m => m.StorageSettingsModule),
				data: {
					breadcrumb: 'სივრცე',
				}
			},
			{
				path: "vacant-positions",
				component: RoutePage,
				loadChildren: () => import("./pages/vacant-positions/vacant-positions.module").then(m => m.VacantPositionsModule),
				data: {
					breadcrumb: "ვაკანტური ადგილები"
				}
			},
		]
	},
	{
		path: 'login',

		loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'online-register',
		component: EmptyPageComponent,
		loadChildren: () => import('./pages/online-register/online-register.module').then(m => m.OnlineRegisterModule)
	},
	{
		path: 'studentInfoQrCode/:token',
		component: EmptyPageComponent,
		loadChildren: () => import('./pages/student-info-qr-code/student-info-qr-code.module').then(m => m.StudentInfoQrCodeModule)
	},

];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
			// relativeLinkResolution: 'legacy',
			// useHash: true
		})
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {
}
