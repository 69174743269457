import {Component, Inject, OnInit} from "@angular/core";
import {BooksService} from "../books.service";
import {MAT_DIALOG_DATA, MatDialog} from "@angular/material/dialog";
import {Book} from "../book";
import {ConfirmDialog} from "../../../theme/components/confirmDialog/confirm.dialog";
import {BookProfAddDialogComponent} from "./book-prof-add-dialog/book-prof-add-dialog.component";

@Component({
	selector: "prof-book-dialog",
	templateUrl: "./prof-book-dialog.html",
	styleUrls: ["./prof-book-dialog.scss"]
})
export class ProfBookDialog implements OnInit {

	items = [];
	displayedColumns = [
		"id",
		"prof_id",
		"prof_name",
		"price",
		"actions"
	];


	constructor(
		private bookService: BooksService,
		@Inject(MAT_DIALOG_DATA) public book: Book,
		private dialog: MatDialog
	) {

	}

	add() {
		this.edit({});
	}

	edit(data: any) {
		this.dialog.open(BookProfAddDialogComponent, {
			data: {
				...data,
				book_id: this.book.id
			}
		}).afterClosed().subscribe(changes => changes && this.getItems());
	}

	// add()
	// {
	// 	this.form.patchValue({
	// 		prof_id:this.form.get('prof').value.id
	// 	})
	// 	this.bookService.addBookProf(this.form.value)
	// 		.subscribe(response => {
	// 			if(response.result == 'yes'){
	// 				this.form.patchValue({
	// 					id: "",
	// 					prof: "",
	// 					price: 0.00,
	// 					additionalPayTime: "",
	// 					additionalPayPrice: 0.00
	// 				})
	//                 this.getItems();
	// 			}else{
	//                 this.error.show(response);
	// 			}
	// 		})
	// }

	// edit(item) {
	// 	this.form.patchValue(item);
	// }




	remove(id) {
		const ref = this.dialog.open(ConfirmDialog, {
			data: {
				title: "პროფესორის წაშლა",
				text: "ნამდვილად გსურთ პროფესორის წაშლა",
				ok: "დიახ",
				cancel: "არა"
			}
		});
		ref.afterClosed().subscribe(result => {
			if (result) {
				this.bookService.removeBookProf({id: id}).subscribe(response => {
					this.getItems();
				});
			}
		});
	}

	getItems() {
		this.bookService.getBookProfs({book_id: this.book.id}).subscribe(response => {
			this.items = response;
		});
	}

	ngOnInit(): void {
		// this.form.patchValue({
		// 	book_id: this.book.id
		// })
		this.getItems();
	}

}
