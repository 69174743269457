import {Component, Inject} from "@angular/core";
import {StudentActionFormDialog} from "../../student-details/actions/student-action-form/student-action-form.dialog";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StudentsService} from "../../students.service";
import {FormBuilder} from "@angular/forms";
import {ErrorsDialog} from "../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: 'student-ban-dialog',
	templateUrl: './student-ban.dialog.html'
})

export class StudentBanDialog extends StudentActionFormDialog {

	disableOkButton: boolean = false;

	constructor(
		public override ref: MatDialogRef<StudentActionFormDialog>,
		@Inject(MAT_DIALOG_DATA) public override data: any,
		public override studentService: StudentsService,
		public override fb: FormBuilder,
		public override error: ErrorsDialog
	) {
		super(ref, data, studentService, fb, error);
		this.form.get('studentId').clearValidators();
		this.form.get('studentId').updateValueAndValidity();
	}


	override ok() {
		this.disableOkButton = true;
		const students =  this.data.students;
		this.studentService.addMassAction({students, ...this.form.value})
			.subscribe(response => {
				if (response.result == "yes") {
					this.ref.close(true);
					this.disableOkButton = false;
				} else {
					this.error.show(response);
					this.disableOkButton = false;
				}
			}, (error) => {
				console.error(error);
				this.disableOkButton = false;
			}, () => {});
	}

}
