import {Injectable, OnDestroy} from "@angular/core";
import {Observable, Observer, Subject} from 'rxjs';
import {AnonymousSubject} from 'rxjs/internal/Subject';
import {map} from 'rxjs/operators';

const CHAT_URL = "wss://signsocket.stepover.com:57357/signsocket/";

export interface Message {
	type: string;
	data: any;
}

declare const signSocket: any;
declare const $: any;

@Injectable()
export class StepOverService implements OnDestroy {

	private subject: AnonymousSubject<any>;
	public messages: Subject<any>;
	timestamp = new Date().getTime();
	messageId: number = 1;
	callbacks = [];
	events = {};

	imageTimer: any = 0;

	constructor() {


		$.extend(signSocket.events,{

			onStaticAppletSignatureFinished: function(e)
			{

				var status = e.data.value0;
				var signsession = e.data.value1;

				console.info( "status: " + status + ", seign session: " + signsession );

				clearInterval( this.imageTimer );
				signSocket.signSocketStopSigning( true );

			},
			onStaticAppletGetImage: function(e)
			{
				var image = "";

				var mode = e.data.value1;
				var page = e.data.value0;

				var data:any = {};
				if( mode == 1 )
				{
					data.messageId = e.data.messageId;
					data.response = "";
				}
				else
				{
					data.messageId = e.data.messageId;
					if (1) {
						data.response = "";
					}
				}

				signSocket.send( "onAppletGetImageResponse", data );
			}

		});


		signSocket.init( function(){

			signSocket.signSocketExecuteDeviceSearch( function( ret ){

				if( ret == 1 )
				{
					signSocket.signSocketStartViewing( 1, 150, function(){  } );
				}

			} );
		});


		// this.messages = <Subject<any>>this.connect(CHAT_URL).pipe(
		// 	map(
		// 		(response: any): any => {
		//
		// 			const data = JSON.parse(response.data);
		// 			return data;
		// 		}
		// 	)
		// );
		//
		// this.messages.subscribe(e => {
		// 	if( typeof this['callbacks'][ e.messageId ] != 'undefined' && typeof this['callbacks'][ e.messageId ] == 'function' )
		// 	{
		// 		this['callbacks'][ e.messageId ]( e.data.ret );
		// 	}
		// });
	}

	ngOnDestroy(): void {
		try {
			if (this.imageTimer) clearInterval(this.imageTimer);
		} catch (ex) {}
    }

	startSigning(top, left, width, height, resolution, page, isHashDialog, sessionType, signatureTimeout, config, callback) {
		signSocket.signSocketStartSigning(top, left, width, height, resolution, page, isHashDialog, sessionType, signatureTimeout, config, () => {
			try {
				if (this.imageTimer) clearInterval(this.imageTimer);
			} catch (ex) {}


			this.imageTimer = setInterval( function() {
				signSocket.signSocketGetSignatureImage( 800, 800, false, ( image ) => {
					callback(image);
				});
			}, 500 );
		});
		return this;
	}

	 stopSigning() {
		signSocket.signSocketStopSigning(true);
	}



	// public connect(url): AnonymousSubject<any> {
	// 	if (!this.subject) {
	// 		this.subject = this.create(url);
	//
	// 	}
	//
	//
	// 	return this.subject;
	// }
	//
	// private create(url): AnonymousSubject<any> {
	//
	// 	let ws = new WebSocket(url);
	// 	let observable = new Observable((obs: Observer<any>) => {
	// 		ws.onmessage = obs.next.bind(obs);
	// 		ws.onerror = obs.error.bind(obs);
	// 		ws.onclose = obs.complete.bind(obs);
	// 		return ws.close.bind(ws);
	// 	});
	// 	let observer = {
	// 		error: null,
	// 		complete: null,
	// 		next: (data: Object) => {
	//
	// 			if (ws.readyState === WebSocket.OPEN) {
	// 				ws.send(JSON.stringify(data));
	// 			}
	// 		}
	// 	};
	// 	return new AnonymousSubject<any>(observer, observable);
	// }
	//
	// signSocketExecuteDeviceSearch(callback): any {
	// 	this.send('executeDeviceSearch', null, callback);
	// }
	//
	// signSocketStartSigning(x , y, width, height, resolution, page, withHashDialog, signSession, signatureTimeout, config ,callback): any {
	// 	this.send('startSigning', { 'x': x, 'y':  y, 'width': width, 'height': height,
	// 		'resolution': resolution, 'page': page, 'withHashDialog' : withHashDialog, 'signSession' : signSession, 'signatureTimeout' : signatureTimeout, config }, callback);
	// }
	//
	// signSocketGetSignatureImage( width, height, withAlpha, callback )
	// {
	// 	this.send( 'getSignatureImage', { 'width': width, 'height': height, 'withAlpha': withAlpha }, callback );
	// };
	//
	// send(message, data, callback): void {
	// 	if (typeof data == "undefined" || data === null) {
	// 		data = {};
	// 	}
	//
	// 	if (!data.messageId) {
	// 		data.messageId = this.timestamp + '-' + this.messageId++;
	// 	}
	//
	// 	this.messages.next({
	// 		type: message,
	// 		data: data
	// 	});
	//
	// 	if ( typeof callback != 'undefined' ) {
	// 		this.callbacks[ data.messageId ] = callback;
	// 	}
	// }
	//
	// signSocketFinishSigning() {
	// 	this.send( 'finishSigning', null, undefined );
	// }
	//
	// signSocketStopSigning( showManufacturerLogo, callback )
	// {
	// 	this.send( 'stopSigning', { 'showManufacturerLogo' : showManufacturerLogo }, callback );
	// };
}