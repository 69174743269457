import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-student-sms-message-dialog',
  templateUrl: './student-sms-message-dialog.component.html',
  styleUrls: ['./student-sms-message-dialog.component.scss']
})
export class StudentSmsMessageDialogComponent implements OnInit {

  public text = "";

  constructor(
      private ref: MatDialogRef<StudentSmsMessageDialogComponent>,
      @Inject(MAT_DIALOG_DATA) private data: any,
  ) {
    this.text = data.text;
  }

  ngOnInit() {
  }

  cancel() {
    this.ref.close(false);
  }

}
