import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import html2canvas from 'html2canvas';
import {DOCUMENT} from "@angular/common";
import {MatrixDialog} from "./matrix.dialog";
import {IniEditorPreloadDialog} from "./ini.editor.preload.dialog";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";

declare let MathQuill: any;

@Component({
    selector: "ini-editor-math-dialog",
    templateUrl: "./ini.editor.math.dialog.html",
    styleUrls: ["./ini.editor.math.dialog.scss"],
    encapsulation: ViewEncapsulation.None
})
export class IniEditorMathDialog implements OnInit, AfterViewInit {

    constructor(
        private ref: MatDialogRef<IniEditorMathDialog>,
        @Inject(DOCUMENT) private _document: any,
        private dialog: MatDialog
    ) {
        this.symbolKeys = this.array_keys(this.symbols);
    }

    MQ = MathQuill.getInterface(2);
    /**/@ViewChild('textareaElement', {static: false}) textareaElement: ElementRef;
    value = "";
    mathField: any;



    tabName = "Symbols";
    symbols = {
        plusEqual: "\\pm",
        infty: "\\infty",
        equal: "=",
        notEqual: "\\neq",
        approximately: "\\sim",
        times: "\\times",
        div: "\\div",
        factorial: "!",
        proportional: "\\propto",
        lessThan: "<",
        muchLessThan: "\\ll",
        greaterTan: ">",
        muchGreaterTan: "\\gg",
        lessTanOrEqualTo: "\\leq",
        greaterTanOrEqualTo: "\\geq",
        minusPlus: "\\mp",
        approximatelyEqualTo: "\\cong",
        almostEqualTo: "\\approx",
        identicalTo: "\\equiv",
        forAll: "\\forall",

        complement: {
            type: "write",
            command: "\\mathsf{c}"
        },
        partialDifferential: "\\partial",
        root: {
            type: "write",
            command: "\\sqrt{}",
            move: 1,
            moveTo: "Left"
        },
        cubeRoot: {
            type: "write",
            command: "\\sqrt[3]{}",
            move: 1,
            moveTo: "Left"
        },
        fourthRoot: {
            type: "write",
            command: "\\sqrt[4]{}",
            move: 1,
            moveTo: "Left"
        },
        union: "\\cup",
        intersection: "\\cap",
        emptySet: "\\emptyset",
        percentage: "%",
        degrees: "\\degree",
        degreesFahrenheit: { command: "\\degree F" },
        degreesCelsius: { command: "\\degree C" },
        increment: "\\Delta",
        nabla: "\\nabla",
        thereExists: "\\exists",
        thereDoesNotExists: "\\nexists",
        elementOf: "\\in",
        containAsMember: "\\ni",
        leftArrow: "\\leftarrow",
        upArrow: "\\uparrow",
        rightArrow: "\\rightarrow",
        downArrow: "\\downarrow",
        leftRightArrow: "\\leftrightarrow",
        therefore: "\\therefore",
        plus: "+",
        minus: "-",
        notSign: "\\neg",
        alpha: "\\alpha",
        beta: "\\beta",
        gamma: "\\gamma",
        delta: "\\delta",
        epsilon: "\\varepsilon",
        epsilonVariant: "\\epsilon",
        theta: "\\theta",
        thetaVariant: "\\vartheta",
        mu: "\\mu",
        pi: "\\pi",
        rho: "\\rho",
        sigma: "\\sigma",
        tau: "\\tau",
        phi: "\\varphi",
        omega: "\\omega",
        asteriskOperator: "\\ast",
        bulletOperator: "\\bullet",
        verticalEllipsis: "\\vdots",
        midLineHorizontalEllipsis: "\\cdots",
        upRightDiagonalEllipsis: "\\udots",
        downRightDiagonalEllipsis: "\\ddots",
        alef: "\\aleph",
        bet: {command: "ℶ"},
        notIn: {command: "\\notin"},
        lim: {command: "\\begin{matrix}\\lim\\\\x\\rightarrow\\infty\\end{matrix}"}
    };

    symbolKeys = [];


    scripts ={
        stackedFraction: {
            command: "\\frac{\\square}{\\square}",
            left: 2
        },
        skewedFraction: {
            command: "^{ \\square } ⁄ _{ \\square }",
            left: 5
        },
        linearFraction: {
            command: "{\\square} ⁄ {\\square}",
            left: 2
        },

        superScript: {
            command: "{\\square} ^{\\square}",
            left: 1
        },
        subScript: {
            command: "{\\square} _{\\square}",
            left: 1
        },
        subScriptSuperScript: {
            command: "{\\square}^{\\square}_{\\square}",
            left: 5
        },
        leftSubScriptSuperScript: {
            command: "^{\\square} _{\\square} {\\square}"
        },

        squareRoot: {
            command: "\\sqrt{\\square}"
        },
        radicalWithDegree: {
            command: "\\sqrt[{\\square}]{\\square}"
        },
        squareRootWithDegree: {
            command: "\\sqrt[2]{\\square}"
        },
        cubicRoot: {
            command: "\\sqrt[3]{\\square}"
        },
        integral_1: {
            command: "\\int \\square"
        },
        integral_2: {
            command: "\\int ^{\\square}_{^{\\square}} \\square"
        },
        integral_3: {
            command: "\\int ^{\\square}_{\\square} \\square"
        },
        doubleIntegral_1: {
            command: "\\iint \\square"
        },
        doubleIntegral_2: {
            command: "\\iint ^{\\square}_{^{\\square}} \\square"
        },
        doubleIntegral_3: {
            command: "\\iint ^{\\square}_{\\square} \\square"
        },
        tripleIntegral_1: {
            command: "\\iiint \\square"
        },
        tripleIntegral_2: {
            command: "\\iiint ^{\\square}_{^{\\square}} \\square"
        },
        tripleIntegral_3: {
            command: "\\iiint ^{\\square}_{\\square} \\square"
        },

        contourIntegral_1: {
            command: "\\oint \\square"
        },
        contourIntegral_2: {
            command: "\\oint ^{\\square}_{^{\\square}} \\square"
        },
        contourIntegral_3: {
            command: "\\oint ^{\\square}_{\\square} \\square"
        },
        surfaceIntegral_1: {
            command: "\\oiint \\square"
        },
        surfaceIntegral_2: {
            command: "\\oiint ^{\\square}_{^{\\square}} \\square"
        },
        surfaceIntegral_3: {
            command: "\\oiint ^{\\square}_{\\square} \\square"
        },
        volumeIntegral_1: {
            command: "\\oiiint \\square"
        },
        volumeIntegral_2: {
            command: "\\oiiint ^{\\square}_{^{\\square}} \\square"
        },
        volumeIntegral_3: {
            command: "\\oiiint ^{\\square}_{\\square} \\square"
        },
        summation: {
            command: "\\sum _{ } ^{ } { }"
        },
        product: {
            command: "\\prod _{ } ^{ }"
        },
        coProduct: {
            command: "\\coprod _{ } ^{ }"
        },
        union: {
            command: "\\bigcup _{ } ^{ }"
        },
        intersection: {
            command: "\\bigcap _{ } ^{ }"
        },
        vee: {
            command: "\\bigvee _{ } ^{ }"
        },
        wedge: {
            command: "\\bigland _{ } ^{ }"
        },
        summation_f1: {
            command: "\\sum _k ^{ } \\left( _k ^n \\right)"
        },
        summation_f2: {
            command: "\\sum _{i=0} ^n \\square"
        },
        summation_f3: {
            command: "\\sum_{_{0<j<n}^{0\\le i\\le m}}^{ }P\\left(i,j\\right)"
        },
        product_f1: {
            command: "\\prod_{k=1}^nA_k"
        },
        union_f1: {
            command: "\\bigcup_{n=1}^m\\left(X_n\\cap Y_n\\right)"
        }
    };

    brackets = [
        12,
        4,
        18,
        4,
        3
    ];

    matrix_count = [
        9,
        4,
        4,
        2
    ];

    matrix = {
        "1_0": "\\begin{matrix} & \\end{matrix}",
        "2_0": "\\begin{matrix} \\\\ \\end{matrix}",
        "3_0": "\\begin{matrix} & & \\end{matrix}",
        "4_0": "\\begin{matrix} \\\\ \\\\ \\end{matrix}",
        "5_0": "\\begin{matrix} & \\\\ & \\end{matrix}",
        "6_0": "\\begin{matrix} & & \\\\ & & \\end{matrix}",
        "7_0": "\\begin{matrix} & \\\\ & \\\\ & \\end{matrix}",
        "8_0": "\\begin{matrix} & & \\\\ & & \\\\ & & \\end{matrix}",
        "0_1": "\\begin{matrix}1&0\\\\0&1\\end{matrix}",
        "1_1": "\\begin{matrix}1&\\\\&1\\end{matrix}",
        "2_1": "\\begin{matrix}1&0&0\\\\0&1&0\\\\0&0&1\\end{matrix}",
        "3_1": "\\begin{matrix}1 & &\\\\ & 1 & \\\\ & & 1 \\end{matrix}",
        "0_2": "\\left( \\begin{matrix} & \\\\ & \\end{matrix} \\right)",
        "1_2": "\\left[ \\begin{matrix} & \\\\ & \\end{matrix} \\right]",
        "2_2": "\\left| \\begin{matrix} & \\\\ & \\end{matrix} \\right|",
        "3_2": "\\left\\lVert\\begin{matrix}&\\\\&\\end{matrix}\\right\\rVert",
        "0_3": "\\left( \\begin{matrix} & \\cdots & \\\\ \\vdots & \\ddots & \\vdots \\\\ & \\cdots & \\end{matrix} \\right)",
        "1_3": "\\left[ \\begin{matrix} & \\cdots & \\\\ \\vdots & \\ddots & \\vdots \\\\ & \\cdots & \\end{matrix} \\right]",
    };

    bracketSymbols = {
        "0_0": "\\left(\\square\\right)",
        "1_0": "\\left[\\square\\right]",
        "2_0": "\\left\\{\\square\\right\\}",
        "3_0": "\\left\\langle\\square\\right\\rangle",
        "4_0": "\\lfloor\\square\\rfloor",
        "5_0": "\\lceil\\square\\rceil",
        "6_0": "\\left|\\square\\right|",
        "7_0": "\\parallel\\square\\parallel",
        "8_0": "\\lbrack \\square \\lbrack",
        "9_0": "\\rbrack \\square \\rbrack",
        "10_0": "\\rbrack \\square \\lbrack",
        "11_0": "\\llbracket\\square\\rrbracket",

        "0_1": "\\left(\\square\\mid\\square\\right)",
        "1_1": "\\left\\{\\square\\mid\\square\\right\\}",
        "2_1": "\\left\\langle\\square\\mid\\square\\right\\rangle",
        "3_1": "\\left\\langle\\square\\mid\\square\\mid\\square\\right\\rangle",

        "0_2": "\( \\square",
        "1_2": "\\square \)",
        "2_2": "\[ \\square",
        "3_2": "\\square \]",
        "4_2": "\\lbrace \\square",
        "5_2": "\\square \\rbrace",
        "6_2": "⟨ \\square",
        "7_2": "\\square ⟩",
        "8_2": "\\lfloor \\square",
        "9_2": "\\square \\rfloor",
        "10_2": "\\lceil \\square",
        "11_2": "\\square \\rceil",
        "12_2": "\\mid \\square",
        "13_2": "\\square \\mid",
        "14_2": "\\parallel \\square",
        "15_2": "\\square \\parallel",
        "16_2": "\\llbracket \\square",
        "17_2": "\\square \\rrbracket",

        "0_3": "\\left\\ftopen_{\\square}^{\\square}\\right\\ftclose",
        "1_3": "\\left\\ftopen_{_{\\square}^{\\square}}^{^{\\square}}\\right\\ftclose",
        "2_3": "_{\\square}^{\\square}",
        "3_3": "\\left(_{\\square}^{\\square}\\right)",

        "0_4": "f\\left(x\\right)=\\left\\ftopen_{x,x\\ge0}^{-x,x<0}\\right\\ftclose",
        "1_4": "\\left(_k^n\\right)",
        "2_4": "\\left\\langle_{\\square}^{\\square}\\right\\rangle"

    };
    addSymbol(index) {
        if (typeof this.symbols[index] == "string") {
            // noinspection TypeScriptValidateJSTypes
            this.mathField.cmd(this.symbols[index]).focus();
        } else {
            switch (this.symbols[index].type) {
                default:
                    this.mathField.write(this.symbols[index].command).focus();
                    if (typeof this.symbols[index].move != "undefined") {
                        for(let i=1; i<=this.symbols[index].move; i++){
                            this.mathField.keystroke(this.symbols[index].moveTo);
                        }
                    }
                    break;
            }
        }

    }

    add(index) {
        if (typeof this.scripts[index] != "undefined") {
            if (typeof this.scripts[index].command != "undefined") {
                this.mathField.write(this.scripts[index].command).focus();
            }
            if (typeof this.scripts[index].left != "undefined") {
                for(let i=1; i<=this.scripts[index].left; i++) {
                    this.mathField.keystroke("Left");
                }
            }
            if (typeof this.scripts[index].up != "undefined") {
                for(let i=1; i<=this.scripts[index].up; i++) {
                    this.mathField.keystroke("Up");
                }
            }
            if (typeof this.scripts[index].down != "undefined") {
                for(let i=1; i<=this.scripts[index].down; i++) {
                    this.mathField.keystroke("Down");
                }
            }
            if (typeof this.scripts[index].right != "undefined") {
                for(let i=1; i<=this.scripts[index].right; i++) {
                    this.mathField.keystroke("Right");
                }
            }

        }
    }

    array_keys(o) {
        const a = [];
        for (const i in o) {
            if (o.hasOwnProperty(i)) {
                a.push(i);
            }
        }
        return a;
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.mathField = this.MQ.MathField(this.textareaElement.nativeElement, {
            spaceBehavesLikeTab: true,
            handlers: {
                edit: () => {
                    this.value = this.mathField.latex();
                }
            }
        });
    }

    cancel() {
        this.ref.close(false);
    }
    bracketCount(c: number): number[] {
        const n: number[] = [];
        for (let i=0; i<c; i++) {
            n.push(i);
        }
        return n;
    }
    bracketStyle(item, i) {
        if (i==4 && item==0) {
            return {
                'background-position': '-'+(item*72)+'px -'+(i*96)+'px',
                width: "144px"
            };
        } else if (i==4 && item>0) {
            return {'background-position': '-'+((item+1)*72)+'px -'+(i*96)+'px'};
        }
        return {'background-position': '-'+(item*72)+'px -'+(i*96)+'px'};
    }


    matrixStyle(item, i) {
        if (i == 3) {
            return {
                'background-position': '-'+(item*144)+'px -'+(i*96)+'px',
                width: "144px"
            };
        }
        return {'background-position': '-'+(item*72)+'px -'+(i*96)+'px'};
    }

    addMatrix(x, y) {
        const type = x+"_"+y;

        if (type == "0_0") {
            const ref = this.dialog.open(MatrixDialog);
            ref.afterClosed().subscribe(result => {
                if (typeof result != "undefined" && result) {
                    const s = [];
                    const line = [" "];
                    for (let j=0; j<result.x-1; j++) {
                        line.push(" & ");
                    }

                    for (let i=0; i<result.y; i++) {
                        s.push(line.join(" "));
                    }

                    if (s.length>0) {
                        this.mathField.write(
                            "\\begin{matrix} "+
                            s.join("\\\\")+
                            "\\end{matrix}"
                        ).focus();
                    }
                }
            });
        } else if (typeof this.matrix[type] != "undefined") {
            this.mathField.write(this.matrix[type]).focus();
        }
    }
    addBrackets(x,y) {
        const type = x+"_"+y;
        if (typeof this.bracketSymbols[type] != "undefined") {
            this.mathField.write(this.bracketSymbols[type]).focus();
        }
    }

    ok() {
        const r = this.dialog.open(IniEditorPreloadDialog,{
            disableClose: true
        });
        r.afterOpened().subscribe(() => {
            const el = this.textareaElement.nativeElement.children[1];
            el.style.display = "inline-block";
            el.style.width = "auto";
            let emptyElements = this._document.getElementsByClassName("mq-empty");
            for (let i =0; i<emptyElements.length; i++) {
                emptyElements[i].style.background = "transparent";
            }
            emptyElements = this._document.getElementsByClassName("mq-ghost");
            for (let i =0; i<emptyElements.length; i++) {
                emptyElements[i].style.display = "none";
            }
            html2canvas(el, {
                logging: false,
                backgroundColor: null
            }).then(canvas => {
                const s = canvas.toDataURL("image/png");
                this.ref.close({
                    src: s
                });
                r.close();
            });

        });


    }

}
