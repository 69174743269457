import {MailboxService} from "../../../pages/mailbox/mailbox.service";

export class Menu {

	budgetService: any | MailboxService = null;
	subs: any = [];
	notifyCount = 0;

	// noinspection JSUnusedGlobalSymbols
  constructor(public id: number,
	            public title: string,
	            public routerLink: string | null,
	            public href: string | null,
	            public icon: string,
	            public permission: string | null = "",
	            public target: string | null = null,
	            public hasSubMenu: boolean=false,
	            public parentId: number=0,
	) {

	}

	// noinspection JSUnusedGlobalSymbols
  setBudgetService(service) {
		this.budgetService = service;
		this.watchService();
	}

	watchService() {
		if (this.budgetService != null) {
			this.subs.push(
				this.budgetService.event.subscribe(msg => {
					if (msg == "messageSent" || msg == "readInbox") {
						this.getNotifyCount();
					} else if (this.routerLink == "/chancellery" &&typeof msg == 'object' && msg != null && msg.action == 'checkChancellery') {
						this.getNotifyChancelleryCount();
					}
				})
			);
			if (this.routerLink == "/chancellery") {
				this.getNotifyChancelleryCount();
			} else {
				this.getNotifyCount();
			}
		}
	}

	getNotifyCount() {
		this.budgetService.getNotifyCount().subscribe(response => {
			this.notifyCount = response.inbox;
		});
	}
	getNotifyChancelleryCount() {
		this.budgetService.getChancelleryCount().subscribe(response => {
			this.notifyCount = response.inbox;
		});
	}
	//
	// ngOnDestroy(): void {
	// 	for (const sub of this.subs) {
	// 		try {
	// 			sub.unsubscribe();
	// 		} catch (ex) {}
	// 	}
	// }

}
