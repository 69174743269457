import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ListService} from "../../../list.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ErrorsDialog} from "../../../../../../shared/error-dialogs/errors.dialog";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PermissionService} from "../../../../../../core/services/permission.service";
import {MatPaginator} from "@angular/material/paginator";

@Component({
    selector: "est-result-form",
    templateUrl: "./est-result-form.html",
    styleUrls: ["./est-result-form.scss"]
})
export class EstResultForm implements OnInit, OnDestroy, AfterViewInit {


    isEnabled = true;
    total = 0;
    limit = 10;
    page = 1;
    canPrev = true;
    canNext = true;
    groups = [];

    constructor(
        private service: ListService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private ref: MatDialogRef<EstResultForm>,
        private error: ErrorsDialog,
        private fb: FormBuilder,
        private permissionService: PermissionService
    ) {
        this.canPrev = this.data.canPrev;
        this.canNext = this.data.canNext;
        this.form = fb.group({
            coId: ["", Validators.required],
            groupId: ["", Validators.required],
            listId: ["", Validators.required],
            studentId: ["", Validators.required],
            results: fb.array([])
        });
        this.subs.push(this.data.event.subscribe(data => {
            if ((data.eventName == "calcEst") &&
                (this.data.list.id == data.listId) &&
                (this.data.group.id == data.groupId) &&
                (this.data.component.coId == data.coId) &&
                (this.data.student.id == data.studentId)
            ) {
                this.setData(data.result);
            }
        }));
        this.isEnabled = this.permissionService.can("menu.learningProcesses.lists.putEst");
        this.loadSavedPagination();

        /*
        let g = {};
        let groups = [];
        for (let com of this.data.component.items) {
            let groupName = "-";
            if (typeof com.group == "undefined" || com.group == null || com.group == 0 || com.group == '') {
                groupName = "-";
            } else {
                groupName = com.group;
            }
            g[groupName] = true;
        }
        for (let group in g) {
            groups.push(group);
        }
        this.groups = groups;
        // */
    }
    round(a:number, b:number=0) {
        return parseFloat(Number(this.toInt(a)).toFixed(b));
    }
    toInt(a:any) {
        let b:any = parseFloat(a);
        if (b+"" == "NaN") return 0; else return b;
    }

    checkCustomValidation() {
        if (typeof this.data.component == "object" && this.data.component != null) {
            if (typeof this.data.component.items == "object" && this.data.component.items !=null && typeof this.data.component.items.length != "undefined") {
                let formIndex = 0;
                for (let item of this.data.component.items) {
                    if (typeof item.data == "object" && item.data !=null && typeof item.data.component != "undefined") {
                        //
                        let otherCompEst:any = this.getOtherCompEst(item.data.component);

                        if (typeof otherCompEst == "object" && otherCompEst != null) {
                            //
                            let count = 0;
                            let sum = 0;
                            let max = 0;
                            let i = 0;
                            if (item.data.groupType == "group") {

                                for (let est of otherCompEst.items) {
                                    try {

                                        let groupName = "-";
                                        if (typeof est.group == "undefined" || est.group == null || est.group == '' || est.group == 0) {
                                            groupName = "-";
                                        } else {
                                            groupName = est.group;
                                        }
                                        if (groupName == item.data.from) {
                                            let est_data = [];
                                            try {
                                                if (typeof est.value == "string") {
                                                    est_data = JSON.parse(est.value);
                                                }
                                            } catch (ex) {
                                                est_data = [];
                                            }

                                            for (let j = 0; j < est.weight; j++) {
                                                i++;
                                                //
                                                if (typeof est_data[j] != "undefined" && est_data[j] == 1) {
                                                    sum += 0.1;
                                                }
                                                max += 0.1;
                                                count++;
                                            }

                                        }
                                    } catch (e) {}
                                }
                            } else {
                                for (let est of otherCompEst.items) {
                                    //
                                    try {

                                        let est_data = [];
                                        try {
                                            if (typeof est.value == "string") {
                                                est_data = JSON.parse(est.value);
                                            }
                                        } catch (ex) {
                                            est_data = [];
                                        }
                                        for (let j = 0; j < est.weight; j++) {
                                            i++;
                                            //
                                            if (i >= item.data.from && i <= item.data.to) {
                                                if (typeof est_data[j] != "undefined" && est_data[j] == 1) {
                                                    sum += 0.1;
                                                }
                                                max += 0.1;
                                                count++;
                                            }
                                        }

                                    } catch (ex) {
                                    }
                                }
                            }
                            if (item.data.type == 'percentage') {
                                if (max >0) {
                                    sum = this.round(100 / max * sum);
                                } else {
                                    sum = 0;
                                }
                            }
                            let f: FormArray = <FormArray>this.form.get("results");
                            if (sum<item.data.argument) {
                                f.controls[formIndex].disable();
                                /*
                                f.controls[formIndex].patchValue({
                                    value: ''
                                });
                                 */
                                //
                            } else {
                                f.controls[formIndex].enable();
                                //
                            }
                        }
                    }
                    formIndex++;
                }
            }
        }
    }

    getOtherCompEst(componentCode) {
        let coId = 0;
        let type = 0;
        let selectableValues = [];
        for (let i in this.data.allComponents) {
            if (this.data.allComponents[i].code == componentCode) {
                coId = this.data.allComponents[i].id;
                type = this.data.allComponents[i].type;
                selectableValues = typeof this.data.allComponents[i].selectableValues == "object" && this.data.allComponents[i].selectableValues != null ? this.data.allComponents[i].selectableValues : [];
                break;
            }
        }
        if (coId != 0) {
            for (let comp of this.data.group.components) {
                if (coId == comp.coId) {
                    if (type == 4) {
                        return {
                            selectable: selectableValues,
                            items: comp.items
                        };
                    }
                }
            }
        }
        return false;
    }

    form: FormGroup;
    subs= [];

    static validEstValue(group: FormGroup) {
        if (group.value.value != "") {
            const value = parseFloat(group.value.value);
            const maxValue = parseFloat(group.value.maxValue);
            if (maxValue != 0 && maxValue != null && maxValue+"" != "NaN") {
                if (value>maxValue) {
                    group.get("value").setErrors({
                        valueTooBig: true
                    });
                    return null;
                }
            }
        }
        group.get("value").setErrors(null);
        return null;
    }

    setData(results) {
        const f = this.form.get("results") as FormArray;
        for (const i in results) {
            try {
                this.data.component.items[i].result = results[i].result;
            } catch (ex) {}
            if (typeof f.get(i) != "undefined") {
                try {
                    f.get(i).patchValue(results[i]);
                } catch (ex) {}
            }
        }
    }

    ok(next=0) {
        /*
        let sendObject = {
            listId: this.data.list.id,
            groupId: this.data.group.id,
            studentId: this.data.student.id,
            coId: this.data.component.coId,
            results: []
        };
        for (let com of this.data.component.items) {
            sendObject.results.push(com.value);
        }
        */
        this.setBlockStatus(false);
        this.service.setEst(this.form.value).subscribe(response => {
            if (response.result == "yes") {
                this.ref.close(
                    next == 1 ? "next" : (
                        next == 2 ? 'prev' : true
                    )
                );
            } else {
                this.error.show(response);
            }
        });
    }

    setBlockStatus(isBlocked) {
        let f: FormArray = < FormArray>this.form.get("results");

        for (let i=0; i<f.controls.length; i++) {
            if (isBlocked) {
                f.controls[i].disable();
            } else {
                f.controls[i].enable();
            }
        }
    }

    cancel() {
        this.ref.close(false);
    }

    next() {
        if (this.canNext) {
            this.ok(1);
        }
    }

    prev() {
        if (this.canPrev) {
            this.ok(2);
        }
    }

    ngOnInit(): void {
        this.form.patchValue({
            coId: this.data.component.coId,
            groupId: this.data.group.id,
            listId: this.data.list.id,
            studentId: this.data.student.id,
            results: [],
        });
        const f = this.form.get("results") as FormArray;
        // clear
        while (f.length !== 0) {
            f.removeAt(0);
        }
        // set
        this.data.component.items.sort((a, b) => {
            let aGroupName = '-';
            let bGroupName = '-';
            if (typeof a.group == "undefined" || a.group == null || a.group == '' || a.group == 0) {
                aGroupName = '-';
            } else {
                aGroupName = a.group;
            }
            if (typeof b.group == "undefined" || b.group == null || b.group == '' || b.group == 0) {
                bGroupName = '-';
            } else {
                bGroupName = b.group;
            }
            if (aGroupName == bGroupName) return 0;
            return aGroupName > bGroupName ? 1 : -1;
        });

        for (const est of this.data.component.items) {
            const v = this.createGroup(est);
            f.push(v);
        }
        if (!this.isEnabled) {
            this.form.disable();
        }
        this.checkCustomValidation();
    }

    ngOnDestroy(): void {
        for (const sub of this.subs) {
            try {
                sub.unsubscribe();
            } catch (ex) {}
        }
    }

    createGroup(info) {
        const est = this.fb.group({
            maxValue: "",
            minLimit: "",
            result: "",
            value: "",
            weight: "",
            group: ""
        }, {
            validator: EstResultForm.validEstValue
        });
        est.patchValue(info);
        return est;
    }

    getItems() {
        let start = this.page*this.limit-this.limit;
        let end = this.page*this.limit;
        let a = [];
        this.total = this.form.get("results")["controls"].length;
        for (let i=0; i<this.total; i++) {
            if (i>=start && i<end) {
                a.push(this.form.get("results")["controls"][i]);
            }
        }

        return a;
    }
    /**/@ViewChild("paginator", {static: false}) paginator: MatPaginator;
    ngAfterViewInit(): void {
        this.paginator.page.subscribe(res => {
            this.page = res.pageIndex+1;
            this.limit = res.pageSize;
            this.savePagination();
        });
    }

    savePagination() {
        try {
            const savedListPagination = localStorage.getItem("saved-list-pagination");
            let savedData = {};
            if (savedListPagination != null && savedListPagination != '') {
                savedData = JSON.parse(savedListPagination);
                if (savedData == null) {
                    savedData = {};
                }
            }
            savedData[this.data.list.id+"-"+this.data.component.coId] = {
                page: this.page,
                limit: this.limit
            };
            localStorage.setItem("saved-list-pagination", JSON.stringify(savedData));
        } catch (ex) {}
    }

    loadSavedPagination() {
        try {
            const savedListPagination = localStorage.getItem("saved-list-pagination");
            if (savedListPagination != null) {
                const savedData = JSON.parse(savedListPagination);
                if (typeof savedData == "object" && savedData != null && typeof savedData[this.data.list.id+"-"+this.data.component.coId] != "undefined") {
                    this.limit = savedData[this.data.list.id+"-"+this.data.component.coId].limit;
                    this.page = savedData[this.data.list.id+"-"+this.data.component.coId].page;
                }
            }
        } catch (ex) {}
    }

    getComponentItems(items) {
        let start = this.page*this.limit-this.limit;
        let end = this.page*this.limit;
        let a = [];
        let i=0;
        for (let item of items) {
            if (i>=start && i<end) {
                a.push(item);
            }
            i++;
        }
        return a;
    }

}
