export class Education {
    constructor(
        public id = 0,
        public organisationName = "",
        public status =0,
        public level = 0,
        public licenseNumber = "",
        public licenseStartYear = "",
        public licenseEndYear = "",
        public semester = "",
        public studentId = 0
    ) {

    }

}
export class EducationResponse {
    result: string;
    data: Education[];
    error: string;
    errors: any;
}
