import {ControlValueAccessor, FormControl} from "@angular/forms";
import {Observable} from "rxjs";
import {Injectable, Input} from "@angular/core";
import {switchMap} from "rxjs/operators";
import {COMMA, ENTER} from "@angular/cdk/keycodes";

@Injectable()
export class IniInput implements ControlValueAccessor {

    firstChange = false;
	multiple = false;
	formControl = new FormControl();
	items: Observable<any[]>;
	@Input("placeholder") placeholder = "";
	@Input("type") type = "old";
	@Input("lang") lang: string = "ka";
	eValue = false;
	forString = false;

    constructor() {
        this.items = this.formControl.valueChanges.pipe(
            //debounceTime(200),
            switchMap(q => {
                if (typeof q == "string" && /*this.eValue &&*/ !this.forString) {
                    if (this.firstChange) {
                        this.firstChange = false;
                    } else {
						if (this.multiple) {
							this.onChange(this.multipleValue);
						} else {
							this.onChange("");
						}
                    }
                    this.eValue = false;
                }
                return this.search(q);
            } )
        );
    }
	separatorKeysCodes: number[] = [ENTER, COMMA];
	multipleValue = [];

	removeMultiple(item: any) {
		this.multipleValue = this.multipleValue.filter(i => i.id != item.id);
		this.emitChange();
	}

	addMultiple(e) {

	}

	selectedMultiple(e: any) {
		const value = e.option.value;
		const exists = this.multipleValue.find(item => item.id == value.id);
		!exists && this.multipleValue.push(value);
		this.formControl.patchValue('');
		this.emitChange();
	}

    public onChange: (value: any) => void;

    search(q: string): Observable<any[]> {
        return new Observable<any[]>(serve => {
            serve.next([]);
        });
    }

    displayAsName(item) {
		if (typeof item != "undefined" && item != null && typeof item.name != "undefined") {
			return item.name;
		}
		return '';
	}

    emitChange() {
        if (typeof this.onChange == "function") {
            this.eValue = true;
            this.onChange(this.multiple ? this.multipleValue : this.formControl.value);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    writeValue(obj: any): void {
		if (this.multiple) {
			this.multipleValue = obj && typeof obj == 'object' && typeof obj.push != 'undefined' ? obj : [];
		} else {
			this.formControl.patchValue(obj);
		}
    }
}
