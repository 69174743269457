import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FileUploadProgress, FileUploadProgressType} from "../interfaces/FileUploadProgress";
import {FileStorage} from "../../../core/services/FileStorage";
import {createId} from "@paralleldrive/cuid2";

declare const Resumable: any;

@Injectable()
export class UploaderService {



	constructor() {

	}

	upload(files: FileList, chatId: number, folder): Observable<FileUploadProgress> {
		return new Observable<FileUploadProgress>(observer => {
			// noinspection DuplicatedCode
			const uid = createId();
			// noinspection DuplicatedCode
			const r = new Resumable({
				target: FileStorage.storageServer + '/upload',
				throttleProgressCallbacks: 1,
				generateUniqueIdentifier: (file) => {
					const relativePath = file.webkitRelativePath||file.relativePath||file.fileName||file.name;
					const size = file.size;
					return uid+'-'+(size + '-' + relativePath.replace(/[^0-9a-zA-Z_-]/img, ''));
				},
				query: {
					api_key: FileStorage.api_key,
					folder,
				}
			});

			let addresses = [];

			r.on('fileSuccess', (event, message: any) => {

				if (typeof message == 'string') {
					try {
						message = JSON.parse(message);
					} catch (ex) {}
				}

				if (message && message.status_code == 1) {
					addresses.push(message.address);
				}

				observer.next({
					type: FileUploadProgressType.FINISHED,
					progress: 100,
					attachment: addresses,
					name: event.file.name,
					size: event.file.size,
					fileType: event.file.type,
				});

			});
			r.on('complete', () => {
				observer.complete();
			});
			r.on('fileProgress', (file) => {
				const progress = Math.floor(file.progress() * 100);
				observer.next({
					type: FileUploadProgressType.PROGRESS,
					progress: progress,
				});
			});
			r.on('fileAdded', () => {
				r.upload();
			});
			r.addFiles(files);



			// const formData = new FormData();
			// for (let i = 0; i < files.length; i++) {
			//   formData.append('files', files[i]);
			// }
			// formData.append('token', this.user.getToken() as string);
			// formData.append('chatId', chatId+'');
			// this.http.post(Config.url+'/upload', formData, {
			//   reportProgress: true,
			//   observe: 'events'
			// })
			//   .subscribe((event: any) => {
			//     if (event.type === HttpEventType.UploadProgress) {
			//       const progress = Math.round(100 * event.loaded / event.total);
			//       observer.next({
			//         type: FileUploadProgressType.PROGRESS,
			//         progress: progress,
			//       });
			//     } else if (event instanceof HttpResponse) {
			//       observer.next({
			//         type: FileUploadProgressType.FINISHED,
			//         progress: 100,
			//         //file: event.body,
			//       });
			//       observer.complete();
			//     }
			//   });

		});
	}


}
