import {Component, Input} from "@angular/core";

@Component({
	selector: "fa",
	template: `<fa-icon [icon]="name"></fa-icon>`
})
export class FaIcon {

	@Input("name") name: any;



}