import {EventEmitter, Injectable} from "@angular/core";

import {Socket} from "ngx-socket-io";
import {UniConfig} from "../../../uni.config";
import {UserService} from "./user.service";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class UserNotificationService extends Socket {

	event: EventEmitter<string> = new EventEmitter<string>();
	isAuth: boolean = false;

	constructor(
		private userService: UserService,
		private http: HttpClient
	) {
		super({
			url: UniConfig.chatSocketUrl,
			options: {
				transports: ["websocket"]
			}
		});

		this.on("AuthSuccess", () => {
			this.isAuth = true;
			this.subscribeEvents();
		});
		this.userService.onChange.subscribe(() => {
			if (this.userService.is_logged) {
				this.auth();
			} else {
				this.logout();
			}
		});
		if (this.userService.is_logged) {
			this.auth();
		}
	}


	subscribeEvents() {
		this.emit("subscribe", {
			channelName: "events-" + this.userService.user.id,
			key: "events-" + this.userService.user.id
		});

		this.on("events-" + this.userService.user.id, (msg) => {
				this.event.emit(msg);
			}
		);
	}

	auth() {
		const token = this.userService.token;
		if (typeof token == "string") {
			this.emit("auth", {
				token
			});
		}
	}

	logout() {
		this.emit("logout");
	}


	getUserNotificationsCount(data): Observable<any> {
		return this.http.post("/user/notifications/count", data);
	}


}
