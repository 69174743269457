import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {UserNotificationService} from "../../../core/services/user-notification.service";
import {Router} from "@angular/router";

@Component({
	selector: 'app-notifications-menu',
	templateUrl: './notifications-menu.component.html',
	encapsulation: ViewEncapsulation.None
})

export class NotificationsMenuComponent implements OnInit, OnDestroy {

	subscriptions: any[] = [];

	constructor(
		public notificationService: UserNotificationService,
		private router: Router
	) {

	}


	unreadNotifications: number = 0;

	ngOnInit(): void {
		this.subscriptions.push(
			this.notificationService.event.subscribe((msg: any) => {
				if (typeof msg === 'object' && msg.action == 'checkUserNotification') {
					this.getNotifications();
				}
			})
		);
		this.getNotifications();
	}

	getNotifications(): void {
		this.notificationService.getUserNotificationsCount({})
			.subscribe(response => {
				this.unreadNotifications = response.unreadNotifications;
			});
	}

	ngOnDestroy() {
		for (let subscription of this.subscriptions) {
			try {
				subscription.unsubscribe();
			} catch (e) {
			}
		}
	}

	reloadPage()
	{
		if(this.router.url === '/user/notifications/list'){
			window.location.reload();
		}
	}

}
