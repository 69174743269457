import {NgModule} from "@angular/core";
import {IniDatePickerComponent} from "./ini-date-picker.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {CommonModule} from "@angular/common";
import {IniDatePickerHeader} from "./ini-date-picker-header";
import {CustomDateAdapter} from "../../../shared/services/customDateAdapter";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule} from "@angular/material/core";

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'DD-MMM-YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        FlexLayoutModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        MatOptionModule
    ],
    exports: [
        IniDatePickerComponent
    ],
    entryComponents:[
        IniDatePickerHeader
    ],
    declarations: [
        IniDatePickerComponent,
        IniDatePickerHeader
    ],
    providers: [
        {provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_LOCALE, useValue: 'en-EN'},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})
export class IniDatePickerModule  {



}
