import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import {AuthService} from "../../../core/auth/auth.service";
import {UserService} from "../../../core/services/user.service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import {UserData} from "../../../core/services/user.data";
import {Router} from "@angular/router";

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit {

    public menuItems: any[] = [];
    public settings: Settings;
    homeLink = "/";
    isUserOnline = 'no';
    showZoomButton = false;
    liveUser:UserData = new UserData();
    userTypes = {
    	user: 'მომხმარებელი',
	    prof: 'პროფესორი',
	    custom: 'მომხმარებელი'
    };

	auth: any = {};
    //public user: User = new User('/assets/img/users/user.jpg');

    constructor(
        public appSettings:AppSettings,
        public menuService:MenuService,
        private authService: AuthService,
        private userService: UserService,
        private cdRef:ChangeDetectorRef,
        private router: Router
        //private userService: UserService,
        //private dialog: MatDialog
    ) {
        this.settings = this.appSettings.settings;
	    this.auth.data = {
			...this.userService.user,
		    jsonGuard: this.userService.user.guard
	    }
        //this.auth.data.image = '/assets/img/users/user.jpg';
        this.userService.onChange.subscribe(() => {
            if (this.userService.user.guard == "prof") {
                this.homeLink = "/lists/list";
            } else {
                this.homeLink = "/";
            }
        });

    }

	openProfileDialog() {

		let route = this.userService.user.guard == "custom" ? 'administrative-personal' : 'academic-personal';

		this.router.navigate(['hr', route, 'details', this.userService.user.hrUserId, 'routes'])
			.then(m => {

			});
	}

    ngOnInit() {
    	if (this.userService.user.guard == 'prof') {
		    this.menuItems = this.menuService.getVerticalMenuItems();
	    }  else {
		    this.menuItems = this.menuService.getVerticalMenuItems().filter( menu => {
		    	return menu.id !== 601;
		    });
	    }
        this.zoomLinkStatus();
    	this.getUserData();
    }

    zoomLinkStatus(): void {
	    this.authService.getZoomLinkStatus().subscribe(response => {
	    	if (response.result == 'yes') {
	    		this.showZoomButton = true;
	    		this.isUserOnline = response.status;
		    }
	    });
    }

    public closeSubMenus(){
        const menu = document.querySelector(".sidenav-menu-outer");
        if(menu){
            for (let i = 0; i < menu.children[0].children.length; i++) {
                const child = menu.children[0].children[i];
                if(child){
                    if(child.children[0].classList.contains('expanded')){
                        child.children[0].classList.remove('expanded');
                        child.children[1].classList.remove('show');
                    }
                }
            }
        }
    }

    logout(){

        this.authService.logout().subscribe();
    }

    handleChange(e: MatCheckboxChange): void {
    	if (e.checked) {
    		this.isUserOnline = 'yes';
	    } else {
    		this.isUserOnline = 'no';
	    }

    	this.updateZoomStatus();
    }

	changeObservingStartStatus(status)
	{
		this.userService.changeObservingStartStatus({
			status: status.checked
		}).subscribe(response => {

		})
	}

	getUserData()
	{
		this.userService.getUserData({}).subscribe(response => {
			this.liveUser = response;
		})
	}

    updateZoomStatus(): void {
    	this.authService.updateZoomLink({isOnline: this.isUserOnline})
		    .subscribe(response => {
		    	if (response.result == 'yes') alert("წარმატებით განახლდა");
		    	else alert("განახლება ვერ მოხერხდა");
		    });
    }
}
