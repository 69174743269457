import { Menu } from './menu.model';


export let verticalMenuItems = [

	new Menu(10, 'კალენდარი', "/hr/calendar", null, "calendar_today", "menu.hr.calendar",null, false,0),
	new Menu(10, 'სტუდენტების რეესტრი', "/students", null, "people", "menu.personalInfo",null, false,0),

	new Menu(20, 'სასწავლო პროცესი', null, null, "vertical_split", "menu.learningProcesses",null, true,0),
	new Menu(200, 'სტრუქტურა', "/structure/schools", null, "timeline", "menu.learningProcesses.structure",null, false,20),
	new Menu(201, 'სასწავლო კურსები', "/books", null, "view_list", "menu.learningProcesses.learningCourses",null, false,20),
	new Menu(202, 'უწყისები', "/lists", null, "list", "menu.learningProcesses.lists",null, false,20),
	new Menu(203, 'კომპონენტები', "/components", null, "memory", "menu.learningProcesses.components",null, false,20),
	new Menu(204, 'აკ.რეგისტრაცია', "/registration", null, "how_to_reg", "menu.academicRegistration", null, false,20),
	new Menu(205, 'აღიარება', "/admissions", null, "switch_video", "menu.admissions", null, false,20),
	new Menu(205, 'მობილობის საგნები', "/mobility-books", null, "format_list_bulleted", "menu.mobility-books", null, false,20),

	new Menu(30, 'კონტაქტი', null, null, "mail", "menu.messages",null, true,0),
	//new Menu(31, 'შეტყობინებები', "/mailbox", null, "mail", "menu.messages",null, false,30),
	// new Menu(32, 'განცხადებები', "/notifications", null, "notifications_active", "menu.settings.statements", null, false,30),
	//new Menu(32, 'შემოსული განცხადებები', "/notifications/incoming", null, "notifications_active", "menu.settings.statements", null, false,30),
	new Menu(33, 'გამოკითხვები', '/poll/dashboard', null, 'poll', 'menu.poll', null, false, 30),
	new Menu(33, 'SMS შეტყობინებები', '/sms', null, 'mail', 'menu.sms', null, false, 30),

	// ბიბლიოთეკა
	new Menu(200, 'ბიბლიოთეკა', null, null, "local_library", "menu.bib", null, true,0),
	new Menu(201, 'კატალოგი', "/bib/catalog", null, "library_books", "menu.bib.catalog", null, false,200),
	new Menu(202, 'სტუდენტები', "/bib/students", null, "school", "menu.bib.students", null, false,200),
	new Menu(203, 'ადმინისტრაცია', "/bib/administration", null, "people", "menu.bib.administration", null, false,200),
	new Menu(204, 'აკად.პერსონალი', "/bib/personals", null, "supervised_user_circle", "menu.bib.personals", null, false,200),
	new Menu(205, 'დაჯავშნილი წიგნები', "/bib/bookingTabs", null, "bookmark", "menu.bib.bookingTabs", null, false,200),
	new Menu(206, 'გაცემული წიგნები', "/bib/outbox", null, "bookmark_border", "menu.bib.outbox", null, false,200),
	new Menu(207, 'პარამეტრები', "/bib/settings", null, "settings", "menu.bib.params", null, false,200),
	new Menu(207, 'ჩემი ბიბლიოთეკა', "/bib/dashboard", null, "library_books", "menu.bib.mybib", null, false,200),
	new Menu(208, 'SMS ლოგები', "/bib/sms", null, "sms", "menu.bib.sms", null, false,200),



	// new Menu(40, 'საბეჭდი ფორმები', "/docs", null, "print", "menu.printForm", null, false,0),

	new Menu(50, 'პარამეტრები', null, null, "settings", "menu.settings",  null, true,0),
	new Menu(500, 'თარგმნა', "/lang", null, "language", "menu.settings.translate", null, false,50),
	new Menu(501, 'მომხმარებლები', "/permissions", null, "supervised_user_circle", "menu.settings.users", null, false,50),
	new Menu(502, 'ფინანსური შაბლონები', "/permissions/finance-templates", null, "attach_money", "menu.settings.finance-templates", null, false,50),
	new Menu(503, 'კონტაქტი', "/contact", null, "contact_page", "menu.settings.contact", null, false,50),
	new Menu(504, 'ხელშეკრულება/დანართ', "/permissions/contract-templates", null, "note", "menu.settings.contact", null, false,50),


	new Menu(60, 'ტესტირება', "/testing", null, "event", "menu.testing", null, false,0),

	/* Prof Only */
	new Menu(80, 'ცხრილი', "/tables", null, "how_to_reg", "menu.tables", null, false,0),
	//new Menu(90, 'დავალებები', "/tasks/prof", null, "how_to_reg", "menu.tasks", null, false,0),

	/* Admin Only */
	//new Menu(100, 'დავალებები', "/tasks", null, "how_to_reg", "menu.tasks", null, false,0),

	new Menu(110, 'კანცელარია', "/chancellery", null, "how_to_reg", "menu.chancellery", null, false,0),

	// new Menu(120, 'პორტფოლიო', '/portfolio', null, 'work', 'menu.portfolio', null, false, 0),
	new Menu(121, 'საათების მოთხოვნა', '/timeDemand', null, 'access_time', 'menu.timeDemand', null, false, 0),

	new Menu(130, 'ფინანსები', '/finances', null, 'attach_money', 'menu.finances', null, true, 0),
	/* --> */new Menu(131, 'საგანმანათლებლო პროგრამა', '/finances/programs', null, 'account_balance', 'menu.finances.programs', null, false, 130),
	/* --> */new Menu(132, 'სტუდენტები', '/finances/students', null, 'group', 'menu.finances.students', null, false, 130),
	/* --> */new Menu(133, 'სასწავლო წლები', '/finances/learning-seasons', null, 'date_range', 'menu.finances.learning-seasons', null, false, 130),
	/* --> */new Menu(134, 'ტრანზაქციები', '/finances/events', null, 'note', 'menu.finances.events', null, false, 130),
	/* --> */new Menu(135, 'გრანტები', '/finances/grants', null, 'gavel', 'menu.finances.grants', null, false, 130),
	/* --> */new Menu(136, 'რეპორტი', '/finances/reports', null, 'trending_up', 'menu.finances.reports', null, false, 130),
	/* --> */new Menu(137, 'შეღავათები', '/finances/discounts', null, 'call_to_action', 'menu.finances.discounts', null, false, 130),
	/* --> */new Menu(138, 'პარამეტრები', '/finances/settings', null, 'build', 'menu.finances.settings', null, false, 130),

	// new Menu(140, 'პერსონალური შეფასება', "/personal-vote", null, "event", "menu.personalVote", null, false,0),

	new Menu(150, 'ონლაინ რეგისტრაცია', '/school-leavers', null, 'school', 'menu.entrant', null, true, 0),
	/* --> */ new Menu(151, 'აბიტურიენტების სია', '/school-leavers', null, 'gavel', 'menu.entrant.list', null, false, 150),
	/* --> */ new Menu(152, 'რეგისტრირებულები', '/school-leavers/registered', null, 'gavel', 'menu.entrant.registered', null, false, 150),
	/* --> */ new Menu(154, 'კონფიგურაცია', '/school-leavers/configuration', null, 'settings', 'menu.entrant.configuration', null, false, 150),
	/* --> */ new Menu(154, 'შაბლონები', '/school-leavers/finances', null, 'note', 'menu.entrant.finances', null, false, 150),
	/* --> */ new Menu(156, 'არქიტექტურის რეგისტრაცია', '/school-leavers/arc', null, 'gavel', 'menu.entrant.list', null, false, 150),

	new Menu(160, 'დოკუმენტები', null, null, 'subtitles', 'menu.documents', null, true, 0),
	/* --> */new Menu(134, 'დოკუმენტები', '/documents', null, 'subtitles', 'menu.documents', null, false, 160),

	new Menu(170, 'სტიპენდიანტის ცხრილი', null, null, 'table_view', 'menu.fellowship', null, true, 0),
	new Menu(171, 'ცხრილი', '/fellowship', null, 'table_views', 'menu.fellowship.view', null, false, 170),

	new Menu(180, 'HR', "/hr", null, "people_outline", 'menu.hr', null, false,0),
	new Menu(190, 'კურსდამთავრებულები', "/alumni", null, "people", "menu.alumni", null, false,0),

	new Menu(300, 'ფინანსები 2', '/finances-second', null, 'attach_money', 'menu.finances-new', null, true,0),
	/* --> */new Menu(301, 'სტუდენტები', '/finances-second/students', null, 'group', 'menu.finances-new.students', null, false, 300),
	/* --> */new Menu(302, 'შეღავათი', '/finances-second/discounts', null, 'gavel', 'menu.finances-new.discounts', null, false, 300),
	/* --> */new Menu(303, 'ტრანზაქციები', '/finances-second/events', null, 'note', 'menu.finances-new.events', null, false, 300),
	/* --> */new Menu(304, 'რეპორტები', '/finances-second/reports', null, 'trending_up', 'menu.finances-new.reports', null, false, 300),
	/* --> */new Menu(305, 'პარამეტრები', '/finances-second/parameters', null, 'settings', 'menu.finances-new.parameters', null, false, 300),
	/* --> */new Menu(306, 'დატვირთვები', '/finances-second/loads', null, 'trending_up', 'menu.finance.loaded.menu', null, false, 300),

	new Menu(400, 'მობილობა/აღიარება', "/mobility", null, "people", "menu.mobility", null, true,0),
	/* --> */new Menu(401, 'მობილობა/აღიარება', "/mobility/list", null, "people", "menu.mobility.list", null, false,400),
	/* --> */new Menu(402, 'მობილობა/სტატუსი', "/mobility/manager", null, "subtitles", "menu.mobility.status", null, false,400),

	new Menu(500, 'რეპორტინგი', '/reports', null, 'import_export', 'menu.reporting', null, true,0),
	/* --> */new Menu(501, 'რეპორტები', '/reports', null, 'import_export', 'menu.reporting.reports', null, false, 500),
	/* --> */new Menu(502, 'ლეპტოპები', '/gift-laptops', null, 'import_export', 'menu.reporting.reports', null, false, 500),
	/* --> */new Menu(503, 'ანალიტიკა', '/analytics', null, 'bar_chart', 'menu.reporting.analytics', null, false, 500),

	new Menu(601, 'ელექტრონული ფაილები', '/el-files', null, 'import_export', 'menu.el-files', null, false, 0),

	new Menu(700, 'დიპლომის დანართი', '/diploma-enclosures', null, 'description', 'menu.diploma-enclosures', null, false,0),

	new Menu(800, 'დაზღვევა', '/insurances', null, 'access_time', 'menu.insurances', null, false, 0),

	new Menu(900, 'ელ. შტამპი', '/el-stamp', null, 'approval', 'menu.el-stamp', null, true, 0),
	/* --> */new Menu(901, 'რეესტრი', '/el-stamp/registry', null, 'app_registration', 'menu.el-stamp.registry', null, false, 900),
	/* --> */new Menu(902, 'პარამეტრები', '/el-stamp/parameters', null, 'settings', 'menu.el-stamp.parameters', null, false, 900),

	// new Menu(800, 'სამოქმედო გეგმა', '/hr/plan/tabs', null, 'assignment', 'menu.hr.action-plan', null, false, 0),
	//
	// new Menu(850, 'ანალიტიკა', null, null, 'work', 'menu.reporting.analytics', null, true, 0),
	// new Menu(900, 'პუბლიკაციები', '/publications', null, 'work', 'menu.publications', null, false, 850),
	// new Menu(950, 'კვლევები', '/research', null, 'work', 'menu.research', null, false, 850),
	// new Menu(960, 'პარტნიორობა', '/partner', null, 'work', 'menu.partner', null, false, 850),
	// new Menu(960, 'PR აქტივობები', '/pr-active', null, 'work', 'menu.pr-active', null, false, 850),
	// new Menu(970, 'მემორანდუმი', '/memorandum', null, 'work', 'menu.memorandum', null, false, 850),
	// new Menu(980, 'ექსპორტიებული გამოკითხვა', '/exported-poll', null, 'work', 'menu.exported-poll', null, false, 850),
	// new Menu(990, 'Lll პროგრამები', '/LiiPrograms', null, 'work', 'menu.LiiPrograms', null, false, 850),
	// new Menu(1000, 'Kpi1', '/kpiOne', null, 'work', 'menu.kpiOne', null, false, 850),
	// new Menu(1010, 'ვაკანტური ადგილები', '/vacant-positions', null, 'groups', 'menu.vacant-positions', null, false, 850),
	//
	// new Menu(1100, 'სივრცის პარამეტრები', '/storage-settings', null, 'storage', 'menu.storage-settings', null, false, 0),
];
