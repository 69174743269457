import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaginationPipe } from './pagination/pagination.pipe';
import { ProfilePicturePipe } from './profilePicture/profilePicture.pipe';
import { TruncatePipe } from './truncate/truncate.pipe';
import { KaSeasonPipe } from "./kaSeason/kaSeason.pipe";
import { ToKaDayPipe } from "../../shared/pipes/toKaDay";
import { StringToLinkPipe } from "./toLink/stringToLink.pipe";
import { IsUnpaidVacationDisabledPipe } from './isUnpaidVacationDisabled/isUnpaidVacationDisabled.pipe';

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		PaginationPipe,
		ProfilePicturePipe,
		TruncatePipe,
		KaSeasonPipe,
		ToKaDayPipe,
		StringToLinkPipe,
		IsUnpaidVacationDisabledPipe
	],
	exports: [
		PaginationPipe,
		ProfilePicturePipe,
		TruncatePipe,
		KaSeasonPipe,
		ToKaDayPipe,
		StringToLinkPipe,
		IsUnpaidVacationDisabledPipe
	]
})
export class PipesModule { }
