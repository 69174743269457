import {NgModule} from "@angular/core";
import {BookInput} from "./book-input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {StudentInput} from "./student-input";
import {AutocompleteNamesService} from "./autocomplete-names.service";

import {ProfInput} from "./prof-input";
import {ProgramInput} from "./program-input";
import {BookMultipleInput} from "./book-multiple-input";
import {ListInput} from "./list-input";
import {CountryInput} from "./country-input";
import {ContactInput} from "./contact-input";
import {AuditoriumInput} from "./auditorium.input";
import {SchoolInput} from "./school-input";
import { SpecialInput } from "./special-input";
import {SeminarInput} from "./seminar-input";
import {SeminarInputDialog} from "./seminar-input-dialog";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSelectModule} from "@angular/material/select";
import {TranslateModule} from "@ngx-translate/core";
import {TimePicker} from "./time-picker/time-picker";
import {UserInput} from "./user-input";
import {SubBookInput} from "./sub-book-input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatIconModule} from "@angular/material/icon";
import {GroupCommentInput} from "./group-comment-input";
import {MatChipsModule} from "@angular/material/chips";


@NgModule({
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatAutocompleteModule,
		MatIconModule,
		MatDialogModule,
		MatButtonModule,
		FlexLayoutModule,
		MatSelectModule,
		TranslateModule,
		FormsModule,
		MatChipsModule
	],
    declarations: [
        BookInput,
	    SubBookInput,
        StudentInput,
        ProfInput,
        ProgramInput,
        BookMultipleInput,
        ListInput,
        CountryInput,
        ContactInput,
        AuditoriumInput,
        SchoolInput,
	    UserInput,
        SpecialInput,
        SeminarInput,
        SeminarInputDialog,
        TimePicker,
	    GroupCommentInput
    ],
    entryComponents: [
        SeminarInputDialog
    ],
    exports: [
        BookInput,
	    SubBookInput,
        StudentInput,
        ProfInput,
        ProgramInput,
        BookMultipleInput,
        ListInput,
        CountryInput,
        ContactInput,
        AuditoriumInput,
        SchoolInput,
        SpecialInput,
        SeminarInput,
        SeminarInputDialog,
        TimePicker,
	    UserInput,
	    GroupCommentInput
    ],
    providers: [
        AutocompleteNamesService
    ]
})
export class IniInputModule {

}
