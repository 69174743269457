import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { StudentsService } from "../students.service";
import { CommandStudentStatus } from "./command-student-status/command-student-status";
import { SettingService } from "../../../core/services/setting.service";
import { ExportTestingProgressDialog } from "../../testing/tests/list/export-testing-progress/export-testing-progress.dialog";
import { EditSemesterDialog } from "./edit-semester-dialog/edit-semester.dialog";
import { MatPaginator } from "@angular/material/paginator";
import { SendSmsDialog } from "./send-sms-dialog/send-sms.dialog";
import { StudentExamAccessDialog } from "../student-exam-access-dialog/student-exam-access.dialog";
import { StudentBanDialog } from "./student-ban-dialog/student-ban.dialog";
import { StudentWelcomeDialog } from "./student-welcome-dialog/student-welcome.dialog";
import { StudentLearningStartDateDialog } from "./student-learning-start-date-dialog/student-learning-start-date.dialog";
import { ProgressDialog } from "../../../theme/components/progressDialog/progress.dialog";
import { AlertService } from "../../../shared/services/alert.service";
import { ConfirmDialog } from "src/app/theme/components/confirmDialog/confirm.dialog";


declare let window: any;
declare let setToken: any;

@Component({
	selector: "student-action-dialog",
	templateUrl: "./student-action-dialog.html",
	styleUrls: ["./student-action-dialog.scss"]
})
export class StudentActionDialog implements OnInit, AfterViewInit, OnDestroy {

	interval = null;
	students: any[] = [];
	filter = {
		ids: [],
		limit: 10
	};

	items: any[] = [];
	total = 0;
	page = 1;
	limit = 10;
	displayedColumns = [
		"id",
		//"photo",
		"name",
		"personalNumber"
	];

	changed = false;

	settingGoogleServices: any = {};
	action = '';
	tags = [];

	constructor(
		private ref: MatDialogRef<StudentActionDialog>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		private service: StudentsService,
		private dialog: MatDialog,
		private settingService: SettingService,
		private alertService: AlertService
	) {
		this.tags = data.tags;
		this.settingService.getSettings("googleService").subscribe(response => {
			this.settingGoogleServices = response;
		});

		for (const i in data.students) {
			if (data.students.hasOwnProperty(i)) {
				this.filter.ids.push(i);
				this.students.push({
					id: i,
					name: data.students[i]
				});
			}
		}
	}

	runAction() {
		switch (this.action) {
			case 'editStatus':
				return this.editStatus();
			case 'editStudentPropertySemester':
				return this.editStudentProperty('semester');
			case 'generateMailBox':
				return this.generateMailBox();
			case 'blockStudents':
				return this.blockStudents();
			case 'sendSmsDialog':
				return this.sendSmsDialog();
			case 'sendAuthSmsToStudents':
				return this.sendAuthSmsToStudents();
			case 'editStudentPropertyGroup':
				return this.editStudentProperty('group');
			case 'examAccess':
				this.examAccess();
				break;
			case 'openWelcomeDialog':
				return this.openWelcomeDialog();
			case 'startDateDialog':
				return this.openStartDateDialog();
			case 'calculateFinances':
				return this.calculateStudentCards();
			default:
				// continue
				break
		}
	}

	calculateStudentCards() {
		this.service.calculateStudentCards({
			ids: this.filter.ids
		}).subscribe(({ result, pid, error }: any) => {
			if (result == 'yes') {
				this.dialog.open(ProgressDialog, {
					data: {
						title: 'ფინანსური მონაცემების გადათვლა',
						source: '/students/get-import-progress/?pid=' + pid,
					},
					width: '400px'
				}).afterClosed().subscribe((response) => {
					if (response.out == 'success') {
						this.alertService.snakbar('სტუდენტების ფინანსური ბარათები წარმატებით გადაითვალა');
						this.ref.close(true);
					} else if (response && response.out) {
						this.alertService.snakbar(response.out);
					}
				})
				this.ref.close();
			} else {
				this.alertService.snakbar(error);
			}
		});
	}

	editStudentProperty(type: string) {
		this.dialog.open(EditSemesterDialog, {
			data: {
				type: type,
				students: this.students
			}
		});
	}




    /**/@ViewChild("paginator", { static: false }) paginator: MatPaginator;
	getData() {
		this.service.getFilteredStudents(this.filter, this.page, this.limit).subscribe(response => {
			this.items = response.data;
			this.total = response.total;
		});
	}

	editStatus() {
		const ref = this.dialog.open(CommandStudentStatus, {
			width: "500px",
			data: {
				students: this.filter.ids
			}
		});
		ref.afterClosed().subscribe(result => {
			if (result) {
				this.getData();
			}
		});
	}
	token = "empty";

	generateMailBox() {
		this.service.getTokenUrl().subscribe(response => {
			if (response.result == "yes") {

				// noinspection UnnecessaryLocalVariableJS
				let t = (token) => {

					this.token = token;
				};
				setToken = t;
				window.open(response.link);

			}
		});
	}

	sendSmsDialog() {
		this.dialog.open(SendSmsDialog, {
			data: {
				students: this.students
			},
			width: '800px'
		});
	}

	sendAuthSmsToStudents() {
		this.dialog.open(ConfirmDialog, {
			data: {
				title: "ავტორიზაციის მესიჯების გაგზავნა",
				text: "ნამდვილად გსურთ სტუდენტებისთვის ავტორიზაციის მესიჯების გაგზავნა?",
				ok: "დიახ",
				cancel: "არა"
			}
		}).afterClosed().subscribe(result => {
			if (result) {
				this.service.sendAuthParamsToStudent(this.students.map(s => parseInt(s.id))).subscribe(response => {
					if (response.result == "yes") {
						this.alertService.snakbar(
							'სტუდენტების ავტორიზაციის კოდები გაიგზავნა'
						);
					}
				});
			}
		});
	}

	ngAfterViewInit(): void {
		this.paginator.page.subscribe(res => {
			this.page = res.pageIndex + 1;
			this.limit = res.pageSize;
			this.getData();
		});
	}

	ngOnInit(): void {
		this.getData();
		setInterval(() => {
			if (this.token != "empty") {
				try {
					this.service.makeBatchMail({
						students: this.filter.ids,
						token: this.token
					}).subscribe(response => {
						if (response.result == "yes") {
							this.dialog.open(ExportTestingProgressDialog, {
								data: {
									pid: response.pid,
									canDownload: false,
									closeAfterDone: true
								},
							}).afterClosed().subscribe(() => {
								this.ref.close(true);
							});
						}
					});
				} catch (e) {

				}

				this.token = "empty";
			}
		}, 1000);
	}

	ngOnDestroy(): void {
		if (this.interval != null) {
			clearInterval(this.interval);
		}
	}

	examAccess(): void {
		const ref = this.dialog.open(StudentExamAccessDialog, {
			disableClose: true,
			width: "500px",
			data: {
				students: this.filter.ids
			}
		});

		ref.afterClosed().subscribe(result => {
			if (result) this.ref.close(true);
		});
	}

	openWelcomeDialog(): void {
		const ref = this.dialog.open(StudentWelcomeDialog, {
			disableClose: true,
			width: "500px",
			data: {
				students: this.filter.ids
			}
		});
	}

	blockStudents(): void {
		const ref = this.dialog.open(StudentBanDialog, {
			disableClose: true,
			width: "500px",
			data: {
				students: this.filter.ids
			}
		});

		ref.afterClosed().subscribe(result => {
			if (result) this.ref.close(true);
		});
	}

	openStartDateDialog(): void {
		const ref = this.dialog.open(StudentLearningStartDateDialog, {
			disableClose: true,
			width: "500px",
			data: {
				students: this.filter.ids
			}
		});
	}

}
