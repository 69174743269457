import {
    Component,
    EventEmitter,
    Inject,
    Input, OnInit,
    Output
} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {IniEditorCommand} from "./ini.editor.command";
import {IniEditorMathDialog} from "./ini.editor.math.dialog";
import {IniEditorConfig} from "./config";
import {MatDialog} from "@angular/material/dialog";

declare let window: any;

@Component({
    selector: "ini-editor-toolbar",
    templateUrl: "./ini.editor.toolbar.component.html",
    styleUrls: ["./ini.editor.toolbar.component.scss"]
})
export class IniEditorToolbarComponent implements OnInit {

    constructor(
        @Inject(DOCUMENT) private _document: any,
        private dialog: MatDialog
    ) {

    }


    @Output() execute: EventEmitter<IniEditorCommand> = new EventEmitter<IniEditorCommand>();
    @Input("textAreaElement") textArea: HTMLElement;
    @Input("config") config: IniEditorConfig;

    style = "";

    undoStatus = false;
    redoStatus = false;
    undoRedoEvent = false;

    styles = {
        italicTitle: {
            "font-style":"italic"
        },
        SubTitle: {
            color: "#aaaaaa",
            "font-style":"italic"
        },
        specialContainer: {
            background: "#eeeeee",
            border: "1px solid #cccccc",
            padding: "5px 10px"
        },
        typeWriter: {
            "font-family": "monospace"
        },
        variable: {
            "font-style": "italic",
            "font-family": "Nunito Sans, Verdana, Helvetica, sans-serif"
        },
        deletedText: {
            "text-decoration": "line-through",
            "font-family": "Nunito Sans, Verdana, Helvetica, sans-serif"
        },
        insertedText: {
            "text-decoration": "underline",
            "font-family": "Nunito Sans, Verdana, Helvetica, sans-serif"
        }
    };

    colors = [
        ["#1abc9c",
        "#2ecc71",
        "#3498db",
        "#9b59b6",
        "#4e5f70",
        "#f1c40f"],

        ["#16a085",
        "#27ae60",
        "#2980b9",
        "#8e44ad",
        "#2c3e50",
        "#f39c12"],

        ["#e67e22",
        "#e74c3c",
        "#ecf0f1",
        "#95a5a6",
        "#dddddd",
        "#ffffff"],

        ["#d35400",
        "#c0392b",
        "#bdc3c7",
        "#7f8c8d",
        "#999999",
        "#000000"]
    ];

    ngOnInit(): void {
        this.textArea.addEventListener("input", () => {
            if (!this.undoRedoEvent) {
                this.undoStatus = true;
                this.redoStatus = false;
            }
            this.undoRedoEvent = false;
        });

    }

    undo() {
        if (!this.isArea()) { return; }
        this.undoRedoEvent = true;
        if (this._document.execCommand("undo", false, null)) {
            this.redoStatus = true;
        } else {
            this.undoStatus = false;
        }
    }

    redo() {
        if (!this.isArea()) { return; }
        this.undoRedoEvent = true;
        if (this._document.execCommand("redo", false, null)) {
            this.undoStatus = true;
        } else {
            this.redoStatus = false;
        }
    }

    isArea(el = null, c=true): boolean {
        let element = null;
        if (c) {
            const range = this._document.getSelection().getRangeAt(0);
            element = range.commonAncestorContainer;
        } else {
            element = el;
        }
        if (element == null) {
            return false;
        }
        if ((element.className+"").indexOf('ini-editor-textarea') != -1) {
            if (element.className == 'ini-editor-textarea') {
                return true;
            }
        }

        if (typeof element.parentNode != "undefined" || element.parentNode != null) {
            return this.isArea(element.parentNode, false);
        }
        return false;
    }

    getStyle(style) {
        if (typeof this.styles[style] != "undefined") {
            return this.styles[style];
        }
        return {};
    }

    setStyleDiv(style) {
        if (typeof this.styles[style] != "undefined") {
            this.execute.emit(new IniEditorCommand("divStyle", this.styles[style]));
        }
    }

    action(action, color) {
        this.execute.emit(new IniEditorCommand(action, color));
    }

    openMathDialog() {
        if (!this.isArea()) { return; }
        let range = null;
        let type = 0;
        if (window.getSelection) {
            const sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                range = window.getSelection().getRangeAt(0);
                type = 1;
            }
        } else if (this._document.selection && this._document.selection.createRange) {
            range = this._document.selection.createRange();
            type = 2;
        }

        const ref = this.dialog.open(IniEditorMathDialog, {
            width: "650px",
            panelClass: "ini-editor-math-dialog",
            backdropClass: "ini-editor-math-backDrop"
        });
        ref.afterClosed().subscribe(result => {
            if (typeof result != "undefined" && result) {

                if (type == 1) {
                    //let expandedSelRange = range.cloneRange();
                    range.collapse(false);
                    const el = this._document.createElement("img");
                    el.src = result.src;
                    const frag = document.createDocumentFragment(); //, node, lastNode;
                    frag.appendChild(el);
                    range.insertNode(frag);
                } else if (type == 2) {
                    const expandedSelRange = range.duplicate();
                    range.collapse(false);
                    range.pasteHTML("<img src='"+result.src+"' alt='' />");
                    expandedSelRange.setEndPoint("EndToEnd", range);
                    expandedSelRange.select();
                }
                this.execute.emit(new IniEditorCommand("change", null));


            }
        });
    }







}