import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, Subject} from "rxjs";
import {LangResult} from "../../pages/lang/lang-tab/lang-result";
import {Language} from "../../pages/lang/language";
import {LangKeyword} from "../../pages/lang/lang-tab/lang-keyword";
import {LangFilter} from "../../pages/lang/lang-tab/lang-filter";
import {CacheService} from "../../core/services/cache.service";

@Injectable()
export class LangService {

    side = 'admin_side';

    constructor(
        private http: HttpClient,
        private cacheService: CacheService
    ) {

    }

    private subjects: Subject<string>[] = [];

    current() {
        const locale = localStorage.getItem("lang");
        return locale == null ? "ka" : locale;
    }

    getItems(filter: any, page: number, limit: number, total: number,side: string): Observable<LangResult> {
        return this.http.post<LangResult>("/lang/getKeywords/?page="+page+"&limit="+limit+(total == 0 ? "withTotal" : ""), filter);
    }

    getLanguages(withGeo=false): Observable<LangResult> {
        return this.http.post<LangResult>("/lang/list", {
	        withGeo
        })

    }

    addLanguage(data: Language): Observable<LangResult> {
        return this.http.post<LangResult>("/lang/add", data);
    }

    editKeyword(data: LangKeyword): Observable<LangResult> {
        return this.http.post<LangResult>("/lang/editKeyword", data);
    }

    generateKeywords(side): Observable<LangResult> {
        return this.http.post<LangResult>("/lang/generateKeywords", {
            side : side
        });
    }

    generateTemplate(side): Observable<LangResult> {
        return this.http.post<LangResult>("/lang/generateTemplate", {
          side: side
        });
    }

    exportToExcel(filter: LangFilter): Observable<LangResult> {
        return this.http.post<LangResult>("/lang/exportToExcel", filter);
    }

    getLanguage(id: string): Observable<LangResult> {
        return this.http.post<LangResult>("/lang/getLanguage", {
            id
        });
    }

    on(eventName: string): Observable<string> {
        this.subjects[eventName] = this.subjects[eventName] || new Subject();
        return this.subjects[eventName].asObservable();
    }

    publish(eventName: string) {
        this.subjects[eventName] = this.subjects[eventName] || new Subject();
        this.subjects[eventName].next();
    }


}