export interface FileUploadProgress {
  type: FileUploadProgressType;
  progress: number;
  attachment?: string[];
  name?: string;
  size?: number;
  fileType?: string;
}

export enum FileUploadProgressType {
  PROGRESS = "PROGRESS",
  FINISHED = "FINISHED",
}
export interface FileAttachment {
  id: number;
  name: string;
  size: number;
  type: string;
  url: string;
}
