export const UniConfig = {
	notificationSocketUrl: "reg.seu.edu.ge:6001",
	listSocketUrl: "wss://reg.seu.edu.ge:3030",
	studentLink: 'https://emis.seu.edu.ge',
	chatSocketUrl: 'wss://reg.seu.edu.ge:4040',
	checkSumOfWeight: true, // ჯამური მინიმალური ზღვრის შემოწმება უწყისში კომპონენტის დამატებისას
	profTerm: 1,
	componentExportIsAllowed: true,
	yleoba_n1000: false, // სასწავლო ბარათში შედეგების დამალვა
	uniLogo : "/assets/img/uni/logo.png",
	uniName: "საქართველოს ეროვნული უნივერსიტეტი სეუ",
	schoolName: "სკოლა",
	signServer: "wss://signsocket.stepover.com:57357/signsocket/"
};
