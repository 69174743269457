import {AfterViewInit, Component, Inject, OnInit, ViewChild} from "@angular/core";
import {BooksService} from "../books.service";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: 'book-logs-dialog',
	templateUrl: './book-logs.dialog.html'
})

export class BookLogsDialog implements OnInit, AfterViewInit {

	id: number = 0;
	type: string = '';
	limit: number = 10;
	page: number = 1;
	total: number = 0;
	items = [];

	actionNames = {
		add: 'დამატება',
		update: 'განახლება',
		edit: 'ცვლილება',
		remove: 'წაშლა',
	};

	constructor(
		public service: BooksService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public errors: ErrorsDialog
	) {
		this.type = data.type;
		this.id = data.id;
	}

	displayedColumns = [
		'id',
		'user',
		'type',
		'action',
		'change',
		'date',
	];



	ngOnInit(): void {
		this.getItems();
	}

	/**/@ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
	ngAfterViewInit(): void {
		this.paginator.page.subscribe(res => {
			this.page = res.pageIndex+1;
			this.limit = res.pageSize;
			this.getItems();
		});
	}


	getItems(): void {
		this.service.getBookLogs({id: this.id, type: this.type}, this.page, this.limit)
			.subscribe(response => {
				this.items = response.data;
				this.total = response.total;
			});
	}

	viewText(text: string): void {
		this.errors.show({error: text});
	}

}