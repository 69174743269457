import {Component} from "@angular/core";
import {Language} from "./language";
import {LangService} from "../../shared/services/lang.service";
import {MatDialog} from "@angular/material/dialog";
import {LanguageFormComponent} from "./language-form/language-form.component";

@Component({
    selector: "lang-component",
    templateUrl: "./lang.component.html",
    styleUrls: ["./lang.component.scss"]
})
export class LangComponent {

    constructor(
        public service: LangService,
        private dialog: MatDialog
    ) {
        this.getLanguages();
        this.service.on("reloadLanguages").subscribe(action => {
            this.getLanguages();
        });
    }

    languages: Language[] = [];

    generateProcess = false;

    getLanguages() {
        this.service.getLanguages().subscribe(response => {
            this.languages = response.languages;
        });
    }

    addLanguage() {
        const ref = this.dialog.open(LanguageFormComponent, {
            data: {
                code: '',
                name: '',
                flag: ''
            }
        });
        ref.afterClosed().subscribe(result => {
            if (typeof result != "undefined" && result) {
                this.getLanguages();
            }
        });
    }
    generateKeywords() {
        this.generateProcess = true;
        this.service.generateKeywords(this.service.side).subscribe(response => {
            this.generateProcess = false;
            top.location.reload();
            alert("Success");
        });
    }

    generateTemplate() {
        this.generateProcess = true;
        this.service.generateTemplate(this.service.side).subscribe(response => {
            this.generateProcess = false;
            alert("Success");
            //top.location.reload();
        });
    }

    change_side()
    {

        this.service.publish("change_side");
    }
}