import {NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {IniEditorToolbarComponent} from "./ini.editor.toolbar.component";
import {IniEditorComponent} from "./ini.editor.component";
import {FlexLayoutModule} from "@angular/flex-layout";
import {IniEditorMathDialog} from "./ini.editor.math.dialog";
import {TranslateModule} from "@ngx-translate/core";
import {MatrixDialog} from "./matrix.dialog";
import {IniEditorPreloadDialog} from "./ini.editor.preload.dialog";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatTabsModule} from "@angular/material/tabs";
import {MatInputModule} from "@angular/material/input";

@NgModule({
    imports: [
        MatIconModule,
        MatSelectModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        MatButtonModule,
        MatTabsModule,
        TranslateModule,
        MatInputModule
    ],
    declarations: [
        IniEditorComponent,
        IniEditorToolbarComponent,
        IniEditorMathDialog,
        MatrixDialog,
        IniEditorPreloadDialog
    ],
    entryComponents: [
        IniEditorMathDialog,
        MatrixDialog,
        IniEditorPreloadDialog
    ],
    exports: [
        IniEditorComponent,
        IniEditorToolbarComponent
    ]
})
export class IniEditorModule {


}