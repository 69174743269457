import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {StudentsService} from "../students.service";
import {Region, StudentFormDialogData} from "../students.models";
import {IniDatePickerHeader} from "../../../core/components/ini-date-picker/ini-date-picker-header";
import {PermissionService} from "../../../core/services/permission.service";
import {StudentMedicMaxCreditLogDialog} from "../student-medic-max-credit-log/student-medic-max-credit-log.dialog";
import {ErrorsDialog} from "../../../shared/error-dialogs/errors.dialog";

@Component({
    selector: "student-form-dialog",
    templateUrl: "./student-form.dialog.html",
    styleUrls: ["./student-form.dialog.scss"]
})
export class StudentFormDialog implements OnInit {

    regions: Region[] = [];
    errors: any = {};
    meta = {
    	tags: []
    };
	fiteredTagList = [];
	fiteredStates:any = [];
	fiteredStates2:any = [];
	fiteredIndianCities:any = [];

    constructor(
        private ref: MatDialogRef<StudentFormDialog>,
        @Inject(MAT_DIALOG_DATA) public data: StudentFormDialogData,
        private service: StudentsService,
        public permissionService: PermissionService,
        private dialog: MatDialog,
        private errorsDialog: ErrorsDialog
    ) {
        this.service.regions().subscribe(response => {
            this.regions = response;
        });

        if (typeof data.student.birthday != "undefined" && data.student.birthday != null) {
        	const splitted = data.student.birthday.split("-");
	        data.student.birthD = splitted[2];
	        data.student.birthM = splitted[1];
	        data.student.birthY = splitted[0];
        }

	    if (typeof data.student.group != "undefined" && data.student.group != null) {
		    const splitted = data.student.group.split("/");
		    data.student.groupDay = splitted[0];
		    data.student.groupMonth = splitted[1];
		    data.student.groupYear = splitted[2];
	    }
    }


    iniDatePickerHeader = IniDatePickerHeader;

    ok() {
        this.errors = {};
        if (this.data.mode == "add") {
            this.service.add(this.data.student, this.data.ignoreValidations).subscribe(response => {
                if (response.result == "yes") {
                    this.ref.close("yes");
                } else {
					this.errorsDialog.show(response);
                }
            });
        } else {
            this.service.edit(this.data.student, this.data.ignoreValidations).subscribe(response => {
                if (response.result == "yes") {
                    this.ref.close("yes");
                } else {
					this.errorsDialog.show(response);
                }
            });
        }

    }

    agents = [];
	states = [];
	indianCities = [];

	ngOnInit(): void {
		this.getAgents();
		this.getStates();
		this.getIndianSities();
		this.getStudentInfoMeta();
	}

	getAgents(): void {
    	this.service.getAgents()
		    .subscribe(response => {
		    	this.agents = response;
		    });
	}

	getStates(): void {
		this.service.getStates()
			.subscribe(response => {
				this.states = this.fiteredStates = this.fiteredStates2 = response;
			});
	}

	getIndianSities(): void {
		this.service.getIndianSities()
			.subscribe(response => {
				this.indianCities = this.fiteredIndianCities = response;
			});
	}

	getStudentInfoMeta()
	{
		this.service.getStudentInfoMeta(this.data.student.id)
			.subscribe(response => {
				this.meta = response;
				this.fiteredTagList = this.meta.tags;
			})
	}

	filterTagOptions(event)
	{
		if(event != "") {
			let searchedItems = [];
			for (var j = 0; j < this.meta.tags.length; j++) {
				let name = this.meta.tags[j].name + " " + this.meta.tags[j].positionName;
				if (name.match(event)) {
					searchedItems.push(this.meta.tags[j])
				}
			}
			this.fiteredTagList = searchedItems;
		}else{
			this.fiteredTagList = this.meta.tags;
		}
	}

	filterStateOptions(event)
	{
		if(event != "") {
			let searchedItems = [];
			for (var j = 0; j < this.states.length; j++) {
				let name = this.states[j].name;
				if (name.match(event)) {
					searchedItems.push(this.states[j])
				}
			}
			this.fiteredStates = searchedItems;
		}else{
			this.fiteredStates = this.states;
		}
	}

	filterState2Options(event)
	{
		if(event != "") {
			let searchedItems = [];
			for (var j = 0; j < this.states.length; j++) {
				let name = this.states[j].name;
				if (name.match(event)) {
					searchedItems.push(this.states[j])
				}
			}
			this.fiteredStates2 = searchedItems;
		}else{
			this.fiteredStates2 = this.states;
		}
	}

	filterIndianSitiesOptions(event)
	{
		if(event != "") {
			let searchedItems = [];
			for (var j = 0; j < this.indianCities.length; j++) {
				let name = this.indianCities[j].name;
				if (name.match(event)) {
					searchedItems.push(this.indianCities[j])
				}
			}
			this.fiteredIndianCities = searchedItems;
		}else{
			this.fiteredIndianCities = this.indianCities;
		}
	}

	openMedicMaxCreditLogDialog()
	{
		this.dialog.open(StudentMedicMaxCreditLogDialog,{
			data : {
				studentId: this.data.student.id
			},
			width: '1200px'
		});
	}
}
