import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MessagesService} from "../messages.service";

@Component({
    selector: "statement-message-dialog",
    templateUrl: "./statement-message-dialog.html",
    styleUrls: ["./statement-message-dialog.scss"]
})
export class StatementMessageDialog {

    constructor(
        private ref: MatDialogRef<StatementMessageDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private service: MessagesService
    ) {
        this.service.markAsRead(this.data.id).subscribe(response => {
            if (response.result == "yes") {
                this.service.publish("refreshUnreadMessages");
            }
        });
    }

    close() {
        this.ref.close();
    }

    baseName(str:string) {
        //if(base.lastIndexOf(".") != -1) base = base.substring(0, base.lastIndexOf("."));
        return str.substring(str.lastIndexOf('/') + 1);
    }
}
