import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from "../../../core/services/user.service";
import {MessagesService} from "../../../pages/messages/messages.service";
import {MatDialog} from "@angular/material/dialog";
import {RequiredMessageDialog} from "./required-message/required-message.dialog";




@Component({
    selector: 'app-messages',
    templateUrl: './app-messages.component.html',
    styleUrls: ['./app-messages.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppMessagesComponent implements OnInit, OnDestroy {


    //public channel: EchoService;

    constructor(
        //private pusherService: PusherService,
        private userService: UserService,
        private service: MessagesService,
        private dialog: MatDialog
        //private echoService: EchoService
    ) {
        /*
        echoService.join("sharedMessages", 'public').listen("sharedMessages", "MessageSent").subscribe(data => {

            if (data.message.id == this.userService.user.id) {
                this.getUnreadMessagesCount(true);
            }
        });
         */
    }

    refreshUnreadMessages: any = null;
    refreshUnreadMessagesWithNotify: any = null;
    ngOnInit() {
        this.getUnreadMessagesCount(true);
        this.refreshUnreadMessages = this.service.on("refreshUnreadMessages");
        this.refreshUnreadMessages.subscribe(() => {
            this.getUnreadMessagesCount();
        });
        this.refreshUnreadMessagesWithNotify = this.service.on("refreshUnreadMessagesWithNotify");
        this.refreshUnreadMessagesWithNotify.subscribe(() => {
            this.getUnreadMessagesCount(true);
        });
    }

    unreadMessages = 0;
    getUnreadMessagesCount(notify = false) {
        this.service.getUnreadMessagesCount().subscribe(response => {
            this.unreadMessages = response.unreadMessages;
            if (notify && this.unreadMessages >0) {
                if (response.priority != 0) {
                    this.dialog.open(RequiredMessageDialog, {
                        data: response,
                        disableClose: response.priority == 1
                    });
                }
            }
        });
    }

    ngOnDestroy(): void {
        try {
            //this.echoService.leave("sharedMessages");
        } catch (ex) {}
    }




}
