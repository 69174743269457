import {Component, Inject} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ListService} from "../../../list.service";
import {ErrorsDialog} from "../../../../../../shared/error-dialogs/errors.dialog";

@Component({
	selector: 'est-sign-form-dialog',
	templateUrl: './est-sign-form.dialog.html',
	styleUrls: ['./est-sign-form.dialog.scss']
})

export class EstSignFormDialog {

	form: FormGroup;
	listId = 0;
	disableSms = false;

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public ref: MatDialogRef<EstSignFormDialog>,
		public service: ListService,
		private errors: ErrorsDialog,
	) {
		this.form = fb.group({
			listId: ["", Validators.required],
			code: ["", Validators.required],
		});

		this.listId = data.listId;

		this.form.patchValue({
			listId: data.listId,
		});


	}


	ok(): void {
		this.service.signList(this.form.value)
			.subscribe(response => {
				if (response.result == 'yes') this.ref.close(true);
				else this.errors.show(response);
			});
	}

	sendSms(): void {
		this.service.sendSmsForListSign(this.form.value)
			.subscribe(response => {
				this.disableSms = true;
				if (response.result == 'no') this.errors.show(response);
			});
	}

	cancel(): void {
		this.ref.close(false);
	}

	smsProgress: any = 0;
	smsTime = 0;
	smsTimer = null;


	// startInterval() {
	// 	this.smsTimer = setInterval(() => {
	// 		if (this.smsProgress >= 100) {
	// 			this.clear();
	// 			this.disableSms = false;
	// 			return;
	// 		}
	// 		this.smsTime += 0.2;
	// 		if (this.smsTime > 0) {
	// 			this.smsProgress = this.round(this.smsTime / 60 * 100, 2);
	// 		}
	// 	}, 200);
	// }

	clear() {
		try {
			clearInterval(this.smsTimer);
			this.smsTime = 0;
			this.smsProgress = 0;
		} catch (e) {
		}
	}

	round(a: number, b: number) {
		return parseFloat(Number(this.toInt(a)).toFixed(b));
	}


	toInt(a: any) {
		let b: any = parseFloat(a);
		if (b + "" == "NaN") {
			return 0;
		} else {
			return b;
		}
	};

}
