import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../core/auth/auth.service";
import {PermissionService} from "../../../core/services/permission.service";
import {JwtHelperService} from "@auth0/angular-jwt";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: "auth-dialog",
	templateUrl: "./auth-dialog.html"
})
export class AuthDialog implements OnInit {

	form: FormGroup;

	constructor(
		private ref: MatDialogRef<AuthDialog>,
		private fb: FormBuilder,
		private auth: AuthService,
		private permissionService: PermissionService
	) {
		this.form = fb.group({
			email: ["", Validators.required],
			password: ["", Validators.required]
		});
	}

	submit() {
		this.auth.tryAuth(this.form.value).then(res => {
			if (res.res == 'yes') {
				this.permissionService.initPermissions();
				const token = localStorage.getItem("token");
				if (token !== null && typeof token !== "undefined" && token != "") {
					const helper = new JwtHelperService();
					const decodedToken = helper.decodeToken(token);
					/*
					if (typeof decodedToken.id !== "undefined") {
						if (decodedToken.guard == "prof") {
							// noinspection JSIgnoredPromiseFromCall

							return;
						}
					}
					*/
					this.ref.close();
				}
			} else {
				alert("username or password is incorrect");
			}
		});
	}

	ngOnInit(): void {
		this.form.patchValue({
			email: "",
			password: ""
		});
	}

}