import {Component} from "@angular/core";

@Component({
	selector: 'app-chat',
	templateUrl: './chat.html',
	styleUrls: ['./chat.scss']
})
export class Chat {
	constructor() {
	}
}