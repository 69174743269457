import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ListResponse} from "../../pages/lists/list/list.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class RawListService {

	constructor(
		private http: HttpClient
	) {
	}

	getLists(filter: any, page: number, limit: number): Observable<ListResponse> {
		return this.http.post<ListResponse>("/lists/getLists/?page="+page+"&limit="+limit, filter);
	}

}