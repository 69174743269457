import {EventEmitter, Injectable} from '@angular/core';
import {Settings} from './app.settings.model';
import {AuthModel} from "./core/models/auth.model";
import {MatSidenav} from "@angular/material/sidenav";

@Injectable()
export class AppSettings {

	public apiUrl = "/api";
	public storage = "";
	public settings : Settings;
	public sideNavElement: MatSidenav = null;
	onChange: EventEmitter<any> = new EventEmitter<any>();

	constructor(public auth: AuthModel) {
		this.settings = new Settings(
			'eLearning',   //theme name
			true,       //loadingSpinner
			true,       //fixedHeader
			true,       //sidenavIsOpened
			true,       //sidenavIsPinned
			true,       //sidenavUserBlock
			'vertical', //horizontal , vertical
			'default',  //default, compact, mini
			'indigo-light',   //indigo-light, teal-light, red-light, blue-dark, green-dark, pink-dark
			false,       // true = rtl, false = ltr
			true,        // true = has footer, false = no footer
			this.auth.data
		);
		if ((window.location.href + "").indexOf("localhost") != -1) {
			//this.apiUrl = "http://localhost:8000";
			this.storage = "http://localhost:8000";
		}
	}

}

