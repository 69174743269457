import {AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {AppSettings} from '../app.settings';
import {Settings} from '../app.settings.model';
import {MenuService} from '../theme/components/menu/menu.service';
import {UserService} from "../core/services/user.service";
import {AppService} from "../app.service";
import {MatDialog} from "@angular/material/dialog";
import {ProfStatementDialog} from "../core/dialogs/prof-statement/prof-statement.dialog";

declare let moment: any;

@Component({
	selector: 'app-pages',
	templateUrl: './pages.component.html',
	styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, AfterViewInit {


	@ViewChild('sidenav', {static: false}) sidenav: any;
	@ViewChild('backToTop') backToTop: any;
	@ViewChild('mainSidenavContent') mainSidenavContent: any;
	@ViewChild('mainContent') mainContent: ElementRef;

	public settings: Settings;
	// noinspection JSUnusedGlobalSymbols
	public menus = ['vertical', 'horizontal'];
	public menuOption: string;
	// noinspection JSUnusedGlobalSymbols
	public menuTypes = ['default', 'compact', 'mini'];
	public menuTypeOption: string;
	public isStickyMenu = false;
	public lastScrollTop = 0;
	public showBackToTop = false;
	// noinspection JSUnusedGlobalSymbols
	public toggleSearchBar = false;
	public scrolledContent: any;
	private defaultMenu: string; //declared for return default menu when window resize
	private askProf = false;

	constructor(
		public appSettings: AppSettings,
		public router: Router,
		private menuService: MenuService,
		public userService: UserService,
		public appService: AppService,
		public dialog: MatDialog
	) {
		this.settings = this.appSettings.settings;
		this.userService.onChange.subscribe(() => {
			if (userService.is_logged && userService.user) {
				this.checkProfStatement();
			}
		});
	}

	checkProfStatement() {
		if (!this.askProf && this.userService.user.guard == 'prof') {
			this.askProf = true;
			this.userService.checkProfStatement().subscribe(({isChecked}: any) => {
				if (!isChecked) {
					this.dialog.open(ProfStatementDialog, {
						width: '500px',
						data: {
							user: this.userService.user
						}
					});
				}
			});
		}
	}

	timeFormat(s) {
		if (s <= 0) return '';
		return moment("2015-01-01").startOf('day')
			.seconds(s)
			.format('mm:ss');
	}

	public onPageScroll(event: any) {
		//(event.target.scrollTop > 300) ? this.backToTop.nativeElement.style.display = 'flex' : this.backToTop.nativeElement.style.display = 'none';
		if (this.settings.menu == 'horizontal') {
			if (this.settings.fixedHeader) {
				var currentScrollTop = (event.target.scrollTop > 56) ? event.target.scrollTop : 0;
				if (currentScrollTop > this.lastScrollTop) {
					document.querySelector('#horizontal-menu')!.classList.add('sticky');
					event.target.classList.add('horizontal-menu-hidden');
				} else {
					document.querySelector('#horizontal-menu')!.classList.remove('sticky');
					event.target.classList.remove('horizontal-menu-hidden');
				}
				this.lastScrollTop = currentScrollTop;
			} else {
				if (event.target.scrollTop > 56) {
					document.querySelector('#horizontal-menu')!.classList.add('sticky');
					event.target.classList.add('horizontal-menu-hidden');
				} else {
					document.querySelector('#horizontal-menu')!.classList.remove('sticky');
					event.target.classList.remove('horizontal-menu-hidden');
				}
			}
		}
	}

	ngOnInit() {
		if (window.innerWidth <= 768) {
			this.settings.menu = 'vertical';
			this.settings.sidenavIsOpened = false;
			this.settings.sidenavIsPinned = false;
		}
		this.menuOption = this.settings.menu;
		this.menuTypeOption = this.settings.menuType;
		this.defaultMenu = this.settings.menu;

		setTimeout(() => {
			this.settings.loadingSpinner = false;
		}, 300);
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (!this.settings.sidenavIsPinned) {
					this.sidenav.close();
				}
				if (window.innerWidth <= 768) {
					this.sidenav.close();
				}
			}
		});
		if (this.settings.menu == "vertical") {
			this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
		}
	}

	// noinspection JSUnusedGlobalSymbols
	public chooseMenu() {
		this.settings.menu = this.menuOption;
		this.defaultMenu = this.menuOption;
		// noinspection JSIgnoredPromiseFromCall
		this.router.navigate(['/']);
	}

	// noinspection JSUnusedGlobalSymbols
	public chooseMenuType() {
		this.settings.menuType = this.menuTypeOption;
	}

	// noinspection JSUnusedGlobalSymbols
	public changeTheme(theme) {
		this.settings.theme = theme;
	}

	public toggleSidenav() {
		this.sidenav.toggle();
	}

	@HostListener('window:resize')
	public onWindowResize(): void {
		if (window.innerWidth <= 768) {
			this.settings.sidenavIsOpened = false;
			this.settings.sidenavIsPinned = false;
			this.settings.menu = 'vertical';
		} else {
			(this.defaultMenu == 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical';
			this.settings.sidenavIsOpened = true;
			this.settings.sidenavIsPinned = true;
		}
	}

	public closeSubMenus() {
		const menu = document.querySelector(".sidenav-menu-outer");
		if (menu) {
			for (let i = 0; i < menu.children[0].children.length; i++) {
				const child = menu.children[0].children[i];
				if (child) {
					if (child.children[0].classList.contains('expanded')) {
						child.children[0].classList.remove('expanded');
						child.children[1].classList.remove('show');
					}
				}
			}
		}
	}

	goToSavedDevices(): void {
		this.router.navigate(['/my-devices'])
			.then(m => {

			});
	}

	ngAfterViewInit(): void {
		this.appSettings.sideNavElement = this.sidenav;
		this.appSettings.onChange.emit("setSideNav");
	}
}
