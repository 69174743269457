import {Component} from "@angular/core";

@Component({
    selector: "testing-component",
    templateUrl: "./testing.component.html",
    styleUrls: ["./testing.component.scss"]
})
export class TestingComponent {

}
