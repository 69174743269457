import {Component, Inject, OnChanges, OnInit, SimpleChanges, ViewChild} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SlickCarouselComponent} from "ngx-slick-carousel";


@Component({
	  selector: "image-slider-dialog",
	  templateUrl: "./image-slider-dialog.html",
	  styleUrls: ["./image-slider-dialog.scss"]
})
export class ImageSliderDialogComponent implements OnChanges, OnInit {

	slides: any[] = [];
	index = 0;
	slideConfig = {
		slidesToShow: 1,
		slidesToScroll: 1,
		autoPlay: false,
		autoplay: false,
		infinity: false,
		infinite: false,
	};
	@ViewChild('slickModal') slickModal: SlickCarouselComponent;

	constructor(
		private dialogRef: MatDialogRef<ImageSliderDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		this.slides = (data.slides || []).map(url => this.safeUrl(url));
		this.index = data.index;
	}

	ngOnChanges(changes: SimpleChanges) {
		if(changes.imageObject) {
			this.slides = (changes.imageObject.currentValue || []).map(url => this.safeUrl(url));
		}
	}

	parseUrl(url: string) {
		const parsedUrl = new URL(url);
		return {
			protocol: parsedUrl.protocol,
			hostname: parsedUrl.hostname,
			port: parsedUrl.port,
			pathname: parsedUrl.pathname,
			search: parsedUrl.search,
			hash: parsedUrl.hash,
		};
	}

	safeUrl(url: string): string {
		const info = this.parseUrl(url);
		const pathArr = info.pathname.split('/');
		const filename = encodeURIComponent(pathArr.pop() || '');
		const path = pathArr.join('/') + '/';
		return `${info.protocol}//${info.hostname}${info.port ? ':' + info.port : ''}${path}${filename}${info.search}${info.hash}`;
	}

	slickInit(e) {
		e.slick.slickGoTo(this.index);
		e.slick.autoPlay(false);
		e.slick.slickPlay();
	}

	ngOnInit(): void {

	}

}
